<mat-dialog-content>
  <div class="text-black dark:!text-white">
    <h4 class="font-bold text-center mt-3 mb-3">
      Something went wrong...
    </h4>
    <p class="text-center">An internal error occurred. Please try agian. If the problem persists, please <a class="text-blue-500 hover:text-blue-400" href="https://docs.google.com/forms/d/11IhpNcRGyDtNDrSciC5F8E0zjzERxfmzMjR8LjIUz_A/viewform?edit_requested=true" target="_blank">contact us.</a></p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="w-full flex justify-end items-end">
    <button (click)="closeErrorMessage()" mat-button class="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded">Close</button>
  </div>
</mat-dialog-actions>
