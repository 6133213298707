<div class="wrapper mt-3" *ngIf="state$ | async as state">
  <app-image [imgSrc]="state.imgSrc" class="w-full mb-2"></app-image>
  <app-progress></app-progress>
  <app-guesses></app-guesses>
  <br />
</div>

<!-- <pre>
    {{ state$ | async | json }}
</pre> -->
