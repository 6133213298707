<div class="mt-3 text-black dark:!text-white" *ngIf="userDetails$ | async as user">
  <a routerLink="/" class="text-blue-500 hover:text-blue-400 cursor-pointer">&#8592; Back</a>
  <div class="mt-3">
    <h2 class="text-xl font-bold">Your account</h2>
    <div *ngIf="!user.loggedIn">
      <p class="mt-3">Create a <a href="https://teuteuf.fr" class="text-blue-500 hover:text-blue-400 cursor-pointer"
          target="_blank">Teuteuf Games</a> or log in to start saving your gaming statistics. Want to know the benefits?
        <a href="https://account.teuteuf.fr/benefits" class="text-blue-500 hover:text-blue-400 cursor-pointer">Discover
          more here</a>.
      </p>
      <div class="flex gap-2 mt-4">
        <button (click)="navigate('sign-up')"
          class="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded">Sign up</button>
        <button (click)="navigate('login')"
          class="border border-slate-400 text-black dark:!text-white font-bold py-2 px-4 rounded">Login</button>
      </div>
    </div>
    <div *ngIf="user.loggedIn">
      <p class="mt-3">Hi {{ user.firstName}} 👋</p>
      <p class="mt-2">We now store your stats after each game. This not only safeguards your progress but also enables
        seamless gameplay across multiple devices.</p>
      <p><a href="https://account.teuteuf.fr/benefits" class="text-blue-500 hover:text-blue-400 cursor-pointer">Learn
          more</a>.</p>

      <div class="archive-bg shadow-md rounded-md p-3 mt-4 text-black">
        <h5 class="font-bold">Want to play previous games?</h5>
        <p class="mt-2">Want to play previous games of WhereTaken? All the way back to the very first game?</p>
        <div class="flex gap-1 flex-col md:flex-row">
          <button routerLink="/archive"
            class="border bg-neutral-100 border-black rounded-md text-black font-bold py-2 px-4 shadow-md mt-2">Play the
            archive</button>
          <button (click)="replayTodaysGame()"
            class="border bg-neutral-100 border-black rounded-md text-black font-bold py-2 px-4 shadow-md mt-2">Replay
            today's game</button>
        </div>
      </div>

      <div class="flex gap-2 mt-4 items-center justify-center">
        <button (click)="signOut()" class="bg-blue-600 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded">Sign
          out</button>
        <button (click)="navigate('preferences')"
          class="border border-slate-400 text-black dark:!text-white font-bold py-2 px-4 rounded">Manage
          account</button>
      </div>
    </div>
  </div>
</div>
