import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDetails } from 'src/app/interfaces/UserDetails';
import { ArchiveService } from 'src/app/services/archive.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {

  userDetails$: Observable<UserDetails>

  constructor(private user: UserService, private archive: ArchiveService) {
    this.userDetails$ = this.user.getUserDetails();
  }

  signOut() {
    this.user.signOut();
  }

  replayTodaysGame() {
    this.archive.replayTodaysGame();
  }

  navigate(page: string) {
    if(page === 'sign-up') {
      window.location.href = 'https://account.teuteuf.fr/sign-up';
    } else if(page === 'login') {
      window.location.href = 'https://account.teuteuf.fr/login';
    } else if(page === 'preferences') {
      window.location.href = 'https://account.teuteuf.fr/';
    }
  }
}
