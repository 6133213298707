import { Country } from '../interfaces/interfaces';

export const countries: Country[] = [
  {
    "name": "Afghanistan",
    "code": "AF",
    "capital": "Kabul",
    "lat": 33,
    "long": 65,
    "cities": [
      "Jalalabad",
      "Kabul",
      "Herat",
      "Ghazni",
      "Kunduz",
      "Kandahar"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "Buddhas of Bamiyan",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/TLKCx6mVIHo",
          "wikiLink": "https://en.wikipedia.org/wiki/Buddhas_of_Bamiyan"
        },
        "landmark": {
          "name": "Hazrat Ali Mazar",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Hazrat_Ali_Mazar#/media/File:Blue_Mosque_in_the_northern_Afghan_city_in_2012.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Hazrat_Ali_Mazar",
          "hasLandmark": true
        },
        "city": {
          "name": "Kandahar",
          "photographer": "",
          "imageLink": "https://pixnio.com/miscellaneous/laying-asphalt-over-culvets-on-kabul-kandahar-road",
          "wikiLink": "https://en.wikipedia.org/wiki/Kandahar"
        },
        "landmarksRound": [
          {
            "code": "BA",
            "number": 1
          },
          {
            "code": "GT",
            "number": 1
          },
          {
            "code": "PF",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Belem Tower",
          "House of the Black Heads",
          "Grytviken Church",
          "Fort Sao Sebastiao",
          "Sky Tower",
          "CN Tower",
          "Kim Il Sung Square"
        ],
        "uniqueId": "NJFsp2rM",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/afghanistan-houses-homes-buildings-79493/",
          "wikiLink": "https://en.wikipedia.org/wiki/Afghanistan"
        },
        "landmark": {
          "name": "Herat Citadel",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Herat_Citadel#/media/File:View_of_Herat_Citadel_from_atop_the_premises.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Herat_Citadel",
          "hasLandmark": true
        },
        "city": {
          "name": "Jalalabad",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Jalalabad#/media/File:Aerial_view_of_Jalalabad_in_2012.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Jalalabad"
        },
        "landmarksRound": [
          {
            "code": "GH",
            "number": 2
          },
          {
            "code": "CD",
            "number": 2
          },
          {
            "code": "RO",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "St. Tryphon's Cathedral",
          "Saqsayhuaman",
          "Seven Colored Earths",
          "Trevi Fountain",
          "Pigeon Rocks",
          "Castillo San Cristobal",
          "Monteverde Cloud Forest"
        ],
        "uniqueId": "Jlgcp5qR",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Aland Islands",
    "code": "AX",
    "capital": "Mariehamn",
    "lat": 60.116667,
    "long": 19.9,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Kumlinge,_%C3%85land_Islands_-_panoramio_%289%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kumlinge"
        },
        "landmark": {
          "name": "Kastelholm Castle",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Kastelholm_Castle#/media/File:KasterholmsCastel.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kastelholm_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Mariehamn",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mariehamn#/media/File:Mariehamn_harbor_5.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mariehamn"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "l3VCCR3D",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Sea_landscape,_%C3%85land.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/%C3%85land"
        },
        "landmark": {
          "name": "Musemship Pommern",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Pommern,_%C3%85land_Maritime_Museum,_2019_%2801%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Museum_ship",
          "hasLandmark": false
        },
        "city": {
          "name": "Sund",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Sund,_%C3%85land#/media/File:Haraldsby,_%C3%85land.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Sund,_%C3%85land"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "2kYHbxyZ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Albania",
    "code": "AL",
    "capital": "Tirana",
    "lat": 41,
    "long": 20,
    "cities": [
      "Durres",
      "Sarande",
      "Kruje",
      "Elbasan",
      "Tirana",
      "Berat"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/mUATQHolSMg",
          "wikiLink": "https://en.wikipedia.org/wiki/Rozafa_Castle"
        },
        "landmark": {
          "name": "The Blue Eye",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Blue_Eye,_Albania,_2017_01.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Blue_Eye,_Albania",
          "hasLandmark": true
        },
        "city": {
          "name": "Berat",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/berat-albania-balkan-mangalem-old-3707970/",
          "wikiLink": "https://en.wikipedia.org/wiki/Berat"
        },
        "landmarksRound": [
          {
            "code": "BR",
            "number": 1
          },
          {
            "code": "TH",
            "number": 2
          },
          {
            "code": "MG",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Monastery of St. Naum",
          "Galle Fort",
          "Machu Picchu",
          "South Pole (ceremonial)",
          "Tegucigalpa Cathedral",
          "Vianden Castle",
          "Fort Christian"
        ],
        "uniqueId": "rWjwfjvt",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cruise-cruise-ship-sea-water-3703999/",
          "wikiLink": "https://en.wikipedia.org/wiki/Albania"
        },
        "landmark": {
          "name": "Berat Castle",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Berat_Castle#/media/File:Berat_-_Festung_2a_Haupttor.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Berat_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Durres",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-sunset-albania-city-lights-6577142/",
          "wikiLink": "https://en.wikipedia.org/wiki/Durr%C3%ABs"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Pzz8k7k4",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Algeria",
    "code": "DZ",
    "capital": "Algiers",
    "lat": 28,
    "long": 3,
    "cities": [
      "Constantine",
      "Oran",
      "Algiers",
      "Setif",
      "Annaba",
      "Batna"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/-sOS_rLimpQ",
          "wikiLink": "https://en.wikipedia.org/wiki/Constantine,_Algeria"
        },
        "landmark": {
          "name": "Timgad",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/timgad-batna-algeria-3079403/",
          "wikiLink": "https://en.wikipedia.org/wiki/Timgad",
          "hasLandmark": true
        },
        "city": {
          "name": "Constantine",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bridge-elevator-constantine-algeria-3583955/",
          "wikiLink": "https://en.wikipedia.org/wiki/Constantine,_Algeria"
        },
        "landmarksRound": [
          {
            "code": "GQ",
            "number": 1
          },
          {
            "code": "KP",
            "number": 1
          },
          {
            "code": "DE",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Kittiwake Shipwreck",
          "Gyeongbukgong Palace",
          "Soroca Fort",
          "",
          "Big Ben",
          "Monteverde Cloud Forest",
          "Arahoho Blowhole"
        ],
        "uniqueId": "MzkLbQXq",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/algeria-hoggar-assekrem-volcano-673777/",
          "wikiLink": "https://en.wikipedia.org/wiki/Algeria"
        },
        "landmark": {
          "name": "Notre-Dame d'Afrique",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Notre-Dame_d%27Afrique#/media/File:Notre_Dame_d'Afrique.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Notre-Dame_d%27Afrique",
          "hasLandmark": true
        },
        "city": {
          "name": "Oran",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/architecture-travel-sky-city-3200537/",
          "wikiLink": "https://en.wikipedia.org/wiki/Oran"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "l5RhgCB6",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "American Samoa",
    "code": "AS",
    "capital": "Pago Pago",
    "lat": -14.33333333,
    "long": -170,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:American_Samoa_tramway_remains.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/American_Samoa"
        },
        "landmark": {
          "name": "Alega Beach",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Alega#/media/File:Beach_near_Tisa's_Barefoot_Bar_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Alega",
          "hasLandmark": true
        },
        "city": {
          "name": "Pago Pago",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Pago_Pago#/media/File:Pagopago_amerika_s%C4%81moa.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Pago_Pago"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "jF2W39pz",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Vatia_from_the_National_Park_of_American_Samoa.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/National_Park_of_American_Samoa"
        },
        "landmark": {
          "name": "Mount Alava",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mount_%CA%BBAlava#/media/File:Mount_'Alava.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mount_%CA%BBAlava",
          "hasLandmark": true
        },
        "city": {
          "name": "Tafuna",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Pago_Pago_International_Airport_from_A%27oloau.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tafuna,_American_Samoa"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "v5LHMr3l",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Andorra",
    "code": "AD",
    "capital": "Andorra la Vella",
    "lat": 42.5,
    "long": 1.5,
    "cities": [
      "Andorra la Vella",
      "Encamp",
      "La Massana",
      "Escaldes-Engordany",
      "Ordino",
      "Canillo"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/3gYKw3uAOwU",
          "wikiLink": "https://en.wikipedia.org/wiki/Andorra_la_Vella"
        },
        "landmark": {
          "name": "Grandvalira",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Grandvalira_ski_resort,_Andorra4.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Soldeu",
          "hasLandmark": true
        },
        "city": {
          "name": "Encamp",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Encamp#/media/File:Vista_d'Encamp_-_Andorra.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Encamp"
        },
        "landmarksRound": [
          {
            "code": "BB",
            "number": 2
          },
          {
            "code": "ET",
            "number": 1
          },
          {
            "code": "TH",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Cathedral of Brasilica",
          "Chinguetti",
          "Marina Bay Sands",
          "George Washington House",
          "Panfilov Park",
          "Christ of Havana",
          "Heydar Aliyev Center"
        ],
        "uniqueId": "m88gdlQg",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/andorra-pyrenees-mountains-3501173/",
          "wikiLink": "https://en.wikipedia.org/wiki/Pyrenees"
        },
        "landmark": {
          "name": "La Noblesse Du Temps",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:%D0%A1%D0%BA%D1%83%D0%BB%D1%8C%D0%BF%D1%82%D1%83%D1%80%D0%B0_%D0%A1%D0%B0%D0%BB%D1%8C%D0%B2%D0%B0%D0%B4%D0%BE%D1%80%D0%B0_%D0%94%D0%B0%D0%BB%D0%B8_%C2%AB%D0%91%D0%BB%D0%B0%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE_%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B8%C2%BB_-_panoramio.jpg",
          "wikiLink": "https://www.inyourpocket.com/andorra-la-vella/la-noblesse-du-temps_160690v",
          "hasLandmark": false
        },
        "city": {
          "name": "Andorra la Vella",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/la-vella-andorra-mountains-pyrenees-2196560/",
          "wikiLink": "https://en.wikipedia.org/wiki/Andorra_la_Vella"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "PW2hhzTQ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Angola",
    "code": "AO",
    "capital": "Luanda",
    "lat": -12.5,
    "long": 18.5,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/Tri_u7qeqGw",
          "wikiLink": "https://en.wikipedia.org/wiki/Muxima"
        },
        "landmark": {
          "name": "Fortress of São Miguel",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Luanda-SMiguelFort1.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Fortress_of_S%C3%A3o_Miguel",
          "hasLandmark": true
        },
        "city": {
          "name": "Luanda",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/angola-luanda-africa-5139571/",
          "wikiLink": "https://en.wikipedia.org/wiki/Luanda"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "3QTSt3Wn",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/angola-landscape-sierra-da-leba-509598/",
          "wikiLink": "https://en.wikipedia.org/wiki/Angola"
        },
        "landmark": {
          "name": "Kalandula Falls",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Kalandula_Falls#/media/File:Kalandula_waterfalls_of_the_Lucala-River_in_Malange,_Angola.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Kalandula_Falls",
          "hasLandmark": true
        },
        "city": {
          "name": "Lubango",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Lubango_sight_%28cropped%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Lubango"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "pXbcQsBB",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Anguilla",
    "code": "AI",
    "capital": "The Valley",
    "lat": 18.25,
    "long": -63.16666666,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/mmGdZmFvnrE",
          "wikiLink": "https://en.wikipedia.org/wiki/Anguilla"
        },
        "landmark": {
          "name": "Shoal Bay",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Anguilla_Shoal_Bay_is_the_BEST_beach_in_the_Caribbean._-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Geography_of_Anguilla",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "WstYksH8",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bay-beach-jetty-boat-anguilla-7233153/",
          "wikiLink": "https://en.wikipedia.org/wiki/Anguilla"
        },
        "landmark": {
          "name": "Little Bay",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/aturkus/2246044292",
          "wikiLink": "https://en.wikipedia.org/wiki/Geography_of_Anguilla",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "yrRSZm4l",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Antarctica",
    "code": "AQ",
    "capital": "Antarctica",
    "lat": -75.250973,
    "long": -0.071389,
    "cities": [
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/LysuRGQjMwo",
          "wikiLink": "https://en.wikipedia.org/wiki/Antarctica"
        },
        "landmark": {
          "name": "South Pole (ceremonial)",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/South_Pole#/media/File:Ceremonial_South_Pole.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/South_Pole",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [
          {
            "code": "MA",
            "number": 1
          },
          {
            "code": "BZ",
            "number": 2
          },
          {
            "code": "BE",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Animal Flower Cave",
          "Villa Folio",
          "Tikal National Park",
          "Machu Picchu",
          "Pamir Mountains",
          "Herat Citadel",
          "Szechenyi Thermal Bath"
        ],
        "uniqueId": "CjRyN2lF",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/iceberg-antarctica-polar-ice-sea-404966/",
          "wikiLink": "https://en.wikipedia.org/wiki/Antarctica"
        },
        "landmark": {
          "name": "Deception Island",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:1_Deception_Island.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Deception_Island",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [
          {
            "code": "IN",
            "number": 2
          },
          {
            "code": "AZ",
            "number": 2
          },
          {
            "code": "YE",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Sao Filipe Royale Fortress",
          "Lotto World",
          "Great Blue Hole",
          "Christiansborg Palace",
          "Aleppo Citadel",
          "Trevi Fountain",
          "Devin Castle"
        ],
        "uniqueId": "3Jq3yzVB",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Antigua and Barbuda",
    "code": "AG",
    "capital": "Saint John's",
    "lat": 17.05,
    "long": -61.8,
    "cities": [
      "Saint John's",
      "Bolands",
      "Potters Village",
      "All Saints",
      "Piggotts",
      "Swetes"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/9CJTrF-HnlU",
          "wikiLink": "https://en.wikipedia.org/wiki/Antigua_and_Barbuda"
        },
        "landmark": {
          "name": "English Harbour",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Antigua_and_Barbuda,_English_Harbour_%2824%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/English_Harbour",
          "hasLandmark": true
        },
        "city": {
          "name": "Saint John's",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/St._John%27s,_Antigua_and_Barbuda#/media/File:St_John's,_Antigua_and_Barbuda_-_panoramio_(11).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/St._John%27s,_Antigua_and_Barbuda"
        },
        "landmarksRound": [
          {
            "code": "TR",
            "number": 1
          },
          {
            "code": "SA",
            "number": 2
          },
          {
            "code": "BR",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Great Blue Hole",
          "Arc de Triomphe",
          "Alhambra",
          "Sky Tower",
          "Somapura Mahavihara",
          "Registan Square",
          "George Washington House"
        ],
        "uniqueId": "ghJqrqJN",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/devil-s-bridge-sea-barbuda-antigua-7155984/",
          "wikiLink": "https://en.wikipedia.org/wiki/Devil%27s_Bridge,_Antigua_and_Barbuda"
        },
        "landmark": {
          "name": "Devil's Bridge",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Devils_Bridge,_Antigua.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Devil%27s_Bridge,_Antigua_and_Barbuda",
          "hasLandmark": true
        },
        "city": {
          "name": "Bolands",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bolands#/media/File:Antigua_-_Bolands_%E2%80%93_Jennings_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bolands"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "qwPlSz3b",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Argentina",
    "code": "AR",
    "capital": "Buenos Aires",
    "lat": -34,
    "long": -64,
    "cities": [
      "Cordoba",
      "Rosario",
      "Buenos Aires",
      "La Plata",
      "Merlo",
      "La Rioja"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/HGMYZ4scFkY",
          "wikiLink": "https://en.wikipedia.org/wiki/La_Boca"
        },
        "landmark": {
          "name": "Buenos Aires Obelisk",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:ObeliscoBA2017.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Obelisco_de_Buenos_Aires",
          "hasLandmark": true
        },
        "city": {
          "name": "Cordoba",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/argentina-city-urban-architecture-7111640/",
          "wikiLink": "https://en.wikipedia.org/wiki/C%C3%B3rdoba,_Argentina"
        },
        "landmarksRound": [
          {
            "code": "MO",
            "number": 1
          },
          {
            "code": "IS",
            "number": 2
          },
          {
            "code": "AQ",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "The Blue Eye",
          "Taj Mahal",
          "Mir Castle",
          "Chillon Castle",
          "Maiden Tower",
          "Senso-ji",
          "Sistine Chapel"
        ],
        "uniqueId": "Lv685Mwz",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/landscape-truck-andes-dessert-74572/",
          "wikiLink": "https://en.wikipedia.org/wiki/Andes"
        },
        "landmark": {
          "name": "Floralis Generica",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Floralis_Generica.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Floralis_Gen%C3%A9rica",
          "hasLandmark": true
        },
        "city": {
          "name": "Rosario",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Rosario,_Santa_Fe#/media/File:Rosario_National_Flag_Memorial_View-20110510-RM-144455.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Rosario,_Santa_Fe"
        },
        "landmarksRound": [
          {
            "code": "LS",
            "number": 1
          },
          {
            "code": "IM",
            "number": 1
          },
          {
            "code": "SG",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Blue Grotto",
          "Pigeon Rocks",
          "Charles Bridge",
          "Trevi Fountain",
          "Amber Palace",
          "Grand Ducal Palace",
          "Great Blue Hole"
        ],
        "uniqueId": "Xj59mp6C",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Armenia",
    "code": "AM",
    "capital": "Yerevan",
    "lat": 40,
    "long": 45,
    "cities": [
      "Gyumri",
      "Yerevan",
      "Vanadzor",
      "Abovyan",
      "Armavir",
      "Kapan"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/SmueverK-sg",
          "wikiLink": "https://en.wikipedia.org/wiki/Temple_of_Garni"
        },
        "landmark": {
          "name": "Noravank Monastery",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Noravank#/media/File:Noravank12.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Noravank",
          "hasLandmark": true
        },
        "city": {
          "name": "Gyumri",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Gyumri#/media/File:View_over_Gyumri_rooftops_to_Mount_Aragats.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Gyumri"
        },
        "landmarksRound": [
          {
            "code": "SJ",
            "number": 1
          },
          {
            "code": "AE",
            "number": 2
          },
          {
            "code": "HN",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Macau Tower",
          "Monteverde Cloud Forest",
          "Baalbek",
          "Ulu Temburong National Park",
          "Castillo San Cristobal",
          "CN Tower",
          "Chillon Castle"
        ],
        "uniqueId": "7CyYfl7x",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/armenia-yerevan-ararat-city-3721418/",
          "wikiLink": "https://en.wikipedia.org/wiki/Yerevan"
        },
        "landmark": {
          "name": "Amberd",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Amberd#/media/File:Amberd_2015.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Amberd",
          "hasLandmark": true
        },
        "city": {
          "name": "Yerevan",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/armenia-yerevan-ararat-city-3721418/",
          "wikiLink": "https://en.wikipedia.org/wiki/Yerevan"
        },
        "landmarksRound": [
          {
            "code": "PL",
            "number": 2
          },
          {
            "code": "MO",
            "number": 1
          },
          {
            "code": "MZ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Karnak",
          "Bran Castle",
          "Kasubi Royal Tombs",
          "Queen Emma Bridge",
          "Middle of the World City",
          "Ostrog Monastery",
          "Timgad"
        ],
        "uniqueId": "vMKGwKVJ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Aruba",
    "code": "AW",
    "capital": "Oranjestad",
    "lat": 12.5,
    "long": -69.96666666,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/Ui99Hiv3Ezg",
          "wikiLink": "https://en.wikipedia.org/wiki/Palm_Beach,_Aruba"
        },
        "landmark": {
          "name": "California Lighthouse",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/California_Lighthouse#/media/File:CaliforniaLight.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/California_Lighthouse",
          "hasLandmark": true
        },
        "city": {
          "name": "Oranjestad",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/aruba-oranjestad-caribbean-antilles-4881217/",
          "wikiLink": "https://en.wikipedia.org/wiki/Oranjestad,_Aruba"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "2gbyk5Hf",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/beach-aruba-sandy-beach-sea-26011/",
          "wikiLink": "https://en.wikipedia.org/wiki/Aruba"
        },
        "landmark": {
          "name": "Natural Pool",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Natural_Pool_(Aruba)#/media/File:Aruba-Natural-Pool-2013.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Natural_Pool_(Aruba)",
          "hasLandmark": true
        },
        "city": {
          "name": "Noord",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Berea_di_Piscado,_Noord,_Aruba_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Noord"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "BVtGT2bn",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Australia",
    "code": "AU",
    "capital": "Canberra",
    "lat": -27,
    "long": 133,
    "cities": [
      "Perth",
      "Brisbane",
      "Canberra",
      "Sydney",
      "Melbourne",
      "Adelaide"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/WEtXkeIlMoM",
          "wikiLink": "https://en.wikipedia.org/wiki/Uluru"
        },
        "landmark": {
          "name": "Sydney Opera House",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sydney-opera-house-building-363244/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sydney_Opera_House",
          "hasLandmark": true
        },
        "city": {
          "name": "Perth",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/perth-western-australia-city-1368337/",
          "wikiLink": "https://en.wikipedia.org/wiki/Perth"
        },
        "landmarksRound": [
          {
            "code": "PF",
            "number": 1
          },
          {
            "code": "NL",
            "number": 2
          },
          {
            "code": "GQ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Kaaba",
          "Chichen Itza",
          "Pyramids of Meroe",
          "Fort Fincastle",
          "Stonehenge",
          "Hato Caves",
          "The Marble Mountains"
        ],
        "uniqueId": "xjmMwGPK",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/australia-uluru-ayers-rock-mountain-630219/",
          "wikiLink": "https://en.wikipedia.org/wiki/Uluru"
        },
        "landmark": {
          "name": "Twelve Apostles",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/twelve-apostles-12-apostles-302024/",
          "wikiLink": "https://en.wikipedia.org/wiki/Twelve_Apostles_Marine_National_Park",
          "hasLandmark": true
        },
        "city": {
          "name": "Brisbane",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/beach-city-southbank-swimmers-sand-1222080/",
          "wikiLink": "https://en.wikipedia.org/wiki/Brisbane"
        },
        "landmarksRound": [
          {
            "code": "CN",
            "number": 2
          },
          {
            "code": "TJ",
            "number": 1
          },
          {
            "code": "KN",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Tavarua",
          "Borobudur Temple",
          "St. Tryphon's Cathedral",
          "Ostrog Monastery",
          "Mount Kenya",
          "Bled Castle",
          "Grytviken Church"
        ],
        "uniqueId": "SbjWCgrr",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Austria",
    "code": "AT",
    "capital": "Vienna",
    "lat": 47.33333333,
    "long": 13.33333333,
    "cities": [
      "Vienna",
      "Salzburg",
      "Linz",
      "Innsbruck",
      "Graz",
      "Villach"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/3wFRlwS91yk",
          "wikiLink": "https://en.wikipedia.org/wiki/Hallstatt"
        },
        "landmark": {
          "name": "Hofburg Palace",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/vienna-hofburg-imperial-palace-5419044/",
          "wikiLink": "https://en.wikipedia.org/wiki/Hofburg",
          "hasLandmark": true
        },
        "city": {
          "name": "Vienna",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/panorama-vienna-austria-city-view-427929/",
          "wikiLink": "https://en.wikipedia.org/wiki/Vienna"
        },
        "landmarksRound": [
          {
            "code": "EG",
            "number": 2
          },
          {
            "code": "AD",
            "number": 1
          },
          {
            "code": "KE",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "The Blue Eye",
          "Liwonde National Park",
          "Salvo Palace",
          "Mount Yasur",
          "Hato Caves",
          "Golden Gate",
          "Chamarel Waterfall"
        ],
        "uniqueId": "n3GxRVdZ",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mountains-alps-trees-1244132/",
          "wikiLink": "https://en.wikipedia.org/wiki/Alps"
        },
        "landmark": {
          "name": "St. Stephen's Cathedral",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/vienna-st-stephen-s-cathedral-church-82261/",
          "wikiLink": "https://en.wikipedia.org/wiki/St._Stephen%27s_Cathedral,_Vienna",
          "hasLandmark": true
        },
        "city": {
          "name": "Salzburg",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/salzburg-city-night-lights-river-5964812/",
          "wikiLink": "https://en.wikipedia.org/wiki/Salzburg"
        },
        "landmarksRound": [
          {
            "code": "AU",
            "number": 1
          },
          {
            "code": "PY",
            "number": 1
          },
          {
            "code": "US",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Floralis Generica",
          "Roman theatre",
          "Rila Monastery",
          "Al Alam Palace",
          "Palace of Versailles",
          "Christiansborg Palace",
          "Tokyo Skytree"
        ],
        "uniqueId": "nXr6HTPP",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Azerbaijan",
    "code": "AZ",
    "capital": "Baku",
    "lat": 40.5,
    "long": 47.5,
    "cities": [
      "Baku",
      "Ganja",
      "Lankaran",
      "Mingrecevir",
      "Nakhchivan",
      "Sheki"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/wX_9ZhIErpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Flame_Towers"
        },
        "landmark": {
          "name": "Maiden Tower",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/maiden-tower-baku-azerbaijan-1997161/",
          "wikiLink": "https://en.wikipedia.org/wiki/Maiden_Tower_(Baku)",
          "hasLandmark": true
        },
        "city": {
          "name": "Baku",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/baku-azerbaijan-panorama-sunset-4626962/",
          "wikiLink": "https://en.wikipedia.org/wiki/Baku"
        },
        "landmarksRound": [
          {
            "code": "LU",
            "number": 1
          },
          {
            "code": "BG",
            "number": 2
          },
          {
            "code": "NP",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Herat Citadel",
          "Seven Colored Earths",
          "Trevi Fountain",
          "Tokyo Skytree",
          "Rila Monastery",
          "Grand Ducal Palace",
          "Old Dongola"
        ],
        "uniqueId": "mrKHBmW8",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/winter-azerbaijan-horse-landscape-2048629/",
          "wikiLink": "https://en.wikipedia.org/wiki/Azerbaijan"
        },
        "landmark": {
          "name": "Heydar Aliyev Center",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Heydar_Aliyev_Center#/media/File:Heydar_Aliyev_Cultural_Center.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Heydar_Aliyev_Center",
          "hasLandmark": true
        },
        "city": {
          "name": "Ganja",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ganja,_Azerbaijan#/media/File:Ganja_Azerbaijan.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ganja,_Azerbaijan"
        },
        "landmarksRound": [
          {
            "code": "PL",
            "number": 2
          },
          {
            "code": "BB",
            "number": 1
          },
          {
            "code": "BR",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Langkawi Sky Bridge",
          "Turku Castle",
          "Memorial de l'Anse",
          "Al Fateh Grand Mosque",
          "Wat Xiengthong",
          "Badshahi Mosque",
          "Tenaru Falls"
        ],
        "uniqueId": "StGvbLLV",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Bahamas",
    "code": "BS",
    "capital": "Nassau",
    "lat": 24.25,
    "long": -76,
    "cities": [
      "Freeport",
      "Marsh Harbour",
      "Dunmore Town",
      "George Town",
      "Coopers Town",
      "Nassau"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/xa-fv2RQCPY",
          "wikiLink": "https://en.wikipedia.org/wiki/Pig_Beach"
        },
        "landmark": {
          "name": "Queen's Staircase",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Queen%27s_Staircase_(Nassau)#/media/File:Queen's_staircase,_Nassau,_Bahamas.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Queen%27s_Staircase_(Nassau)",
          "hasLandmark": true
        },
        "city": {
          "name": "Nassau",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bahamas-lighthouse-caribbean-sea-783799/",
          "wikiLink": "https://en.wikipedia.org/wiki/Nassau,_Bahamas"
        },
        "landmarksRound": [
          {
            "code": "CY",
            "number": 1
          },
          {
            "code": "NO",
            "number": 1
          },
          {
            "code": "BY",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Gangtey Monastery",
          "Buenos Aires Obelisk",
          "Hato Caves",
          "Castle Cornet",
          "Azadi Tower",
          "Christ Church Cathedral",
          "Easter Island"
        ],
        "uniqueId": "K7HPd6wy",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bahamas-lighthouse-caribbean-sea-783799/",
          "wikiLink": "https://en.wikipedia.org/wiki/The_Bahamas"
        },
        "landmark": {
          "name": "Fort Fincastle",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Fort_Fincastle_(The_Bahamas)#/media/File:Fort_Fincastle.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Fort_Fincastle_(The_Bahamas)",
          "hasLandmark": true
        },
        "city": {
          "name": "Freeport",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Freeport,_Bahamas#/media/File:Lucayan_Harbor_JonWorth.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Freeport,_Bahamas"
        },
        "landmarksRound": [
          {
            "code": "MM",
            "number": 1
          },
          {
            "code": "VA",
            "number": 1
          },
          {
            "code": "OM",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "The Blue Mosque",
          "Timgad",
          "Rizal Park",
          "Hazrat Ali Mazar",
          "Red Square",
          "Sukhbaatar Square",
          "Fisherman's Bastion"
        ],
        "uniqueId": "bQnCnt7D",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Bahrain",
    "code": "BH",
    "capital": "Manama",
    "lat": 26,
    "long": 50.55,
    "cities": [
      "Muharraq",
      "Manama",
      "Riffa",
      "A'ali",
      "Sitra",
      "Hamad Town"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/S7FEiLILEuM",
          "wikiLink": "https://en.wikipedia.org/wiki/Bahrain_World_Trade_Center"
        },
        "landmark": {
          "name": "Bahrain Fort",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Qal%27at_al-Bahrain#/media/File:Dilmun06.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Qal%27at_al-Bahrain",
          "hasLandmark": true
        },
        "city": {
          "name": "Muharraq",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/muharraq-manama-bahrain-gulf-3439937/",
          "wikiLink": "https://en.wikipedia.org/wiki/Muharraq"
        },
        "landmarksRound": [
          {
            "code": "LK",
            "number": 1
          },
          {
            "code": "CH",
            "number": 2
          },
          {
            "code": "KZ",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Monteverde Cloud Forest",
          "Amalienborg",
          "Church of Saint Lazarus",
          "Acropolis",
          "Kelonia",
          "Gediminas Castle Tower",
          "Narikala Fortress"
        ],
        "uniqueId": "tzghy2QV",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sunset-sundown-bahrain-600769/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bahrain"
        },
        "landmark": {
          "name": "Al Fateh Grand Mosque",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Al_Fateh_Grand_Mosque#/media/File:Manama_al-Fateh_Grand_Mosque_Exterior_Norden_3.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Al_Fateh_Grand_Mosque",
          "hasLandmark": true
        },
        "city": {
          "name": "Manama",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-night-bahrain-skyline-evening-6758248/",
          "wikiLink": "https://en.wikipedia.org/wiki/Manama"
        },
        "landmarksRound": [
          {
            "code": "KR",
            "number": 2
          },
          {
            "code": "TH",
            "number": 1
          },
          {
            "code": "UZ",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Gediminas Castle Tower",
          "Tokyo Skytree",
          "Kasubi Royal Tombs",
          "Cologne Cathedral",
          "Stone House",
          "Registan Square",
          "Tavarua"
        ],
        "uniqueId": "rr7tSNd3",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Bangladesh",
    "code": "BD",
    "capital": "Dhaka",
    "lat": 24,
    "long": 90,
    "cities": [
      "Chattogram",
      "Narayanganj",
      "Dhaka",
      "Khulna",
      "Sylhet",
      "Rangpur"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/DWoOJ2C2uns",
          "wikiLink": "https://en.wikipedia.org/wiki/Sonargaon"
        },
        "landmark": {
          "name": "Somapura Mahavihara",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Somapura_Mahavihara#/media/File:%E0%A6%AA%E0%A6%BE%E0%A6%B9%E0%A6%BE%E0%A6%A1%E0%A6%BC%E0%A6%AA%E0%A7%81%E0%A6%B0_%E0%A6%AC%E0%A7%8C%E0%A6%A6%E0%A7%8D%E0%A6%A7_%E0%A6%AC%E0%A6%BF%E0%A6%B9%E0%A6%BE%E0%A6%B0_22.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Somapura_Mahavihara",
          "hasLandmark": true
        },
        "city": {
          "name": "Chattogram",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Chittagong#/media/File:Shah_Amanat_763.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Chittagong"
        },
        "landmarksRound": [
          {
            "code": "IT",
            "number": 2
          },
          {
            "code": "HU",
            "number": 1
          },
          {
            "code": "IR",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Burj Khalifa",
          "Dubai Frame",
          "Prince's Palace of Monaco",
          "Chillon Castle",
          "Baiterek",
          "Silver Pagoda",
          "Voortrekker Monument"
        ],
        "uniqueId": "c3SCD8f6",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bangladesh-lake-boat-green-boat-3543466/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bangladesh"
        },
        "landmark": {
          "name": "Dhakeshwari Temple",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Dhakeshwari_Temple#/media/File:Shiva_temples_Dhakeshwari_Mandir_2_by_Ragib_Hasan.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Dhakeshwari_Temple",
          "hasLandmark": true
        },
        "city": {
          "name": "Narayanganj",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Narayanganj#/media/File:Skyline_in_Narayanganj_(02).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Narayanganj"
        },
        "landmarksRound": [
          {
            "code": "FI",
            "number": 1
          },
          {
            "code": "CH",
            "number": 1
          },
          {
            "code": "TH",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Cape of Good Hope",
          "Amber Palace",
          "Skopje Fortress",
          "St. Alexander Nevsky Cathedral",
          "Deadvlei",
          "Al Fateh Grand Mosque",
          "Merry Cemetery"
        ],
        "uniqueId": "73xwgnhl",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Barbados",
    "code": "BB",
    "capital": "Bridgetown",
    "lat": 13.16666666,
    "long": -59.53333333,
    "cities": [
      "Bridgetown",
      "Bathsheba",
      "Speightstown",
      "Holetown",
      "Oistins",
      "Saint John"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/-76qup1BmIs",
          "wikiLink": "https://en.wikipedia.org/wiki/Barbados"
        },
        "landmark": {
          "name": "Animal Flower Cave",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Animal_Flower_Cave#/media/File:ANIMAL_FLOWER_CAVE_-_BARBADOS.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Animal_Flower_Cave",
          "hasLandmark": true
        },
        "city": {
          "name": "Bridgetown",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/barbados-bridgetown-3925817/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bridgetown"
        },
        "landmarksRound": [
          {
            "code": "PF",
            "number": 1
          },
          {
            "code": "BS",
            "number": 2
          },
          {
            "code": "JO",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Cape Coast Castle",
          "Olavinlinna",
          "Niagara Falls",
          "Pamir Mountains",
          "Mount Kenya",
          "The Marble Mountains",
          "Predjama Castle"
        ],
        "uniqueId": "hWm3Jqtg",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/clearwater-villa-beach-barbados-1549544/",
          "wikiLink": "https://en.wikipedia.org/wiki/Barbados"
        },
        "landmark": {
          "name": "George Washington House",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/George_Washington_House_(Barbados)#/media/File:GEORGE_WASHINGTON_HOUSE_-_BARBADOS.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/George_Washington_House_(Barbados)",
          "hasLandmark": true
        },
        "city": {
          "name": "Bathsheba",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bathsheba,_Barbados#/media/File:Bathsheba,_Barbados_08.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bathsheba,_Barbados"
        },
        "landmarksRound": [
          {
            "code": "ME",
            "number": 1
          },
          {
            "code": "EC",
            "number": 1
          },
          {
            "code": "AT",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Boudhanath",
          "Roman theatre",
          "Vaduz Cathedral",
          "Mount Kenya",
          "Patuxay Monument",
          "Shwedagon Pagoda",
          "Monday Falls"
        ],
        "uniqueId": "6ZKpk5j9",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Belarus",
    "code": "BY",
    "capital": "Minsk",
    "lat": 53,
    "long": 28,
    "cities": [
      "Grodno",
      "Minsk",
      "Brest",
      "Gomel",
      "Mogilev",
      "Pinsk"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/NxFNWUMxnGM",
          "wikiLink": "https://en.wikipedia.org/wiki/Minsk"
        },
        "landmark": {
          "name": "Mir Castle",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mir-castle-complex-belarus-castle-6823633/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mir_Castle_Complex",
          "hasLandmark": true
        },
        "city": {
          "name": "Grodno",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/belarus-sunrise-grodno-city-7100477/",
          "wikiLink": "https://en.wikipedia.org/wiki/Grodno"
        },
        "landmarksRound": [
          {
            "code": "AG",
            "number": 1
          },
          {
            "code": "CY",
            "number": 1
          },
          {
            "code": "NZ",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Rila Monastery",
          "Queen Emma Bridge",
          "Tikal National Park",
          "Angel Falls",
          "Island of Mozambique",
          "Tash Rabat",
          "Fort Fincastle"
        ],
        "uniqueId": "2z8BC2jp",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/vilino-belarus-landscape-scenic-107571/",
          "wikiLink": "https://en.wikipedia.org/wiki/Belarus"
        },
        "landmark": {
          "name": "Brest Fortress",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Brest_Fortress#/media/File:Brest_Brest_Fortress_Kholm_Gate_9209_2150.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Brest_Fortress",
          "hasLandmark": true
        },
        "city": {
          "name": "Minsk",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/minsk-belarus-dji-europe-4420398/",
          "wikiLink": "https://en.wikipedia.org/wiki/Minsk"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "WtQCk44t",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Belgium",
    "code": "BE",
    "capital": "Brussels",
    "lat": 50.83333333,
    "long": 4,
    "cities": [
      "Antwerp",
      "Brussels",
      "Ghent",
      "Leuven",
      "Namur",
      "Bruges"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/_BBlUZhRzjg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bruges"
        },
        "landmark": {
          "name": "Atomium",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/atomium-brussels-travel-landmark-3590775/",
          "wikiLink": "https://en.wikipedia.org/wiki/Atomium",
          "hasLandmark": true
        },
        "city": {
          "name": "Bruges",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/brugge-bruges-belgium-architecture-5278796/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bruges"
        },
        "landmarksRound": [
          {
            "code": "MQ",
            "number": 1
          },
          {
            "code": "BH",
            "number": 1
          },
          {
            "code": "IN",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Rock of Cashel",
          "Sule Pagoda",
          "Predjama Castle",
          "Epupa Falls",
          "Wieliczka Salt Mine",
          "Erbil Citadel",
          "Vianden Castle"
        ],
        "uniqueId": "jnQRnDY6",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/brugge-bruges-belgium-architecture-5278796/",
          "wikiLink": "https://en.wikipedia.org/wiki/Belgium"
        },
        "landmark": {
          "name": "Gravensteen",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/gent-belgium-ghent-flanders-castle-4778045/",
          "wikiLink": "https://en.wikipedia.org/wiki/Gravensteen",
          "hasLandmark": true
        },
        "city": {
          "name": "Antwerp",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/antwerp-belgium-clouds-architecture-1665194/",
          "wikiLink": "https://en.wikipedia.org/wiki/Antwerp"
        },
        "landmarksRound": [
          {
            "code": "PK",
            "number": 1
          },
          {
            "code": "ES",
            "number": 2
          },
          {
            "code": "LA",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Dunn's River Falls",
          "Castle Rushden",
          "Olavinlinna",
          "Deadvlei",
          "Ulu Temburong National Park",
          "Borobudur Temple",
          "Neveh Shalom Synagogue"
        ],
        "uniqueId": "WykRxlk8",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Belize",
    "code": "BZ",
    "capital": "Belmopan",
    "lat": 17.25,
    "long": -88.75,
    "cities": [
      "Belize City",
      "San Ignacio",
      "Belmopan",
      "San Pedro",
      "Punta Gorda",
      "Dangriga"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/erKKEmRLuV0",
          "wikiLink": "https://en.wikipedia.org/wiki/Ambergris_Caye"
        },
        "landmark": {
          "name": "Xunatunich Mayan Ruins",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Xunantunich#/media/File:Friese,_El_Castillo,_Xunantunich_(7981048802).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Xunantunich",
          "hasLandmark": true
        },
        "city": {
          "name": "Belize City",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/truck-overloaded-belize-city-road-7469016/",
          "wikiLink": "https://en.wikipedia.org/wiki/Belize_City"
        },
        "landmarksRound": [
          {
            "code": "CH",
            "number": 2
          },
          {
            "code": "AU",
            "number": 1
          },
          {
            "code": "AD",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Monument to the Heroes of the Restoration",
          "Baiterek",
          "Elmina Castle",
          "Liwonde National Park",
          "Neveh Shalom Synagogue",
          "Hato Caves",
          "Hazrat Ali Mazar"
        ],
        "uniqueId": "JckdBp6M",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/water-creek-landscape-flow-hdr-7726729/",
          "wikiLink": "https://en.wikipedia.org/wiki/Belize"
        },
        "landmark": {
          "name": "Great Blue Hole",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Great_Blue_Hole#/media/File:Great_Blue_Hole.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Great_Blue_Hole",
          "hasLandmark": true
        },
        "city": {
          "name": "San Ignacio",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/San_Ignacio,_Belize#/media/File:Burns_Ave_in_San_Ignacio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/San_Ignacio,_Belize"
        },
        "landmarksRound": [
          {
            "code": "BT",
            "number": 1
          },
          {
            "code": "KY",
            "number": 2
          },
          {
            "code": "PE",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Tenaru Falls",
          "Monte Carlo Casino",
          "Lukan Longshan Temple",
          "Queen's Staircase",
          "Grytviken Church",
          "Mostar Old Bridge",
          "Katse Dam"
        ],
        "uniqueId": "2Dg4fyW5",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Benin",
    "code": "BJ",
    "capital": "Porto-Novo",
    "lat": 9.5,
    "long": 2.25,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/benign-africa-child-black-2223164/",
          "wikiLink": "https://en.wikipedia.org/wiki/Benin"
        },
        "landmark": {
          "name": "Pendjari National Park",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Pendjari_National_Park#/media/File:Pendjari_fluss.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Pendjari_National_Park",
          "hasLandmark": true
        },
        "city": {
          "name": "Porto Novo",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Porto-Novo#/media/File:Grande_Mosquee_Porto-Novo_Benin_Joseph_Herve_Ahissou.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Porto-Novo"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "fYyfNZ8S",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/benin-lake-africa-water-boat-2222345/",
          "wikiLink": "https://en.wikipedia.org/wiki/Benin"
        },
        "landmark": {
          "name": "Porte du Non Retour",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:The_Door_of_No_Return_Ouidah_%285%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Door_of_No_Return,_Ouidah",
          "hasLandmark": true
        },
        "city": {
          "name": "Cotonou",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Cotonou#/media/File:B%C3%A9nin_Cotonou_Place_of_Red_Star.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Cotonou"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "3d2Pz5BP",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Bermuda",
    "code": "BM",
    "capital": "Hamilton",
    "lat": 32.33333333,
    "long": -64.75,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/0a17lg30MtY",
          "wikiLink": ""
        },
        "landmark": {
          "name": "Spittal Pond",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Spittal_Pond_Nature_Reserve#/media/File:Spittal_Pond.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Spittal_Pond_Nature_Reserve",
          "hasLandmark": true
        },
        "city": {
          "name": "St. George's",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/St._George%27s,_Bermuda#/media/File:Ordnance_Island,_St._George's_Town,_and_Fort_George,_Bermuda.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/St._George%27s,_Bermuda"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "R5vSp9H7",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bermuda-pink-sand-beach-ocean-4096704/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bermuda"
        },
        "landmark": {
          "name": "Unfinished Church",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Unfinished_Church_2.jpg",
          "wikiLink": "",
          "hasLandmark": true
        },
        "city": {
          "name": "Hamilton",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Hamilton,_Bermuda#/media/File:Front_Street,_Hamilton,_Bermuda.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Hamilton,_Bermuda"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "jW9HN3PX",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Bhutan",
    "code": "BT",
    "capital": "Thimphu",
    "lat": 27.5,
    "long": 90.5,
    "cities": [
      "Thimphu",
      "Paro",
      "Phuentsholing",
      "Gelephu",
      "Punakha",
      "Wangdue Phodrang"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/ZdwVvRdel8A",
          "wikiLink": "https://en.wikipedia.org/wiki/Paro_Taktsang"
        },
        "landmark": {
          "name": "Chimi Lhakhang",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Chimi_Lhakhang#/media/File:Chimi_Lhakhang,_Bhutan_02.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Chimi_Lhakhang",
          "hasLandmark": true
        },
        "city": {
          "name": "Thimphu",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Thimphu#/media/File:Thimphu_view_080907.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Thimphu"
        },
        "landmarksRound": [
          {
            "code": "IN",
            "number": 2
          },
          {
            "code": "HN",
            "number": 1
          },
          {
            "code": "NA",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Rizal Park",
          "Kim Il Sung Square",
          "South Pole (ceremonial)",
          "Masjid al-Haram",
          "Umayyad Mosque",
          "Stone House",
          "Kittiwake Shipwreck"
        ],
        "uniqueId": "2zXT6jWH",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/temple-monastery-cliff-cliffside-171377/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bhutan"
        },
        "landmark": {
          "name": "Gangtey Monastery",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Gangteng_Monastery#/media/File:Main_Temple_Gangte_Goemba.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Gangteng_Monastery",
          "hasLandmark": true
        },
        "city": {
          "name": "Paro",
          "photographer": "",
          "imageLink": "https://upload.wikimedia.org/wikipedia/commons/6/68/Paro%2C_Paro%2C_Bhutan_%288026008882%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Paro,_Bhutan"
        },
        "landmarksRound": [
          {
            "code": "AZ",
            "number": 1
          },
          {
            "code": "GL",
            "number": 1
          },
          {
            "code": "DZ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Dunn's River Falls",
          "Boudhanath",
          "Mount Kenya",
          "Chichen Itza",
          "Arecibo Observatory",
          "Tavarua",
          "Tian Tan Buddha"
        ],
        "uniqueId": "bW9BnWFG",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Bolivia",
    "code": "BO",
    "capital": "Sucre",
    "lat": -17,
    "long": -65,
    "cities": [
      "Sucre",
      "Santa Cruz de la Sierra",
      "La Paz",
      "El Alto",
      "Cochabamba",
      "Oruro"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/Y_Z-ltvMjdg",
          "wikiLink": "https://en.wikipedia.org/wiki/Salar_de_Uyuni"
        },
        "landmark": {
          "name": "Uyuni Salt Flat",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/salt-lake-salt-minerals-594609/",
          "wikiLink": "https://en.wikipedia.org/wiki/Salar_de_Uyuni",
          "hasLandmark": true
        },
        "city": {
          "name": "Santa Cruz de la Sierra",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/santa-cruz-de-la-sierra-bolivia-4797349/",
          "wikiLink": "https://en.wikipedia.org/wiki/Santa_Cruz_de_la_Sierra"
        },
        "landmarksRound": [
          {
            "code": "CO",
            "number": 1
          },
          {
            "code": "CY",
            "number": 1
          },
          {
            "code": "UY",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Vaduz Cathedral",
          "Leaning Tower of Pisa",
          "Memorial de l'Anse",
          "Mount Paget",
          "Xunatunich Mayan Ruins",
          "Wat Xiengthong",
          "Vianden Castle"
        ],
        "uniqueId": "l7bMnjXT",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/desert-landscape-sand-mountain-4388204/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bolivia"
        },
        "landmark": {
          "name": "Plaza Murillo",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Plaza_Murillo#/media/File:Plaza_Murillo-Palacio_and_Catedral.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Plaza_Murillo",
          "hasLandmark": true
        },
        "city": {
          "name": "La Paz",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/la-paz-bolivia-south-america-city-458029/",
          "wikiLink": "https://en.wikipedia.org/wiki/La_Paz"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "dQrhDbx3",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Bosnia and Herzegovina",
    "code": "BA",
    "capital": "Sarajevo",
    "lat": 44,
    "long": 18,
    "cities": [
      "Sarajevo",
      "Banja Luka",
      "Brcko",
      "Tuzla",
      "Zenica",
      "Prijedor"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/GZjH4cVGgCs",
          "wikiLink": "https://en.wikipedia.org/wiki/Stari_Most"
        },
        "landmark": {
          "name": "Kravica Waterfall",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/waterfall-travel-tourism-nature-6567498/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kravica_(waterfall)",
          "hasLandmark": true
        },
        "city": {
          "name": "Sarajevo",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sarajevo-water-balkan-bridhe-2597608/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sarajevo"
        },
        "landmarksRound": [
          {
            "code": "MY",
            "number": 2
          },
          {
            "code": "KG",
            "number": 2
          },
          {
            "code": "MG",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Boudhanath",
          "Diocletian's Palace",
          "Vardzia",
          "Ampitheater of El Jem",
          "Vaduz Cathedral",
          "Baiterek",
          "Chimi Lhakhang"
        ],
        "uniqueId": "GDw6c4vL",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mostar-bridge-travel-bosnia-1155672/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bosnia_and_Herzegovina"
        },
        "landmark": {
          "name": "Mostar Old Bridge",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mostar-bosnia-bosnia-and-herzegovina-4596513/",
          "wikiLink": "https://en.wikipedia.org/wiki/Stari_Most",
          "hasLandmark": true
        },
        "city": {
          "name": "Banja Luka",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Banja_Luka#/media/File:Banjalukaview000.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Banja_Luka"
        },
        "landmarksRound": [
          {
            "code": "GG",
            "number": 1
          },
          {
            "code": "CZ",
            "number": 2
          },
          {
            "code": "HR",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Bahrain Fort",
          "Easter Island",
          "Mount Yasur",
          "Stone House",
          "Wat Xiengthong",
          "Wieliczka Salt Mine",
          "Trevi Fountain"
        ],
        "uniqueId": "5LKm6zZ6",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Botswana",
    "code": "BW",
    "capital": "Gaborone",
    "lat": -22,
    "long": 24,
    "cities": [
      "Francistown",
      "Gaborone",
      "Serowe",
      "Molepolole",
      "Maun",
      "Kanye"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/vcN7_lk91rM",
          "wikiLink": "https://en.wikipedia.org/wiki/Botswana"
        },
        "landmark": {
          "name": "Okavango Delta",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/okavango-delta-river-landscape-5158380/",
          "wikiLink": "https://en.wikipedia.org/wiki/Okavango_Delta",
          "hasLandmark": true
        },
        "city": {
          "name": "Gaborone",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/botswana-gaborone-architecture-970276/",
          "wikiLink": "https://en.wikipedia.org/wiki/Gaborone"
        },
        "landmarksRound": [
          {
            "code": "RS",
            "number": 1
          },
          {
            "code": "MD",
            "number": 1
          },
          {
            "code": "UY",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Machu Picchu",
          "Prince's Palace of Monaco",
          "Soroca Fort",
          "Old Panama",
          "Koutoubia",
          "Blue Grotto",
          "Deadvlei"
        ],
        "uniqueId": "qswpPGDV",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/botswana-baobab-baobab-tree-694485/",
          "wikiLink": "https://en.wikipedia.org/wiki/Botswana"
        },
        "landmark": {
          "name": "Tsodilo Hills",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Tsodilo#/media/File:Tsodilo_Hills_rock_paintings3.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tsodilo",
          "hasLandmark": true
        },
        "city": {
          "name": "Francistown",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Francistown#/media/File:Photo_Submission_by_Aone_Mokwena_(4).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Francistown"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "jZb9Rm79",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Bouvet Islands",
    "code": "BV",
    "capital": "TODO",
    "lat": -54.423199,
    "long": 3.413194,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Bouvet_island.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bouvet_Island"
        },
        "landmark": {
          "name": "Olavtoppen",
          "photographer": "",
          "imageLink": "",
          "wikiLink": "https://en.wikipedia.org/wiki/Olavtoppen",
          "hasLandmark": false
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "R8ybNCsp",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Bouvet_Island_west_coast_glacier.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bouvet_Island"
        },
        "landmark": {
          "name": "Cape Circoncision",
          "photographer": "",
          "imageLink": "",
          "wikiLink": "https://en.wikipedia.org/wiki/Cape_Circoncision",
          "hasLandmark": false
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "jwWMCWm6",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Brazil",
    "code": "BR",
    "capital": "Brasília",
    "lat": -10,
    "long": -55,
    "cities": [
      "Sao Paulo",
      "Fortaleza",
      "Brasília",
      "Rio de Janeiro",
      "Manaus",
      "Recife"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/WjvrLSTkRck",
          "wikiLink": "https://en.wikipedia.org/wiki/Rio_de_Janeiro"
        },
        "landmark": {
          "name": "Christ the Redeemer",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/brazil-rio-landscape-tourism-ocean-4809011/",
          "wikiLink": "https://en.wikipedia.org/wiki/Christ_the_Redeemer_(statue)",
          "hasLandmark": true
        },
        "city": {
          "name": "Sao Paulo",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/s%c3%a3o-paulo-buildings-urban-1206160/",
          "wikiLink": "https://en.wikipedia.org/wiki/S%C3%A3o_Paulo"
        },
        "landmarksRound": [
          {
            "code": "CH",
            "number": 1
          },
          {
            "code": "TJ",
            "number": 2
          },
          {
            "code": "KZ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Taj Mahal",
          "Kaaba",
          "Okavango Delta",
          "The Grand Palace",
          "Rijksmuseum",
          "Old Town Square",
          "Rock of Cashel"
        ],
        "uniqueId": "8ynvBntn",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/rio-de-janeiro-brazil-travel-rio-4566312/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rio_de_Janeiro"
        },
        "landmark": {
          "name": "Cathedral of Brasilica",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Cathedral_of_Bras%C3%ADlia#/media/File:Catedral_Metropolitana_de_Brasilia.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Cathedral_of_Bras%C3%ADlia",
          "hasLandmark": true
        },
        "city": {
          "name": "Fortaleza",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cityscape-lights-night-dark-urban-4743866/",
          "wikiLink": "https://en.wikipedia.org/wiki/Fortaleza"
        },
        "landmarksRound": [
          {
            "code": "CN",
            "number": 2
          },
          {
            "code": "NL",
            "number": 2
          },
          {
            "code": "DE",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Anne Frank House",
          "South Pole (ceremonial)",
          "Erbil Citadel",
          "Castle Cornet",
          "Animal Flower Cave",
          "Pigeon Rocks",
          "Ostrog Monastery"
        ],
        "uniqueId": "HjYmDgpC",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "British Indian Ocean Territory",
    "code": "IO",
    "capital": "Diego Garcia",
    "lat": -6,
    "long": 71.5,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Chagossians_visiting_the_British_Indian_Ocean_Territory_as_tourists.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/British_Indian_Ocean_Territory"
        },
        "landmark": {
          "name": "Salomons Atoll",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Salomons_Atoll_in_the_Chagos.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Salomon_Islands",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "MDFDwjnQ",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:The_O_Club_on_Diego_Garcia.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Naval_Support_Facility_Diego_Garcia"
        },
        "landmark": {
          "name": "Turtle Cove",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/33590535@N06/4165898282",
          "wikiLink": "https://en.wikipedia.org/wiki/British_Indian_Ocean_Territory",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "mmsYzZ8v",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "British Virgin Islands",
    "code": "VG",
    "capital": "Road Town",
    "lat": 18.431383,
    "long": -64.62305,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bay-british-virgin-islands-yacht-434934/",
          "wikiLink": "https://en.wikipedia.org/wiki/British_Virgin_Islands"
        },
        "landmark": {
          "name": "The Baths",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/The_Baths#/media/File:Virgin_Gorda_Boulders2.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/The_Baths",
          "hasLandmark": true
        },
        "city": {
          "name": "Road Town",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/road-town-tortola-2633026/",
          "wikiLink": "https://en.wikipedia.org/wiki/Road_Town"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Qv3fcVtm",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/road-town-tortola-2633026/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tortola"
        },
        "landmark": {
          "name": "White Bay Bar",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:British_Virgin_Islands_%E2%80%94_Jost_van_Dyke_%E2%80%94_White_Bay_%28bar%29.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Jost_Van_Dyke",
          "hasLandmark": true
        },
        "city": {
          "name": "Spanish Town",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Virgin_Gorda_Airstrip.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Spanish_Town,_British_Virgin_Islands"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "CcnKFhQQ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Brunei",
    "code": "BN",
    "capital": "Bandar Seri Begawan",
    "lat": 4.5,
    "long": 114.6666667,
    "cities": [
      "Kampung Sinarubai",
      "Bandar Seri Begawan",
      "Kuala Belait",
      "Seria",
      "Napok",
      "Selayun"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/ship-junk-mosque-saifuddin-brunei-523587/",
          "wikiLink": "https://en.wikipedia.org/wiki/Brunei"
        },
        "landmark": {
          "name": "Ulu Temburong National Park",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ulu_Temburong_National_Park#/media/File:Ulu_Ulu_Resort_Jetty.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ulu_Temburong_National_Park",
          "hasLandmark": true
        },
        "city": {
          "name": "Bandar Seri Begawan",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bandar_Seri_Begawan#/media/File:Panorama_Bandar_Seri_Begawan_19.05.2018.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bandar_Seri_Begawan"
        },
        "landmarksRound": [
          {
            "code": "FI",
            "number": 2
          },
          {
            "code": "VI",
            "number": 2
          },
          {
            "code": "CZ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Old Town Square",
          "Szechenyi Thermal Bath",
          "Soroca Fort",
          "Palace of Versailles",
          "Annandale Waterfall",
          "Marcus Aurelius Arch",
          "Dhakeshwari Temple"
        ],
        "uniqueId": "x3wDWrsv",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/brunei-empire-hotel-overseas-travel-2946137/",
          "wikiLink": "https://en.wikipedia.org/wiki/Brunei"
        },
        "landmark": {
          "name": "Jame' Asr Hassanil Bolkiah Mosque",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Jame%27_Asr_Hassanil_Bolkiah_Mosque#/media/File:Jame'_Asri_Sultan_Hassanal_Bolkiah_(18404222579).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Jame%27_Asr_Hassanil_Bolkiah_Mosque",
          "hasLandmark": true
        },
        "city": {
          "name": "Kampung Sinarubai",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Kampong_Sinarubai#/media/File:Brunei_9_August_2022_07.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kampong_Sinarubai"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "dSVnzhGP",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Bulgaria",
    "code": "BG",
    "capital": "Sofia",
    "lat": 43,
    "long": 25,
    "cities": [
      "Plovdiv",
      "Sofia",
      "Varna",
      "Pleven",
      "Ruse",
      "Shumen"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/UPoSvgmFKmo",
          "wikiLink": "https://en.wikipedia.org/wiki/Alexander_Nevsky_Cathedral,_Sofia"
        },
        "landmark": {
          "name": "Rila Monastery",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/rila-monastery-church-4905350/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rila_Monastery",
          "hasLandmark": true
        },
        "city": {
          "name": "Plovdiv",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/hill-sunset-city-dusk-travel-6977948/",
          "wikiLink": "https://en.wikipedia.org/wiki/Plovdiv"
        },
        "landmarksRound": [
          {
            "code": "UA",
            "number": 1
          },
          {
            "code": "JM",
            "number": 2
          },
          {
            "code": "DK",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Roman theatre",
          "Fisherman's Bastion",
          "Red Square",
          "Floralis Generica",
          "Vianden Castle",
          "Noravank Monastery",
          "Arecibo Observatory"
        ],
        "uniqueId": "bpCWhWxg",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/scenic-mountain-landscape-blue-2014114/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bulgaria"
        },
        "landmark": {
          "name": "St. Alexander Nevsky Cathedral",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Alexander_Nevsky_Cathedral,_Sofia#/media/File:Anevski.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Alexander_Nevsky_Cathedral,_Sofia",
          "hasLandmark": true
        },
        "city": {
          "name": "Sofia",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sofia-city-tramway-3658934/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sofia"
        },
        "landmarksRound": [
          {
            "code": "JP",
            "number": 1
          },
          {
            "code": "LU",
            "number": 2
          },
          {
            "code": "MM",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Animal Flower Cave",
          "Macau Tower",
          "Narikala Fortress",
          "Green Grotto Caves",
          "Souq Waqif",
          "Mount Nyriagongo",
          "Memorial de l'Anse"
        ],
        "uniqueId": "9FC7q3j2",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Burkina Faso",
    "code": "BF",
    "capital": "Ouagadougou",
    "lat": 13,
    "long": -2,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/africa-afrique-burkina-faso-pottery-170206/",
          "wikiLink": "https://en.wikipedia.org/wiki/Burkina_Faso"
        },
        "landmark": {
          "name": "Grand Mosque of Bobo-Dioulasso",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Grand_Mosque_of_Bobo-Dioulasso#/media/File:Moschee_von_Bobo-Dioulasso.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Grand_Mosque_of_Bobo-Dioulasso",
          "hasLandmark": true
        },
        "city": {
          "name": "Bobo-Dioulasso",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bobo-Dioulasso#/media/File:BoboDioulasso-Market.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Bobo-Dioulasso"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "HPgZYP2H",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/africa-burkina-faso-homes-205218/",
          "wikiLink": "https://en.wikipedia.org/wiki/Burkina_Faso"
        },
        "landmark": {
          "name": "Ouagadougou Cathedral",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ouagadougou_Cathedral#/media/File:Cathedrale_Ouagadougou.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ouagadougou_Cathedral",
          "hasLandmark": true
        },
        "city": {
          "name": "Ouagadougou",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ouagadougou#/media/File:OUAGADOUGOU3.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Ouagadougou"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "qXKD3ypx",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Burundi",
    "code": "BI",
    "capital": "Bujumbura",
    "lat": -3.5,
    "long": 30,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/wilderness-africa-nature-scenic-2434470/",
          "wikiLink": "https://en.wikipedia.org/wiki/Burundi"
        },
        "landmark": {
          "name": "Source du Nil",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/pyramid-burundi-landscape-africa-6599068/",
          "wikiLink": "https://en.wikipedia.org/wiki/Musenyi",
          "hasLandmark": true
        },
        "city": {
          "name": "Gitega",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Gitega_city.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Gitega"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "RK6rkM3k",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/burundi-african-gitega-6878378/",
          "wikiLink": "https://en.wikipedia.org/wiki/Gitega"
        },
        "landmark": {
          "name": "Regina Mundi Cathedral",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Regina_Mundi_Cathedral,_Bujumbura#/media/File:Bujumbura_Cathedral.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Regina_Mundi_Cathedral,_Bujumbura",
          "hasLandmark": true
        },
        "city": {
          "name": "Bujumbura",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bujumbura#/media/File:View_of_bujumbura.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Bujumbura"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "dc4nPltV",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Cambodia",
    "code": "KH",
    "capital": "Phnom Penh",
    "lat": 13,
    "long": 105,
    "cities": [
      "Siem Reap",
      "Phnom Penh",
      "Krong Kampot",
      "Krong Battambang",
      "Samraong",
      "Krong Kracheh"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/OvEr7BwXxxg",
          "wikiLink": "https://en.wikipedia.org/wiki/Angkor_Wat"
        },
        "landmark": {
          "name": "Angkor Wat",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/angkor-wat-temple-amazing-934094/",
          "wikiLink": "https://en.wikipedia.org/wiki/Angkor_Wat",
          "hasLandmark": true
        },
        "city": {
          "name": "Siem Reap",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Siem_Reap#/media/File:Siem_Reap_Skyline_at_Night.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Siem_Reap"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "zHPNVrlx",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/travel-tourist-cambodia-island-6268605/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cambodia"
        },
        "landmark": {
          "name": "Silver Pagoda",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/silver-pagoda-buddhist-temple-7149961/",
          "wikiLink": "https://en.wikipedia.org/wiki/Silver_Pagoda",
          "hasLandmark": true
        },
        "city": {
          "name": "Phnom Penh",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/asia-modern-tower-district-5392799/",
          "wikiLink": "https://en.wikipedia.org/wiki/Phnom_Penh"
        },
        "landmarksRound": [
          {
            "code": "CY",
            "number": 2
          },
          {
            "code": "HN",
            "number": 1
          },
          {
            "code": "GT",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Palace of Versailles",
          "Wat Arun",
          "Annandale Waterfall",
          "Tash Rabat",
          "Al Fateh Grand Mosque",
          "Independence Monument",
          "Wieliczka Salt Mine"
        ],
        "uniqueId": "yywQqlpZ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Cameroon",
    "code": "CM",
    "capital": "Yaounde",
    "lat": 6,
    "long": 12,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/bZnciM7XIYw",
          "wikiLink": "https://en.wikipedia.org/wiki/Douala"
        },
        "landmark": {
          "name": "Mount Cameroon",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mount-cameroon-africa-1489500/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mount_Cameroon",
          "hasLandmark": true
        },
        "city": {
          "name": "Douala",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Douala#/media/File:Ars&Urbis_International_Workshop_-_Emiliano_Gandolfi_36.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Douala"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "k39TTLXV",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/travel-nature-landscape-skirt-3269736/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cameroon"
        },
        "landmark": {
          "name": "Cameroon Reunification",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Reunification_Monument#/media/File:Monument_Reunification_2.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Reunification_Monument",
          "hasLandmark": true
        },
        "city": {
          "name": "Yaounde",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Yaound%C3%A9#/media/File:Yaound%C3%A9_Palais_des_Congr%C3%A8s.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Yaound%C3%A9"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "x3zXwtt9",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Canada",
    "code": "CA",
    "capital": "Ottawa",
    "lat": 60,
    "long": -95,
    "cities": [
      "Vancouver",
      "Toronto",
      "Ottawa",
      "Quebec City",
      "Calgary",
      "Montreal"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/uouH1bGxeeU",
          "wikiLink": "https://en.wikipedia.org/wiki/Ontario"
        },
        "landmark": {
          "name": "CN Tower",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cn-tower-tower-toronto-sunset-7432218/",
          "wikiLink": "https://en.wikipedia.org/wiki/CN_Tower",
          "hasLandmark": true
        },
        "city": {
          "name": "Vancouver",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/science-world-false-creek-vancouver-210775/",
          "wikiLink": "https://en.wikipedia.org/wiki/Vancouver"
        },
        "landmarksRound": [
          {
            "code": "TM",
            "number": 1
          },
          {
            "code": "ZA",
            "number": 1
          },
          {
            "code": "DK",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Diocletian's Palace",
          "Church of Our Savior",
          "Heydar Aliyev Center",
          "Blue Grotto",
          "Galle Fort",
          "Christ the Redeemer",
          "Taj Mahal"
        ],
        "uniqueId": "3VC2ZKKc",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/lighthouse-ocean-sea-coast-travel-1872998/",
          "wikiLink": "https://en.wikipedia.org/wiki/Canada"
        },
        "landmark": {
          "name": "Niagara Falls",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/waterfall-boat-niagara-falls-canada-5050298/",
          "wikiLink": "https://en.wikipedia.org/wiki/Niagara_Falls",
          "hasLandmark": true
        },
        "city": {
          "name": "Toronto",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/toronto-skyline-cityscape-164813/",
          "wikiLink": "https://en.wikipedia.org/wiki/Toronto"
        },
        "landmarksRound": [
          {
            "code": "BS",
            "number": 2
          },
          {
            "code": "CH",
            "number": 1
          },
          {
            "code": "MA",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "African Renaissance Monument",
          "Tegucigalpa Cathedral",
          "Registan Square",
          "Grandvalira",
          "Villa Folio",
          "Kathmandu Durbar Square",
          "Galle Fort"
        ],
        "uniqueId": "B5h6fBKN",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Cape Verde",
    "code": "CV",
    "capital": "Praia",
    "lat": 16,
    "long": -24,
    "cities": [
      "Praia",
      "Mindelo",
      "Tarrafal",
      "Espargos",
      "Sao Filipe",
      "Assomada"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/-1EKogAcUNc",
          "wikiLink": "https://en.wikipedia.org/wiki/Sal,_Cape_Verde"
        },
        "landmark": {
          "name": "Sao Filipe Royale Fortress",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Cape_Verde_Santiago_Fort_Real_de_S_Filipe.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Forte_Real_de_S%C3%A3o_Filipe",
          "hasLandmark": true
        },
        "city": {
          "name": "Mindelo",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mindelo#/media/File:Cabo_2010_Monte_Cara.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mindelo"
        },
        "landmarksRound": [
          {
            "code": "CD",
            "number": 2
          },
          {
            "code": "MT",
            "number": 1
          },
          {
            "code": "LT",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Motherland Monument",
          "Tomb of Hafez",
          "Tikal National Park",
          "Belem Tower",
          "Szechenyi Thermal Bath",
          "Kasubi Royal Tombs",
          "Matobo National Park"
        ],
        "uniqueId": "jLcTPXwX",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/fisherman-sea-atlantic-island-678467/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cape_Verde"
        },
        "landmark": {
          "name": "Blue Eye Cave",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:2014_Cap_Verde._Sal._Blue_Eye.JPG",
          "wikiLink": "",
          "hasLandmark": true
        },
        "city": {
          "name": "Praia",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Praia#/media/File:Praia_Ribeira_de_Palmarejo_(cropped).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Praia"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "mWzbXqFk",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Cayman Islands",
    "code": "KY",
    "capital": "George Town",
    "lat": 19.5,
    "long": -80.5,
    "cities": [
      "George Town",
      "Bodden Town",
      "West Bay",
      "East End",
      "North Side",
      "West Bay"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/iTTiIKcV3-M",
          "wikiLink": "https://en.wikipedia.org/wiki/Cayman_Islands"
        },
        "landmark": {
          "name": "Pedro St. James Castle",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Pedro_St._James_Castle#/media/File:Pedro_St._James_Castle_Restored.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Pedro_St._James_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "West Bay",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:West_Bay,_Cayman_Islands_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/West_Bay,_Cayman_Islands"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "fWBDF5h6",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/grand-cayman-cayman-islands-2072631/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cayman_Islands"
        },
        "landmark": {
          "name": "Kittiwake Shipwreck",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/crdunn/9062785028/in/photostream/",
          "wikiLink": "https://en.wikipedia.org/wiki/USS_Kittiwake_(ASR-13)",
          "hasLandmark": true
        },
        "city": {
          "name": "George Town",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/George_Town,_Cayman_Islands#/media/File:KY-Georgetown-01.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/George_Town,_Cayman_Islands"
        },
        "landmarksRound": [
          {
            "code": "BS",
            "number": 1
          },
          {
            "code": "BE",
            "number": 1
          },
          {
            "code": "SI",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Narikala Fortress",
          "Hofburg Palace",
          "La Sagrada Familia",
          "Lukan Longshan Temple",
          "Boudhanath",
          "Pigeon Rocks",
          "Masjid al-Haram"
        ],
        "uniqueId": "sY7R2vvB",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Central African Republic",
    "code": "CF",
    "capital": "Bangui",
    "lat": 7,
    "long": 21,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Central_African_Republic_1995.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Central_African_Republic"
        },
        "landmark": {
          "name": "Notre Dame of Bangui Cathedral",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Cath%C3%A9drale_Notre-Dame_(Bangui)#/media/File:Une_foule_devant_la_Cath%C3%A9drale_de_Bangui,_le_7_mai_2018.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Cath%C3%A9drale_Notre-Dame_(Bangui)",
          "hasLandmark": true
        },
        "city": {
          "name": "Berberati",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:R%C3%A9union_au_village.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Berb%C3%A9rati"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "QpCSzHRb",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/usarmyafrica/11382638006",
          "wikiLink": "https://en.wikipedia.org/wiki/Central_African_Republic"
        },
        "landmark": {
          "name": "Boali",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/eiti/4112150917",
          "wikiLink": "https://en.wikipedia.org/wiki/Boali",
          "hasLandmark": true
        },
        "city": {
          "name": "Bangui",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bangui#/media/File:The_Bangui_City.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bangui"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "TWymSTlg",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Chad",
    "code": "TD",
    "capital": "N'Djamena",
    "lat": 15,
    "long": 19,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/caravan-desert-sahara-sand-camel-4483792/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sahara"
        },
        "landmark": {
          "name": "Emi Koussi",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Emi_Koussi#/media/File:Emi_Koussi_inner_crater.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Emi_Koussi",
          "hasLandmark": true
        },
        "city": {
          "name": "Moundou",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Moundou#/media/File:La_ville_de_Moundou_4.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Moundou"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "q3S5lz6r",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/road-tree-africa-path-chad-696570/",
          "wikiLink": "https://en.wikipedia.org/wiki/Chad"
        },
        "landmark": {
          "name": "Lake Yoa",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Lake_Yoa#/media/File:Lac_Yoa2.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Lake_Yoa",
          "hasLandmark": true
        },
        "city": {
          "name": "N'Djamena",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/kendoerr/15386229016",
          "wikiLink": "https://en.wikipedia.org/wiki/N%27Djamena"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "nm29lVpX",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Chile",
    "code": "CL",
    "capital": "Santiago",
    "lat": -30,
    "long": -71,
    "cities": [
      "Vina del Mar",
      "Santiago",
      "Arica",
      "Iquique",
      "Valparaiso",
      "La Serena"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/H6KJ2D0LphU",
          "wikiLink": "https://en.wikipedia.org/wiki/Valpara%C3%ADso"
        },
        "landmark": {
          "name": "Easter Island",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/chili-easter-island-moai-rapa-nui-1740813/",
          "wikiLink": "https://en.wikipedia.org/wiki/Easter_Island",
          "hasLandmark": true
        },
        "city": {
          "name": "Vina del Mar",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/vi%c3%b1a-del-mar-chile-south-america-263227/",
          "wikiLink": "https://en.wikipedia.org/wiki/Vi%C3%B1a_del_Mar"
        },
        "landmarksRound": [
          {
            "code": "RO",
            "number": 1
          },
          {
            "code": "MZ",
            "number": 1
          },
          {
            "code": "BS",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Arahoho Blowhole",
          "Romney Manor",
          "Soroca Fort",
          "Senso-ji",
          "La Sagrada Familia",
          "National Shrine Basilica of our Lady of Las Lajas",
          "Arecibo Observatory"
        ],
        "uniqueId": "RgHNqp7k",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/chile-mountains-lake-landscape-7430434/",
          "wikiLink": "https://en.wikipedia.org/wiki/Chile"
        },
        "landmark": {
          "name": "La Moneda Palace",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Palacio_de_La_Moneda_(Chile)#/media/File:Palacio_de_LaMoneda02.png",
          "wikiLink": "https://en.wikipedia.org/wiki/Palacio_de_La_Moneda_(Chile)",
          "hasLandmark": true
        },
        "city": {
          "name": "Santiago",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/santiago-chile-downtown-buildings-263235/",
          "wikiLink": "https://en.wikipedia.org/wiki/Santiago"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "5wypvvH9",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "China",
    "code": "CN",
    "capital": "Beijing",
    "lat": 35,
    "long": 105,
    "cities": [
      "Nanjing",
      "Beijing",
      "Guangzhou",
      "Chengdu",
      "Shenzhen",
      "Shanghai"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/Pnc2Uxb7PG0",
          "wikiLink": "https://en.wikipedia.org/wiki/Nanjing"
        },
        "landmark": {
          "name": "Oriental Pearl TV Tower",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/shanghai-the-bund-2422329/",
          "wikiLink": "https://en.wikipedia.org/wiki/Oriental_Pearl_Tower",
          "hasLandmark": true
        },
        "city": {
          "name": "Shanghai",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/night-city-shanghai-asia-east-4694750/",
          "wikiLink": "https://en.wikipedia.org/wiki/Shanghai"
        },
        "landmarksRound": [
          {
            "code": "PK",
            "number": 1
          },
          {
            "code": "CA",
            "number": 1
          },
          {
            "code": "BR",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Ocean Park",
          "Ouzoud Falls",
          "George Washington House",
          "Church of Saint Lazarus",
          "Monument to the Heroes of the Restoration",
          "Big Ben",
          "Trevi Fountain"
        ],
        "uniqueId": "CBx73K7w",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/yellow-mountains-mountain-ranges-532857/",
          "wikiLink": "https://en.wikipedia.org/wiki/Huangshan"
        },
        "landmark": {
          "name": "Forbidden City",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/china-beijing-tiananmen-4644682/",
          "wikiLink": "https://en.wikipedia.org/wiki/Forbidden_City",
          "hasLandmark": true
        },
        "city": {
          "name": "Nanjing",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/architecture-photography-nanjing-2187968/",
          "wikiLink": "https://en.wikipedia.org/wiki/Nanjing"
        },
        "landmarksRound": [
          {
            "code": "AE",
            "number": 2
          },
          {
            "code": "BE",
            "number": 2
          },
          {
            "code": "RO",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Epupa Falls",
          "Sukhbaatar Square",
          "Hagia Sophia",
          "Angel Falls",
          "Voortrekker Monument",
          "Langkawi Sky Bridge",
          "Patuxay Monument"
        ],
        "uniqueId": "rLxVkKX2",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Christmas Island",
    "code": "CX",
    "capital": "Flying Fish Cove",
    "lat": -10.5,
    "long": 105.6666667,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/gm5iCSYrLpQ",
          "wikiLink": "https://en.wikipedia.org/wiki/Christmas_Island_red_crab"
        },
        "landmark": {
          "name": "Christmas Island National Park",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Christmas_Island_National_Park#/media/File:Idyllic_Bathing_Pool_(25513646381).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Christmas_Island_National_Park",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "fYRCb42r",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Flying_Fish_Cove_at_Christmas_Island.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Flying_Fish_Cove"
        },
        "landmark": {
          "name": "Dolly Beach",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/davidstanleytravel/25232760539",
          "wikiLink": "https://en.wikipedia.org/wiki/Christmas_Island#Beaches",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "7lkdDmkJ",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Cocos (Keeling) Islands",
    "code": "CC",
    "capital": "West Island",
    "lat": -12.5,
    "long": 96.83333333,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Cocos_%28Keeling%29_Islands_2017_%2843%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands"
        },
        "landmark": {
          "name": "Pulu Keeling National Park",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Pulu_keeling_park.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/North_Keeling",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "gpPWwTZc",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Cocos_%28Keeling%29_Islands_%2832206670676%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Cocos_(Keeling)_Islands"
        },
        "landmark": {
          "name": "Oceania House",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Oceania_House_%2824589304043%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Oceania_House",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Tg7LTdjv",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Colombia",
    "code": "CO",
    "capital": "Bogotá",
    "lat": 4,
    "long": -72,
    "cities": [
      "Medellin",
      "Cali",
      "Bogotá",
      "Bucaramanga",
      "Barranquilla",
      "Cartagena"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/J0suKy48jfk",
          "wikiLink": "https://en.wikipedia.org/wiki/Cartagena,_Colombia"
        },
        "landmark": {
          "name": "Valle de Cocora",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/colombia-el-bosque-de-palmas-4907636/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cocora_Valley",
          "hasLandmark": true
        },
        "city": {
          "name": "Medellin",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/colombia-medellin-landscape-2722716/",
          "wikiLink": "https://en.wikipedia.org/wiki/Medell%C3%ADn"
        },
        "landmarksRound": [
          {
            "code": "SN",
            "number": 2
          },
          {
            "code": "ME",
            "number": 2
          },
          {
            "code": "DZ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Monday Falls",
          "Panfilov Park",
          "Motherland Monument",
          "Ocean Park",
          "Tjibaou Cultural Center",
          "Hazrat Ali Mazar",
          "Tash Rabat"
        ],
        "uniqueId": "5Yx7G2n3",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-architecture-urban-bogota-4457801/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cundinamarca_Department"
        },
        "landmark": {
          "name": "National Shrine Basilica of our Lady of Las Lajas",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/las-lajas-church-las-lajas-sanctuary-6611015/",
          "wikiLink": "https://en.wikipedia.org/wiki/Las_Lajas_Shrine",
          "hasLandmark": true
        },
        "city": {
          "name": "Cali",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-cali-colombia-buildings-sky-3703330/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cali"
        },
        "landmarksRound": [
          {
            "code": "BD",
            "number": 1
          },
          {
            "code": "VN",
            "number": 2
          },
          {
            "code": "EC",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Pyramiden",
          "Pyramids of Meroe",
          "Voortrekker Monument",
          "Senso-ji",
          "Memorial de l'Anse",
          "Tikal National Park",
          "Christiansborg Palace"
        ],
        "uniqueId": "QvrS2VrB",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Comoros",
    "code": "KM",
    "capital": "Moroni",
    "lat": -12.16666666,
    "long": 44.25,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Anjouan_-_Islands_of_Comoros.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Anjouan"
        },
        "landmark": {
          "name": "Mount Karthala",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mount_Karthala#/media/File:Mount_Karthala_(11000398163).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mount_Karthala",
          "hasLandmark": true
        },
        "city": {
          "name": "Moroni",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Moroni_harbour_%282%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Moroni,_Comoros"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "JxcR5Y3r",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/dkeats/5975110268",
          "wikiLink": "https://en.wikipedia.org/wiki/Comoros"
        },
        "landmark": {
          "name": "Chomoni Beach",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/davidstanleytravel/10952257536",
          "wikiLink": "https://en.wikipedia.org/wiki/Comoros",
          "hasLandmark": true
        },
        "city": {
          "name": "Mutsamudu",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mutsamudu#/media/File:Mutsamudu_port1.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mutsamudu"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "brxVPcm7",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Cook Islands",
    "code": "CK",
    "capital": "Avarua",
    "lat": -21.23333333,
    "long": -159.7666667,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://www.tripadvisor.com.au/Tourism-g294328-Cook_Islands-Vacations.html",
          "wikiLink": "https://en.wikipedia.org/wiki/Rarotonga"
        },
        "landmark": {
          "name": "One Foot Island",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Tapuaetai#/media/File:Aitutaki-Motu_Tapuaetai.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tapuaetai",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "pnWmLMgf",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/palms-tropical-beach-tropical-sand-3242342/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cook_Islands"
        },
        "landmark": {
          "name": "Pukapuka",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Pukapuka#/media/File:Pukapuka_Aerial_efs_1280.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Pukapuka",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "w5jqCKSf",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Costa Rica",
    "code": "CR",
    "capital": "San Jose",
    "lat": 10,
    "long": -84,
    "cities": [
      "Cartago",
      "San Jose",
      "Liberia",
      "Alajuela",
      "Limon",
      "Puntarenas"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/-TvZ6VB915M",
          "wikiLink": "https://en.wikipedia.org/wiki/Sloth"
        },
        "landmark": {
          "name": "Monteverde Cloud Forest",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Monteverde_Cloud_Forest_Reserve#/media/File:Monteverde_puente.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Monteverde_Cloud_Forest_Reserve",
          "hasLandmark": true
        },
        "city": {
          "name": "Cartago",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/moutains-cartago-costa-rica-2802462/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cartago,_Costa_Rica"
        },
        "landmarksRound": [
          {
            "code": "FI",
            "number": 1
          },
          {
            "code": "VE",
            "number": 2
          },
          {
            "code": "ME",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Leaning Tower of Pisa",
          "The Grand Palace",
          "Akershus Fortress",
          "Easter Island",
          "Cathedral Our Lady of the Rosary",
          "Wieliczka Salt Mine",
          "St. Peter's Church"
        ],
        "uniqueId": "SPPXfFhh",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/volcano-eruption-costa-rica-arenal-718277/",
          "wikiLink": "https://en.wikipedia.org/wiki/Nuevo_Arenal"
        },
        "landmark": {
          "name": "Arenal Volcano",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/volcano-costa-rica-clouds-blue-sky-2355772/",
          "wikiLink": "https://en.wikipedia.org/wiki/Arenal_Volcano",
          "hasLandmark": true
        },
        "city": {
          "name": "San Jose",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/San_Jos%C3%A9,_Costa_Rica#/media/File:San_Jose_2020_by_@caya_here-2.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/San_Jos%C3%A9,_Costa_Rica"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "5Wz4845y",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Croatia",
    "code": "HR",
    "capital": "Zagreb",
    "lat": 45.16666666,
    "long": 15.5,
    "cities": [
      "Zagreb",
      "Dubrovnik",
      "Zadar",
      "Split",
      "Rijeka",
      "Pula"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/ohoBiu7_KNc",
          "wikiLink": "https://en.wikipedia.org/wiki/Dubrovnik"
        },
        "landmark": {
          "name": "Diocletian's Palace",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/split-croatia-easter-europe-sea-2274113/",
          "wikiLink": "https://en.wikipedia.org/wiki/Diocletian%27s_Palace",
          "hasLandmark": true
        },
        "city": {
          "name": "Zagreb",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/skyline-city-croatia-cityscape-4637445/",
          "wikiLink": "https://en.wikipedia.org/wiki/Zagreb"
        },
        "landmarksRound": [
          {
            "code": "BE",
            "number": 2
          },
          {
            "code": "LS",
            "number": 1
          },
          {
            "code": "KH",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Belvedere Lookout",
          "Tegucigalpa Cathedral",
          "Winter Palace",
          "Wawel Castle",
          "Kaaba",
          "Deception Island",
          "Chillon Castle"
        ],
        "uniqueId": "5JXzM9CP",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/dubrovnik-croatia-kings-landing-512798/",
          "wikiLink": "https://en.wikipedia.org/wiki/Dubrovnik"
        },
        "landmark": {
          "name": "Pula Arena",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/amphitheater-pula-arena-roman-261115/",
          "wikiLink": "https://en.wikipedia.org/wiki/Pula_Arena",
          "hasLandmark": true
        },
        "city": {
          "name": "Dubrovnik",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/dubrovnik-croatia-kings-landing-512798/",
          "wikiLink": "https://en.wikipedia.org/wiki/Dubrovnik"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "ycZ3vnYZ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Cuba",
    "code": "CU",
    "capital": "Havana",
    "lat": 21.5,
    "long": -80,
    "cities": [
      "Havana",
      "Santiago de Cuba",
      "Santa Clara",
      "Cienfuegos",
      "Trinidad",
      "Holguin"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/aZjw7xI3QAA",
          "wikiLink": "https://en.wikipedia.org/wiki/Vi%C3%B1ales"
        },
        "landmark": {
          "name": "Christ of Havana",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/christ-statue-havana-cuba-1116434/",
          "wikiLink": "https://en.wikipedia.org/wiki/Christ_of_Havana",
          "hasLandmark": true
        },
        "city": {
          "name": "Havana",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/havana-cuba-city-urban-sea-wharf-4027210/",
          "wikiLink": "https://en.wikipedia.org/wiki/Havana"
        },
        "landmarksRound": [
          {
            "code": "BE",
            "number": 1
          },
          {
            "code": "MT",
            "number": 1
          },
          {
            "code": "AE",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Baalbek",
          "Amber Palace",
          "Ocean Park",
          "La Sagrada Familia",
          "Great Blue Hole",
          "Liwonde National Park",
          "Fasil Ghebbi"
        ],
        "uniqueId": "GJtltjRy",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cuba-antique-car-automobile-vehicle-1211907/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cuba"
        },
        "landmark": {
          "name": "Castillo De Los Tres Reyes Del Morro",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/fort-castle-santiago-de-cuba-cuba-1872566/",
          "wikiLink": "https://en.wikipedia.org/wiki/Morro_Castle_(Havana)",
          "hasLandmark": true
        },
        "city": {
          "name": "Santiago de Cuba",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/road-alley-city-pavement-cuba-3076970/",
          "wikiLink": "https://en.wikipedia.org/wiki/Santiago_de_Cuba"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "LvSmCKWb",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Curaçao",
    "code": "CW",
    "capital": "Willemstad",
    "lat": 12.116667,
    "long": -68.933333,
    "cities": [
      "Willemstad",
      "Sint Michiel",
      "Sint Willibrordus",
      "Tera Kora",
      "Barber",
      "Otrobanda"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/cKbKFQa32Q4",
          "wikiLink": "https://en.wikipedia.org/wiki/Willemstad"
        },
        "landmark": {
          "name": "Queen Emma Bridge",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Queen_Emma_Bridge#/media/File:View_of_Otrobanda,_Willemstad,_Cura%C3%A7ao_-_February_2020.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Queen_Emma_Bridge",
          "hasLandmark": true
        },
        "city": {
          "name": "Willemstad",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/curacao-willemstad-island-dutch-407320/",
          "wikiLink": "https://en.wikipedia.org/wiki/Willemstad"
        },
        "landmarksRound": [
          {
            "code": "SR",
            "number": 2
          },
          {
            "code": "TR",
            "number": 2
          },
          {
            "code": "CA",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Kravica Waterfall",
          "Hagia Sophia",
          "Kelonia",
          "Cathedral Our Lady of the Rosary",
          "Blue Grotto",
          "Castle Rushden",
          "Panfilov Park"
        ],
        "uniqueId": "bnZPclnG",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Handelskade,_Willemstad,_Cura%C3%A7ao_%284383516303%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Willemstad"
        },
        "landmark": {
          "name": "Hato Caves",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Hato_Caves#/media/File:HATO_CAVES_,_CURACAO.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Hato_Caves",
          "hasLandmark": true
        },
        "city": {
          "name": "Sint Michiel",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Sint_Michiel#/media/File:Pano_Bluebay_front_Cropped.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Sint_Michiel"
        },
        "landmarksRound": [
          {
            "code": "PL",
            "number": 2
          },
          {
            "code": "KY",
            "number": 2
          },
          {
            "code": "FR",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Petronas Towers",
          "Iluissat Icefjord",
          "Cathedral Our Lady of the Rosary",
          "Elmina Castle",
          "Ouzoud Falls",
          "Al Alam Palace",
          "Acropolis"
        ],
        "uniqueId": "8qbbwK2L",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Cyprus",
    "code": "CY",
    "capital": "Nicosia",
    "lat": 35,
    "long": 33,
    "cities": [
      "Nicosia",
      "Larnaca",
      "Paralimni",
      "Limassol",
      "Kyrenia",
      "Paphos"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/i51lWwDYHzQ",
          "wikiLink": "https://en.wikipedia.org/wiki/Larnaca"
        },
        "landmark": {
          "name": "Bellapais Abbey",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/jorge-11/4530565439",
          "wikiLink": "https://en.wikipedia.org/wiki/Bellapais_Abbey",
          "hasLandmark": true
        },
        "city": {
          "name": "Larnaca",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cyprus-larnaca-square-travel-3936046/",
          "wikiLink": "https://en.wikipedia.org/wiki/Larnaca"
        },
        "landmarksRound": [
          {
            "code": "MM",
            "number": 2
          },
          {
            "code": "NA",
            "number": 1
          },
          {
            "code": "CD",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Rijksmuseum",
          "Animal Flower Cave",
          "Hazrat Ali Mazar",
          "Epupa Falls",
          "Liwonde National Park",
          "Valle de Cocora",
          "Alhambra"
        ],
        "uniqueId": "m4kQlJnJ",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cyprus-coast-cliff-cavo-greko-3184019/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cape_Greco"
        },
        "landmark": {
          "name": "Church of Saint Lazarus",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Church_of_Saint_Lazarus,_Larnaca#/media/File:Cyprus-lazarus-church_hg.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Church_of_Saint_Lazarus,_Larnaca",
          "hasLandmark": true
        },
        "city": {
          "name": "Nicosia",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Nicosia#/media/File:Nicosia_panoramic_view_Cyprus_Tower_25_Jean_Nouvel.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Nicosia"
        },
        "landmarksRound": [
          {
            "code": "GS",
            "number": 1
          },
          {
            "code": "IR",
            "number": 1
          },
          {
            "code": "KG",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Atomium",
          "Panfilov Park",
          "National Shrine Basilica of our Lady of Las Lajas",
          "Fort Christian",
          "Temple of Literature",
          "Kaaba",
          "Queen's Staircase"
        ],
        "uniqueId": "lKN4T8m7",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Czechia",
    "code": "CZ",
    "capital": "Prague",
    "lat": 49.75,
    "long": 15.5,
    "cities": [
      "Brno",
      "Pilsen",
      "Prague",
      "Ostrava",
      "Karlovy Vary",
      "Liberec"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/1ak3Z7ZmtQA",
          "wikiLink": "https://en.wikipedia.org/wiki/Charles_Bridge"
        },
        "landmark": {
          "name": "Charles Bridge",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/prague-czech-republic-sunset-city-3540876/",
          "wikiLink": "https://en.wikipedia.org/wiki/Charles_Bridge",
          "hasLandmark": true
        },
        "city": {
          "name": "Brno",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cathedral-church-brno-moravia-dom-7481427/",
          "wikiLink": "https://en.wikipedia.org/wiki/Brno"
        },
        "landmarksRound": [
          {
            "code": "MN",
            "number": 1
          },
          {
            "code": "AD",
            "number": 1
          },
          {
            "code": "CA",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Chamarel Waterfall",
          "CN Tower",
          "Middle of the World City",
          "Maiden Tower",
          "Al Alam Palace",
          "Alexander Nevsky Cathedral",
          "Fasil Ghebbi"
        ],
        "uniqueId": "Nw5jGPmC",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bridges-prague-czechia-water-city-3337124/",
          "wikiLink": "https://en.wikipedia.org/wiki/Prague"
        },
        "landmark": {
          "name": "Old Town Square",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/prague-old-town-czech-cafe-tourist-2229156/",
          "wikiLink": "https://en.wikipedia.org/wiki/Old_Town_Square",
          "hasLandmark": true
        },
        "city": {
          "name": "Pilsen",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/pilsen-czech-republic-city-6472695/",
          "wikiLink": "https://en.wikipedia.org/wiki/Plze%C5%88"
        },
        "landmarksRound": [
          {
            "code": "LA",
            "number": 2
          },
          {
            "code": "KH",
            "number": 2
          },
          {
            "code": "PK",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Dubai Frame",
          "Castillo San Cristobal",
          "Anne Frank House",
          "Salvo Palace",
          "Trevi Fountain",
          "Chichen Itza",
          "Amalienborg"
        ],
        "uniqueId": "x97DcHgV",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Democratic Republic of Congo",
    "code": "CD",
    "capital": "Kinshasa",
    "lat": 0,
    "long": 25,
    "cities": [
      "Kisangani",
      "Kinshasa",
      "Bukavu",
      "Kananga",
      "Goma",
      "Lubumbashi"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/river-trading-4215417/",
          "wikiLink": "https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo"
        },
        "landmark": {
          "name": "Lake Kivu",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/lake-lake-kivu-africa-sunset-7345240/",
          "wikiLink": "https://en.wikipedia.org/wiki/Lake_Kivu",
          "hasLandmark": true
        },
        "city": {
          "name": "Kisangani",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Kisangani#/media/File:Kisangani_rond-point_Cath%C3%A9drale_et_Congo_Palace.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kisangani"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Q9vpH5tF",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixnio.com/travel/classrooms-at-the-lusakela-agriculture-station-democratic-republic-of-congo-africa",
          "wikiLink": "https://en.wikipedia.org/wiki/Democratic_Republic_of_the_Congo"
        },
        "landmark": {
          "name": "Mount Nyriagongo",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mount_Nyiragongo#/media/File:Lava_Lake_Nyiragongo_2.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mount_Nyiragongo",
          "hasLandmark": true
        },
        "city": {
          "name": "Kinshasa",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/buildings-road-city-cars-stree-7122158/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kinshasa"
        },
        "landmarksRound": [
          {
            "code": "PY",
            "number": 1
          },
          {
            "code": "FO",
            "number": 2
          },
          {
            "code": "AM",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Tenaru Falls",
          "Tavarua",
          "St. Alexander Nevsky Cathedral",
          "Chichen Itza",
          "Kaaba",
          "Chimi Lhakhang",
          "Alhambra"
        ],
        "uniqueId": "T6n4fNQP",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Denmark",
    "code": "DK",
    "capital": "Copenhagen",
    "lat": 56,
    "long": 10,
    "cities": [
      "Aarhus",
      "Copenhagen",
      "Odense",
      "Esbjerg",
      "Roskilde",
      "Aalborg"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/imLsDPLnr7Y",
          "wikiLink": "https://en.wikipedia.org/wiki/L%C3%B8kken,_Denmark"
        },
        "landmark": {
          "name": "Amalienborg",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/amalienborg-palace-copenhagen-954884/",
          "wikiLink": "https://en.wikipedia.org/wiki/Amalienborg",
          "hasLandmark": true
        },
        "city": {
          "name": "Aalborg",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cityscape-skyline-buildings-city-5587226/",
          "wikiLink": "https://en.wikipedia.org/wiki/Aalborg"
        },
        "landmarksRound": [
          {
            "code": "BR",
            "number": 1
          },
          {
            "code": "MQ",
            "number": 1
          },
          {
            "code": "BG",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Stone House",
          "Bran Castle",
          "Soroca Fort",
          "Rila Monastery",
          "Zaisan Memorial",
          "Bahrain Fort",
          "Hagia Sophia"
        ],
        "uniqueId": "NVNtMM48",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/lighthouse-landmark-coast-tower-5606162/",
          "wikiLink": "https://en.wikipedia.org/wiki/Skagen_Lighthouse"
        },
        "landmark": {
          "name": "Christiansborg Palace",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/christiansborg-palace-7032184/",
          "wikiLink": "https://en.wikipedia.org/wiki/Christiansborg_Palace",
          "hasLandmark": true
        },
        "city": {
          "name": "Aarhus",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/aarhus-%c3%a5en-city-life-river-side-949700/",
          "wikiLink": "https://en.wikipedia.org/wiki/Aarhus"
        },
        "landmarksRound": [
          {
            "code": "MQ",
            "number": 1
          },
          {
            "code": "US",
            "number": 1
          },
          {
            "code": "GL",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Bellapais Abbey",
          "Frogner Park",
          "Castle Cornet",
          "Christ of Havana",
          "Blue Lagoon",
          "Chichen Itza",
          "Arecibo Observatory"
        ],
        "uniqueId": "YKFW9Gdd",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Djibouti",
    "code": "DJ",
    "capital": "Djibouti",
    "lat": 11.5,
    "long": 43,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/vmfKwrqkOkA",
          "wikiLink": "https://en.wikipedia.org/wiki/Lake_Abbe"
        },
        "landmark": {
          "name": "Mosquee Al-Hamoudi",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Hamoudi_Mosque#/media/File:Hamoudi_Mosque_(210510633).jpeg",
          "wikiLink": "https://en.wikipedia.org/wiki/Hamoudi_Mosque",
          "hasLandmark": true
        },
        "city": {
          "name": "Tadjourah",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Tadjoura#/media/File:Tagore.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Lake_Assal_(Djibouti)"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "gLLHKCdq",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/djibouti-africa-ras-bir-beach-sea-143451/",
          "wikiLink": "https://en.wikipedia.org/wiki/Djibouti"
        },
        "landmark": {
          "name": "Lac 'Assal",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Lake_Assal_(Djibouti)#/media/File:Lake_Assal_3-Djibouti.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Lake_Assal_(Djibouti)",
          "hasLandmark": true
        },
        "city": {
          "name": "Djibouti City",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Djibplamar.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Djibouti_City"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "NRtDFrPX",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Dominica",
    "code": "DM",
    "capital": "Roseau",
    "lat": 15.41666666,
    "long": -61.33333333,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/xQmCJOklax4",
          "wikiLink": "https://en.wikipedia.org/wiki/Roseau"
        },
        "landmark": {
          "name": "Trafalgar Falls",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Trafalgar_Falls.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Dominica",
          "hasLandmark": true
        },
        "city": {
          "name": "Portsmouth",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Portsmouth,_Dominica#/media/File:Portsmouth_(Dominica).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Portsmouth,_Dominica"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Y7QFCcGL",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/dominica-roseau-caribbean-holiday-4645317/",
          "wikiLink": "https://en.wikipedia.org/wiki/Dominica"
        },
        "landmark": {
          "name": "Emerald Pool",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Emerald_Pool,_Dominica.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Emerald_Pool",
          "hasLandmark": true
        },
        "city": {
          "name": "Roseau",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/dominica-roseau-caribbean-holiday-4645317/",
          "wikiLink": "https://en.wikipedia.org/wiki/Roseau"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "QkVTVWKw",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Dominican Republic",
    "code": "DO",
    "capital": "Santo Domingo",
    "lat": 19,
    "long": -70.66666666,
    "cities": [
      "Santo Domingo",
      "Santiago De Los Caballeros",
      "La Romana",
      "Puerto Plata",
      "Higuey",
      "San Pedro De Macoris"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/r-YpH_-pd34",
          "wikiLink": "https://en.wikipedia.org/wiki/Ciudad_Colonial_(Santo_Domingo)"
        },
        "landmark": {
          "name": "Columbus Park",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Parque_Colon#/media/File:Gente_CCSD_07_2018_0662.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Parque_Colon",
          "hasLandmark": true
        },
        "city": {
          "name": "Santo Domingo",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/market-shops-street-urban-business-6389041/",
          "wikiLink": "https://en.wikipedia.org/wiki/Santo_Domingo"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "khPvl7nn",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/dominican-republic-caribbean-sea-321214/",
          "wikiLink": "https://en.wikipedia.org/wiki/Dominican_Republic"
        },
        "landmark": {
          "name": "Monument to the Heroes of the Restoration",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Monumento_de_Santiago#/media/File:Monumento_a_los_H%C3%A9roes_de_la_Restauraci%C3%B3n.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Monumento_de_Santiago",
          "hasLandmark": true
        },
        "city": {
          "name": "Santiago De Los Caballeros",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Santiago_de_los_Caballeros#/media/File:Santiago-edit.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Santiago_de_los_Caballeros"
        },
        "landmarksRound": [
          {
            "code": "MC",
            "number": 1
          },
          {
            "code": "SY",
            "number": 2
          },
          {
            "code": "MN",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Kittiwake Shipwreck",
          "Church of Our Savior",
          "Dhakeshwari Temple",
          "Temple of Literature",
          "Wawel Castle",
          "Hato Caves",
          "Romney Manor"
        ],
        "uniqueId": "vHCmxXdd",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Ecuador",
    "code": "EC",
    "capital": "Quito",
    "lat": -2,
    "long": -77.5,
    "cities": [
      "Quito",
      "Guayaquil",
      "Loja",
      "Cuenca",
      "Ambato",
      "Manta"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/Gz-ZBb6-mrE",
          "wikiLink": "https://en.wikipedia.org/wiki/Marine_iguana"
        },
        "landmark": {
          "name": "Middle of the World City",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ciudad_Mitad_del_Mundo#/media/File:Mitad_del_Mundo,_Quito,_Ecuador,_2015-07-22,_DD_15.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Ciudad_Mitad_del_Mundo",
          "hasLandmark": true
        },
        "city": {
          "name": "Quito",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bas%C3%ADlica_del_Voto_Nacional#/media/File:Bas%C3%ADlica_del_Voto_Nacional_from_El_Panecillo.jpeg",
          "wikiLink": "https://en.wikipedia.org/wiki/Quito"
        },
        "landmarksRound": [
          {
            "code": "NO",
            "number": 1
          },
          {
            "code": "KN",
            "number": 2
          },
          {
            "code": "FR",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Timgad",
          "Rhine Falls",
          "Temple of Literature",
          "Acropolis",
          "St. Tryphon's Cathedral",
          "Arecibo Observatory",
          "La Sagrada Familia"
        ],
        "uniqueId": "kC8Dyk2h",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/lake-wetland-mountains-landscape-6791971/",
          "wikiLink": "https://en.wikipedia.org/wiki/Ecuador"
        },
        "landmark": {
          "name": "Basilica del Voto Nacional",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bas%C3%ADlica_del_Voto_Nacional#/media/File:Bas%C3%ADlica_del_Voto_Nacional_-_Clock_towers.jpeg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bas%C3%ADlica_del_Voto_Nacional",
          "hasLandmark": true
        },
        "city": {
          "name": "Guayaquil",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bella-vista-guayaquil-ecuador-7456957/",
          "wikiLink": "https://en.wikipedia.org/wiki/Guayaquil"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "n7M9B5Z7",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Egypt",
    "code": "EG",
    "capital": "Cairo",
    "lat": 27,
    "long": 30,
    "cities": [
      "Giza",
      "Cairo",
      "Luxor",
      "Aswan",
      "Alexandria",
      "Faiyum"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/pwMbtwA9LRc",
          "wikiLink": "https://en.wikipedia.org/wiki/Mosque-Madrasa_of_Sultan_Hassan"
        },
        "landmark": {
          "name": "Karnak",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Karnak#/media/File:Temple_de_Louxor_68.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Karnak",
          "hasLandmark": true
        },
        "city": {
          "name": "Alexandria",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/poor-crowded-living-washing-day-942712/",
          "wikiLink": "https://en.wikipedia.org/wiki/Alexandria"
        },
        "landmarksRound": [
          {
            "code": "TM",
            "number": 2
          },
          {
            "code": "AT",
            "number": 1
          },
          {
            "code": "MM",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Frogner Park",
          "Dubai Frame",
          "Chorsu Bazaar",
          "The Marble Mountains",
          "Predjama Castle",
          "Narikala Fortress",
          "Bled Castle"
        ],
        "uniqueId": "ZG2dbkqn",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/felucca-nile-sunset-flow-sailboat-7237715/",
          "wikiLink": "https://en.wikipedia.org/wiki/Nile"
        },
        "landmark": {
          "name": "Abu Simbel Temples",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/abu-simbel-temple-egypt-luxor-2703666/",
          "wikiLink": "https://en.wikipedia.org/wiki/Abu_Simbel",
          "hasLandmark": true
        },
        "city": {
          "name": "Giza",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sahara-tourism-travel-vista-6870770/",
          "wikiLink": "https://en.wikipedia.org/wiki/Giza"
        },
        "landmarksRound": [
          {
            "code": "MR",
            "number": 1
          },
          {
            "code": "OM",
            "number": 1
          },
          {
            "code": "GD",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Chillon Castle",
          "Easter Island",
          "Christ Church Cathedral",
          "Angel Falls",
          "Pulhapanzak Waterfalls",
          "Belem Tower",
          "St. Olaf's Church"
        ],
        "uniqueId": "LWZblL35",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "El Salvador",
    "code": "SV",
    "capital": "San Salvador",
    "lat": 13.83333333,
    "long": -88.91666666,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/gLysUycgcF4",
          "wikiLink": "https://en.wikipedia.org/wiki/Plaza_Gerardo_Barrios"
        },
        "landmark": {
          "name": "Metropolitan Cathedral of San Salvador",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/San_Salvador_Cathedral#/media/File:Fachada_de_Catedral_Metropolitana_de_San_Salvador.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/San_Salvador_Cathedral",
          "hasLandmark": true
        },
        "city": {
          "name": "Santa Ana",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Santa_Ana,_El_Salvador#/media/File:ES_Palacio_Municipal_Santa_Ana_05_2012_1597.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Santa_Ana,_El_Salvador"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "rCr9QmgC",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/el-salvador-cerro-el-pimp-moutains-1820097/",
          "wikiLink": "https://en.wikipedia.org/wiki/El_Salvador"
        },
        "landmark": {
          "name": "Joya De Ceren Archeological Site",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Joya_de_Cer%C3%A9n#/media/File:ES_Estructura_1_Area_1_Joya_Ceren_05_2012_1513.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Joya_de_Cer%C3%A9n",
          "hasLandmark": true
        },
        "city": {
          "name": "San Miguel",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/San_Miguel,_El_Salvador#/media/File:Hospital_Sn_Francisco.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/San_Miguel,_El_Salvador"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "JBpVfTyk",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Equatorial Guinea",
    "code": "GQ",
    "capital": "Malabo",
    "lat": 2,
    "long": 10,
    "cities": [
      "Malabo",
      "Bata",
      "Luba",
      "Mongomo",
      "Evinayong",
      "Aconibe"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/equatorial_guinea/4641618625",
          "wikiLink": "https://en.wikipedia.org/wiki/Equatorial_Guinea"
        },
        "landmark": {
          "name": "Pico Basile",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Pico_Basil%C3%A9#/media/File:Pico_Basil%C3%A9.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Pico_Basil%C3%A9",
          "hasLandmark": true
        },
        "city": {
          "name": "Bata",
          "photographer": "",
          "imageLink": "https://commons.m.wikimedia.org/wiki/File:Bata,_2015-02_-i---i-_%2816513134635%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bata,_Equatorial_Guinea"
        },
        "landmarksRound": [
          {
            "code": "TH",
            "number": 2
          },
          {
            "code": "AL",
            "number": 1
          },
          {
            "code": "FI",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Bran Castle",
          "Tikal National Park",
          "Karnak",
          "Voortrekker Monument",
          "Forbidden City",
          "Skopje Fortress",
          "Fort Sao Sebastiao"
        ],
        "uniqueId": "6ppRjRfT",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/equatorial_guinea/5238701955",
          "wikiLink": "https://en.wikipedia.org/wiki/Malabo"
        },
        "landmark": {
          "name": "Torre de la Libertad",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Torre_de_La_Libertad#/media/File:Torre_de_la_Libertad.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Torre_de_La_Libertad",
          "hasLandmark": true
        },
        "city": {
          "name": "Malabo",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/wapster/3297513032",
          "wikiLink": "https://en.wikipedia.org/wiki/Malabo"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "RKNFrhY2",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Eritrea",
    "code": "ER",
    "capital": "Asmara",
    "lat": 15,
    "long": 39,
    "cities": [
      "Massawa",
      "Asmara",
      "Mitsiwa",
      "Assab",
      "Adi Ugri",
      "Keren"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/eritrea-landscape-tents-huts-105081/",
          "wikiLink": "https://en.wikipedia.org/wiki/Eritrea"
        },
        "landmark": {
          "name": "Fiat Tagliero",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Fiat_Tagliero_Building#/media/File:Fiat_tagliero,_08.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Fiat_Tagliero_Building",
          "hasLandmark": true
        },
        "city": {
          "name": "Massawa",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Massawa#/media/File:Massawa_Housing_Complex_(8527948059).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Massawa"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "2w6BBTdD",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/eritrea-mountains-valley-landscape-114822/",
          "wikiLink": "https://en.wikipedia.org/wiki/Eritrea"
        },
        "landmark": {
          "name": "Cathedral Our Lady of the Rosary",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Church_of_Our_Lady_of_the_Rosary,_Asmara#/media/File:Asmara,_cattedrale_cattolica,_01.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Church_of_Our_Lady_of_the_Rosary,_Asmara",
          "hasLandmark": true
        },
        "city": {
          "name": "Asmara",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Asmara#/media/File:Asmara_panorama,_Eritrea.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Asmara"
        },
        "landmarksRound": [
          {
            "code": "NP",
            "number": 1
          },
          {
            "code": "MQ",
            "number": 1
          },
          {
            "code": "ZA",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Mount Paget",
          "Monument to the Heroes of the Restoration",
          "Queen's Staircase",
          "Tsingy De Bemaraha",
          "Jardin de Balata",
          "Christ of Havana",
          "Buenos Aires Obelisk"
        ],
        "uniqueId": "tgcXkSqh",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Estonia",
    "code": "EE",
    "capital": "Tallinn",
    "lat": 59,
    "long": 26,
    "cities": [
      "Tartu",
      "Tallinn",
      "Narva",
      "Haapsalu",
      "Parnu",
      "Viljandi"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/2OSEWkQHiGI",
          "wikiLink": "https://en.wikipedia.org/wiki/Tallinn_Old_Town"
        },
        "landmark": {
          "name": "St. Olaf's Church",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Tallinn,_St._Olaf%27s_Church.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/St._Olaf%27s_Church,_Tallinn",
          "hasLandmark": true
        },
        "city": {
          "name": "Tartu",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/river-city-cityscape-estonia-tartu-7764765/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tartu"
        },
        "landmarksRound": [
          {
            "code": "GS",
            "number": 1
          },
          {
            "code": "LY",
            "number": 2
          },
          {
            "code": "PE",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Annandale Waterfall",
          "Saint Sava Temple",
          "Chorsu Bazaar",
          "Blue Grotto",
          "Acropolis",
          "Tokyo Skytree",
          "Mulafossur Waterfall"
        ],
        "uniqueId": "6glKjrwF",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/estonia-tallinn-roofs-architecture-912315/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tallinn"
        },
        "landmark": {
          "name": "Alexander Nevsky Cathedral",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/estonia-tallinn-3731467/",
          "wikiLink": "https://en.wikipedia.org/wiki/Alexander_Nevsky_Cathedral,_Tallinn",
          "hasLandmark": true
        },
        "city": {
          "name": "Tallinn",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/estonia-tallinn-historic-center-3737128/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tallinn"
        },
        "landmarksRound": [
          {
            "code": "SY",
            "number": 2
          },
          {
            "code": "ER",
            "number": 2
          },
          {
            "code": "CW",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Al Fateh Grand Mosque",
          "Tian Tan Buddha",
          "Wat Xiengthong",
          "Umayyad Mosque",
          "English Harbour",
          "Gyeongbukgong Palace",
          "Ruins of St. Paul's"
        ],
        "uniqueId": "VHT27WRH",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Eswatini",
    "code": "SZ",
    "capital": "Lobamba",
    "lat": -26.5,
    "long": 31.5,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/swaziland-africa-village-swazi-2035749/",
          "wikiLink": "https://en.wikipedia.org/wiki/Eswatini"
        },
        "landmark": {
          "name": "Sibebe Rock",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Sibebe#/media/File:Granite_monolith.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Sibebe",
          "hasLandmark": true
        },
        "city": {
          "name": "Manzini",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Manzini,_Eswatini#/media/File:SZ-manzini-zufahrt.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Manzini,_Eswatini"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "l5TX9zwZ",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/File:Central_Bank_of_Eswatini.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Central_Bank_of_Eswatini"
        },
        "landmark": {
          "name": "Milwane Wildlife Sanctuary",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mlilwane_Wildlife_Sanctuary#/media/File:Zabras_in_Mlilwane_Wildlife_Sanctuary.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mlilwane_Wildlife_Sanctuary",
          "hasLandmark": true
        },
        "city": {
          "name": "Mbabane",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-mbabane-landscape-town-3862466/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mbabane"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "tMdYMcZT",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Ethiopia",
    "code": "ET",
    "capital": "Addis Ababa",
    "lat": 8,
    "long": 38,
    "cities": [
      "Dire Dawa",
      "Addis Ababa",
      "Gondar",
      "Adama",
      "Bahir Dar",
      "Hawassa"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/TgaK_57wRWU",
          "wikiLink": "https://en.wikipedia.org/wiki/Danakil_Desert"
        },
        "landmark": {
          "name": "Fasil Ghebbi",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Fasilides_Palace_02.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Fasil_Ghebbi",
          "hasLandmark": true
        },
        "city": {
          "name": "Dire Dawa",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Dire_Dawa#/media/File:ET-Dire_Dawa,_Ethiopia_(13).JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Dire_Dawa"
        },
        "landmarksRound": [
          {
            "code": "TJ",
            "number": 1
          },
          {
            "code": "SG",
            "number": 1
          },
          {
            "code": "AG",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Charles Bridge",
          "Ocean Park",
          "Temple of Literature",
          "Turku Castle",
          "Roman theatre",
          "Mir Castle",
          "Bratislava Castle"
        ],
        "uniqueId": "mmd9kktk",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/tricycle-road-hills-wet-road-5700034/",
          "wikiLink": "https://en.wikipedia.org/wiki/Ethiopia"
        },
        "landmark": {
          "name": "Obelisk of Axum",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Obelisk_of_Axum#/media/File:Rome_Stele.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Obelisk_of_Axum",
          "hasLandmark": true
        },
        "city": {
          "name": "Addis Ababa",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Addis_Ababa#/media/File:Addis_Ababa_Skyline_2023.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Addis_Ababa"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "zJpVv4dq",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Falkland Islands",
    "code": "FK",
    "capital": "Stanley",
    "lat": -51.75,
    "long": -59,
    "cities": [
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/KMLTYWDi0GA",
          "wikiLink": "https://en.wikipedia.org/wiki/Rockhopper_penguin"
        },
        "landmark": {
          "name": "Saunders Island",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Saunders_Island,_Falkland_Islands#/media/File:Necksaundersisland2.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Saunders_Island,_Falkland_Islands",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "4BmPQsgS",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cruise-falkland-islands-voyage-4709457/",
          "wikiLink": "https://en.wikipedia.org/wiki/Falkland_Islands"
        },
        "landmark": {
          "name": "Christ Church Cathedral",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Christ_Church_Cathedral_(Falkland_Islands)#/media/File:FAL-2016-Stanley,_Falkland_Islands%E2%80%93Christ_Church_Cathedral.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Christ_Church_Cathedral_(Falkland_Islands)",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [
          {
            "code": "MZ",
            "number": 1
          },
          {
            "code": "CN",
            "number": 2
          },
          {
            "code": "SY",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Magnus Cathedral",
          "Noravank Monastery",
          "Lukan Longshan Temple",
          "Bratislava Castle",
          "Arc de Triomphe",
          "Narikala Fortress",
          "Trevi Fountain"
        ],
        "uniqueId": "BgGYK5Cd",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Faroe Islands",
    "code": "FO",
    "capital": "Torshavn",
    "lat": 62,
    "long": -7,
    "cities": [
      "Torshavn",
      "Hoyvik",
      "Vagur",
      "Argir",
      "Klaksvik",
      "Sandavagur"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/NrP9I1utqug",
          "wikiLink": "https://en.wikipedia.org/wiki/G%C3%A1sadalur"
        },
        "landmark": {
          "name": "Magnus Cathedral",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Magnus_Cathedral#/media/File:Kirkjub%C3%B8ur,_Faroe_Islands.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Magnus_Cathedral",
          "hasLandmark": true
        },
        "city": {
          "name": "Hoyvik",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Hoyv%C3%ADk#/media/File:Faroe_Islands,_Streymoy,_T%C3%B3rshavn_(3).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Hoyv%C3%ADk"
        },
        "landmarksRound": [
          {
            "code": "AF",
            "number": 2
          },
          {
            "code": "UY",
            "number": 1
          },
          {
            "code": "YE",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Villa Folio",
          "Mount Kenya",
          "Hagia Sophia",
          "Mount Yasur",
          "The Grand Palace",
          "Amalienborg",
          "Liwonde National Park"
        ],
        "uniqueId": "G74GrBSc",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sea-ocean-rocks-faroe-islands-7233669/",
          "wikiLink": "https://en.wikipedia.org/wiki/Faroe_Islands"
        },
        "landmark": {
          "name": "Mulafossur Waterfall",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mulafossur-g%c3%a1sadalur-gasadalur-3632137/",
          "wikiLink": "https://en.wikipedia.org/wiki/Faroe_Islands",
          "hasLandmark": true
        },
        "city": {
          "name": "Torshavn",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Tinganes_57.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/T%C3%B3rshavn"
        },
        "landmarksRound": [
          {
            "code": "DK",
            "number": 2
          },
          {
            "code": "AQ",
            "number": 1
          },
          {
            "code": "PA",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Petronas Towers",
          "Skopje Fortress",
          "Senso-ji",
          "Brandenburg Gate",
          "Ostrog Monastery",
          "Palace of Versailles",
          "Tsingy De Bemaraha"
        ],
        "uniqueId": "LKy2NT5R",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Fiji",
    "code": "FJ",
    "capital": "Suva",
    "lat": -18,
    "long": 175,
    "cities": [
      "Suva",
      "Labasa",
      "Ba",
      "Lautoka",
      "Levuka",
      "Nadi"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/Gg2VVz2ycAc",
          "wikiLink": "https://en.wikipedia.org/wiki/Kuata"
        },
        "landmark": {
          "name": "Tavarua",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Tavarua#/media/File:Tavarua_Island,_Fiji.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Tavarua",
          "hasLandmark": true
        },
        "city": {
          "name": "Nadi",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Nadi#/media/File:Nadi.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Nadi"
        },
        "landmarksRound": [
          {
            "code": "CL",
            "number": 1
          },
          {
            "code": "SD",
            "number": 2
          },
          {
            "code": "CO",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Tokyo Skytree",
          "Grytviken Church",
          "Amberd",
          "Queen's Staircase",
          "Monte Carlo Casino",
          "Atomium",
          "Cape Coast Castle"
        ],
        "uniqueId": "bXnnLn6J",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/fiji-south-seas-vacations-island-3839354/",
          "wikiLink": "https://en.wikipedia.org/wiki/Fiji"
        },
        "landmark": {
          "name": "Qalito Island",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Castaway.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Castaway_Island",
          "hasLandmark": true
        },
        "city": {
          "name": "Suva",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Suva#/media/File:Suva,_Fiji_76.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Suva"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "4Wk9y4nL",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Finland",
    "code": "FI",
    "capital": "Helsinki",
    "lat": 64,
    "long": 26,
    "cities": [
      "Tampere",
      "Helsinki",
      "Turku",
      "Espoo",
      "Oulu",
      "Kuopio"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/I9SWvZ9sO2U",
          "wikiLink": "https://en.wikipedia.org/wiki/Aleksanterinkatu"
        },
        "landmark": {
          "name": "Turku Castle",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/architecture-old-building-gothic-3316484/",
          "wikiLink": "https://en.wikipedia.org/wiki/Turku_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Tampere",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/night-dark-city-the-dark-sky-winter-2126404/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tampere"
        },
        "landmarksRound": [
          {
            "code": "AU",
            "number": 1
          },
          {
            "code": "SK",
            "number": 1
          },
          {
            "code": "TM",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Island of Mozambique",
          "Kittiwake Shipwreck",
          "Santa Catalina Arch",
          "Deception Island",
          "Cliffs of Moher",
          "Tomb of Hafez",
          "Angel Falls"
        ],
        "uniqueId": "myF2CXDV",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/lake-forest-nature-landscape-water-5045059/",
          "wikiLink": "https://en.wikipedia.org/wiki/Finland"
        },
        "landmark": {
          "name": "Olavinlinna",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/castle-olavinlinna-savonlinna-6940332/",
          "wikiLink": "https://en.wikipedia.org/wiki/Olavinlinna",
          "hasLandmark": true
        },
        "city": {
          "name": "Helsinki",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/helsinki-cathedral-cathedral-church-4189824/",
          "wikiLink": "https://en.wikipedia.org/wiki/Helsinki"
        },
        "landmarksRound": [
          {
            "code": "CW",
            "number": 1
          },
          {
            "code": "MU",
            "number": 1
          },
          {
            "code": "TW",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Timgad",
          "Somapura Mahavihara",
          "Ampitheater of El Jem",
          "Stone House",
          "Elmina Castle",
          "Christiansborg Palace",
          "Deadvlei"
        ],
        "uniqueId": "65sfmwGC",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "France",
    "code": "FR",
    "capital": "Paris",
    "lat": 46,
    "long": 2,
    "cities": [
      "Marseille",
      "Nice",
      "Paris",
      "Bordeaux",
      "Toulouse",
      "Lyon"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/ZtdNFSpugQE",
          "wikiLink": "https://en.wikipedia.org/wiki/%C3%89tretat"
        },
        "landmark": {
          "name": "Arc de Triomphe",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/arc-de-triomphe-paris-landmark-5432392/",
          "wikiLink": "https://en.wikipedia.org/wiki/Arc_de_Triomphe",
          "hasLandmark": true
        },
        "city": {
          "name": "Marseille",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/marseille-cathedral-the-major-1334093/",
          "wikiLink": "https://en.wikipedia.org/wiki/Marseille"
        },
        "landmarksRound": [
          {
            "code": "JM",
            "number": 1
          },
          {
            "code": "MN",
            "number": 1
          },
          {
            "code": "MK",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Abu Simbel Temples",
          "Ocean Park",
          "Pigeon Rocks",
          "CN Tower",
          "Wat Arun",
          "Chinguetti",
          "Saint Michael's Cave"
        ],
        "uniqueId": "cl33yTgt",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/vineyard-field-rural-burgundy-6399505/",
          "wikiLink": "https://en.wikipedia.org/wiki/Burgundy"
        },
        "landmark": {
          "name": "Palace of Versailles",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/castle-palace-fortress-building-6356704/",
          "wikiLink": "https://en.wikipedia.org/wiki/Palace_of_Versailles",
          "hasLandmark": true
        },
        "city": {
          "name": "Nice",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/nice-city-sea-france-panorama-4646272/",
          "wikiLink": "https://en.wikipedia.org/wiki/Nice"
        },
        "landmarksRound": [
          {
            "code": "AL",
            "number": 1
          },
          {
            "code": "ET",
            "number": 1
          },
          {
            "code": "KZ",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Devin Castle",
          "Stone House",
          "Turku Castle",
          "Burj Khalifa",
          "Church of Our Savior",
          "Stonehenge",
          "Mostar Old Bridge"
        ],
        "uniqueId": "Lnl5JlcH",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "French Guiana",
    "code": "GF",
    "capital": "Cayenne",
    "lat": 4,
    "long": -53,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sea-shore-orla-cayenne-2060138/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cayenne"
        },
        "landmark": {
          "name": "Devil's Island",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/amanderson/16118324033",
          "wikiLink": "https://en.wikipedia.org/wiki/Devil%27s_Island",
          "hasLandmark": true
        },
        "city": {
          "name": "Matoury",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Matoury#/media/File:Larivot.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Matoury"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "QDCDMm2y",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/french-guiana-rainforest-forest-1207208/",
          "wikiLink": "https://en.wikipedia.org/wiki/French_Guiana"
        },
        "landmark": {
          "name": "Fort Ceperou",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Fort_C%C3%A9p%C3%A9rou#/media/File:Cayenne_Fort_C%C3%A9p%C3%A9rou_2013.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Fort_C%C3%A9p%C3%A9rou",
          "hasLandmark": true
        },
        "city": {
          "name": "Cayenne",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Cayenne#/media/File:Cayenne_city_(8525272038).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Cayenne"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "p8sKFFFR",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "French Polynesia",
    "code": "PF",
    "capital": "Papeete",
    "lat": -15,
    "long": -140,
    "cities": [
      "Papeete",
      "Arue",
      "Vaitape",
      "Papara",
      "Pa'ea",
      "Puna'auia"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/ru_MKgg0sws",
          "wikiLink": "https://en.wikipedia.org/wiki/Mo%27orea"
        },
        "landmark": {
          "name": "Belvedere Lookout",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/michaelrperry/6597138321",
          "wikiLink": "https://en.wikipedia.org/wiki/French_Polynesia",
          "hasLandmark": true
        },
        "city": {
          "name": "Puna'auia",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Punaauia#/media/File:Papeete_-_Marina_Taina.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Punaauia"
        },
        "landmarksRound": [
          {
            "code": "MN",
            "number": 1
          },
          {
            "code": "LB",
            "number": 1
          },
          {
            "code": "IS",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Villa Folio",
          "Mount Yasur",
          "Machu Picchu",
          "Tenaru Falls",
          "Amalienborg",
          "Liwonde National Park",
          "Olavinlinna"
        ],
        "uniqueId": "H8FjylSw",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/moorea-french-polynesia-tropical-680033/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tahiti"
        },
        "landmark": {
          "name": "Arahoho Blowhole",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/ruggybear/48033965818",
          "wikiLink": "https://en.wikipedia.org/wiki/French_Polynesia",
          "hasLandmark": true
        },
        "city": {
          "name": "Papeete",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Papeete#/media/File:FP-papeete-hafen-1.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Papeete"
        },
        "landmarksRound": [
          {
            "code": "TJ",
            "number": 2
          },
          {
            "code": "ES",
            "number": 2
          },
          {
            "code": "GS",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Zaisan Memorial",
          "Al Alam Palace",
          "Acropolis",
          "Somapura Mahavihara",
          "Blue Lagoon",
          "Cologne Cathedral",
          "The Blue Eye"
        ],
        "uniqueId": "hkGlsC3W",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Gabon",
    "code": "GA",
    "capital": "Libreville",
    "lat": -1,
    "long": 11.75,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/75Q_rXHFLXs",
          "wikiLink": "https://en.wikipedia.org/wiki/Gabon"
        },
        "landmark": {
          "name": "Lope National Park",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Elephants_in_Lop%C3%A9_National_Park.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Lop%C3%A9_National_Park",
          "hasLandmark": true
        },
        "city": {
          "name": "Port-Gentil",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Port-Gentil#/media/File:POG-From_The_Air.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Port-Gentil"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "ZTq9TJsf",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/africa-gabon-people-travels-379378/",
          "wikiLink": "https://en.wikipedia.org/wiki/Gabon"
        },
        "landmark": {
          "name": "Akanda National Park",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/gabon-sunset-color-sea-ocean-3871786/",
          "wikiLink": "https://en.wikipedia.org/wiki/Akanda_National_Park",
          "hasLandmark": true
        },
        "city": {
          "name": "Libreville",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Libreville#/media/File:Portlbv.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Libreville"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "BRXX6CmP",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Gambia",
    "code": "GM",
    "capital": "Banjul",
    "lat": 13.46666666,
    "long": -16.56666666,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/GygPFmXGD1o",
          "wikiLink": "https://en.wikipedia.org/wiki/Gambia_River"
        },
        "landmark": {
          "name": "Kunta Kinteh Island",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/island-river-boat-tourism-africa-2657237/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kunta_Kinteh_Island",
          "hasLandmark": true
        },
        "city": {
          "name": "Brikama",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Brikama#/media/File:Brikama_Nyambai_Road.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Brikama"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "GCLYFD4X",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/river-scene-gambia-fishing-village-2667905/",
          "wikiLink": "https://en.wikipedia.org/wiki/The_Gambia"
        },
        "landmark": {
          "name": "Arch 22",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Arch_22#/media/File:Gambia_banjul_arch22.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Arch_22",
          "hasLandmark": true
        },
        "city": {
          "name": "Serekunda",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Serekunda_market.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Serekunda"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Yg9t4NK6",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Georgia",
    "code": "GE",
    "capital": "Tbilisi",
    "lat": 42,
    "long": 43.5,
    "cities": [
      "Tbilisi",
      "Batumi",
      "Gori",
      "Poti",
      "Kutaisi",
      "Rustavi"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/5iQWgow3_S0",
          "wikiLink": "https://en.wikipedia.org/wiki/Gergeti_Trinity_Church"
        },
        "landmark": {
          "name": "Narikala Fortress",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/tbilisi-georgia-travels-fortress-3643368/",
          "wikiLink": "https://en.wikipedia.org/wiki/Narikala",
          "hasLandmark": true
        },
        "city": {
          "name": "Tbilisi",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/church-tbilisi-architecture-georgia-4580337/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tbilisi"
        },
        "landmarksRound": [
          {
            "code": "BG",
            "number": 1
          },
          {
            "code": "PE",
            "number": 1
          },
          {
            "code": "SR",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Burj Khalifa",
          "Liwonde National Park",
          "Monument to the Heroes of the Restoration",
          "Huka Falls",
          "Panfilov Park",
          "Nizwa Fort",
          "Blue Grotto"
        ],
        "uniqueId": "sWpnwnZJ",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/caucasus-mountains-georgia-country-3110386/",
          "wikiLink": "https://en.wikipedia.org/wiki/Georgia_(country)"
        },
        "landmark": {
          "name": "Vardzia",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/vardzia-cave-city-landscape-5187928/",
          "wikiLink": "https://en.wikipedia.org/wiki/Vardzia",
          "hasLandmark": true
        },
        "city": {
          "name": "Batumi",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/batumi-georgia-adjara-view-resort-4926134/",
          "wikiLink": "https://en.wikipedia.org/wiki/Batumi"
        },
        "landmarksRound": [
          {
            "code": "TH",
            "number": 1
          },
          {
            "code": "CN",
            "number": 1
          },
          {
            "code": "LY",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Tokyo Skytree",
          "Seven Colored Earths",
          "Senso-ji",
          "Gravensteen",
          "Karnak",
          "Hazrat Ali Mazar",
          "Tikal National Park"
        ],
        "uniqueId": "9qYHpHbZ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Germany",
    "code": "DE",
    "capital": "Berlin",
    "lat": 51,
    "long": 9,
    "cities": [
      "Munich",
      "Hamburg",
      "Berlin",
      "Cologne",
      "Dusseldorf",
      "Frankfurt"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/QICQFkZp0Aw",
          "wikiLink": "https://en.wikipedia.org/wiki/Neuschwanstein_Castle"
        },
        "landmark": {
          "name": "Brandenburg Gate",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/brand-front-of-the-brandenburg-gate-5117579/",
          "wikiLink": "https://en.wikipedia.org/wiki/Brandenburg_Gate",
          "hasLandmark": true
        },
        "city": {
          "name": "Munich",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/munich-olympic-stadium-tv-tower-2516492/",
          "wikiLink": "https://en.wikipedia.org/wiki/Munich"
        },
        "landmarksRound": [
          {
            "code": "SY",
            "number": 1
          },
          {
            "code": "SK",
            "number": 2
          },
          {
            "code": "PH",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Anne Frank House",
          "Castillo San Cristobal",
          "Jardin de Balata",
          "Tikal National Park",
          "Xunatunich Mayan Ruins",
          "National Shrine Basilica of our Lady of Las Lajas",
          "Seven Colored Earths"
        ],
        "uniqueId": "rP8tG7cH",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bastei-bridge-rock-formation-3014467/",
          "wikiLink": "https://en.wikipedia.org/wiki/Elbe_Sandstone_Mountains"
        },
        "landmark": {
          "name": "Cologne Cathedral",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cologne-cathedral-night-time-5512117/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cologne_Cathedral",
          "hasLandmark": true
        },
        "city": {
          "name": "Hamburg",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/hamburg-speicherstadt-channel-2976711/",
          "wikiLink": "https://en.wikipedia.org/wiki/Hamburg"
        },
        "landmarksRound": [
          {
            "code": "GS",
            "number": 1
          },
          {
            "code": "DZ",
            "number": 1
          },
          {
            "code": "DO",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Winter Palace",
          "Hofburg Palace",
          "The Grand Palace",
          "Church of Our Savior",
          "Oriental Pearl TV Tower",
          "Heydar Aliyev Center",
          "Abu Simbel Temples"
        ],
        "uniqueId": "VyVbxKYC",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Ghana",
    "code": "GH",
    "capital": "Accra",
    "lat": 8,
    "long": -2,
    "cities": [
      "Accra",
      "Kumasi",
      "Tamale",
      "Cape Coast",
      "Sunyani",
      "Tema"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/a0BGODzhmqg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ada_Foah"
        },
        "landmark": {
          "name": "Elmina Castle",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/ghana-elmina-castle-3787972/",
          "wikiLink": "https://en.wikipedia.org/wiki/Elmina_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Kumasi",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Downtown_Kumasi,_Ghana.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kumasi"
        },
        "landmarksRound": [
          {
            "code": "LU",
            "number": 2
          },
          {
            "code": "SA",
            "number": 1
          },
          {
            "code": "ES",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Green Grotto Caves",
          "Church of Saint Lazarus",
          "Sukhbaatar Square",
          "Taj Mahal",
          "Senso-ji",
          "Vardzia",
          "Gyeongbukgong Palace"
        ],
        "uniqueId": "yXRvQ8nF",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/ghana-west-africa-africa-village-1927853/",
          "wikiLink": "https://en.wikipedia.org/wiki/Ghana"
        },
        "landmark": {
          "name": "Cape Coast Castle",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/castle-cape-coast-ghana-2419575/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cape_Coast_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Tamale",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Road,_Tamale.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tamale,_Ghana"
        },
        "landmarksRound": [
          {
            "code": "TN",
            "number": 1
          },
          {
            "code": "RU",
            "number": 2
          },
          {
            "code": "AQ",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Ampitheater of El Jem",
          "The Marble Mountains",
          "The Grand Palace",
          "Pico Basile",
          "Iluissat Icefjord",
          "Golden Gate",
          "Rock of Cashel"
        ],
        "uniqueId": "WTY8nvPl",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Gibraltar",
    "code": "GI",
    "capital": "Gibraltar",
    "lat": 36.13333333,
    "long": -5.35,
    "cities": [
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/dVoNU7j3o_0",
          "wikiLink": "https://en.wikipedia.org/wiki/Ibrahim-al-Ibrahim_Mosque"
        },
        "landmark": {
          "name": "Saint Michael's Cave",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/St._Michael%27s_Cave#/media/File:The_stage_inside_St._Michael's_Cave.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/St._Michael%27s_Cave",
          "hasLandmark": true
        },
        "city": {
          "name": "Westside Gibraltar",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Gibraltar_%2839472230260%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Westside,_Gibraltar"
        },
        "landmarksRound": [
          {
            "code": "VU",
            "number": 1
          },
          {
            "code": "NP",
            "number": 2
          },
          {
            "code": "KP",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Marcus Aurelius Arch",
          "Castle Cornet",
          "St. Peter's Church",
          "Easter Island",
          "Castillo San Cristobal",
          "Tikal National Park",
          "Pulhapanzak Waterfalls"
        ],
        "uniqueId": "7RHhrvg5",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/gibraltar-mediterranean-sea-horn-50863/",
          "wikiLink": "https://en.wikipedia.org/wiki/Gibraltar"
        },
        "landmark": {
          "name": "Rock of Gibraltar",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/gibraltar-english-rock-coast-3509369/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rock_of_Gibraltar",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "z5dlnmfK",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Greece",
    "code": "GR",
    "capital": "Athens",
    "lat": 39,
    "long": 22,
    "cities": [
      "Thessaloniki",
      "Patras",
      "Athens",
      "Nafplion",
      "Heraklion",
      "Rhodes"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/x-HCoTDGgZw",
          "wikiLink": "https://en.wikipedia.org/wiki/Parga"
        },
        "landmark": {
          "name": "Acropolis",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/athens-sunset-acropolis-greece-7402123/",
          "wikiLink": "https://en.wikipedia.org/wiki/Acropolis_of_Athens",
          "hasLandmark": true
        },
        "city": {
          "name": "Thessaloniki",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/greece-thessaloniki-city-europe-2736426/",
          "wikiLink": "https://en.wikipedia.org/wiki/Thessaloniki"
        },
        "landmarksRound": [
          {
            "code": "BA",
            "number": 1
          },
          {
            "code": "GE",
            "number": 2
          },
          {
            "code": "CA",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Iluissat Icefjord",
          "Shwedagon Pagoda",
          "Lukan Longshan Temple",
          "Frogner Park",
          "",
          "Rizal Park",
          "Skopje Fortress"
        ],
        "uniqueId": "fMnN2lgn",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/santorini-greece-buildings-houses-416135/",
          "wikiLink": "https://en.wikipedia.org/wiki/Santorini"
        },
        "landmark": {
          "name": "Parthenon",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/acropolis-athens-greece-ancient-2725910/",
          "wikiLink": "https://en.wikipedia.org/wiki/Parthenon",
          "hasLandmark": true
        },
        "city": {
          "name": "Patras",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Patras_Castle#/media/File:Patra_and_the_castle.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Patras"
        },
        "landmarksRound": [
          {
            "code": "GD",
            "number": 1
          },
          {
            "code": "CW",
            "number": 2
          },
          {
            "code": "BB",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Saint Sava Temple",
          "Monastery of St. Naum",
          "Baalbek",
          "Fort Fincastle",
          "Niagara Falls",
          "Sky Tower",
          "Kim Il Sung Square"
        ],
        "uniqueId": "mhsQ5p8s",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Greenland",
    "code": "GL",
    "capital": "Nuuk",
    "lat": 72,
    "long": -40,
    "cities": [
      "Ilulissat",
      "Nuuk",
      "Tasiilaq",
      "Sisimiut",
      "Paamiut",
      "Qaqortoq"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/EM_G_WFK9z4",
          "wikiLink": "https://en.wikipedia.org/wiki/Aasiaat"
        },
        "landmark": {
          "name": "Church of Our Savior",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Church_of_Our_Saviour_(Qaqortoq)#/media/File:Le_temple_%C3%A0_Qaqortoq,_Groenland.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Church_of_Our_Saviour_(Qaqortoq)",
          "hasLandmark": true
        },
        "city": {
          "name": "Ilulissat",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ilulissat#/media/File:The_colors_from_Ilulissat_-_Greenland._-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ilulissat"
        },
        "landmarksRound": [
          {
            "code": "LA",
            "number": 2
          },
          {
            "code": "SA",
            "number": 2
          },
          {
            "code": "GI",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Cologne Cathedral",
          "Forbidden City",
          "Epupa Falls",
          "Church of Saint Lazarus",
          "Dhakeshwari Temple",
          "Akershus Fortress",
          "Pamir Mountains"
        ],
        "uniqueId": "4XgbDrNb",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mountains-snow-glacier-sea-arctic-5559241/",
          "wikiLink": "https://en.wikipedia.org/wiki/Greenland"
        },
        "landmark": {
          "name": "Iluissat Icefjord",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ilulissat_Icefjord#/media/File:Scenic_view_of_Greenland_icebergs_in_Baffin_Bay_in_Disko_Bay_-_Buiobuione_photo_13.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ilulissat_Icefjord",
          "hasLandmark": true
        },
        "city": {
          "name": "Nuuk",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/greenland-nuuk-town-arctic-1226101/",
          "wikiLink": "https://en.wikipedia.org/wiki/Nuuk"
        },
        "landmarksRound": [
          {
            "code": "SN",
            "number": 2
          },
          {
            "code": "AM",
            "number": 2
          },
          {
            "code": "BA",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "St. Peter's Church",
          "Castillo San Cristobal",
          "Kravica Waterfall",
          "Senso-ji",
          "Epupa Falls",
          "Nizwa Fort",
          "The Grand Palace"
        ],
        "uniqueId": "n7K2T8JG",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Grenada",
    "code": "GD",
    "capital": "Saint George's",
    "lat": 12.11666666,
    "long": -61.66666666,
    "cities": [
      "Saint George's",
      "Calivigny",
      "Gouyave",
      "Victoria",
      "Sauteurs",
      "Saint Davids"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/c9yXt2dL1JI",
          "wikiLink": "https://en.wikipedia.org/wiki/St._George%27s,_Grenada"
        },
        "landmark": {
          "name": "Annandale Waterfall",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Willis,_Grenada#/media/File:Annandale_Waterfalls.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Willis,_Grenada",
          "hasLandmark": true
        },
        "city": {
          "name": "Saint George's",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:The_Carenage_C_IM0448.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/St._George%27s,_Grenada"
        },
        "landmarksRound": [
          {
            "code": "GG",
            "number": 1
          },
          {
            "code": "LY",
            "number": 2
          },
          {
            "code": "BE",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Epupa Falls",
          "Somapura Mahavihara",
          "Romney Manor",
          "Floralis Generica",
          "Monday Falls",
          "Kittiwake Shipwreck",
          "Olavinlinna"
        ],
        "uniqueId": "TyMNnSZs",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/caribbean-island-tropical-harbor-1934661/",
          "wikiLink": "https://en.wikipedia.org/wiki/Grenada"
        },
        "landmark": {
          "name": "Fort Frederick",
          "photographer": "",
          "imageLink": "https://commons.m.wikimedia.org/wiki/File:Fort_Frederick,_Grenada_%2840001365693%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Grenada",
          "hasLandmark": true
        },
        "city": {
          "name": "Gouyave",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Gouyave#/media/File:Gouyave_town_aerial_view.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Gouyave"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "VsGXyLmj",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Guadeloupe",
    "code": "GP",
    "capital": "Basse-Terre",
    "lat": 16.25,
    "long": -61.583333,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/y32dchsQ-Gk",
          "wikiLink": "https://en.wikipedia.org/wiki/Terre-de-Haut_Island"
        },
        "landmark": {
          "name": "Plage de Grande Anse",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Plage_de_la_Grande_Anse_%C3%A0_Deshaies.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Deshaies",
          "hasLandmark": true
        },
        "city": {
          "name": "Pointe-a-Pitre",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Port_de_de_Pointe-%C3%A0-Pitre.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Pointe-%C3%A0-Pitre"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "3CvXdnkK",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/caribbean-sea-sand-island-holiday-1656604/",
          "wikiLink": "https://en.wikipedia.org/wiki/Guadeloupe"
        },
        "landmark": {
          "name": "Jardin Botanique de Deshaies",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Jardin_botanique_de_Deshaies#/media/File:Jardin_Botanique_-_%C3%A9tang_aux_n%C3%A9nuphars.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Jardin_botanique_de_Deshaies",
          "hasLandmark": true
        },
        "city": {
          "name": "Basse-Terre",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/pom-angers/50806203587",
          "wikiLink": "https://en.wikipedia.org/wiki/Basse-Terre"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "bMbnkQLT",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Guam",
    "code": "GU",
    "capital": "Hagåtña",
    "lat": 13.46666666,
    "long": 144.7833333,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/guam-landscape-scenic-bay-harbor-107281/",
          "wikiLink": "https://en.wikipedia.org/wiki/Guam"
        },
        "landmark": {
          "name": "Fort Nuestra Senora de la Soledad",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Fort_Nuestra_Se%C3%B1ora_de_la_Soledad#/media/File:Fort_Nuestra_Se%C3%B1ora_de_la_Soled%C3%A5d_-_Umatac,_Guam_-_DSC00948.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Fort_Nuestra_Se%C3%B1ora_de_la_Soledad",
          "hasLandmark": true
        },
        "city": {
          "name": "Dededo",
          "photographer": "",
          "imageLink": "https://simple.wikipedia.org/wiki/Dededo,_Guam#/media/File:Dededo_Flea_Market1.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Dededo"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "NJK8fMkJ",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/guam-sea-ocean-water-rocks-rocky-102374/",
          "wikiLink": "https://en.wikipedia.org/wiki/Guam"
        },
        "landmark": {
          "name": "Two Lover's Point",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Two_Lovers_Point#/media/File:Two_lovers_point_(80103943).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Two_Lovers_Point",
          "hasLandmark": true
        },
        "city": {
          "name": "Mangilao",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Campus_view_-_University_of_Guam_-_DSC00995.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Mangilao,_Guam"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "nWFdtPzh",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Guatemala",
    "code": "GT",
    "capital": "Guatemala City",
    "lat": 15.5,
    "long": -90.25,
    "cities": [
      "Villa Nueva",
      "Antigua Guatemala",
      "Guatemala City",
      "Quetzaltenango",
      "Escuintla",
      "Chichicastenango"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/antigua-guatemala-sunrise-guatemala-2652478/",
          "wikiLink": "https://en.wikipedia.org/wiki/Guatemala"
        },
        "landmark": {
          "name": "Tikal Temple I",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/pyramid-maya-temple-ruins-prales-5408476/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tikal_National_Park",
          "hasLandmark": true
        },
        "city": {
          "name": "Villa Nueva",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Villa_Nueva,_Guatemala#/media/File:Zona_12_de_Villa_Nueva.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Villa_Nueva,_Guatemala"
        },
        "landmarksRound": [
          {
            "code": "IR",
            "number": 1
          },
          {
            "code": "HU",
            "number": 2
          },
          {
            "code": "SY",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Masjid al-Haram",
          "Zaisan Memorial",
          "Gyeongbukgong Palace",
          "Arecibo Observatory",
          "Dhakeshwari Temple",
          "Souq Waqif",
          "Maiden Tower"
        ],
        "uniqueId": "RgrdSwmV",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/N4gc_5rZ9dM",
          "wikiLink": "https://en.wikipedia.org/wiki/Tikal_Temple_I"
        },
        "landmark": {
          "name": "Santa Catalina Arch",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Arco_de_Santa_Catalina#/media/File:Santa_Catalina_Arch_-_Antigua_Guatemala_Feb_2020.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Arco_de_Santa_Catalina",
          "hasLandmark": true
        },
        "city": {
          "name": "Antigua Guatemala",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/view-city-panorama-cross-statue-4987453/",
          "wikiLink": "https://en.wikipedia.org/wiki/Antigua_Guatemala"
        },
        "landmarksRound": [
          {
            "code": "DE",
            "number": 2
          },
          {
            "code": "MN",
            "number": 2
          },
          {
            "code": "MA",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Patuxay Monument",
          "Gyeongbukgong Palace",
          "Gravensteen",
          "Prince's Palace of Monaco",
          "Queen Emma Bridge",
          "Atomium",
          "Bellapais Abbey"
        ],
        "uniqueId": "52cczBHK",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Guernsey",
    "code": "GG",
    "capital": "Saint Peter Port",
    "lat": 49.46666666,
    "long": -2.58333333,
    "cities": [
      "Saint Peter Port",
      "Saint Andrew",
      "Pleasant City",
      "Lore City",
      "Quaker City",
      "Old Washington"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/N5ZHXIdy-io",
          "wikiLink": "https://en.wikipedia.org/wiki/Guernsey"
        },
        "landmark": {
          "name": "The Little Chapel",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Little_Chapel#/media/File:Little_Chapel_op_Guernsey.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Little_Chapel",
          "hasLandmark": true
        },
        "city": {
          "name": "Saint Peter Port",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:St_Peter_Port_Guernsey.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Peter_Port"
        },
        "landmarksRound": [
          {
            "code": "SA",
            "number": 1
          },
          {
            "code": "MA",
            "number": 2
          },
          {
            "code": "KP",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Devin Castle",
          "Christ of Havana",
          "Aleppo Citadel",
          "Mir Castle",
          "Al Fateh Grand Mosque",
          "Soroca Fort",
          "Oriental Pearl TV Tower"
        ],
        "uniqueId": "b6h64Pwg",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/guernsey-landscape-dusk-2722964/",
          "wikiLink": "https://en.wikipedia.org/wiki/Guernsey"
        },
        "landmark": {
          "name": "Castle Cornet",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Guernsey_Castle_Cornet.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Castle_Cornet",
          "hasLandmark": true
        },
        "city": {
          "name": "Saint Andrew",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:The_Little_Chapel,_Guernsey.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Andrew,_Guernsey"
        },
        "landmarksRound": [
          {
            "code": "NL",
            "number": 2
          },
          {
            "code": "SG",
            "number": 1
          },
          {
            "code": "DO",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Turku Castle",
          "Devin Castle",
          "Pigeon Rocks",
          "National Shrine Basilica of our Lady of Las Lajas",
          "Animal Flower Cave",
          "Winter Palace",
          "Bahrain Fort"
        ],
        "uniqueId": "nfb8dcVj",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Guinea",
    "code": "GN",
    "capital": "Conakry",
    "lat": 11,
    "long": -10,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Guinea#/media/File:Fouta_Djallon_(14582291826).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Guinea"
        },
        "landmark": {
          "name": "Bride's Veil Waterfall",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Guinea#/media/File:Le_%22Voile%22_de_la_mari%C3%A9e_%C3%A0_Kindia.png",
          "wikiLink": "https://en.wikipedia.org/wiki/Guinea",
          "hasLandmark": false
        },
        "city": {
          "name": "Conakry",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Guinea#/media/File:Conakry.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Conakry"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "rVw47nfy",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/woods-fog-guinea-mist-africa-2357567/",
          "wikiLink": "https://en.wikipedia.org/wiki/Guinea"
        },
        "landmark": {
          "name": "Grand Mosque of Conakry",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Grand_Mosque_of_Conakry#/media/File:Mosqu%C3%A9e_Faycal.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Grand_Mosque_of_Conakry",
          "hasLandmark": false
        },
        "city": {
          "name": "Nzerekore",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Nz%C3%A9r%C3%A9kor%C3%A9_%2814603266964%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Nz%C3%A9r%C3%A9kor%C3%A9"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "hPRb4n8g",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Guinea-Bissau",
    "code": "GW",
    "capital": "Bissau",
    "lat": 12,
    "long": -15,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/village-africa-guinea-bissau-431731/",
          "wikiLink": "https://en.wikipedia.org/wiki/Guinea-Bissau"
        },
        "landmark": {
          "name": "Fortaleza de São José da Amura",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Fortaleza_de_S%C3%A3o_Jos%C3%A9_da_Amura#/media/File:Interior_da_Fortaleza_de_S%C3%A3o_Jos%C3%A9_de_Amura,_Bissau.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Fortaleza_de_S%C3%A3o_Jos%C3%A9_da_Amura",
          "hasLandmark": true
        },
        "city": {
          "name": "Gabu",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Gabu_(town)#/media/File:Gabumainstreet.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Gabu_(town)"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "RgxCjZsW",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/boat-island-sea-costa-ocean-water-434919/",
          "wikiLink": "https://en.wikipedia.org/wiki/Guinea-Bissau"
        },
        "landmark": {
          "name": "Bissau Cathedral",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Catedral_de_Bissau_%282%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bissau_Cathedral",
          "hasLandmark": true
        },
        "city": {
          "name": "Bissau",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bissau#/media/File:Avenida_dos_Combatentes_da_Liberdade_da_P%C3%A1tria,_Bissau_(1).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bissau"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "9XtqnHlM",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Guyana",
    "code": "GY",
    "capital": "Georgetown",
    "lat": 5,
    "long": -59,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/pZyD9-XReQQ",
          "wikiLink": "https://en.wikipedia.org/wiki/Georgetown,_Guyana"
        },
        "landmark": {
          "name": "Kaieteur Falls",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/waterfalls-a-single-drop-guyana-5089714/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kaieteur_Falls",
          "hasLandmark": true
        },
        "city": {
          "name": "Linden",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Linden,_Guyana#/media/File:Linden_Bauxite_Company_-_panoramio_-_Marco_Farouk_Basir.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Linden,_Guyana"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "rcsGlCMG",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/guyana-sky-clouds-lake-stream-80836/",
          "wikiLink": "https://en.wikipedia.org/wiki/Guyana"
        },
        "landmark": {
          "name": "Demerara Bridge",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Demerara_Harbour_Bridge#/media/File:20191122_Guyana_0171_Georgetown_sRGB_(49295935467).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Demerara_Harbour_Bridge",
          "hasLandmark": true
        },
        "city": {
          "name": "Georgetown",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Georgetown,_Guyana#/media/File:20191122_Guyana_0082_Georgetown_sRGB_(49295764796).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Georgetown,_Guyana"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "cXtKSgkM",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Haiti",
    "code": "HT",
    "capital": "Port-au-Prince",
    "lat": 19,
    "long": -72.41666666,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/HlseIzr_6fI",
          "wikiLink": "https://en.wikipedia.org/wiki/Cap-Ha%C3%AFtien"
        },
        "landmark": {
          "name": "Laferriere Citadel",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Citadelle_Laferri%C3%A8re#/media/File:Citadelle_Laferri%C3%A8re.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Citadelle_Laferri%C3%A8re",
          "hasLandmark": true
        },
        "city": {
          "name": "Petion-Ville",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/P%C3%A9tion-Ville#/media/File:Hills_of_Petion-Ville,_Haiti.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/P%C3%A9tion-Ville"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "r2ClY2wZ",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/haiti-mountains-landscape-steep-79646/",
          "wikiLink": "https://en.wikipedia.org/wiki/Haiti"
        },
        "landmark": {
          "name": "Sans-Soucis Palace",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Sans-Souci_Palace#/media/File:Sans-Souci_Palace,_National_History_Park,_Haiti.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Sans-Souci_Palace",
          "hasLandmark": true
        },
        "city": {
          "name": "Port-au-Prince",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Port-au-Prince#/media/File:US_Navy_100221-N-5787K-002_An_aerial_view_of_the_logistical_area_near_the_port_in_Port-au-Prince_(cropped).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Port-au-Prince"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "FMSHYbml",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Heard Island and McDonald Islands",
    "code": "HM",
    "capital": "",
    "lat": -53.08181,
    "long": 73.504158,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Heard_Island_%28cropped%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands"
        },
        "landmark": {
          "name": "Mawson Peak",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/laikolosse/33754828666",
          "wikiLink": "https://en.wikipedia.org/wiki/Mawson_Peak",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "rvpGq8xp",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Heard_Island_by_Sentinel-2_Cloudless.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Heard_Island_and_McDonald_Islands"
        },
        "landmark": {
          "name": "Big Ben",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/laikolosse/33829886965",
          "wikiLink": "https://en.wikipedia.org/wiki/Big_Ben_(Heard_Island)",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "9VNJ2ljl",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Honduras",
    "code": "HN",
    "capital": "Tegucigalpa",
    "lat": 15,
    "long": -86.5,
    "cities": [
      "Tegucigalpa",
      "Comayagua",
      "La Ceiba",
      "Choloma",
      "Choluteca",
      "San Pedro Sula"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/WFFmZ0qIUYU",
          "wikiLink": "https://en.wikipedia.org/wiki/St._Peter_the_Apostle_Cathedral,_San_Pedro_Sula"
        },
        "landmark": {
          "name": "Tegucigalpa Cathedral",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Tegucigalpa_Cathedral#/media/File:Barrio_La_Hoya,_Tegucigalpa,_Honduras_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tegucigalpa_Cathedral",
          "hasLandmark": true
        },
        "city": {
          "name": "San Pedro Sula",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/san-pedro-sula-city-road-honduras-6647765/",
          "wikiLink": "https://en.wikipedia.org/wiki/San_Pedro_Sula"
        },
        "landmarksRound": [
          {
            "code": "RE",
            "number": 2
          },
          {
            "code": "GH",
            "number": 2
          },
          {
            "code": "NP",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Szechenyi Thermal Bath",
          "Ulu Temburong National Park",
          "Tomb of Hafez",
          "Alhambra",
          "Gangtey Monastery",
          "Independence Monument",
          "Wawel Castle"
        ],
        "uniqueId": "2cQrSxJM",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/roatan-honduras-port-architecture-1358175/",
          "wikiLink": "https://en.wikipedia.org/wiki/Honduras"
        },
        "landmark": {
          "name": "Pulhapanzak Waterfalls",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Cataratas_de_Pulhapanzak_%28III%29.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Tourism_in_Honduras",
          "hasLandmark": true
        },
        "city": {
          "name": "Tegucigalpa",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Tegucigalpa#/media/File:Tegucigalpa,_Honduras_Long_Exposure_from_Humuya_Inn.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tegucigalpa"
        },
        "landmarksRound": [
          {
            "code": "AU",
            "number": 1
          },
          {
            "code": "TR",
            "number": 2
          },
          {
            "code": "MW",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Devin Castle",
          "Macau Tower",
          "Sukhbaatar Square",
          "Monastery of St. Naum",
          "Golden Gate Bridge",
          "Mount Yasur",
          "St. Peter's Church"
        ],
        "uniqueId": "jpLw8PZF",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Hong Kong",
    "code": "HK",
    "capital": "City of Victoria",
    "lat": 22.267,
    "long": 114.188,
    "cities": [
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/WMSvsWzhM0g",
          "wikiLink": "https://en.wikipedia.org/wiki/Hong_Kong"
        },
        "landmark": {
          "name": "Tian Tan Buddha",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/tian-tan-buddha-bronze-hongkong-958763/",
          "wikiLink": "https://en.wikipedia.org/wiki/The_Big_Buddha_(Hong_Kong)",
          "hasLandmark": true
        },
        "city": {
          "name": "Tsuen Wan",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Tsuen_Wan#/media/File:TsuenWan_atNight.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tsuen_Wan"
        },
        "landmarksRound": [
          {
            "code": "SK",
            "number": 1
          },
          {
            "code": "CD",
            "number": 2
          },
          {
            "code": "ES",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "The Blue Mosque",
          "Marina Bay Sands",
          "Katse Dam",
          "Lukan Longshan Temple",
          "Bellapais Abbey",
          "Voortrekker Monument",
          "Tash Rabat"
        ],
        "uniqueId": "5NH4nJ2n",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/the-peak-hong-kong-scenic-romantic-243269/",
          "wikiLink": "https://en.wikipedia.org/wiki/Hong_Kong"
        },
        "landmark": {
          "name": "Ocean Park",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/hong-kong-ocean-park-the-cable-car-1100890/",
          "wikiLink": "https://en.wikipedia.org/wiki/Ocean_Park_Hong_Kong",
          "hasLandmark": true
        },
        "city": {
          "name": "Kowloon",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/hong-kong-kowloon-city-evening-6603261/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kowloon"
        },
        "landmarksRound": [
          {
            "code": "SG",
            "number": 1
          },
          {
            "code": "YE",
            "number": 1
          },
          {
            "code": "FJ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Katse Dam",
          "Maiden Tower",
          "Hofburg Palace",
          "The Blue Mosque",
          "Al Fateh Grand Mosque",
          "Dhakeshwari Temple",
          "Winter Palace"
        ],
        "uniqueId": "CyyCsWNj",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Hungary",
    "code": "HU",
    "capital": "Budapest",
    "lat": 47,
    "long": 20,
    "cities": [
      "Budapest",
      "Debrecen",
      "Pecs",
      "Szeged",
      "Sopron",
      "Gyor"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/qnUg1rdnUlk",
          "wikiLink": "https://en.wikipedia.org/wiki/Hungarian_Parliament_Building"
        },
        "landmark": {
          "name": "Fisherman's Bastion",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/fisherman-s-bastion-landmark-7502270/",
          "wikiLink": "https://en.wikipedia.org/wiki/Fisherman%27s_Bastion",
          "hasLandmark": true
        },
        "city": {
          "name": "Budapest",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/river-budapest-hungary-architecture-5978743/",
          "wikiLink": "https://en.wikipedia.org/wiki/Budapest"
        },
        "landmarksRound": [
          {
            "code": "HN",
            "number": 2
          },
          {
            "code": "CN",
            "number": 1
          },
          {
            "code": "BY",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Tikal National Park",
          "Rhine Falls",
          "Hagia Sophia",
          "The Grand Palace",
          "Monteverde Cloud Forest",
          "Angel Falls",
          "Oriental Pearl TV Tower"
        ],
        "uniqueId": "pmB7JJ4R",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/szechenyi-chain-bridge-1758196/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sz%C3%A9chenyi_Chain_Bridge"
        },
        "landmark": {
          "name": "Szechenyi Thermal Bath",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/szechenyi-spa-budapest-hungary-1333668/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sz%C3%A9chenyi_thermal_bath",
          "hasLandmark": true
        },
        "city": {
          "name": "Debrecen",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Debrecen,_Hungary_-_panoramio_%286%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Debrecen"
        },
        "landmarksRound": [
          {
            "code": "BN",
            "number": 1
          },
          {
            "code": "CA",
            "number": 1
          },
          {
            "code": "SK",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Dunn's River Falls",
          "Bled Castle",
          "Golden Gate",
          "Ulu Temburong National Park",
          "Golubac Fortress",
          "Pigeon Rocks",
          "Chimi Lhakhang"
        ],
        "uniqueId": "VNqQbXHR",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Iceland",
    "code": "IS",
    "capital": "Reykjavik",
    "lat": 65,
    "long": -18,
    "cities": [
      "Reykjavik",
      "Akureyri",
      "Husavik",
      "Isafjordir",
      "Selfoss",
      "Seydisfjordur"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/4ThcC1DtspE",
          "wikiLink": "https://unsplash.com/photos/4ThcC1DtspE"
        },
        "landmark": {
          "name": "Blue Lagoon",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/blue-lagoon-thermal-pool-4535986/",
          "wikiLink": "https://en.wikipedia.org/wiki/Blue_Lagoon_(geothermal_spa)",
          "hasLandmark": true
        },
        "city": {
          "name": "Reykjavik",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/reykjavik-iceland-city-urban-1988082/",
          "wikiLink": "https://en.wikipedia.org/wiki/Reykjav%C3%ADk"
        },
        "landmarksRound": [
          {
            "code": "CA",
            "number": 1
          },
          {
            "code": "BB",
            "number": 2
          },
          {
            "code": "GQ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Cathedral Our Lady of the Rosary",
          "Hofburg Palace",
          "Tsingy De Bemaraha",
          "Arecibo Observatory",
          "Liwonde National Park",
          "Ulu Temburong National Park",
          "Marcus Aurelius Arch"
        ],
        "uniqueId": "7rd2cmhz",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/seljalandsfoss-waterfalls-iceland-1751463/",
          "wikiLink": "https://en.wikipedia.org/wiki/Seljalandsfoss"
        },
        "landmark": {
          "name": "Gullfoss",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/gullfoss-iceland-water-waterfall-1421642/",
          "wikiLink": "https://en.wikipedia.org/wiki/Gullfoss",
          "hasLandmark": true
        },
        "city": {
          "name": "Akureyri",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/iceland-akureyri-snow-mountains-4511994/",
          "wikiLink": "https://en.wikipedia.org/wiki/Akureyri"
        },
        "landmarksRound": [
          {
            "code": "CY",
            "number": 1
          },
          {
            "code": "PF",
            "number": 2
          },
          {
            "code": "MM",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Dhakeshwari Temple",
          "Vaduz Cathedral",
          "Villa Folio",
          "Ostrog Monastery",
          "Wieliczka Salt Mine",
          "Fasil Ghebbi",
          "Santa Catalina Arch"
        ],
        "uniqueId": "Np4nBZMk",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "India",
    "code": "IN",
    "capital": "New Delhi",
    "lat": 20,
    "long": 77,
    "cities": [
      "Jaipur",
      "Kolkata",
      "New Delhi",
      "Mumbai",
      "Chennai",
      "Lucknow"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/nE2gf1scItI",
          "wikiLink": "https://en.wikipedia.org/wiki/Victoria_Memorial,_Kolkata"
        },
        "landmark": {
          "name": "Amber Palace",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/india-amber-fortress-palace-1545838/",
          "wikiLink": "https://en.wikipedia.org/wiki/Amber_Fort",
          "hasLandmark": true
        },
        "city": {
          "name": "Jaipur",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/jaipur-city-india-top-view-indian-166512/",
          "wikiLink": "https://en.wikipedia.org/wiki/Jaipur"
        },
        "landmarksRound": [
          {
            "code": "KG",
            "number": 2
          },
          {
            "code": "MO",
            "number": 2
          },
          {
            "code": "MZ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Hazrat Ali Mazar",
          "Nizwa Fort",
          "Azadi Tower",
          "Tokyo Skytree",
          "Hato Caves",
          "Castillo San Cristobal",
          "Easter Island"
        ],
        "uniqueId": "SnGQxrW8",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/india-varinasia-ganges-boats-1309206/",
          "wikiLink": "https://en.wikipedia.org/wiki/India"
        },
        "landmark": {
          "name": "Taj Mahal",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/taj-mahal-india-monument-building-4109110/",
          "wikiLink": "https://en.wikipedia.org/wiki/Taj_Mahal",
          "hasLandmark": true
        },
        "city": {
          "name": "Kolkata",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/traffic-city-road-travel-dusk-3331559/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kolkata"
        },
        "landmarksRound": [
          {
            "code": "KN",
            "number": 2
          },
          {
            "code": "AZ",
            "number": 2
          },
          {
            "code": "GB",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Stone House",
          "Kim Il Sung Square",
          "Liwonde National Park",
          "Pulhapanzak Waterfalls",
          "Boudhanath",
          "Chillon Castle",
          "Floralis Generica"
        ],
        "uniqueId": "K4zFpdy4",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Indonesia",
    "code": "ID",
    "capital": "Jakarta",
    "lat": -5,
    "long": 120,
    "cities": [
      "Bandung",
      "Jakarta",
      "Makassar",
      "Semarang",
      "Bekasi City",
      "Yogyakarta"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/8o4W9LZv6eo",
          "wikiLink": "https://en.wikipedia.org/wiki/Kebumen_Regency"
        },
        "landmark": {
          "name": "Borobudur Temple",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/borobudur-temple-indonesia-historic-743263/",
          "wikiLink": "https://en.wikipedia.org/wiki/Borobudur",
          "hasLandmark": true
        },
        "city": {
          "name": "Bandung",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/morning-bandung-city-mountain-urban-7341522/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bandung"
        },
        "landmarksRound": [
          {
            "code": "LV",
            "number": 2
          },
          {
            "code": "JP",
            "number": 1
          },
          {
            "code": "NO",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Kim Il Sung Square",
          "Memorial de l'Anse",
          "Mir Castle",
          "Neveh Shalom Synagogue",
          "Narikala Fortress",
          "Blue Grotto",
          "Iskanderkul"
        ],
        "uniqueId": "tpTpd3BF",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sea-mangrove-before-sunrise-mystery-6328687/",
          "wikiLink": "https://en.wikipedia.org/wiki/Halmahera"
        },
        "landmark": {
          "name": "Prambanan Temple",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/temple-candi-prambanan-598969/",
          "wikiLink": "https://en.wikipedia.org/wiki/Prambanan",
          "hasLandmark": true
        },
        "city": {
          "name": "Jakarta",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/jakarta-city-indonesia-urban-4660555/",
          "wikiLink": "https://en.wikipedia.org/wiki/Jakarta"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Hfg4MPvW",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Iran",
    "code": "IR",
    "capital": "Tehran",
    "lat": 32,
    "long": 53,
    "cities": [
      "Kermanshah",
      "Mashhad",
      "Tehran",
      "Tabriz",
      "Isfahan",
      "Yazd"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/mbiddulph/31233739431/",
          "wikiLink": "https://en.wikipedia.org/wiki/Nasir-ol-Molk_Mosque"
        },
        "landmark": {
          "name": "Azadi Tower",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Azadi_Tower#/media/File:Azadi_Tower_(29358497718).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Azadi_Tower",
          "hasLandmark": true
        },
        "city": {
          "name": "Kermanshah",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Kermanshah_Photos_M8.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kermanshah"
        },
        "landmarksRound": [
          {
            "code": "GI",
            "number": 1
          },
          {
            "code": "EE",
            "number": 2
          },
          {
            "code": "VU",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Panfilov Park",
          "Rila Monastery",
          "Umayyad Mosque",
          "Mount Paget",
          "National Shrine Basilica of our Lady of Las Lajas",
          "Fort Sao Sebastiao",
          "Sistine Chapel"
        ],
        "uniqueId": "BlWmcyRR",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mountain-summit-snow-winter-6734031/",
          "wikiLink": "https://en.wikipedia.org/wiki/Iran"
        },
        "landmark": {
          "name": "Tomb of Hafez",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Tomb_of_Hafez#/media/File:Hafez_880714_095.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tomb_of_Hafez",
          "hasLandmark": true
        },
        "city": {
          "name": "Mashhad",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/town-holy-mashhad-ardehal-6780263/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mashhad"
        },
        "landmarksRound": [
          {
            "code": "IQ",
            "number": 1
          },
          {
            "code": "TH",
            "number": 1
          },
          {
            "code": "SD",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Marcus Aurelius Arch",
          "Patuxay Monument",
          "Cape Coast Castle",
          "Bled Castle",
          "Machu Picchu",
          "Zaisan Memorial",
          "St. Tryphon's Cathedral"
        ],
        "uniqueId": "5vcxpXzr",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Iraq",
    "code": "IQ",
    "capital": "Baghdad",
    "lat": 33,
    "long": 44,
    "cities": [
      "Mosul",
      "Erbil",
      "Baghdad",
      "Basrah",
      "Hillah",
      "Najaf"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/8eJTLS3lIiw",
          "wikiLink": "https://en.wikipedia.org/wiki/Erbil"
        },
        "landmark": {
          "name": "Erbil Citadel",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Citadel_of_Erbil#/media/File:27._Part_of_the_wall_of_Erbil_Citadel,_Hawler,_Erbil_Governorate,_Iraq.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Citadel_of_Erbil",
          "hasLandmark": true
        },
        "city": {
          "name": "Mosul",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Mosul_-_Iraq.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mosul"
        },
        "landmarksRound": [
          {
            "code": "PR",
            "number": 1
          },
          {
            "code": "MA",
            "number": 1
          },
          {
            "code": "SD",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Queen Emma Bridge",
          "Monastery of St. Naum",
          "Skopje Fortress",
          "Kim Il Sung Square",
          "Lukan Longshan Temple",
          "Hazrat Ali Mazar",
          "George Washington House"
        ],
        "uniqueId": "S8DGCrnv",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/hillah-iraq-landscape-desert-80384/",
          "wikiLink": "https://en.wikipedia.org/wiki/Iraq"
        },
        "landmark": {
          "name": "Mutanabbi Statue",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mutanabbi_Street#/media/File:Al-Mutanabbi_Statue_in_Baghdad(Cropped).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mutanabbi_Street",
          "hasLandmark": true
        },
        "city": {
          "name": "Erbil",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Citadel_of_Erbil#/media/File:Hawler_Castle.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Erbil"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "7NpScmTC",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Ireland",
    "code": "IE",
    "capital": "Dublin",
    "lat": 53,
    "long": -8,
    "cities": [
      "Galway",
      "Dublin",
      "Waterford",
      "Ennis",
      "Kilkenny",
      "Cork"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/FxMR4Dp9rl0",
          "wikiLink": "https://en.wikipedia.org/wiki/Glendalough_Upper_Lake"
        },
        "landmark": {
          "name": "Cliffs of Moher",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cliff-of-moher-cliff-ireland-4520630/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cliffs_of_Moher",
          "hasLandmark": true
        },
        "city": {
          "name": "Cork",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Cork_(city)#/media/File:Patrick_Street,_Cork,_January_2018.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Cork_(city)"
        },
        "landmarksRound": [
          {
            "code": "JM",
            "number": 2
          },
          {
            "code": "CO",
            "number": 1
          },
          {
            "code": "JO",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Lukan Longshan Temple",
          "Wat Arun",
          "Mir Castle",
          "Temple of Literature",
          "Panfilov Park",
          "The Blue Eye",
          "Bahrain Fort"
        ],
        "uniqueId": "w6mMbZLy",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/beach-lonely-hike-ireland-sea-5043569/",
          "wikiLink": "https://en.wikipedia.org/wiki/Ireland"
        },
        "landmark": {
          "name": "Rock of Cashel",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/rock-or-cashel-ireland-2633048/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rock_of_Cashel",
          "hasLandmark": true
        },
        "city": {
          "name": "Galway",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/galway-ireland-galway-city-city-5194111/",
          "wikiLink": "https://en.wikipedia.org/wiki/Galway"
        },
        "landmarksRound": [
          {
            "code": "UA",
            "number": 1
          },
          {
            "code": "LK",
            "number": 1
          },
          {
            "code": "DK",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "The Blue Mosque",
          "Vaduz Cathedral",
          "Amberd",
          "Pamir Mountains",
          "Soroca Fort",
          "Golubac Fortress",
          "Registan Square"
        ],
        "uniqueId": "Jf4RH6ds",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Isle of Man",
    "code": "IM",
    "capital": "Douglas",
    "lat": 54.25,
    "long": -4.5,
    "cities": [
      "Douglas",
      "Port Erin",
      "Castletown",
      "Cregneash",
      "Ramsey",
      "Laxey"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/UF-F4mSV8VA",
          "wikiLink": "https://en.wikipedia.org/wiki/Bradda_Head"
        },
        "landmark": {
          "name": "Castle Rushden",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Castle_Rushen#/media/File:Castle_Rushen.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Castle_Rushen",
          "hasLandmark": true
        },
        "city": {
          "name": "Port Erin",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Port_Erin,_Isle_of_Man,_May,_2017-2.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Port_Erin"
        },
        "landmarksRound": [
          {
            "code": "IQ",
            "number": 1
          },
          {
            "code": "KG",
            "number": 2
          },
          {
            "code": "EG",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Motherland Monument",
          "Fasil Ghebbi",
          "Predjama Castle",
          "Koutoubia",
          "South Pole (ceremonial)",
          "Grandvalira",
          "Tegucigalpa Cathedral"
        ],
        "uniqueId": "LWRJV2Qk",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sunrise-snow-mountains-sunlight-5882167/",
          "wikiLink": "https://en.wikipedia.org/wiki/Isle_of_Man"
        },
        "landmark": {
          "name": "Peel Castle",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Peel_Castle#/media/File:Peel_Castle_cathedral.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Peel_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Castletown",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/brighton/3886816038",
          "wikiLink": "https://en.wikipedia.org/wiki/Castletown,_Isle_of_Man"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "kqYDVWqW",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Israel",
    "code": "IL",
    "capital": "Jerusalem",
    "lat": 31.47,
    "long": 35.13,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/yBJuiaMHlsk",
          "wikiLink": "https://en.wikipedia.org/wiki/Tel_Aviv"
        },
        "landmark": {
          "name": "Western Wall and the Dome of the Rock",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/jerusalem-western-wall-temple-4592574/",
          "wikiLink": "https://en.wikipedia.org/wiki/Western_Wall",
          "hasLandmark": false
        },
        "city": {
          "name": "Tel Aviv",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/tel-aviv-city-beach-coast-sea-7626789/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tel_Aviv"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "RcgPjFdT",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/israel-jerusalem-jewish-palestine-4426385/",
          "wikiLink": "https://en.wikipedia.org/wiki/Jerusalem"
        },
        "landmark": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": "",
          "hasLandmark": false
        },
        "city": {
          "name": "Haifa",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/stairs-park-rise-staircase-2999277/",
          "wikiLink": "https://en.wikipedia.org/wiki/Haifa"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "8TJMr92D",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Italy",
    "code": "IT",
    "capital": "Rome",
    "lat": 42.83333333,
    "long": 12.83333333,
    "cities": [
      "Milan",
      "Rome",
      "Venice",
      "Naples",
      "Verona",
      "Florence"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/r9RW20TrQ0Y",
          "wikiLink": "https://en.wikipedia.org/wiki/Tuscany"
        },
        "landmark": {
          "name": "Trevi Fountain",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/trevi-fountain-fontana-di-trevi-19688/",
          "wikiLink": "https://en.wikipedia.org/wiki/Trevi_Fountain",
          "hasLandmark": true
        },
        "city": {
          "name": "Florence",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/italy-florence-church-tuscany-4256018/",
          "wikiLink": "https://en.wikipedia.org/wiki/Florence"
        },
        "landmarksRound": [
          {
            "code": "MZ",
            "number": 2
          },
          {
            "code": "MY",
            "number": 2
          },
          {
            "code": "IE",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Xunatunich Mayan Ruins",
          "Golden Gate Bridge",
          "Hagia Sophia",
          "Ruhyyet Palace",
          "Ampitheater of El Jem",
          "Registan Square",
          "Temple of Literature"
        ],
        "uniqueId": "xflMyCVg",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/village-sea-coast-coastline-279013/",
          "wikiLink": "https://en.wikipedia.org/wiki/Liguria"
        },
        "landmark": {
          "name": "Leaning Tower of Pisa",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/pisa-leaning-tower-unintended-tilt-1056568/",
          "wikiLink": "https://en.wikipedia.org/wiki/Leaning_Tower_of_Pisa",
          "hasLandmark": true
        },
        "city": {
          "name": "Milan",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-center-street-milan-italy-705879/",
          "wikiLink": "https://en.wikipedia.org/wiki/Milan"
        },
        "landmarksRound": [
          {
            "code": "BR",
            "number": 1
          },
          {
            "code": "AQ",
            "number": 2
          },
          {
            "code": "KH",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Voortrekker Monument",
          "Magnus Cathedral",
          "Roman theatre",
          "Tian Tan Buddha",
          "Chichen Itza",
          "Kathmandu Durbar Square",
          "Kittiwake Shipwreck"
        ],
        "uniqueId": "5gyQN8RJ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Ivory Coast",
    "code": "CI",
    "capital": "Yamoussoukro",
    "lat": 8,
    "long": -5,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/africa-ivory-coast-lakes-6289446/",
          "wikiLink": "https://en.wikipedia.org/wiki/Yamoussoukro"
        },
        "landmark": {
          "name": "The Basilica of Our Lady of Peace of Yamoussoukro",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Basilica_of_Our_Lady_of_Peace#/media/File:Notre_dame_de_la_paix_yamoussoukro_by_felix_krohn.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Basilica_of_Our_Lady_of_Peace",
          "hasLandmark": true
        },
        "city": {
          "name": "Abidjan",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/abidjan-plateau-city-road-taxi-4700184/",
          "wikiLink": "https://en.wikipedia.org/wiki/Abidjan"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "W3tdRtTk",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/children-african-portrait-youth-5445501/",
          "wikiLink": "https://en.wikipedia.org/wiki/Ivory_Coast"
        },
        "landmark": {
          "name": "Plateau Mosque",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:MosquePlateauAbidjan2016_1.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Plateau,_Ivory_Coast",
          "hasLandmark": true
        },
        "city": {
          "name": "Bouake",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Bouak%C3%A9_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bouak%C3%A9"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "v2BDQLTG",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Jamaica",
    "code": "JM",
    "capital": "Kingston",
    "lat": 18.25,
    "long": -77.5,
    "cities": [
      "Montego Bay",
      "Port Antonio",
      "Spanish Town",
      "Portmore",
      "Mandeville",
      "Kingston"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/wGyHvtLcW_U",
          "wikiLink": "https://en.wikipedia.org/wiki/Jamaica"
        },
        "landmark": {
          "name": "Dunn's River Falls",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/jamaica-dunn-s-river-falls-to-travel-2695087/",
          "wikiLink": "https://en.wikipedia.org/wiki/Dunn%27s_River_Falls",
          "hasLandmark": true
        },
        "city": {
          "name": "Kingston",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Northern_suburbs_of_Kingston,_Jamaica.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kingston,_Jamaica"
        },
        "landmarksRound": [
          {
            "code": "SD",
            "number": 2
          },
          {
            "code": "GG",
            "number": 2
          },
          {
            "code": "BH",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "St. Peter's Church",
          "Tsingy De Bemaraha",
          "Aleppo Citadel",
          "Annandale Waterfall",
          "Luis I Bridge",
          "Matobo National Park",
          "Tomb of Hafez"
        ],
        "uniqueId": "6scPkgm7",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/jamaica-negril-beach-boat-2409533/",
          "wikiLink": "https://en.wikipedia.org/wiki/Jamaica"
        },
        "landmark": {
          "name": "Green Grotto Caves",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Green_Grotto_Caves_-_Jamaica.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Green_Grotto_Caves",
          "hasLandmark": true
        },
        "city": {
          "name": "Montego Bay",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Montego_Bay#/media/File:Montego_bay-1001.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Montego_Bay"
        },
        "landmarksRound": [
          {
            "code": "BZ",
            "number": 2
          },
          {
            "code": "ZA",
            "number": 1
          },
          {
            "code": "MC",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Gangtey Monastery",
          "Registan Square",
          "Szechenyi Thermal Bath",
          "Katse Dam",
          "Monday Falls",
          "Kaaba",
          "English Harbour"
        ],
        "uniqueId": "wHnTvftT",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Japan",
    "code": "JP",
    "capital": "Tokyo",
    "lat": 36,
    "long": 138,
    "cities": [
      "Osaka",
      "Tokyo",
      "Yokohama",
      "Sapporo",
      "Fukuoka",
      "Kyoto"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/8sOZJ8JF0S8",
          "wikiLink": "https://en.wikipedia.org/wiki/Meguro_River"
        },
        "landmark": {
          "name": "Tokyo Skytree",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/tokyo-sky-tree-japan-cityscape-2086596/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tokyo_Tower",
          "hasLandmark": true
        },
        "city": {
          "name": "Kyoto",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/street-road-buildings-japan-kyoto-7717463/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kyoto"
        },
        "landmarksRound": [
          {
            "code": "ID",
            "number": 1
          },
          {
            "code": "PR",
            "number": 1
          },
          {
            "code": "GG",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Panfilov Park",
          "Marina Bay Sands",
          "Stone House",
          "Bellapais Abbey",
          "Hagia Sophia",
          "Christ the Redeemer",
          "Bahrain Fort"
        ],
        "uniqueId": "pC9jkvMf",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/rice-terraces-sunset-mountains-2389023/",
          "wikiLink": "https://en.wikipedia.org/wiki/Japan"
        },
        "landmark": {
          "name": "Senso-ji",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sens%c5%8d-ji-temple-kyoto-japan-1437734/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sens%C5%8D-ji",
          "hasLandmark": true
        },
        "city": {
          "name": "Osaka",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/japan-osaka-pedestrians-crossing-2014616/",
          "wikiLink": "https://en.wikipedia.org/wiki/Osaka"
        },
        "landmarksRound": [
          {
            "code": "CH",
            "number": 2
          },
          {
            "code": "KZ",
            "number": 1
          },
          {
            "code": "MU",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Ulu Temburong National Park",
          "Bahrain Fort",
          "Vilnius Cathedral",
          "Turku Castle",
          "Dunn's River Falls",
          "Panfilov Park",
          "Badshahi Mosque"
        ],
        "uniqueId": "DmvHrrSp",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Jersey",
    "code": "JE",
    "capital": "Saint Helier",
    "lat": 49.25,
    "long": -2.16666666,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/9gWighoa5kA",
          "wikiLink": "https://en.wikipedia.org/wiki/Mont_Orgueil"
        },
        "landmark": {
          "name": "Mont Orgueil Castle",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mont_Orgueil#/media/File:Gorey_village_with_Harbour_and_Mont_Orguell_Castle_-_Island_of_Jersey_-_Foto_2017_Wolfgang_Pehlemann_DSC05012.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mont_Orgueil",
          "hasLandmark": false
        },
        "city": {
          "name": "St Helier",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/jersey-st-helier-capital-city-1705794/",
          "wikiLink": "https://en.wikipedia.org/wiki/St_Helier"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "S3ctmFLj",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/gorey-jersey-harbour-castle-island-1035943/",
          "wikiLink": "https://en.wikipedia.org/wiki/Jersey"
        },
        "landmark": {
          "name": "Elizabeth Castle",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Elizabeth_Castle",
          "wikiLink": "https://en.wikipedia.org/wiki/Elizabeth_Castle",
          "hasLandmark": false
        },
        "city": {
          "name": "St Brelade",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Saint_Brelade#/media/File:Saint_Brelade's_Bay_Jersey.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Brelade"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "2Lhp9Dhw",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Jordan",
    "code": "JO",
    "capital": "Amman",
    "lat": 31,
    "long": 36,
    "cities": [
      "Zarqa",
      "Amman",
      "Aqaba",
      "As-Salt",
      "Al-Mafraq",
      "Irbid"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/eoZTnFtusDs",
          "wikiLink": "https://en.wikipedia.org/wiki/Petra"
        },
        "landmark": {
          "name": "Roman theatre",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Roman_theatre_(structure)",
          "wikiLink": "https://en.wikipedia.org/wiki/Roman_theatre_(structure)#/media/File:Roman_theater_of_Amman_01.jpg",
          "hasLandmark": true
        },
        "city": {
          "name": "Zarqa",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Zarqa#/media/File:%D8%A7%D9%84%D8%B2%D8%B1%D9%82%D8%A7%D8%A1_%D8%AE%D9%84%D8%A7%D9%84_%D8%AD%D8%B8%D8%B1_%D8%A7%D9%84%D8%AA%D8%AC%D9%88%D9%84_%D9%86%D9%8A%D8%B3%D8%A7%D9%86_%D9%A2%D9%A0%D9%A2%D9%A1.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Zarqa"
        },
        "landmarksRound": [
          {
            "code": "NL",
            "number": 1
          },
          {
            "code": "MR",
            "number": 1
          },
          {
            "code": "FR",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Chorsu Bazaar",
          "Grytviken Church",
          "Tokyo Skytree",
          "Skopje Fortress",
          "Brandenburg Gate",
          "Silver Pagoda",
          "Sistine Chapel"
        ],
        "uniqueId": "XGjbHPQW",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/petra-ad-deir-world-heritage-6294051/",
          "wikiLink": "https://en.wikipedia.org/wiki/Jordan"
        },
        "landmark": {
          "name": "Petra",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/petra-ad-deir-world-heritage-6294051/",
          "wikiLink": "https://en.wikipedia.org/wiki/Petra",
          "hasLandmark": true
        },
        "city": {
          "name": "Amman",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/amman-jordan-city-urban-buildings-7130516/",
          "wikiLink": "https://en.wikipedia.org/wiki/Amman"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "F2YsxBs2",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Kazakhstan",
    "code": "KZ",
    "capital": "Astana",
    "lat": 48,
    "long": 68,
    "cities": [
      "Astana",
      "Shymkent",
      "Almaty",
      "Turkistan",
      "Shymkent",
      "Aktau"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/YtYdNGabzFo",
          "wikiLink": "https://en.wikipedia.org/wiki/Almaty_Region"
        },
        "landmark": {
          "name": "Baiterek",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/astana-kazakhstan-nur-sultan-hotel-4977790/",
          "wikiLink": "https://en.wikipedia.org/wiki/Baiterek_(monument)",
          "hasLandmark": true
        },
        "city": {
          "name": "Astana",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/astana-kazakhstan-left-coast-river-3725230/",
          "wikiLink": "https://en.wikipedia.org/wiki/Astana"
        },
        "landmarksRound": [
          {
            "code": "BA",
            "number": 2
          },
          {
            "code": "RE",
            "number": 1
          },
          {
            "code": "LV",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Rijksmuseum",
          "Bratislava Castle",
          "Ocean Park",
          "Akershus Fortress",
          "The Marble Mountains",
          "Old Panama",
          "Gediminas Castle Tower"
        ],
        "uniqueId": "ghhsCLzw",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/canyon-mountains-river-kazakhstan-1740973/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kazakhstan"
        },
        "landmark": {
          "name": "Palace of Peace and Reconciliation",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Palace_of_Peace_and_Reconciliation#/media/File:%D0%90%D1%81%D1%82%D0%B0%D0%BD%D0%B0,_%D0%94%D0%B2%D0%BE%D1%80%D0%B5%D1%86_%D0%BC%D0%B8%D1%80%D0%B0_%D0%B8_%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D1%8F_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Palace_of_Peace_and_Reconciliation",
          "hasLandmark": true
        },
        "city": {
          "name": "Shymkent",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Shymkent#/media/File:Ordabassy-Square-Shymkent-Kazakhstan.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Shymkent"
        },
        "landmarksRound": [
          {
            "code": "MO",
            "number": 1
          },
          {
            "code": "MZ",
            "number": 2
          },
          {
            "code": "VA",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Valle de Cocora",
          "Lotto World",
          "House of the Black Heads",
          "Merry Cemetery",
          "Senso-ji",
          "Herat Citadel",
          "St. Olaf's Church"
        ],
        "uniqueId": "C5j4xLnG",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Kenya",
    "code": "KE",
    "capital": "Nairobi",
    "lat": 1,
    "long": 38,
    "cities": [
      "Mombasa",
      "Nairobi",
      "Eldoret",
      "Malindi",
      "Kisumu",
      "Nakuru"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/T-LfvX-7IVg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tsavo_East_National_Park"
        },
        "landmark": {
          "name": "Mount Kenya",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mount-kenya-sunset-mountain-peak-7377792/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mount_Kenya",
          "hasLandmark": true
        },
        "city": {
          "name": "Mombasa",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mombasa#/media/File:Downtown_Mombasa.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mombasa"
        },
        "landmarksRound": [
          {
            "code": "TM",
            "number": 2
          },
          {
            "code": "ES",
            "number": 2
          },
          {
            "code": "AU",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Turku Castle",
          "Island of Mozambique",
          "South Pole (ceremonial)",
          "Cathedral Our Lady of the Rosary",
          "Saint Sava Temple",
          "Voortrekker Monument",
          "Sukhbaatar Square"
        ],
        "uniqueId": "4YJvkpCX",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/safari-elephant-landscape-nature-4043090/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kenya"
        },
        "landmark": {
          "name": "Tsavo East National Park",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/elephant-africa-safari-tsavo-east-4478695/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tsavo_East_National_Park",
          "hasLandmark": true
        },
        "city": {
          "name": "Nairobi",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/nairobi-kenya-street-crowded-2770345/",
          "wikiLink": "https://en.wikipedia.org/wiki/Nairobi"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "WsktzlFb",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Kerguelen Islands",
    "code": "TF",
    "capital": "Port-aux-Francais",
    "lat": -49.280366,
    "long": 69.348557,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Volcan_du_Diable_%28Kerguelen_islands%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Volcan_du_Diable"
        },
        "landmark": {
          "name": "Mont Ross",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Ross_Mountain_seen_from_Diablo_Lake_overlook.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mont_Ross",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "CqwJs5Bs",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Kerguelen_CookGlacier.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Cook_Ice_Cap"
        },
        "landmark": {
          "name": "Lake Armor",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Lake_Armor#/media/File:Lac_Armor_%C3%A0_droite_mont%C3%A9e_Volcan_du_Diable.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Lake_Armor",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "QdZY6lL7",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Kiribati",
    "code": "KI",
    "capital": "South Tarawa",
    "lat": 1.41666666,
    "long": 173,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/fanning-island-kiribati-beach-1663125/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tabuaeran"
        },
        "landmark": {
          "name": "Tarawa",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:South_Tarawa_from_the_air.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tarawa",
          "hasLandmark": true
        },
        "city": {
          "name": "Eita",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Impacts_of_coastal_erosion_and_drought_on_coconut_palms_in_Eita,_Tarawa,_Kiribati.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/South_Tarawa"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "KTBvngTz",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:South_Tarawa_from_the_air.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/South_Tarawa"
        },
        "landmark": {
          "name": "Kiritimati",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Kiritimati#/media/File:London,_Kiritimati.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kiritimati",
          "hasLandmark": true
        },
        "city": {
          "name": "London",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:London,_Kiritimati.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/London,_Kiribati"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "bzr4Rc9Q",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Kosovo",
    "code": "XK",
    "capital": "Pristina",
    "lat": 42.666667,
    "long": 21.166667,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/bwSYcD0okkM",
          "wikiLink": "https://en.wikipedia.org/wiki/Prizren"
        },
        "landmark": {
          "name": "Prizeren Fortress",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Prizren_Fortress#/media/File:Prizren_Fortress_(2021).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Prizren_Fortress",
          "hasLandmark": false
        },
        "city": {
          "name": "Mitrovica",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Mitrovica_gjat%C3%AB_mbr%C3%ABmjes.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mitrovica,_Kosovo"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "hPKd33BM",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/prizren-kosovo-city-view-town-3430989/",
          "wikiLink": "https://en.wikipedia.org/wiki/Prizren"
        },
        "landmark": {
          "name": "Newborn Monument",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Newborn_Monument_Pristina.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Newborn_monument",
          "hasLandmark": false
        },
        "city": {
          "name": "Pristina",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/rainrannu/2700887728",
          "wikiLink": "https://en.wikipedia.org/wiki/Pristina"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "8PQXYFDc",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Kuwait",
    "code": "KW",
    "capital": "Kuwait City",
    "lat": 29.5,
    "long": 45.75,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/ICJkwztWGcI",
          "wikiLink": "https://en.wikipedia.org/wiki/Kuwait_Towers"
        },
        "landmark": {
          "name": "Kuwait Towers",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kuwait-towers-landmarks-kuwait-blue-520621/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kuwait_Towers",
          "hasLandmark": true
        },
        "city": {
          "name": "Fahaheel",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/kendoerr/5220651016",
          "wikiLink": "https://en.wikipedia.org/wiki/Fahaheel"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "rdVdx6pQ",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kuwait-ruins-sea-house-coast-1824090/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kuwait"
        },
        "landmark": {
          "name": "Liberation Tower",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Liberation_Tower_(Kuwait)#/media/File:Liberation_Tower_(245936909).jpeg",
          "wikiLink": "https://en.wikipedia.org/wiki/Liberation_Tower_(Kuwait)",
          "hasLandmark": true
        },
        "city": {
          "name": "Kuwait City",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/street-cars-city-downtown-kuwait-5363692/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kuwait_City"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "MQLf8cxY",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Kyrgyzstan",
    "code": "KG",
    "capital": "Bishkek",
    "lat": 41,
    "long": 75,
    "cities": [
      "Bishkek",
      "Osh",
      "Jalal-Abad",
      "Tokmok",
      "Naryn",
      "Karakol"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/jTO7daIRh0U",
          "wikiLink": "https://en.wikipedia.org/wiki/Kyrgyzstan"
        },
        "landmark": {
          "name": "Panfilov Park",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Statue_of_Ivan_Panfilov_in_Bishkek.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Panfilov_Park,_Bishkek",
          "hasLandmark": true
        },
        "city": {
          "name": "Osh",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Osh#/media/File:Osh_03-2016_img27_view_from_Sulayman_Mountain.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Osh"
        },
        "landmarksRound": [
          {
            "code": "CH",
            "number": 2
          },
          {
            "code": "ES",
            "number": 2
          },
          {
            "code": "AU",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Alhambra",
          "Vaduz Cathedral",
          "Tsingy De Bemaraha",
          "Ulu Temburong National Park",
          "Soroca Fort",
          "Deadvlei",
          "Red Square"
        ],
        "uniqueId": "gqY6DWLQ",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kyrgyzstan-toktogul-dam-dam-naryn-4765706/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kyrgyzstan"
        },
        "landmark": {
          "name": "Tash Rabat",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kyrgyzstan-tash-rabat-sightseeing-3738065/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tash_Rabat",
          "hasLandmark": true
        },
        "city": {
          "name": "Bishkek",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mountains-winter-offspring-bishkek-7589047/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bishkek"
        },
        "landmarksRound": [
          {
            "code": "BB",
            "number": 1
          },
          {
            "code": "BE",
            "number": 2
          },
          {
            "code": "PT",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "St. Tryphon's Cathedral",
          "Tjibaou Cultural Center",
          "Wawel Castle",
          "Castle Rushden",
          "Island of Mozambique",
          "Abu Simbel Temples",
          "Ulu Temburong National Park"
        ],
        "uniqueId": "9LymcYJ7",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Laos",
    "code": "LA",
    "capital": "Vientiane",
    "lat": 18,
    "long": 105,
    "cities": [
      "Vientiane",
      "Luang Prabang",
      "Savannakhet",
      "Thakhek",
      "Phonsavan",
      "Pakse"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/yJ2SHIpNb9M",
          "wikiLink": "https://en.wikipedia.org/wiki/Vang_Vieng"
        },
        "landmark": {
          "name": "Wat Xiengthong",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Wat_Xieng_Thong#/media/File:Temple_Wat_Xieng_Thong_-_Luang_Prabang_-_Laos.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Wat_Xieng_Thong",
          "hasLandmark": true
        },
        "city": {
          "name": "Pakse",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/river-buidlings-trees-coast-shore-6956330/",
          "wikiLink": "https://en.wikipedia.org/wiki/Pakse"
        },
        "landmarksRound": [
          {
            "code": "LU",
            "number": 1
          },
          {
            "code": "RO",
            "number": 2
          },
          {
            "code": "LS",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Koutoubia",
          "Mount Nyriagongo",
          "Dunn's River Falls",
          "Grand Ducal Palace",
          "Villa Folio",
          "Vaduz Cathedral",
          "Mostar Old Bridge"
        ],
        "uniqueId": "2xRW7Y9W",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/road-luang-prabang-street-city-4878453/",
          "wikiLink": "https://en.wikipedia.org/wiki/Laos"
        },
        "landmark": {
          "name": "Patuxay Monument",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/patuxai-laos-vientiane-monument-1532500/",
          "wikiLink": "https://en.wikipedia.org/wiki/Patuxai",
          "hasLandmark": true
        },
        "city": {
          "name": "Vientiane",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Vientiane#/media/File:20171118_Vientiane_3220_DxO.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Vientiane"
        },
        "landmarksRound": [
          {
            "code": "NZ",
            "number": 2
          },
          {
            "code": "KZ",
            "number": 1
          },
          {
            "code": "ZW",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Old Town Square",
          "Buenos Aires Obelisk",
          "Chimi Lhakhang",
          "Parthenon",
          "Koutoubia",
          "Twelve Apostles",
          "Matobo National Park"
        ],
        "uniqueId": "wQlspKJR",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Latvia",
    "code": "LV",
    "capital": "Riga",
    "lat": 57,
    "long": 25,
    "cities": [
      "Liepaja",
      "Riga",
      "Jurmala",
      "Kuldiga",
      "Cesis",
      "Jelgava"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/jFqUJacYIsw",
          "wikiLink": "https://en.wikipedia.org/wiki/Riga"
        },
        "landmark": {
          "name": "House of the Black Heads",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/House_of_the_Blackheads_(Riga)#/media/File:House_of_Blackheads_at_Dusk_3,_Riga,_Latvia_-_Diliff.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/House_of_the_Blackheads_(Riga)",
          "hasLandmark": true
        },
        "city": {
          "name": "Liepaja",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Liep%C4%81ja#/media/File:Liepaja_Blick_von_der_evangelischen_Dreifaltigkeitskathedrale_2.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Liep%C4%81ja"
        },
        "landmarksRound": [
          {
            "code": "CH",
            "number": 2
          },
          {
            "code": "MU",
            "number": 1
          },
          {
            "code": "MG",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Sule Pagoda",
          "Monument to the Heroes of the Restoration",
          "Green Grotto Caves",
          "",
          "Chillon Castle",
          "Island of Mozambique",
          "Oriental Pearl TV Tower"
        ],
        "uniqueId": "6yyz4p4L",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/latvia-riga-daugava-flow-3725546/",
          "wikiLink": "https://en.wikipedia.org/wiki/Latvia"
        },
        "landmark": {
          "name": "St. Peter's Church",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/St._Peter%27s_Church,_Riga#/media/File:St._Peter's_Church_facade,_Riga,_Latvia_-_Diliff.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/St._Peter%27s_Church,_Riga",
          "hasLandmark": true
        },
        "city": {
          "name": "Riga",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/riga-aerial-view-night-traffic-2935039/",
          "wikiLink": "https://en.wikipedia.org/wiki/Riga"
        },
        "landmarksRound": [
          {
            "code": "BW",
            "number": 1
          },
          {
            "code": "FO",
            "number": 2
          },
          {
            "code": "FI",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Fort Sao Sebastiao",
          "Kathmandu Durbar Square",
          "Pico Basile",
          "English Harbour",
          "Monte Carlo Casino",
          "Petronas Towers",
          "Grytviken Church"
        ],
        "uniqueId": "N6nZby9X",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Lebanon",
    "code": "LB",
    "capital": "Beirut",
    "lat": 33.83333333,
    "long": 35.83333333,
    "cities": [
      "Tripoli",
      "Beirut",
      "Tyre",
      "Byblos",
      "Sidon",
      "Baalbek"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/KqEYj1VHA_o",
          "wikiLink": "https://en.wikipedia.org/wiki/Beirut"
        },
        "landmark": {
          "name": "Pigeon Rocks",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Pigeon%27s_Rock_Beirut_Lebanon.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Raouch%C3%A9",
          "hasLandmark": true
        },
        "city": {
          "name": "Tripoli",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Tripoli,_Lebanon#/media/File:The_city_of_Tripoli,_Lebanon.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tripoli,_Lebanon"
        },
        "landmarksRound": [
          {
            "code": "MD",
            "number": 1
          },
          {
            "code": "RU",
            "number": 1
          },
          {
            "code": "MU",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Burj Khalifa",
          "Predjama Castle",
          "Ruins of St. Paul's",
          "St. Stephen's Cathedral",
          "Kittiwake Shipwreck",
          "Buenos Aires Obelisk",
          "Twelve Apostles"
        ],
        "uniqueId": "HjMwTJtC",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/landscape-village-church-catholic-3663721/",
          "wikiLink": "https://en.wikipedia.org/wiki/Lebanon"
        },
        "landmark": {
          "name": "Baalbek",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-roman-temple-antique-3659506/",
          "wikiLink": "https://en.wikipedia.org/wiki/Baalbek",
          "hasLandmark": true
        },
        "city": {
          "name": "Beirut",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cityscape-city-town-town-hall-3696637/",
          "wikiLink": "https://en.wikipedia.org/wiki/Beirut"
        },
        "landmarksRound": [
          {
            "code": "QA",
            "number": 1
          },
          {
            "code": "PL",
            "number": 1
          },
          {
            "code": "KZ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Ostrog Monastery",
          "Sydney Opera House",
          "Patuxay Monument",
          "Senso-ji",
          "Ouzoud Falls",
          "Christ Church Cathedral",
          "The Blue Mosque"
        ],
        "uniqueId": "jLpx8zdj",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Lesotho",
    "code": "LS",
    "capital": "Maseru",
    "lat": -29.5,
    "long": 28.5,
    "cities": [
      "Maseru",
      "Teyateyaneng",
      "Qachas Nek",
      "Mafeteng",
      "Butha Buthe",
      "Quthing"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/wg4Yd9iK0ho",
          "wikiLink": "https://en.wikipedia.org/wiki/Maloti_Mountains"
        },
        "landmark": {
          "name": "Katse Dam",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Katse_Dam#/media/File:Katse_Dam.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Katse_Dam",
          "hasLandmark": true
        },
        "city": {
          "name": "Maseru",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Maseru#/media/File:Maseru_from_Parliament_Hill.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Maseru"
        },
        "landmarksRound": [
          {
            "code": "BR",
            "number": 1
          },
          {
            "code": "BZ",
            "number": 1
          },
          {
            "code": "CY",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Chimi Lhakhang",
          "Tash Rabat",
          "Monteverde Cloud Forest",
          "Rhine Falls",
          "Big Ben",
          "Vardzia",
          "Liwonde National Park"
        ],
        "uniqueId": "JjHC6s22",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/lesotho-mountain-village-927577/",
          "wikiLink": "https://en.wikipedia.org/wiki/Lesotho"
        },
        "landmark": {
          "name": "Maluti Mountains",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Maloti_Mountains#/media/File:Snow_Capped_Maloti_Mountains_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Maloti_Mountains",
          "hasLandmark": true
        },
        "city": {
          "name": "Teyateyaneng",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Ha_Mohapi_Village_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Teyateyaneng"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "X95c8QPf",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Liberia",
    "code": "LR",
    "capital": "Monrovia",
    "lat": 6.5,
    "long": -9.5,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Liberia,_Africa_-_panoramio_%28255%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Liberia"
        },
        "landmark": {
          "name": "Kpatawee Falls",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/55953988@N00/5578860617",
          "wikiLink": "https://en.wikipedia.org/wiki/Liberia",
          "hasLandmark": true
        },
        "city": {
          "name": "Kakata",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Kakata#/media/File:City_of_Kakata,_photograph_of_main_road_near_BWI,_May_2012.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kakata"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "6k3M667m",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/davidstanleytravel/6831730706",
          "wikiLink": "https://en.wikipedia.org/wiki/Mesurado_River"
        },
        "landmark": {
          "name": "Lake Piso",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Another_view_of_Lake_Piso,_Bomi_County.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Lake_Piso",
          "hasLandmark": true
        },
        "city": {
          "name": "Monrovia",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Monrovia#/media/File:Liberia,_Africa_-_panoramio_(256).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Monrovia"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "gZ9gZ8Wk",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Libya",
    "code": "LY",
    "capital": "Tripoli",
    "lat": 25,
    "long": 17,
    "cities": [
      "Tripoli",
      "Benghazi",
      "Sirte",
      "Khoms",
      "Al Bayda",
      "Sabratah"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/ci3Eot3SyEQ",
          "wikiLink": "https://en.wikipedia.org/wiki/Sabratha"
        },
        "landmark": {
          "name": "Leptis Magna",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Leptis_Magna_%2829%29_%288288918733%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Leptis_Magna",
          "hasLandmark": true
        },
        "city": {
          "name": "Tripoli",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Tripoli,_Libya#/media/File:Tripoli_Central_Business_District_from_Oea_Park.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Tripoli,_Libya"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "kts5RHn2",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/oasis-libya-desert-palm-trees-67549/",
          "wikiLink": "https://en.wikipedia.org/wiki/Libya"
        },
        "landmark": {
          "name": "Marcus Aurelius Arch",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Arch_of_Marcus_Aurelius_-_libya_-_%D9%82%D9%88%D8%B3_%D9%85%D8%A7%D8%B1%D9%83%D9%88%D8%B3_%D8%A3%D9%88%D8%B1%D9%8A%D9%84%D9%8A%D9%88%D8%B32_-_%D9%84%D9%8A%D8%A8%D9%8A%D8%A7.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Arch_of_Marcus_Aurelius",
          "hasLandmark": true
        },
        "city": {
          "name": "Benghazi",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Benghazi#/media/File:Al_Daawa_alIslamiyah2.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Benghazi"
        },
        "landmarksRound": [
          {
            "code": "HK",
            "number": 1
          },
          {
            "code": "MT",
            "number": 1
          },
          {
            "code": "CH",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Senso-ji",
          "Heydar Aliyev Center",
          "Erbil Citadel",
          "Great Blue Hole",
          "Tian Tan Buddha",
          "Baalbek",
          "Skopje Fortress"
        ],
        "uniqueId": "FzNfYyZ8",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Liechtenstein",
    "code": "LI",
    "capital": "Vaduz",
    "lat": 47.26666666,
    "long": 9.53333333,
    "cities": [
      "Triesenberg",
      "Vaduz",
      "Triesen",
      "Balzers",
      "Mauren",
      "Schaan"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/4CKnbVZ4Tew",
          "wikiLink": "https://en.wikipedia.org/wiki/Vaduz_Castle"
        },
        "landmark": {
          "name": "Vaduz Cathedral",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Vaduz_Cathedral#/media/File:Vaduz_Kathedrale_St._Florin_01.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Vaduz_Cathedral",
          "hasLandmark": true
        },
        "city": {
          "name": "Triesenberg",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Oben_am_jungen_Rhein.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Triesenberg"
        },
        "landmarksRound": [
          {
            "code": "SI",
            "number": 1
          },
          {
            "code": "TW",
            "number": 2
          },
          {
            "code": "CZ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Cathedral of Brasilica",
          "Gangtey Monastery",
          "Old Dongola",
          "Sukhbaatar Square",
          "Grandvalira",
          "Castle Rushden",
          "Tsingy De Bemaraha"
        ],
        "uniqueId": "mHmsjwMH",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/liechtenstein-city-architecture-176116/",
          "wikiLink": "https://en.wikipedia.org/wiki/Liechtenstein"
        },
        "landmark": {
          "name": "Vaduz Castle",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/castle-fortress-forest-fog-trees-5704327/",
          "wikiLink": "https://en.wikipedia.org/wiki/Vaduz_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Vaduz",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/liechtenstein-r%c3%a4tikon-vaduz-7145060/",
          "wikiLink": "https://en.wikipedia.org/wiki/Vaduz"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "2cPYSLKm",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Lithuania",
    "code": "LT",
    "capital": "Vilnius",
    "lat": 56,
    "long": 24,
    "cities": [
      "Kaunas",
      "Klaipeda",
      "Palanga",
      "Kedainiai",
      "Druskininkai",
      "Vilnius"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/NMV4RIqrP-A",
          "wikiLink": "https://en.wikipedia.org/wiki/Trakai"
        },
        "landmark": {
          "name": "Gediminas Castle Tower",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Gediminas%27_Tower#/media/File:Gedimino_pilis_by_Augustas_Didzgalvis.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Gediminas%27_Tower",
          "hasLandmark": true
        },
        "city": {
          "name": "Vilnius",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-vilnius-capital-europe-2764129/",
          "wikiLink": "https://en.wikipedia.org/wiki/Vilnius"
        },
        "landmarksRound": [
          {
            "code": "DE",
            "number": 1
          },
          {
            "code": "CH",
            "number": 2
          },
          {
            "code": "BH",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Grytviken Church",
          "Amberd",
          "Pulhapanzak Waterfalls",
          "Bratislava Castle",
          "Middle of the World City",
          "Tikal National Park",
          "Pico Basile"
        ],
        "uniqueId": "Sc3t5V5T",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/vilnius-lithuania-eastern-europe-1029634/",
          "wikiLink": "https://en.wikipedia.org/wiki/Lithuania"
        },
        "landmark": {
          "name": "Vilnius Cathedral",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/lithuania-vilnius-cathedral-912362/",
          "wikiLink": "https://en.wikipedia.org/wiki/Vilnius_Cathedral",
          "hasLandmark": true
        },
        "city": {
          "name": "Kaunas",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/lithuania-kaunas-city-3721840/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kaunas"
        },
        "landmarksRound": [
          {
            "code": "VA",
            "number": 1
          },
          {
            "code": "TJ",
            "number": 2
          },
          {
            "code": "FO",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Silver Pagoda",
          "Ostrog Monastery",
          "Macau Tower",
          "Deception Island",
          "Kelonia",
          "Amber Palace",
          "Fort Sao Sebastiao"
        ],
        "uniqueId": "LJy3wfXT",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Luxembourg",
    "code": "LU",
    "capital": "Luxembourg City",
    "lat": 49.75,
    "long": 6.16666666,
    "cities": [
      "Echternach",
      "Luxembourg City",
      "Diekirch",
      "Clervaux",
      "Wiltz",
      "Esch-sur-Alzette"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/FPdyoYNdvaM",
          "wikiLink": "https://en.wikipedia.org/wiki/Luxembourg_City"
        },
        "landmark": {
          "name": "Vianden Castle",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/vianden-castle-luxembourg-landmark-1137019/",
          "wikiLink": "https://en.wikipedia.org/wiki/Vianden_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Echternach",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/echternach-luxembourg-hiking-6133761/",
          "wikiLink": "https://en.wikipedia.org/wiki/Echternach"
        },
        "landmarksRound": [
          {
            "code": "MZ",
            "number": 2
          },
          {
            "code": "BD",
            "number": 2
          },
          {
            "code": "MM",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Cathedral Our Lady of the Rosary",
          "Middle of the World City",
          "St. Stephen's Cathedral",
          "Saqsayhuaman",
          "Iluissat Icefjord",
          "Old Panama",
          "Acropolis"
        ],
        "uniqueId": "7KHqqZTd",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/luxembourg-luxembourg-city-landscape-1164663/",
          "wikiLink": "https://en.wikipedia.org/wiki/Luxembourg"
        },
        "landmark": {
          "name": "Grand Ducal Palace",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Grand_Ducal_Palace,_Luxembourg#/media/File:Palacio_Gran_Ducal_de_Luxemburgo.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Grand_Ducal_Palace,_Luxembourg",
          "hasLandmark": true
        },
        "city": {
          "name": "Luxembourg City",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/luxembourg-basic-historic-center-2354945/",
          "wikiLink": "https://en.wikipedia.org/wiki/Luxembourg_City"
        },
        "landmarksRound": [
          {
            "code": "SI",
            "number": 1
          },
          {
            "code": "PT",
            "number": 1
          },
          {
            "code": "IS",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Easter Island",
          "Christiansborg Palace",
          "Amber Palace",
          "Macau Tower",
          "Pyramiden",
          "Pulhapanzak Waterfalls",
          "Chillon Castle"
        ],
        "uniqueId": "YjNr6txQ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Macau",
    "code": "MO",
    "capital": "Macau",
    "lat": 22.198745,
    "long": 113.543873,
    "cities": [
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/dZbJOsTl4PM",
          "wikiLink": "https://en.wikipedia.org/wiki/Casino_Lisboa_(Macau)"
        },
        "landmark": {
          "name": "Ruins of St. Paul's",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/macau-macao-asia-china-tourism-5149823/",
          "wikiLink": "https://en.wikipedia.org/wiki/Ruins_of_Saint_Paul%27s",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [
          {
            "code": "PR",
            "number": 1
          },
          {
            "code": "BO",
            "number": 1
          },
          {
            "code": "UA",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Romney Manor",
          "Kaaba",
          "Cape of Good Hope",
          "Al Fateh Grand Mosque",
          "Anne Frank House",
          "Dunn's River Falls",
          "Angel Falls"
        ],
        "uniqueId": "cTDXm5xb",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/macao-macau-china-architecture-4769469/",
          "wikiLink": "https://en.wikipedia.org/wiki/Macau"
        },
        "landmark": {
          "name": "Macau Tower",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/macau-tower-nanwan-lake-panoramic-1730546/",
          "wikiLink": "https://en.wikipedia.org/wiki/Macau_Tower",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [
          {
            "code": "US",
            "number": 1
          },
          {
            "code": "LT",
            "number": 1
          },
          {
            "code": "BR",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Chamarel Waterfall",
          "Aleppo Citadel",
          "Palace of Versailles",
          "Wat Arun",
          "National Shrine Basilica of our Lady of Las Lajas",
          "Somapura Mahavihara",
          "Gangtey Monastery"
        ],
        "uniqueId": "zlclbnL4",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Madagascar",
    "code": "MG",
    "capital": "Antananarivo",
    "lat": -20,
    "long": 47,
    "cities": [
      "Antananarivo",
      "Toamasina",
      "Mahajanga",
      "Toliara",
      "Antisiranana",
      "Antsirabe"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/yPSbirjJWzs",
          "wikiLink": "https://en.wikipedia.org/wiki/Adansonia_grandidieri"
        },
        "landmark": {
          "name": "Tsingy De Bemaraha",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Tsingy_de_Bemaraha_National_Park#/media/File:Tsingy_de_Bemaraha.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tsingy_de_Bemaraha_National_Park",
          "hasLandmark": true
        },
        "city": {
          "name": "Antananarivo",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/buildings-houses-roofs-palm-trees-6117067/",
          "wikiLink": "https://en.wikipedia.org/wiki/Antananarivo"
        },
        "landmarksRound": [
          {
            "code": "MK",
            "number": 1
          },
          {
            "code": "BD",
            "number": 1
          },
          {
            "code": "VE",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Liwonde National Park",
          "Koutoubia",
          "Boudhanath",
          "Temple of Literature",
          "Pyramiden",
          "Kathmandu Durbar Square",
          "Amber Palace"
        ],
        "uniqueId": "jSQgyWsh",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/baobab-madagascar-tree-nature-5030877/",
          "wikiLink": "https://en.wikipedia.org/wiki/Madagascar"
        },
        "landmark": {
          "name": "Baobab Avenue",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Avenue_of_the_Baobabs#/media/File:Adansonia_grandidieri_Pat_Hooper.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Avenue_of_the_Baobabs#/media/File:Adansonia_grandidieri_Pat_Hooper.jpg",
          "hasLandmark": true
        },
        "city": {
          "name": "Toamasina",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Toamasina#/media/File:Port_Toamasina_Madagascar.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Toamasina"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Xtb4QXfL",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Malawi",
    "code": "MW",
    "capital": "Lilongwe",
    "lat": -13.5,
    "long": 34,
    "cities": [
      "Lilongwe",
      "Zomba",
      "Mzuzu",
      "Mangochi",
      "Kasungu",
      "Blantyre"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/f8Vkuc6fXQA",
          "wikiLink": "https://en.wikipedia.org/wiki/Malawi"
        },
        "landmark": {
          "name": "Liwonde National Park",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Liwonde_National_Park#/media/File:Liwonde_Park_-_viiew_of_Shire_River.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Liwonde_National_Park",
          "hasLandmark": true
        },
        "city": {
          "name": "Blantyre",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Blantyre#/media/File:Blantyre_City.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Blantyre"
        },
        "landmarksRound": [
          {
            "code": "GL",
            "number": 1
          },
          {
            "code": "AR",
            "number": 2
          },
          {
            "code": "FI",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Olavinlinna",
          "Monument to the Heroes of the Restoration",
          "Umayyad Mosque",
          "Middle of the World City",
          "Tian Tan Buddha",
          "Amalienborg",
          "Abu Simbel Temples"
        ],
        "uniqueId": "4LhbTmbj",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/malawi-view-ocean-sea-beach-242567/",
          "wikiLink": "https://en.wikipedia.org/wiki/Malawi"
        },
        "landmark": {
          "name": "Mumbo Island",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mumbo_Island#/media/File:Mumbo_Island_02.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mumbo_Island",
          "hasLandmark": true
        },
        "city": {
          "name": "Lilongwe",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/neiljs/5579483953",
          "wikiLink": "https://en.wikipedia.org/wiki/Lilongwe"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "52x6WNWS",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Malaysia",
    "code": "MY",
    "capital": "Kuala Lumpur",
    "lat": 2.5,
    "long": 112.5,
    "cities": [
      "Kuching",
      "Kuala Lumpur",
      "Kota Kinabalu",
      "Malacca",
      "Ipoh",
      "Johor Bahru"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/WaL-LPfgUBg",
          "wikiLink": "https://en.wikipedia.org/wiki/Batu_Caves"
        },
        "landmark": {
          "name": "Petronas Towers",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kuala-lumpur-petronas-twin-towers-1820944/",
          "wikiLink": "https://en.wikipedia.org/wiki/Petronas_Towers",
          "hasLandmark": true
        },
        "city": {
          "name": "Kuching",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Kuching#/media/File:Traffic_Junction_Near_Civic_Centre_Tower,_Kuching.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kuching"
        },
        "landmarksRound": [
          {
            "code": "MO",
            "number": 1
          },
          {
            "code": "PY",
            "number": 1
          },
          {
            "code": "MU",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Gediminas Castle Tower",
          "Leaning Tower of Pisa",
          "Wieliczka Salt Mine",
          "Heydar Aliyev Center",
          "Frogner Park",
          "Saqsayhuaman",
          "Motherland Monument"
        ],
        "uniqueId": "bVrS9cY2",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kuala-lumpur-petronas-twin-towers-1820944/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kuala_Lumpur"
        },
        "landmark": {
          "name": "Langkawi Sky Bridge",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bridge-rope-architecture-steel-2354814/",
          "wikiLink": "https://en.wikipedia.org/wiki/Langkawi_Sky_Bridge",
          "hasLandmark": true
        },
        "city": {
          "name": "Kuala Lumpur",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-skyline-buildings-urban-1284258/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kuala_Lumpur"
        },
        "landmarksRound": [
          {
            "code": "MU",
            "number": 1
          },
          {
            "code": "RE",
            "number": 1
          },
          {
            "code": "JO",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Grand Ducal Palace",
          "Amber Palace",
          "Baalbek",
          "Dunn's River Falls",
          "Lukan Longshan Temple",
          "Ocean Park",
          "Mulafossur Waterfall"
        ],
        "uniqueId": "xQpLdXQG",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Maldives",
    "code": "MV",
    "capital": "Male",
    "lat": 3.25,
    "long": 73,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/xPsFXsbXJRg",
          "wikiLink": "https://en.wikipedia.org/wiki/Maldives"
        },
        "landmark": {
          "name": "Islamic Center",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Islamic_Centre_(Maldives)#/media/File:Viber_image_2021-04-27_21-51-07-925_(2).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Islamic_Centre_(Maldives)",
          "hasLandmark": true
        },
        "city": {
          "name": "Male",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Maldives#/media/File:Male-total.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mal%C3%A9"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "6S88Ftxd",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/maldives-hut-shack-villa-beach-4703551/",
          "wikiLink": "https://en.wikipedia.org/wiki/Maldives"
        },
        "landmark": {
          "name": "Republic Square",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Maldives#/media/File:JumhooreeMaidan.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Maldives#Culture",
          "hasLandmark": true
        },
        "city": {
          "name": "Addu",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Hulhumeedhoo_Aerial_view_2013.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Addu_City"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "WjVg3kNk",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Mali",
    "code": "ML",
    "capital": "Bamako",
    "lat": 17,
    "long": -4,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mali-boats-good-produce-town-80855/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mali"
        },
        "landmark": {
          "name": "Great Mosque of Djenne",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Great_Mosque_of_Djenn%C3%A9#/media/File:Great_Mosque_of_Djenn%C3%A9_1.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Great_Mosque_of_Djenn%C3%A9",
          "hasLandmark": false
        },
        "city": {
          "name": "Gao",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Gao#/media/File:Gao_Mali_2006.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Gao"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Yp2bwBGx",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Village_Telly_in_Mali.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mali"
        },
        "landmark": {
          "name": "Tomb of Askia",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Tomb_of_Askia#/media/File:Askia.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tomb_of_Askia",
          "hasLandmark": false
        },
        "city": {
          "name": "Bamako",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bamako#/media/File:Bamakolooking_north_from_the_old_bridge.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bamako"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "PVP9cP55",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Malta",
    "code": "MT",
    "capital": "Valletta",
    "lat": 35.83333333,
    "long": 14.58333333,
    "cities": [
      "Birgu",
      "Cospicua",
      "Senglea",
      "Mdina",
      "Sliema",
      "Valletta"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/dWK5VEPIVwk",
          "wikiLink": "https://en.wikipedia.org/wiki/Sanctuary_of_Our_Lady_of_Mellie%C4%A7a"
        },
        "landmark": {
          "name": "Blue Grotto",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/malta-blue-grotto-sea-2747465/",
          "wikiLink": "https://en.wikipedia.org/wiki/Blue_Grotto_(Malta)",
          "hasLandmark": true
        },
        "city": {
          "name": "Valletta",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/malta-travel-tourism-europe-island-485321/",
          "wikiLink": "https://en.wikipedia.org/wiki/Valletta"
        },
        "landmarksRound": [
          {
            "code": "CW",
            "number": 2
          },
          {
            "code": "DZ",
            "number": 1
          },
          {
            "code": "BT",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Langkawi Sky Bridge",
          "Tenaru Falls",
          "Gangtey Monastery",
          "Sule Pagoda",
          "Rijksmuseum",
          "Palace of Peace and Reconciliation",
          "Fasil Ghebbi"
        ],
        "uniqueId": "Bl3kfcYB",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/popeye-village-malta-architecture-4158038/",
          "wikiLink": "https://en.wikipedia.org/wiki/Malta"
        },
        "landmark": {
          "name": "Mosta Rotunda",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mosta-rotunda-basilica-church-7503028/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rotunda_of_Mosta",
          "hasLandmark": true
        },
        "city": {
          "name": "Birgu",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Birgu#/media/File:Birgu-Vittoriosa_-_Malta.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Birgu"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "MqMSpkWJ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Marshall Islands",
    "code": "MH",
    "capital": "Majuro",
    "lat": 9,
    "long": 168,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/marshall-islands-usa-beach-coast-1064876/",
          "wikiLink": "https://en.wikipedia.org/wiki/Marshall_Islands"
        },
        "landmark": {
          "name": "Bikini Atoll",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bikini_Atoll#/media/File:Sikorsky_SH-3G_Sea_King_from_Helicopter_Combat_Support_Squadron_1_in_flight_during_an_aerial_radiation_survey_over_Bikini_Atoll_in_November_1978.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bikini_Atoll",
          "hasLandmark": false
        },
        "city": {
          "name": "Majuro",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Marshall_Islands#/media/File:Sunset_-_Majuro.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Majuro"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "fM6lBNys",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Marshall_Islands_%2840325565%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Marshall_Islands"
        },
        "landmark": {
          "name": "Arno Atoll",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Arno_Atoll#/media/File:ArnoAtoll_ASTER_2002jul25.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Marshall_Islands",
          "hasLandmark": false
        },
        "city": {
          "name": "Ebeye",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ebeye_Island#/media/File:Ebeye_Island.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ebeye_Island"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "pFLdwLlQ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Martinique",
    "code": "MQ",
    "capital": "Fort-de-France",
    "lat": 14.666667,
    "long": -61,
    "cities": [
      "Fort-de-France",
      "Le Lamentin",
      "Le Robert",
      "Sainte-Marie",
      "Le Francois",
      "Ducos"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/caribbean-martinique-sea-beach-67886/",
          "wikiLink": "https://en.wikipedia.org/wiki/Martinique"
        },
        "landmark": {
          "name": "Jardin de Balata",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Balata_Garden#/media/File:Jardin_de_Balata.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Balata_Garden",
          "hasLandmark": true
        },
        "city": {
          "name": "Le Lamentin",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Le_Lamentin_decollage_banner.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Le_Lamentin"
        },
        "landmarksRound": [
          {
            "code": "GG",
            "number": 2
          },
          {
            "code": "IE",
            "number": 1
          },
          {
            "code": "BS",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "The Little Chapel",
          "Wat Arun",
          "Seven Colored Earths",
          "Castle Rushden",
          "Predjama Castle",
          "Pulhapanzak Waterfalls",
          "Saint Michael's Cave"
        ],
        "uniqueId": "9DxzRbvj",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/nature-sky-blue-sky-clouds-2358189/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mount_Pel%C3%A9e"
        },
        "landmark": {
          "name": "Memorial de l'Anse",
          "photographer": "",
          "imageLink": "https://fr.wikipedia.org/wiki/Cap_110#/media/Fichier:Cap110.jpg",
          "wikiLink": "https://fr.wikipedia.org/wiki/Cap_110",
          "hasLandmark": true
        },
        "city": {
          "name": "Fort-de-France",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Fort-de-France#/media/File:Fort-de-France-baie.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Fort-de-France"
        },
        "landmarksRound": [
          {
            "code": "LB",
            "number": 2
          },
          {
            "code": "KZ",
            "number": 2
          },
          {
            "code": "PT",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Gyeongbukgong Palace",
          "Acropolis",
          "Sukhbaatar Square",
          "Elmina Castle",
          "Cathedral Our Lady of the Rosary",
          "Amalienborg",
          "Ocean Park"
        ],
        "uniqueId": "P8cLT8xq",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Mauritania",
    "code": "MR",
    "capital": "Nouakchott",
    "lat": 20,
    "long": -12,
    "cities": [
      "Nouakchott",
      "Nouadhibou",
      "Nema",
      "Kaedi",
      "Kiffa",
      "Atar"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/0xFA04r_0ds",
          "wikiLink": "https://en.wikipedia.org/wiki/Mauritania"
        },
        "landmark": {
          "name": "Chinguetti",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Chinguetti#/media/File:Chinguetti_mosquee.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Chinguetti",
          "hasLandmark": true
        },
        "city": {
          "name": "Nouakchott",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Nouakchott#/media/File:Nouakchott.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Nouakchott"
        },
        "landmarksRound": [
          {
            "code": "LS",
            "number": 1
          },
          {
            "code": "CW",
            "number": 2
          },
          {
            "code": "MO",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Matobo National Park",
          "Diocletian's Palace",
          "Petronas Towers",
          "Chichen Itza",
          "Baiterek",
          "Ruhyyet Palace",
          "Temple of Literature"
        ],
        "uniqueId": "yCGrYdmG",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/desert-camels-arab-mauritania-733728/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mauritania"
        },
        "landmark": {
          "name": "Ben Amera",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ben_Amera#/media/File:Ben_Amira_2006.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ben_Amera",
          "hasLandmark": true
        },
        "city": {
          "name": "Nouadhibou",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Nouadhibou#/media/File:Nouadhibou,BlvdMedian.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Nouadhibou"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "2z6L3Lky",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Mauritius",
    "code": "MU",
    "capital": "Port Louis",
    "lat": -20.28333333,
    "long": 57.55,
    "cities": [
      "Port Louis",
      "Curepipe",
      "Bel Air",
      "Vacoas-Phoenix",
      "Central Flacq",
      "Quatre Bornes"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/wVuRAom5v6U",
          "wikiLink": "https://en.wikipedia.org/wiki/Le_Morne_Brabant"
        },
        "landmark": {
          "name": "Seven Colored Earths",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mauritius-seven-colored-earth-4502626/",
          "wikiLink": "https://en.wikipedia.org/wiki/Seven_Coloured_Earths",
          "hasLandmark": true
        },
        "city": {
          "name": "Curepipe",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:The_view_from_Trou_aux_Cerfs_%283007258484%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Curepipe"
        },
        "landmarksRound": [
          {
            "code": "KE",
            "number": 1
          },
          {
            "code": "FI",
            "number": 1
          },
          {
            "code": "CY",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Blue Lagoon",
          "Vianden Castle",
          "Church of Our Savior",
          "Cologne Cathedral",
          "Monday Falls",
          "Aleppo Citadel",
          "Memorial de l'Anse"
        ],
        "uniqueId": "LZW6YxFW",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/boat-see-men-mauritus-coast-850510/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mauritius"
        },
        "landmark": {
          "name": "Chamarel Waterfall",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/waterfall-green-landscape-tropical-798571/",
          "wikiLink": "https://en.wikipedia.org/wiki/Chamarel",
          "hasLandmark": true
        },
        "city": {
          "name": "Port Louis",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Port_Louis#/media/File:Port_Louis,_Mauritius.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Port_Louis"
        },
        "landmarksRound": [
          {
            "code": "AU",
            "number": 2
          },
          {
            "code": "AD",
            "number": 1
          },
          {
            "code": "MW",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Al Fateh Grand Mosque",
          "Chinguetti",
          "Floralis Generica",
          "Prince's Palace of Monaco",
          "Rijksmuseum",
          "Olavinlinna",
          "Monday Falls"
        ],
        "uniqueId": "j9x4fznp",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Mayotte",
    "code": "YT",
    "capital": "Mamoudzou",
    "lat": -12.83333333,
    "long": 45.16666666,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/tr%c3%a9vani-beach-mayotte-indian-ocean-604064/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tr%C3%A9vani"
        },
        "landmark": {
          "name": "Mont Choungui",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mont_Choungui#/media/File:Choungui_nord.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mont_Choungui",
          "hasLandmark": true
        },
        "city": {
          "name": "Mamoudzou",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mamoudzou#/media/File:Mamoudzou_(10029936275).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mamoudzou"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "L8HY3s4g",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sada-mayotte-mosque-604056/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mayotte"
        },
        "landmark": {
          "name": "Dziani Dzaha",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Dziani_Dzaha#/media/File:Dziani_Dzaha_(2850515800).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Dziani_Dzaha",
          "hasLandmark": true
        },
        "city": {
          "name": "Dzaoudzi",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mayotte-dzaoudzi-archipelago-2232049/",
          "wikiLink": "https://en.wikipedia.org/wiki/Dzaoudzi"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Nvlgp7jb",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Mexico",
    "code": "MX",
    "capital": "Mexico City",
    "lat": 23,
    "long": -102,
    "cities": [
      "Oaxaca",
      "Mexico City",
      "Merida",
      "Guadalajara",
      "Cancun",
      "Puebla"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/OYGN7McXKCM",
          "wikiLink": "https://en.wikipedia.org/wiki/Chichen_Itza"
        },
        "landmark": {
          "name": "Basilica of Our Lady of Guadalupe",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Basilica_of_Our_Lady_of_Guadalupe#/media/File:Bazilika_de_Guadalupe_DSC_7276.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Basilica_of_Our_Lady_of_Guadalupe",
          "hasLandmark": true
        },
        "city": {
          "name": "Oaxaca",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/oaxaca-city-houses-mexico-colonial-381666/",
          "wikiLink": "https://en.wikipedia.org/wiki/Oaxaca_City"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "kfDmz2Ky",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/guanajuato-city-buildings-mountains-5838206/",
          "wikiLink": "https://en.wikipedia.org/wiki/Guanajuato"
        },
        "landmark": {
          "name": "Chichen Itza",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/chich%c3%a9n-itza-mexico-pyramid-maya-3874449/",
          "wikiLink": "https://en.wikipedia.org/wiki/Chichen_Itza",
          "hasLandmark": true
        },
        "city": {
          "name": "Mexico City",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-travel-tourism-mexico-city-7069010/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mexico_City"
        },
        "landmarksRound": [
          {
            "code": "IQ",
            "number": 1
          },
          {
            "code": "BD",
            "number": 2
          },
          {
            "code": "GS",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Golden Gate Bridge",
          "Cathedral of Brasilica",
          "Christ Church Cathedral",
          "Hofburg Palace",
          "Queen Emma Bridge",
          "Amber Palace",
          "Kelonia"
        ],
        "uniqueId": "snp58RMZ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Micronesia",
    "code": "FM",
    "capital": "Palikir",
    "lat": 6.91666666,
    "long": 158.25,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Islands_on_the_southern_barrier_reef_of_Pohnpei_%28Federated_States_of_Micronesia%29_-_Bikini.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Pohnpei"
        },
        "landmark": {
          "name": "Nan Madol",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Nan_Madol#/media/File:Nan_Madol_megalithic_site,_Pohnpei_(Federated_States_of_Micronesia)_4.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Nan_Madol",
          "hasLandmark": false
        },
        "city": {
          "name": "Kolonia",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Pohnpei_Airport,_Micronesia_%284018917563%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kolonia"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Kgs7FN3Y",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Islands_on_the_southern_barrier_reef_of_Pohnpei_%28Federated_States_of_Micronesia%29_-_Penieu_2.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Pohnpei"
        },
        "landmark": {
          "name": "Chuuk Lagoon",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Chuuk_lagoon,_Mochun_Fanananei_Pass_aerial_view_%28cropped%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Chuuk_Lagoon",
          "hasLandmark": false
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "FffWkShY",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Moldova",
    "code": "MD",
    "capital": "Chisinau",
    "lat": 47,
    "long": 29,
    "cities": [
      "Chisinau",
      "Soroca",
      "Bender",
      "Balti",
      "Comrat",
      "Tiraspol"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/j0EXEOHgTlk",
          "wikiLink": "https://en.wikipedia.org/wiki/Orhei"
        },
        "landmark": {
          "name": "Soroca Fort",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Soroca_Fort#/media/File:%D0%A1%D0%BE%D1%80%D0%BE%D0%BA%D1%81%D0%BA%D0%B0%D1%8F_%D0%BA%D1%80%D0%B5%D0%BF%D0%BE%D1%81%D1%82%D1%8C_Cetatea_Soroca_Soroca_Fortress_(42928890910).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Soroca_Fort",
          "hasLandmark": true
        },
        "city": {
          "name": "Tiraspol",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Tiraspol#/media/File:%D0%9F%D0%BB%D0%BE%D1%89%D0%B0%D0%B4%D1%8C_%D0%A1%D1%83%D0%B2%D0%BE%D1%80%D0%BE%D0%B2%D0%B0_%D0%BF%D0%BE%D1%81%D0%BB%D0%B5_%D0%BF%D0%B0%D1%80%D0%B0%D0%B4%D0%B0.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tiraspol"
        },
        "landmarksRound": [
          {
            "code": "HN",
            "number": 1
          },
          {
            "code": "TJ",
            "number": 1
          },
          {
            "code": "TH",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Marina Bay Sands",
          "Winter Palace",
          "Stone House",
          "Romney Manor",
          "Chorsu Bazaar",
          "Ruins of St. Paul's",
          "Voortrekker Monument"
        ],
        "uniqueId": "xBH2h5VD",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/moldova-landscape-scenic-stream-105172/",
          "wikiLink": "https://en.wikipedia.org/wiki/Moldova"
        },
        "landmark": {
          "name": "Stephen the Great Monument",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Monumentul_a_lui_Stefan_cel_Mare,_Chisinau_Stefan_the_Great_Monument,_Chisinau,_Moldova_%2849601054843%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Stephen_the_Great_Monument",
          "hasLandmark": true
        },
        "city": {
          "name": "Chisinau",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Chi%C8%99in%C4%83u#/media/File:Arcul_de_Triumf_din_Chi%C8%99in%C4%83u_11_-_May_-_2019_18.09.59_67.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Chi%C8%99in%C4%83u"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "8HhXg33x",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Monaco",
    "code": "MC",
    "capital": "Monaco",
    "lat": 43.73333333,
    "long": 7.4,
    "cities": [
      "Monaco City",
      "La Condamine",
      "Monte Carlo",
      "Moneghetti",
      "La Koll",
      "Saint-Roman"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/QvEULJG78U8",
          "wikiLink": "https://en.wikipedia.org/wiki/Fontvieille,_Monaco"
        },
        "landmark": {
          "name": "Prince's Palace of Monaco",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/prince-palace-monaco-palace-187312/",
          "wikiLink": "https://en.wikipedia.org/wiki/Prince%27s_Palace_of_Monaco",
          "hasLandmark": true
        },
        "city": {
          "name": "Monaco City",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/monaco-center-monte-carlo-1552605/",
          "wikiLink": "https://en.wikipedia.org/wiki/Monaco_City"
        },
        "landmarksRound": [
          {
            "code": "CH",
            "number": 1
          },
          {
            "code": "ID",
            "number": 1
          },
          {
            "code": "CW",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Big Ben",
          "Bran Castle",
          "Gediminas Castle Tower",
          "Kelonia",
          "Cologne Cathedral",
          "Petronas Towers",
          "Mount Nyriagongo"
        ],
        "uniqueId": "pTYHL53h",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/monaco-city-bay-europe-france-410301/",
          "wikiLink": "https://en.wikipedia.org/wiki/Monaco"
        },
        "landmark": {
          "name": "Monte Carlo Casino",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/game-bank-casino-monte-carlo-188881/",
          "wikiLink": "https://en.wikipedia.org/wiki/Monte_Carlo_Casino",
          "hasLandmark": true
        },
        "city": {
          "name": "La Condamine",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/La_Condamine#/media/File:%D0%9B%D0%B0-%D0%9A%D0%BE%D0%BD%D0%B4%D0%B0%D0%BC%D0%B8%D0%BD._%D0%9D%D0%B0_%D1%83%D0%BB._%D0%9F%D1%80%D0%B8%D0%BD%D1%86%D0%B5%D1%81%D1%81%D1%8B_%D0%9A%D0%B0%D1%80%D0%BE%D0%BB%D0%B8%D0%BD%D1%8B._-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/La_Condamine"
        },
        "landmarksRound": [
          {
            "code": "SJ",
            "number": 1
          },
          {
            "code": "CN",
            "number": 2
          },
          {
            "code": "FK",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "The Little Chapel",
          "Predjama Castle",
          "Golden Gate",
          "Cape of Good Hope",
          "Azadi Tower",
          "Somapura Mahavihara",
          "Rizal Park"
        ],
        "uniqueId": "2YhxJyJx",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Mongolia",
    "code": "MN",
    "capital": "Ulaanbaatar",
    "lat": 46,
    "long": 105,
    "cities": [
      "Ulaanbaatar",
      "Erdenet",
      "Ulgii",
      "Darkhan",
      "Murun",
      "Dalanzadgad"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/yhbanN00pb8",
          "wikiLink": "https://en.wikipedia.org/wiki/Yurt"
        },
        "landmark": {
          "name": "Zaisan Memorial",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Zaisan_Memorial#/media/File:World_War_II_in_Ulaanbaatar_(6228546502).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Zaisan_Memorial",
          "hasLandmark": true
        },
        "city": {
          "name": "Ulaanbaatar",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/night-view-urban-ulaanbaatar-centre-2172718/",
          "wikiLink": "https://en.wikipedia.org/wiki/Ulaanbaatar"
        },
        "landmarksRound": [
          {
            "code": "NA",
            "number": 2
          },
          {
            "code": "VN",
            "number": 2
          },
          {
            "code": "GE",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Olavinlinna",
          "Leaning Tower of Pisa",
          "Mostar Old Bridge",
          "Mount Nyriagongo",
          "Rhine Falls",
          "Vilnius Cathedral",
          "Bran Castle"
        ],
        "uniqueId": "6PhLHm2R",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/desert-sand-dunes-mongolia-gobi-7630943/",
          "wikiLink": "https://en.wikipedia.org/wiki/Gobi_Desert"
        },
        "landmark": {
          "name": "Sukhbaatar Square",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/S%C3%BCkhbaatar_Square#/media/File:Chinggis_Square.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/S%C3%BCkhbaatar_Square",
          "hasLandmark": true
        },
        "city": {
          "name": "Erdenet",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Erdenet#/media/File:Erdenet_01.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Erdenet"
        },
        "landmarksRound": [
          {
            "code": "KG",
            "number": 1
          },
          {
            "code": "CW",
            "number": 1
          },
          {
            "code": "PY",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Timgad",
          "Tash Rabat",
          "Rock of Cashel",
          "Masjid al-Haram",
          "Christ the Redeemer",
          "Anne Frank House",
          "Mount Kenya"
        ],
        "uniqueId": "hyhm4F9n",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Montenegro",
    "code": "ME",
    "capital": "Podgorica",
    "lat": 42.5,
    "long": 19.3,
    "cities": [
      "Podgorica",
      "Niksic",
      "Budva",
      "Herceg Novi",
      "Koto",
      "Tivat"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/_nupO8tu6sg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bay_of_Kotor"
        },
        "landmark": {
          "name": "Ostrog Monastery",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/montenegro-monastery-ostrog-rocks-2348571/",
          "wikiLink": "https://en.wikipedia.org/wiki/Ostrog_Monastery",
          "hasLandmark": true
        },
        "city": {
          "name": "Niksic",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Nik%C5%A1i%C4%87#/media/File:Nik%C5%A1i%C4%87.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Nik%C5%A1i%C4%87"
        },
        "landmarksRound": [
          {
            "code": "EE",
            "number": 1
          },
          {
            "code": "AL",
            "number": 1
          },
          {
            "code": "HK",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Chinguetti",
          "Mount Paget",
          "Palace of Peace and Reconciliation",
          "Vardzia",
          "Akershus Fortress",
          "Tikal National Park",
          "Leaning Tower of Pisa"
        ],
        "uniqueId": "z8xgpDcr",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/perast-cove-kotor-montenegro-sea-4612526/",
          "wikiLink": "https://en.wikipedia.org/wiki/Montenegro"
        },
        "landmark": {
          "name": "St. Tryphon's Cathedral",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/montenegro-old-kotor-medieval-3404144/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kotor_Cathedral",
          "hasLandmark": true
        },
        "city": {
          "name": "Podgorica",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/montenegro-podgorica-communication-1351910/",
          "wikiLink": "https://en.wikipedia.org/wiki/Podgorica"
        },
        "landmarksRound": [
          {
            "code": "BA",
            "number": 1
          },
          {
            "code": "CD",
            "number": 2
          },
          {
            "code": "BA",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Vaduz Cathedral",
          "Masjid al-Haram",
          "Tegucigalpa Cathedral",
          "Tomb of Hafez",
          "Frogner Park",
          "Alexander Nevsky Cathedral",
          "Taj Mahal"
        ],
        "uniqueId": "Kg3xhxJt",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Montserrat",
    "code": "MS",
    "capital": "Plymouth",
    "lat": 16.75,
    "long": -62.2,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/landscape-nature-moutains-panorama-3524568/",
          "wikiLink": "https://en.wikipedia.org/wiki/Montserrat"
        },
        "landmark": {
          "name": "Montserrat Volcano Observatory",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Montserrat_Volcano_Observatory#/media/File:Montserrat_Volcano_Observatory.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Montserrat_Volcano_Observatory",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "8fW9gbJH",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/landscape-nature-park-water-7189086/",
          "wikiLink": "https://en.wikipedia.org/wiki/Montserrat"
        },
        "landmark": {
          "name": "Soufriere Hills",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Soufri%C3%A8re_Hills#/media/File:Soufri%C3%A8re_Hills_volcano_in_Monserrat.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Soufri%C3%A8re_Hills",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "WzC6RHwB",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Morocco",
    "code": "MA",
    "capital": "Rabat",
    "lat": 32,
    "long": -5,
    "cities": [
      "Marrakesh",
      "Rabat",
      "Fes",
      "Casablanca",
      "Tangier",
      "Meknes"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/NncAbldgViA",
          "wikiLink": "https://en.wikipedia.org/wiki/Chefchaouen"
        },
        "landmark": {
          "name": "Ouzoud Falls",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/ouzoud-morocco-falls-waterfall-298820/",
          "wikiLink": "https://en.wikipedia.org/wiki/Ouzoud_Falls",
          "hasLandmark": true
        },
        "city": {
          "name": "Marrakesh",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/morocco-marrakesh-city-centre-5989338/",
          "wikiLink": "https://en.wikipedia.org/wiki/Marrakesh"
        },
        "landmarksRound": [
          {
            "code": "AR",
            "number": 2
          },
          {
            "code": "MU",
            "number": 1
          },
          {
            "code": "LS",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Frogner Park",
          "Castle Cornet",
          "Ampitheater of El Jem",
          "Zaisan Memorial",
          "Blue Grotto",
          "Arecibo Observatory",
          "Narikala Fortress"
        ],
        "uniqueId": "vXVp64Kb",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/morocco-city-historic-village-clay-2349647/",
          "wikiLink": "https://en.wikipedia.org/wiki/Morocco"
        },
        "landmark": {
          "name": "Koutoubia",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/morocco-marrakech-koutoubia-minaret-1361243/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kutubiyya_Mosque",
          "hasLandmark": true
        },
        "city": {
          "name": "Rabat",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/morocco-rabat-architecture-city-6203712/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rabat"
        },
        "landmarksRound": [
          {
            "code": "ME",
            "number": 2
          },
          {
            "code": "BG",
            "number": 1
          },
          {
            "code": "MO",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Sistine Chapel",
          "The Blue Eye",
          "Castillo San Cristobal",
          "Maiden Tower",
          "Tomb of Hafez",
          "Saint Michael's Cave",
          "Umayyad Mosque"
        ],
        "uniqueId": "xdyF4nTk",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Mozambique",
    "code": "MZ",
    "capital": "Maputo",
    "lat": -18.25,
    "long": 35,
    "cities": [
      "Beira",
      "Quelimane",
      "Pemba",
      "Chimoio",
      "Lichinga",
      "Maputo"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/LbhNj3qARcM",
          "wikiLink": "https://en.wikipedia.org/wiki/Maputo"
        },
        "landmark": {
          "name": "Island of Mozambique",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Island_of_Mozambique#/media/File:Ilha_de_Mocambique.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Island_of_Mozambique",
          "hasLandmark": true
        },
        "city": {
          "name": "Maputo",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mozambique-africa-maputo-bridge-3974424/",
          "wikiLink": "https://en.wikipedia.org/wiki/Maputo"
        },
        "landmarksRound": [
          {
            "code": "FI",
            "number": 1
          },
          {
            "code": "TR",
            "number": 2
          },
          {
            "code": "NZ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Stone House",
          "Heydar Aliyev Center",
          "Amalienborg",
          "Liwonde National Park",
          "Tjibaou Cultural Center",
          "Pico Basile",
          "Arc de Triomphe"
        ],
        "uniqueId": "QxXbbvwq",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/african-women-walking-along-road-2983081/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mozambique"
        },
        "landmark": {
          "name": "Fort Sao Sebastiao",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Forte_de_S%C3%A3o_Sebasti%C3%A3o_-_Igreja.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Fort_S%C3%A3o_Sebasti%C3%A3o",
          "hasLandmark": true
        },
        "city": {
          "name": "Beira",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Beira,_Mozambique#/media/File:Beira_center_2000.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Beira,_Mozambique"
        },
        "landmarksRound": [
          {
            "code": "HK",
            "number": 2
          },
          {
            "code": "BZ",
            "number": 2
          },
          {
            "code": "RS",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Kim Il Sung Square",
          "Sao Filipe Royale Fortress",
          "Vilnius Cathedral",
          "Borobudur Temple",
          "Pigeon Rocks",
          "Atomium",
          ""
        ],
        "uniqueId": "n5qZtvn7",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Myanmar",
    "code": "MM",
    "capital": "Naypyidaw",
    "lat": 22,
    "long": 98,
    "cities": [
      "Naypyidaw",
      "Yangon",
      "Mandalay",
      "Bago",
      "Myeik",
      "Sagaing"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/a_-hYhh-R3Q",
          "wikiLink": "https://en.wikipedia.org/wiki/Bagan"
        },
        "landmark": {
          "name": "Shwedagon Pagoda",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/golden-temple-temple-pagoda-259800/",
          "wikiLink": "https://en.wikipedia.org/wiki/Shwedagon_Pagoda",
          "hasLandmark": true
        },
        "city": {
          "name": "Yangon",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/yangon-shwedagon-sulepagoda-myanmar-5050076/",
          "wikiLink": "https://en.wikipedia.org/wiki/Yangon"
        },
        "landmarksRound": [
          {
            "code": "GD",
            "number": 1
          },
          {
            "code": "ME",
            "number": 1
          },
          {
            "code": "EG",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Vardzia",
          "Saint Sava Temple",
          "Sydney Opera House",
          "Burj Khalifa",
          "Matobo National Park",
          "English Harbour",
          "Koutoubia"
        ],
        "uniqueId": "nFSGQNCR",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kuthodaw-pagoda-temple-city-pagoda-5632067/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kuthodaw_Pagoda"
        },
        "landmark": {
          "name": "Sule Pagoda",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Sule_Pagoda#/media/File:Sule_Pagoda_Yangoon.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Sule_Pagoda",
          "hasLandmark": true
        },
        "city": {
          "name": "Mandalay",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Mandalay_--_Downtown.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Mandalay"
        },
        "landmarksRound": [
          {
            "code": "KP",
            "number": 1
          },
          {
            "code": "BD",
            "number": 2
          },
          {
            "code": "JM",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Mount Yasur",
          "Somapura Mahavihara",
          "St. Peter's Church",
          "Oriental Pearl TV Tower",
          "Patuxay Monument",
          "Narikala Fortress",
          "Christ Church Cathedral"
        ],
        "uniqueId": "4QHQ3lgv",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Namibia",
    "code": "NA",
    "capital": "Windhoek",
    "lat": -22,
    "long": 17,
    "cities": [
      "Rundu",
      "Windhoek",
      "Walvis Bay",
      "Swakopmund",
      "Gobabis",
      "Keetmanshoop"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/pWshkmAH4qA",
          "wikiLink": "https://en.wikipedia.org/wiki/Sandwich_Harbour"
        },
        "landmark": {
          "name": "Deadvlei",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/namibia-deadvlei-desert-dessert-4790487/",
          "wikiLink": "https://en.wikipedia.org/wiki/Deadvlei",
          "hasLandmark": true
        },
        "city": {
          "name": "Rundu",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Rundu#/media/File:Rundu_(2019).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Rundu"
        },
        "landmarksRound": [
          {
            "code": "NO",
            "number": 1
          },
          {
            "code": "MK",
            "number": 1
          },
          {
            "code": "BD",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Bahrain Fort",
          "Trevi Fountain",
          "Aleppo Citadel",
          "Pyramiden",
          "South Pole (ceremonial)",
          "Palace of Versailles",
          "Tavarua"
        ],
        "uniqueId": "FKsvHFX8",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sossusvlei-namib-namibia-sand-dune-5339728/",
          "wikiLink": "https://en.wikipedia.org/wiki/Namibia"
        },
        "landmark": {
          "name": "Epupa Falls",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Epupa_Falls_2.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Epupa_Falls",
          "hasLandmark": true
        },
        "city": {
          "name": "Windhoek",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/church-namibia-windhoek-3767555/",
          "wikiLink": "https://en.wikipedia.org/wiki/Windhoek"
        },
        "landmarksRound": [
          {
            "code": "NO",
            "number": 2
          },
          {
            "code": "NP",
            "number": 1
          },
          {
            "code": "PL",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Castillo San Cristobal",
          "Pulhapanzak Waterfalls",
          "Bled Castle",
          "Tegucigalpa Cathedral",
          "Christ of Havana",
          "Hofburg Palace",
          "Rhine Falls"
        ],
        "uniqueId": "SbFsg75Y",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Nauru",
    "code": "NR",
    "capital": "Yaren",
    "lat": -0.53333333,
    "long": 166.9166667,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/bjJU61GHALI",
          "wikiLink": "https://en.wikipedia.org/wiki/Nauru"
        },
        "landmark": {
          "name": "Buada Lagoon",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Buada_Lagoon#/media/File:Buada_Lagoon,_Nauru_2007.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Buada_Lagoon",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "pdwJfCnR",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Living_on_a_Blue_Planet_-_Nauru.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Nauru"
        },
        "landmark": {
          "name": "Anibare Bay",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Anibare_Bay#/media/File:Living_on_a_Blue_Planet_-_Nauru.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Anibare_Bay",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "9CmHDnrz",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Nepal",
    "code": "NP",
    "capital": "Kathmandu",
    "lat": 28,
    "long": 84,
    "cities": [
      "Kathmandu",
      "Pokhara",
      "Bharatpur",
      "Biratnagar",
      "Janakpur",
      "Itahari"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/2bxAoXcfwwM",
          "wikiLink": "https://en.wikipedia.org/wiki/Pokhara"
        },
        "landmark": {
          "name": "Boudhanath",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/buddha-nepal-hindu-buddhism-2522466/",
          "wikiLink": "https://en.wikipedia.org/wiki/Boudhanath",
          "hasLandmark": true
        },
        "city": {
          "name": "Kathmandu",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/pashupatinath-temple-ancient-nepal-2470062/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kathmandu"
        },
        "landmarksRound": [
          {
            "code": "EG",
            "number": 1
          },
          {
            "code": "YE",
            "number": 1
          },
          {
            "code": "KY",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Devin Castle",
          "Grand Ducal Palace",
          "Hagia Sophia",
          "Wawel Castle",
          "Monte Carlo Casino",
          "Voortrekker Monument",
          "Diocletian's Palace"
        ],
        "uniqueId": "SJC5nkqy",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mount-everest-mountains-trekking-6395759/",
          "wikiLink": "https://en.wikipedia.org/wiki/Nepal"
        },
        "landmark": {
          "name": "Kathmandu Durbar Square",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/temple-palace-durbar-square-street-5790082/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kathmandu_Durbar_Square",
          "hasLandmark": true
        },
        "city": {
          "name": "Pokhara",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/fishtail-mountain-pokhara-city-5009522/",
          "wikiLink": "https://en.wikipedia.org/wiki/Pokhara"
        },
        "landmarksRound": [
          {
            "code": "AM",
            "number": 1
          },
          {
            "code": "NC",
            "number": 2
          },
          {
            "code": "ET",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Sky Tower",
          "Mount Yasur",
          "Panfilov Park",
          "Monastery of St. Naum",
          "Cathedral Our Lady of the Rosary",
          "Vianden Castle",
          "Mount Kenya"
        ],
        "uniqueId": "wN4P69pY",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Netherlands",
    "code": "NL",
    "capital": "Amsterdam",
    "lat": 52.5,
    "long": 5.75,
    "cities": [
      "Utrecht",
      "Amsterdam",
      "The Hague",
      "Eindhoven",
      "Breda",
      "Rotterdam"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/MaNZajl-IFY",
          "wikiLink": "https://en.wikipedia.org/wiki/Deventer"
        },
        "landmark": {
          "name": "Rijksmuseum",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/rijksmuseum-amsterdam-museum-2127625/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rijksmuseum",
          "hasLandmark": true
        },
        "city": {
          "name": "Rotterdam",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/rotterdam-maastoren-erasmus-bridge-4152380/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rotterdam"
        },
        "landmarksRound": [
          {
            "code": "PK",
            "number": 1
          },
          {
            "code": "JP",
            "number": 1
          },
          {
            "code": "MR",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "St. Peter's Church",
          "Badshahi Mosque",
          "Bahrain Fort",
          "Palace of Peace and Reconciliation",
          "Patuxay Monument",
          "Dhakeshwari Temple",
          "Rila Monastery"
        ],
        "uniqueId": "p89Clw7b",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/canal-buildings-city-114290/",
          "wikiLink": "https://en.wikipedia.org/wiki/Netherlands"
        },
        "landmark": {
          "name": "Anne Frank House",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Anne_Frank_House#/media/File:Amsterdam_(NL),_Anne-Frank-Huis_--_2015_--_7185.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Anne_Frank_House",
          "hasLandmark": true
        },
        "city": {
          "name": "Utrecht",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/utrecht-holland-bell-tower-panorama-887134/",
          "wikiLink": "https://en.wikipedia.org/wiki/Utrecht"
        },
        "landmarksRound": [
          {
            "code": "CW",
            "number": 2
          },
          {
            "code": "CA",
            "number": 2
          },
          {
            "code": "GE",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Villa Folio",
          "Tjibaou Cultural Center",
          "Grand Ducal Palace",
          "Kelonia",
          "Olavinlinna",
          "Monastery of St. Naum",
          "Tomb of Hafez"
        ],
        "uniqueId": "cTKSpdfj",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "New Caledonia",
    "code": "NC",
    "capital": "Noumea",
    "lat": -21.5,
    "long": 165.5,
    "cities": [
      "Noumea",
      "Dumbea",
      "Mont-Dore",
      "Paita",
      "Bourail",
      "Kone"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/KDABcgfxFEM",
          "wikiLink": "https://en.wikipedia.org/wiki/New_Caledonia"
        },
        "landmark": {
          "name": "Amedee Lighthouse",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Am%C3%A9d%C3%A9e_Lighthouse#/media/File:Noum%C3%A9a_Phare_Am%C3%A9d%C3%A9e.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Am%C3%A9d%C3%A9e_Lighthouse",
          "hasLandmark": true
        },
        "city": {
          "name": "Dumbea",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Dumb%C3%A9a#/media/File:Monts-Koghis_-_Pic_Malaoui_(687_m)_-_Vue_sur_Noum%C3%A9a_(3225081920).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Jean-Marie_Tjibaou_Cultural_Centre"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "fNzR3G5m",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/new-caledonia-landscape-riverbed-101389/",
          "wikiLink": "https://en.wikipedia.org/wiki/New_Caledonia"
        },
        "landmark": {
          "name": "Tjibaou Cultural Center",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Jean-Marie_Tjibaou_Cultural_Centre#/media/File:Egrant-190-91.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Jean-Marie_Tjibaou_Cultural_Centre",
          "hasLandmark": true
        },
        "city": {
          "name": "Noumea",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Noum%C3%A9a#/media/File:Noumea_centre_1402561139_375c811796_o.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Noum%C3%A9a"
        },
        "landmarksRound": [
          {
            "code": "EG",
            "number": 2
          },
          {
            "code": "IT",
            "number": 1
          },
          {
            "code": "JM",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Pyramiden",
          "Maiden Tower",
          "Golden Gate Bridge",
          "George Washington House",
          "Vianden Castle",
          "Galle Fort",
          "Heydar Aliyev Center"
        ],
        "uniqueId": "VWlgv7Bc",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "New Zealand",
    "code": "NZ",
    "capital": "Wellington",
    "lat": -41,
    "long": 174,
    "cities": [
      "Christchurch",
      "Wellington",
      "Auckland",
      "Tauranga",
      "Dunedin",
      "Hamilton"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/73F4pKoUkM0",
          "wikiLink": "https://en.wikipedia.org/wiki/Lake_Tekapo"
        },
        "landmark": {
          "name": "Sky Tower",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-sky-clouds-buildings-1283687/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sky_Tower_(Auckland)",
          "hasLandmark": true
        },
        "city": {
          "name": "Christchurch",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Christchurch#/media/File:Sumner._Christchurch_NZ_(13506509155).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Christchurch"
        },
        "landmarksRound": [
          {
            "code": "IN",
            "number": 1
          },
          {
            "code": "BN",
            "number": 1
          },
          {
            "code": "TW",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Monteverde Cloud Forest",
          "Turku Castle",
          "Bratislava Castle",
          "Forbidden City",
          "Twelve Apostles",
          "Fasil Ghebbi",
          "Vardzia"
        ],
        "uniqueId": "DxmSWkKt",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/eagle-mountains-lake-reflection-1450672/",
          "wikiLink": "https://en.wikipedia.org/wiki/New_Zealand"
        },
        "landmark": {
          "name": "Huka Falls",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/new-zealand-taupo-huka-falls-4003565/",
          "wikiLink": "https://en.wikipedia.org/wiki/Huka_Falls",
          "hasLandmark": true
        },
        "city": {
          "name": "Wellington",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/new-zealand-wellington-capital-city-4875972/",
          "wikiLink": "https://en.wikipedia.org/wiki/Wellington"
        },
        "landmarksRound": [
          {
            "code": "IR",
            "number": 2
          },
          {
            "code": "BH",
            "number": 2
          },
          {
            "code": "BS",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Stone House",
          "Church of Our Savior",
          "Animal Flower Cave",
          "Sydney Opera House",
          "Abu Simbel Temples",
          "Chillon Castle",
          "Silver Pagoda"
        ],
        "uniqueId": "fcQ7SNCG",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Nicaragua",
    "code": "NI",
    "capital": "Managua",
    "lat": 13,
    "long": -85,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/7lXv17JzmpA",
          "wikiLink": "https://en.wikipedia.org/wiki/San_Crist%C3%B3bal_Volcano"
        },
        "landmark": {
          "name": "Cristo de La Misericordia",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Christ_of_the_Mercy#/media/File:Christ_of_the_Mercy_statue,_San_Juan_del_Sur.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Christ_of_the_Mercy",
          "hasLandmark": true
        },
        "city": {
          "name": "Leon",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Le%C3%B3n,_Nicaragua#/media/File:Iglesiadelarecoleccion.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Le%C3%B3n,_Nicaragua"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "rhGNM4qh",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/plantation-coffee-nicaragua-jungle-108987/",
          "wikiLink": "https://en.wikipedia.org/wiki/Nicaragua"
        },
        "landmark": {
          "name": "Leon Cathedral",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Le%C3%B3n_Cathedral,_Nicaragua#/media/File:Nicaragua_2017-03-13_(33663091692).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Le%C3%B3n_Cathedral,_Nicaragua",
          "hasLandmark": true
        },
        "city": {
          "name": "Managua",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/managua-panorama-lake-city-horizon-943433/",
          "wikiLink": "https://en.wikipedia.org/wiki/Managua"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "6NHnYFJP",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Niger",
    "code": "NE",
    "capital": "Niamey",
    "lat": 16,
    "long": 8,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/niger-evils-river-africa-436253/",
          "wikiLink": "https://en.wikipedia.org/wiki/Niger"
        },
        "landmark": {
          "name": "Great Mosque of Niamey",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Vue_de_la_grande_mosqu%C3%A9e_de_Niamey_01.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Grand_Mosque_of_Niamey",
          "hasLandmark": true
        },
        "city": {
          "name": "Zinder",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Zinder#/media/File:Zinder_centre_ville.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Zinder"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "dHy6xVdn",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Niger,_Niamey,_Dunes_%283%29,_landscape.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Niamey"
        },
        "landmark": {
          "name": "Agadez Grand Mosque",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Niger,_Agadez_%2828%29,_grand_mosque,_old_city.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Agadez_Mosque",
          "hasLandmark": true
        },
        "city": {
          "name": "Niamey",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Niamey#/media/File:Niamey_from_grand_mosque_theatre_2006.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Niamey"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "nWMQwvwN",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Nigeria",
    "code": "NG",
    "capital": "Abuja",
    "lat": 10,
    "long": 8,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/6UqJTfoXIq8",
          "wikiLink": "https://en.wikipedia.org/wiki/Lagos"
        },
        "landmark": {
          "name": "Tafawa Balewa Square",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Tafawa_Balewa_Square_Image.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tafawa_Balewa_Square",
          "hasLandmark": true
        },
        "city": {
          "name": "Abuja",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Abuja#/media/File:View_of_Abuja_from_Katampe_hill_01.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Abuja"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "ZrlvDrk8",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/vehicles-road-city-traffic-street-5871002/",
          "wikiLink": "https://en.wikipedia.org/wiki/Lagos"
        },
        "landmark": {
          "name": "Gidan Rumfa",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Gidan_Rumfa#/media/File:Gate_to_the_Gidan_Rumfa_(2009)_in_Kano,_Nigeria..jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Gidan_Rumfa",
          "hasLandmark": true
        },
        "city": {
          "name": "Kano",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Kano_(city)#/media/File:KanofromDalaHill.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kano_(city)"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "x5Gy29v3",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Niue",
    "code": "NU",
    "capital": "Alofi",
    "lat": -19.03333333,
    "long": -169.8666667,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Niue_K%C3%BCstenlandschaft_2.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Niue"
        },
        "landmark": {
          "name": "Limu Pools",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Limu_Pools,_Niue.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Niue",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "SrbbZDCb",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Rockpools_and_cliff_at_Niue.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Niue"
        },
        "landmark": {
          "name": "Talava Arches",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Talava_Arches_1,_Niue.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Geography_of_Niue",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "F4LpPKHg",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Norfolk Island",
    "code": "NF",
    "capital": "Kingston",
    "lat": -29.03333333,
    "long": 167.95,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/coast-coastline-scenic-scenery-2446324/",
          "wikiLink": "https://en.wikipedia.org/wiki/Norfolk_Island"
        },
        "landmark": {
          "name": "Emily Bay",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Emily_Bay_Norfolk_Island.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Norfolk_Island",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "7Fb7PfwB",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/norfolk-island-islands-landscape-2446296/",
          "wikiLink": "https://en.wikipedia.org/wiki/Norfolk_Island"
        },
        "landmark": {
          "name": "St. Barnabas Chapel",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Norfolk_Island_St_Barnabas_Chapel.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Norfolk_Island",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "SfVQGJvf",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "North Korea",
    "code": "KP",
    "capital": "Pyongyang",
    "lat": 40,
    "long": 127,
    "cities": [
      "Sinujiu",
      "Pyongyang",
      "Chongjin",
      "Nampo",
      "Hamhung",
      "Wonsan"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/3dSv5LXts8A",
          "wikiLink": "https://en.wikipedia.org/wiki/Pyongyang"
        },
        "landmark": {
          "name": "Kim Il Sung Square",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/north-korea-pyongyang-building-2662076/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kim_Il-sung_Square",
          "hasLandmark": true
        },
        "city": {
          "name": "Wonsan",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Wonsan#/media/File:Wonsan_waterfront_(2937890043).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Wonsan"
        },
        "landmarksRound": [
          {
            "code": "PF",
            "number": 1
          },
          {
            "code": "IE",
            "number": 2
          },
          {
            "code": "OM",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Wat Xiengthong",
          "Pulhapanzak Waterfalls",
          "Castillo San Cristobal",
          "Turku Castle",
          "Parthenon",
          "La Sagrada Familia",
          "Gediminas Castle Tower"
        ],
        "uniqueId": "CpRnMc5p",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/north-korea-soldiers-poor-force-2377251/",
          "wikiLink": "https://en.wikipedia.org/wiki/North_Korea"
        },
        "landmark": {
          "name": "Juche Tower",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Juche_Tower#/media/File:Juche-Tower-2014.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Juche_Tower",
          "hasLandmark": true
        },
        "city": {
          "name": "Sinujiu",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Sinuiju#/media/File:Aerial_view_of_Downtown_Sinuiju.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Sinuiju"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "fYqQHQDC",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "North Macedonia",
    "code": "MK",
    "capital": "Skopje",
    "lat": 41.83333333,
    "long": 22,
    "cities": [
      "Tetovo",
      "Skopje",
      "Ohrid",
      "Prilep",
      "Veles",
      "Bitola"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/4CzzaJnQh0c",
          "wikiLink": "https://en.wikipedia.org/wiki/Skopje"
        },
        "landmark": {
          "name": "Skopje Fortress",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Skopje_Fortress#/media/File:KaleFortress-Skopje2.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Skopje_Fortress",
          "hasLandmark": true
        },
        "city": {
          "name": "Bitola",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bitola#/media/File:Sirok_sokak.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bitola"
        },
        "landmarksRound": [
          {
            "code": "LA",
            "number": 2
          },
          {
            "code": "HK",
            "number": 1
          },
          {
            "code": "PT",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Fort Christian",
          "Red Square",
          "Niagara Falls",
          "Timgad",
          "Luis I Bridge",
          "Stonehenge",
          "Registan Square"
        ],
        "uniqueId": "7sLrSfxR",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/beach-boats-morning-landscape-5252448/",
          "wikiLink": "https://en.wikipedia.org/wiki/North_Macedonia"
        },
        "landmark": {
          "name": "Monastery of St. Naum",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/monastery-of-saint-naum-ochrid-lake-4334306/",
          "wikiLink": "https://en.wikipedia.org/wiki/Monastery_of_Saint_Naum",
          "hasLandmark": true
        },
        "city": {
          "name": "Tetovo",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-landscape-houses-architecture-6261441/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tetovo"
        },
        "landmarksRound": [
          {
            "code": "NZ",
            "number": 2
          },
          {
            "code": "UG",
            "number": 1
          },
          {
            "code": "CA",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Amberd",
          "Deception Island",
          "Taj Mahal",
          "Lotto World",
          "Elmina Castle",
          "Amalienborg",
          "Azadi Tower"
        ],
        "uniqueId": "QRBjKdSr",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Northern Mariana Islands",
    "code": "MP",
    "capital": "Saipan",
    "lat": 15.2,
    "long": 145.75,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/saipan-island-beach-ocean-175425/",
          "wikiLink": "https://en.wikipedia.org/wiki/Saipan"
        },
        "landmark": {
          "name": "Suicide Cliff",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Suicide_Cliff#/media/File:SUICIDE_CLIFF.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Suicide_Cliff",
          "hasLandmark": false
        },
        "city": {
          "name": "Susupe",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Susupe#/media/File:Downtownsusupe.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Susupe"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "dXtBJMkg",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/saipan-island-beach-ocean-175424/",
          "wikiLink": "https://en.wikipedia.org/wiki/Saipan"
        },
        "landmark": {
          "name": "House of Taga",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Fallen_latte_stones_at_Taga_House,_Tinian.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/House_of_Taga",
          "hasLandmark": false
        },
        "city": {
          "name": "Garapan",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Beach_Road_-_Garapan_Saipan_-_panoramio_%281%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Garapan"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "WsXlbpJB",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Norway",
    "code": "NO",
    "capital": "Oslo",
    "lat": 62,
    "long": 10,
    "cities": [
      "Oslo",
      "Bergen",
      "Alesund",
      "Frederikstad",
      "Kristiansand",
      "Stavanger"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/khbjgGAerPU",
          "wikiLink": "https://en.wikipedia.org/wiki/Preikestolen"
        },
        "landmark": {
          "name": "Akershus Fortress",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/akershus-fortress-at-night-norway-2517239/",
          "wikiLink": "https://en.wikipedia.org/wiki/Akershus_Fortress",
          "hasLandmark": true
        },
        "city": {
          "name": "Oslo",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/oslo-akerbrygge-norway-1091011/",
          "wikiLink": "https://en.wikipedia.org/wiki/Oslo"
        },
        "landmarksRound": [
          {
            "code": "EG",
            "number": 2
          },
          {
            "code": "FR",
            "number": 1
          },
          {
            "code": "BB",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Umayyad Mosque",
          "Lukan Longshan Temple",
          "Monteverde Cloud Forest",
          "Devin Castle",
          "Saint Sava Temple",
          "Badshahi Mosque",
          "Wawel Castle"
        ],
        "uniqueId": "8cKjvhjn",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mountains-sunset-clouds-estuary-5784152/",
          "wikiLink": "https://en.wikipedia.org/wiki/Norway"
        },
        "landmark": {
          "name": "Frogner Park",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Frogner_Park#/media/File:NOR-2016-Frogner_Park-Vigeland_Installation-The_Monolith.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Frogner_Park",
          "hasLandmark": true
        },
        "city": {
          "name": "Bergen",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/norway-bergen-landscape-4766392/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bergen"
        },
        "landmarksRound": [
          {
            "code": "BY",
            "number": 1
          },
          {
            "code": "IS",
            "number": 1
          },
          {
            "code": "EE",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Chimi Lhakhang",
          "Amber Palace",
          "Wat Arun",
          "George Washington House",
          "Sukhbaatar Square",
          "Ruhyyet Palace",
          "Christ the Redeemer"
        ],
        "uniqueId": "D8N5hgjX",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Oman",
    "code": "OM",
    "capital": "Muscat",
    "lat": 21,
    "long": 57,
    "cities": [
      "Muscat",
      "Salalah",
      "Sohar",
      "Sur",
      "Rustaq",
      "Seeb"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/9R-jEko1PKc",
          "wikiLink": "https://en.wikipedia.org/wiki/Muttrah"
        },
        "landmark": {
          "name": "Nizwa Fort",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/nizwa-fort-fort-oman-travel-3245729/",
          "wikiLink": "https://en.wikipedia.org/wiki/Nizwa_Fort",
          "hasLandmark": true
        },
        "city": {
          "name": "Muscat",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/muscat-oman-harbor-travel-boat-2455049/",
          "wikiLink": "https://en.wikipedia.org/wiki/Muscat"
        },
        "landmarksRound": [
          {
            "code": "AE",
            "number": 1
          },
          {
            "code": "MZ",
            "number": 2
          },
          {
            "code": "HN",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Blue Grotto",
          "",
          "Tokyo Skytree",
          "Patuxay Monument",
          "Panfilov Park",
          "Tenaru Falls",
          "Magnus Cathedral"
        ],
        "uniqueId": "R97WbFjy",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sultan-qaboos-grand-mosque-oman-5963726/",
          "wikiLink": "https://en.wikipedia.org/wiki/Oman"
        },
        "landmark": {
          "name": "Al Alam Palace",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/oman-muscat-nutmeg-capital-city-5508234/",
          "wikiLink": "https://en.wikipedia.org/wiki/Al_Alam_Palace",
          "hasLandmark": true
        },
        "city": {
          "name": "Salalah",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Salalah#/media/File:Al_hafa_corniche.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Salalah"
        },
        "landmarksRound": [
          {
            "code": "NP",
            "number": 2
          },
          {
            "code": "BD",
            "number": 2
          },
          {
            "code": "TW",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Baalbek",
          "Floralis Generica",
          "Church of Saint Lazarus",
          "Christ Church Cathedral",
          "Alexander Nevsky Cathedral",
          "Ulu Temburong National Park",
          "Iluissat Icefjord"
        ],
        "uniqueId": "C5yr3BWW",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Pakistan",
    "code": "PK",
    "capital": "Islamabad",
    "lat": 30,
    "long": 70,
    "cities": [
      "Karachi",
      "Islamabad",
      "Lahore",
      "Peshawar",
      "Faisalabad",
      "Quetta"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/KOwjW3N0Cto",
          "wikiLink": "https://en.wikipedia.org/wiki/Uch"
        },
        "landmark": {
          "name": "Badshahi Mosque",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/lahore-lhr-badshahi-mosque-2299807/",
          "wikiLink": "https://en.wikipedia.org/wiki/Badshahi_Mosque",
          "hasLandmark": true
        },
        "city": {
          "name": "Karachi",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Karachi#/media/File:Bahadurabad_Area_Karachi_City.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Karachi"
        },
        "landmarksRound": [
          {
            "code": "FJ",
            "number": 1
          },
          {
            "code": "LK",
            "number": 1
          },
          {
            "code": "SG",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Predjama Castle",
          "Tjibaou Cultural Center",
          "Chichen Itza",
          "Twelve Apostles",
          "Buenos Aires Obelisk",
          "Saint Michael's Cave",
          "Rila Monastery"
        ],
        "uniqueId": "jh6NmVw9",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bridge-village-morning-khanpur-961208/",
          "wikiLink": "https://en.wikipedia.org/wiki/Pakistan"
        },
        "landmark": {
          "name": "Lahore Fort",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Lahore_Fort#/media/File:Lahore_Fort_Top_view.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Lahore_Fort",
          "hasLandmark": true
        },
        "city": {
          "name": "Islamabad",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Islamabad#/media/File:Faisal_Mosque_in_the_background_of_Centaurus_Mall.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Islamabad"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "9Nykn5zf",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Palau",
    "code": "PW",
    "capital": "Ngerulmud",
    "lat": 7.5,
    "long": 134.5,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/0lCw_kqmb7w",
          "wikiLink": "https://en.wikipedia.org/wiki/Palau"
        },
        "landmark": {
          "name": "Belau National Museum",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Belau_National_Museum#/media/File:A_bai_in_Palau.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Belau_National_Museum",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Nwg55lFd",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/island-water-resort-hotel-2407521/",
          "wikiLink": "https://en.wikipedia.org/wiki/Palau"
        },
        "landmark": {
          "name": "Rock Islands",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Rock_Islands#/media/File:Ngerukewid-2016-aerial-view-Luka-Peternel.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Rock_Islands",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "PTqwlctG",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Palestine",
    "code": "PS",
    "capital": "Ramallah",
    "lat": 31.9,
    "long": 35.2,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/jerusalem-israel-old-town-walls-1712855/",
          "wikiLink": "https://en.wikipedia.org/wiki/Jerusalem"
        },
        "landmark": {
          "name": "Western Wall and the Dome of the Rock",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/jerusalem-western-wall-temple-4592574/",
          "wikiLink": "https://en.wikipedia.org/wiki/Dome_of_the_Rock",
          "hasLandmark": false
        },
        "city": {
          "name": "Nablus",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Nablus#/media/File:Nablus,_January_2022.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Nablus"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "xdsz3swY",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pxhere.com/en/photo/1598181",
          "wikiLink": "https://en.wikipedia.org/wiki/Palestine_(region)"
        },
        "landmark": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": "",
          "hasLandmark": false
        },
        "city": {
          "name": "Gaza City",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Gaza_City#/media/File:Gaza_City.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Gaza_City"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "F4PKvMJJ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Panama",
    "code": "PA",
    "capital": "Panama City",
    "lat": 9,
    "long": -80,
    "cities": [
      "Colon",
      "Panama City",
      "Tocumen",
      "David",
      "Ancon",
      "Santiago"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/8oMiql-Q9nM",
          "wikiLink": "https://en.wikipedia.org/wiki/Casco_Viejo,_Panama"
        },
        "landmark": {
          "name": "Old Panama",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Panam%C3%A1_Viejo#/media/File:Iglesia_y_Convento_de_las_Monjas_de_la_Concepci%C3%B3n,_Panam%C3%A1_Viejo.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Panam%C3%A1_Viejo",
          "hasLandmark": true
        },
        "city": {
          "name": "Colon",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Col%C3%B3n,_Panama#/media/File:CO-colon-2000-02.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Col%C3%B3n,_Panama"
        },
        "landmarksRound": [
          {
            "code": "MW",
            "number": 1
          },
          {
            "code": "BY",
            "number": 1
          },
          {
            "code": "NA",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Pigeon Rocks",
          "Belem Tower",
          "Golden Gate",
          "Oriental Pearl TV Tower",
          "Hazrat Ali Mazar",
          "Valle de Cocora",
          "Parthenon"
        ],
        "uniqueId": "F85rWmJW",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/beach-san-blas-islands-panama-4388225/",
          "wikiLink": "https://en.wikipedia.org/wiki/Panama"
        },
        "landmark": {
          "name": "Panama Canal",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/panama-panamericana-bridge-4953251/",
          "wikiLink": "https://en.wikipedia.org/wiki/Panama_Canal",
          "hasLandmark": true
        },
        "city": {
          "name": "Panama City",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/panama-bay-architecture-skyline-182959/",
          "wikiLink": "https://en.wikipedia.org/wiki/Panama_City"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "VrQGxvJV",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Papua New Guinea",
    "code": "PG",
    "capital": "Port Moresby",
    "lat": -6,
    "long": 147,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/GOIR5V_OH-k",
          "wikiLink": "https://en.wikipedia.org/wiki/Papua_New_Guinea"
        },
        "landmark": {
          "name": "Mount Wilhelm",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mountains-lakes-nature-landscape-5960985/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mount_Wilhelm",
          "hasLandmark": true
        },
        "city": {
          "name": "Lae",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Lae#/media/File:Lae-CityPanorama.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Lae"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "76F2mm9z",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/rabaul-volcano-papua-new-guinea-4380826/",
          "wikiLink": "https://en.wikipedia.org/wiki/Papua_New_Guinea"
        },
        "landmark": {
          "name": "Kokoda Track",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Kokoda_track_Papua_New_Guinea.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Kokoda_Track",
          "hasLandmark": true
        },
        "city": {
          "name": "Port Moresby",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Port_Moresby#/media/File:POM_Downtown.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Port_Moresby"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Q99jcmZ7",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Paraguay",
    "code": "PY",
    "capital": "Asuncion",
    "lat": -23,
    "long": -58,
    "cities": [
      "Ciudad del Este",
      "Asuncion",
      "San Lorenzo",
      "Nemby",
      "Encarnacion",
      "Capiata"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/5zqhGcQTMQU",
          "wikiLink": "https://en.wikipedia.org/wiki/Aregu%C3%A1"
        },
        "landmark": {
          "name": "Monday Falls",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Saltos_del_Monday#/media/File:Salto_Monday_Paraguay.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Saltos_del_Monday",
          "hasLandmark": true
        },
        "city": {
          "name": "Asuncion",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ciudad_del_Este#/media/File:CDE011.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ciudad_del_Este"
        },
        "landmarksRound": [
          {
            "code": "CO",
            "number": 2
          },
          {
            "code": "MW",
            "number": 1
          },
          {
            "code": "AM",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Panfilov Park",
          "Chillon Castle",
          "African Renaissance Monument",
          "Kim Il Sung Square",
          "Silver Pagoda",
          "Tsingy De Bemaraha",
          "Pulhapanzak Waterfalls"
        ],
        "uniqueId": "6tjtRWTf",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/road-rain-automobile-woman-184623/",
          "wikiLink": "https://en.wikipedia.org/wiki/Paraguay"
        },
        "landmark": {
          "name": "Jesuit Mission of the Holy Trinity",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/La_Sant%C3%ADsima_Trinidad_de_Paran%C3%A1#/media/File:Trinidad_(Paraguay).JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/La_Sant%C3%ADsima_Trinidad_de_Paran%C3%A1",
          "hasLandmark": true
        },
        "city": {
          "name": "Ciudad del Este",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ciudad_del_Este#/media/File:CDE011.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ciudad_del_Este"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "jBF97XLL",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Peru",
    "code": "PE",
    "capital": "Lima",
    "lat": -10,
    "long": -76,
    "cities": [
      "Arequipa",
      "Lima",
      "Cusco",
      "Cajamarca",
      "Huancayo",
      "Chiclayo"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/hjanvZlqoB8",
          "wikiLink": "https://en.wikipedia.org/wiki/Vinicunca"
        },
        "landmark": {
          "name": "Machu Picchu",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/machupicchu-peru-i-ncas-1138641/",
          "wikiLink": "https://en.wikipedia.org/wiki/Machu_Picchu",
          "hasLandmark": true
        },
        "city": {
          "name": "Arequipa",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/square-arequipa-peru-cathedral-5165633/",
          "wikiLink": "https://en.wikipedia.org/wiki/Arequipa"
        },
        "landmarksRound": [
          {
            "code": "DZ",
            "number": 1
          },
          {
            "code": "ME",
            "number": 1
          },
          {
            "code": "MO",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Trevi Fountain",
          "Red Square",
          "Chinguetti",
          "The Blue Eye",
          "Akershus Fortress",
          "Amber Palace",
          "Seven Colored Earths"
        ],
        "uniqueId": "VvQ6slll",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/peru-tourism-cuzco-landscape-5143632/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cusco"
        },
        "landmark": {
          "name": "Saqsayhuaman",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Sacsayhuam%C3%A1n#/media/File:Sacsayhuam%C3%A1n,_Cusco,_Per%C3%BA,_2015-07-31,_DD_05.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Sacsayhuam%C3%A1n",
          "hasLandmark": true
        },
        "city": {
          "name": "Lima",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/lima-cityscape-city-sunset-peru-1157119/",
          "wikiLink": "https://en.wikipedia.org/wiki/Lima"
        },
        "landmarksRound": [
          {
            "code": "AM",
            "number": 2
          },
          {
            "code": "CO",
            "number": 1
          },
          {
            "code": "VI",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Marcus Aurelius Arch",
          "Boudhanath",
          "Easter Island",
          "Niagara Falls",
          "Amberd",
          "Azadi Tower",
          "Souq Waqif"
        ],
        "uniqueId": "fKyvWMGF",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Philippines",
    "code": "PH",
    "capital": "Manila",
    "lat": 13,
    "long": 122,
    "cities": [
      "Cebu City",
      "Manila",
      "Quezon City",
      "Davao City",
      "Caloocan",
      "Taguig"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/n6BxzwOGTC4",
          "wikiLink": "https://en.wikipedia.org/wiki/Bontoc,_Mountain_Province"
        },
        "landmark": {
          "name": "Rizal Park",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Rizal_Park#/media/File:Rizal_Park_Front_View.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Rizal_Park",
          "hasLandmark": true
        },
        "city": {
          "name": "Cebu City",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Cebu_City#/media/File:Cebu_City_(Aerial).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Cebu_City"
        },
        "landmarksRound": [
          {
            "code": "ID",
            "number": 1
          },
          {
            "code": "CY",
            "number": 2
          },
          {
            "code": "GS",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Villa Folio",
          "Monument to the Heroes of the Restoration",
          "Castillo San Cristobal",
          "Vianden Castle",
          "Rock of Cashel",
          "Macau Tower",
          "Mount Nyriagongo"
        ],
        "uniqueId": "KJVGLHSY",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/pacific-rainbow-ocean-clouds-4491547/",
          "wikiLink": "https://en.wikipedia.org/wiki/Philippines"
        },
        "landmark": {
          "name": "Fort Santiago",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Fort_Santiago#/media/File:Entrance_of_Fort_Santiago,_Intramuros,_Manila_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Fort_Santiago",
          "hasLandmark": true
        },
        "city": {
          "name": "Manila",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/manila-city-manila-bay-night-sea-1709394/",
          "wikiLink": "https://en.wikipedia.org/wiki/Manila"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "rN3zWlfd",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Pitcairn Islands",
    "code": "PN",
    "capital": "Adamstown",
    "lat": -25.06666666,
    "long": -130.1,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/doublenz/10531933983",
          "wikiLink": "https://en.wikipedia.org/wiki/Pitcairn_Islands"
        },
        "landmark": {
          "name": "Christian's Cave",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:%22Christian%27s_Cave%22_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Pitcairn_Islands",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "ZD7NPQDb",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Pitcairn_Island_In_The_Distance.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Pitcairn_Islands"
        },
        "landmark": {
          "name": "Ducie Island",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ducie_Island#/media/File:Ducie02_AKK.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ducie_Island",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "cxnWs5yz",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Poland",
    "code": "PL",
    "capital": "Warsaw",
    "lat": 52,
    "long": 20,
    "cities": [
      "Warsaw",
      "Gdansk",
      "Poznan",
      "Wroclaw",
      "Zakopane",
      "Krakow"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/i-p1zqFros8",
          "wikiLink": "https://en.wikipedia.org/wiki/Main_Square,_Krak%C3%B3w"
        },
        "landmark": {
          "name": "Wieliczka Salt Mine",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/wieliczka-salt-mine-church-mine-4482528/",
          "wikiLink": "https://en.wikipedia.org/wiki/Wieliczka_Salt_Mine",
          "hasLandmark": true
        },
        "city": {
          "name": "Krakow",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/street-tower-krakow-poland-tourism-4472321/",
          "wikiLink": "https://en.wikipedia.org/wiki/Krak%C3%B3w"
        },
        "landmarksRound": [
          {
            "code": "SY",
            "number": 2
          },
          {
            "code": "GH",
            "number": 2
          },
          {
            "code": "KN",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Amalienborg",
          "Bled Castle",
          "Stonehenge",
          "Pigeon Rocks",
          "Cliffs of Moher",
          "La Sagrada Familia",
          "Golden Gate"
        ],
        "uniqueId": "C5Xm5Zhy",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/Gqc1jl2kd1U",
          "wikiLink": "https://en.wikipedia.org/wiki/Poland"
        },
        "landmark": {
          "name": "Wawel Castle",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/royal-cathedral-wawel-royal-castle-601223/",
          "wikiLink": "https://en.wikipedia.org/wiki/Wawel_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Warsaw",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/warsaw-the-old-town-sunset-evening-823079/",
          "wikiLink": "https://en.wikipedia.org/wiki/Warsaw"
        },
        "landmarksRound": [
          {
            "code": "CH",
            "number": 2
          },
          {
            "code": "BH",
            "number": 2
          },
          {
            "code": "CN",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Okavango Delta",
          "Liwonde National Park",
          "Palace of Versailles",
          "Fort Fincastle",
          "Deadvlei",
          "Kittiwake Shipwreck",
          "Wat Xiengthong"
        ],
        "uniqueId": "z65pzymh",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Portugal",
    "code": "PT",
    "capital": "Lisbon",
    "lat": 39.5,
    "long": -8,
    "cities": [
      "Porto",
      "Braga",
      "Lisbon",
      "Faro",
      "Aveiro",
      "Evora"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/ljhCEaHYWJ8",
          "wikiLink": "https://en.wikipedia.org/wiki/Trams_in_Lisbon"
        },
        "landmark": {
          "name": "Belem Tower",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bel%c3%a9m-tower-belem-tower-lisbon-2809818/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bel%C3%A9m_Tower",
          "hasLandmark": true
        },
        "city": {
          "name": "Porto",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/portugal-porto-eifel-bridge-1350646/",
          "wikiLink": "https://en.wikipedia.org/wiki/Porto"
        },
        "landmarksRound": [
          {
            "code": "BB",
            "number": 1
          },
          {
            "code": "UA",
            "number": 2
          },
          {
            "code": "BY",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Langkawi Sky Bridge",
          "Jardin de Balata",
          "Old Panama",
          "Herat Citadel",
          "Arc de Triomphe",
          "Chorsu Bazaar",
          "St. Tryphon's Cathedral"
        ],
        "uniqueId": "lHK5Lf9h",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/tram-train-road-building-monument-4379656/",
          "wikiLink": "https://en.wikipedia.org/wiki/Portugal"
        },
        "landmark": {
          "name": "Luis I Bridge",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bridge-city-river-dom-luis-i-bridge-6819792/",
          "wikiLink": "https://en.wikipedia.org/wiki/Dom_Lu%C3%ADs_I_Bridge",
          "hasLandmark": true
        },
        "city": {
          "name": "Braga",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bridge-house-village-city-5753249/",
          "wikiLink": "https://en.wikipedia.org/wiki/Braga"
        },
        "landmarksRound": [
          {
            "code": "LT",
            "number": 1
          },
          {
            "code": "VA",
            "number": 1
          },
          {
            "code": "HU",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "St. Olaf's Church",
          "Dunn's River Falls",
          "Arecibo Observatory",
          "Anne Frank House",
          "Ouzoud Falls",
          "Mir Castle",
          "Dragon and Tiger Pagodas"
        ],
        "uniqueId": "MVs7mY68",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Puerto Rico",
    "code": "PR",
    "capital": "San Juan",
    "lat": 18.25,
    "long": -66.5,
    "cities": [
      "San Juan",
      "Bayamon",
      "Ponce",
      "Utuado",
      "Carolina",
      "Mayaguez"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/48iZAbNgU8U",
          "wikiLink": "https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico"
        },
        "landmark": {
          "name": "Castillo San Cristobal",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Castillo_San_Crist%C3%B3bal_%28Fort_San_Crist%C3%B3bal%29_%285422458974%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Castillo_San_Crist%C3%B3bal_(San_Juan)",
          "hasLandmark": true
        },
        "city": {
          "name": "San Juan",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:San_Juan_2015.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico"
        },
        "landmarksRound": [
          {
            "code": "GB",
            "number": 1
          },
          {
            "code": "JM",
            "number": 2
          },
          {
            "code": "CY",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Brandenburg Gate",
          "Green Grotto Caves",
          "Koutoubia",
          "Forbidden City",
          "Cathedral of Brasilica",
          "Romney Manor",
          "Prince's Palace of Monaco"
        ],
        "uniqueId": "b6TmcYlX",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/graveyard-san-juan-puerto-rico-602357/",
          "wikiLink": "https://en.wikipedia.org/wiki/San_Juan,_Puerto_Rico"
        },
        "landmark": {
          "name": "Arecibo Observatory",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Arecibo_Radiotelescopio_SJU_06_2019_7472.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Arecibo_Observatory",
          "hasLandmark": true
        },
        "city": {
          "name": "Bayamon",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Barrio_Hato_Tejas,_Bayam%C3%B3n,_Puerto_Rico.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bayam%C3%B3n,_Puerto_Rico"
        },
        "landmarksRound": [
          {
            "code": "CH",
            "number": 1
          },
          {
            "code": "BH",
            "number": 1
          },
          {
            "code": "MR",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Stone House",
          "Hagia Sophia",
          "Mount Paget",
          "The Grand Palace",
          "Great Blue Hole",
          "National Shrine Basilica of our Lady of Las Lajas",
          "House of the Black Heads"
        ],
        "uniqueId": "FXrqtvws",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Qatar",
    "code": "QA",
    "capital": "Doha",
    "lat": 25.5,
    "long": 51.25,
    "cities": [
      "Doha",
      "Al Rayyan",
      "Al Khor",
      "Dukhan",
      "Al Wakrah",
      "Ar-Rayyan"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/Do6yoytec5E",
          "wikiLink": "https://en.wikipedia.org/wiki/Museum_of_Islamic_Art,_Doha"
        },
        "landmark": {
          "name": "Souq Waqif",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Souq_Waqif#/media/File:Souq_Waqif,_Doha,_Catar,_2013-08-05,_DD_84.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Souq_Waqif",
          "hasLandmark": true
        },
        "city": {
          "name": "Doha",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/skyscrapers-building-architectural-3850732/",
          "wikiLink": "https://en.wikipedia.org/wiki/Doha"
        },
        "landmarksRound": [
          {
            "code": "HU",
            "number": 1
          },
          {
            "code": "MY",
            "number": 2
          },
          {
            "code": "LU",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Kim Il Sung Square",
          "Tenaru Falls",
          "Vardzia",
          "Tsingy De Bemaraha",
          "Katse Dam",
          "Leaning Tower of Pisa",
          "National Shrine Basilica of our Lady of Las Lajas"
        ],
        "uniqueId": "ZdpBx5B4",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/skyscrapers-building-architectural-3850732/",
          "wikiLink": "https://en.wikipedia.org/wiki/Doha"
        },
        "landmark": {
          "name": "The Pearl Monument",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Doha_Corniche#/media/File:The_Pearl_Monument.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Doha_Corniche",
          "hasLandmark": true
        },
        "city": {
          "name": "Al Rayyan",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Al_Rayyan_(city)#/media/File:View_of_Wadi_Al_Gaiya_Street_in_Mehairja_from_Aspire_Zone_parking_lot.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Al_Rayyan_(city)"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "6kJkqxyN",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Republic of the Congo",
    "code": "CG",
    "capital": "Brazzaville",
    "lat": -1,
    "long": 15,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/File:Brazzaville_bird_eye_view.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Brazzaville"
        },
        "landmark": {
          "name": "Basilique Sainte Anne du Congo",
          "photographer": "",
          "imageLink": "https://snl.no/Brazzaville",
          "wikiLink": "https://en.wikipedia.org/wiki/Basilica_of_Sainte-Anne-du-Congo_in_Brazzaville",
          "hasLandmark": true
        },
        "city": {
          "name": "Pointe-Noire",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Pointe-Noire#/media/File:Pointe-Noire_downtown.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Pointe-Noire"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "yYP4qS2w",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/File:Pointe-Noire_downtown.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Pointe-Noire"
        },
        "landmark": {
          "name": "Pierre Savorgnan de Brazza Memorial",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Memorial_de_Brazza.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Pierre_Savorgnan_de_Brazza",
          "hasLandmark": true
        },
        "city": {
          "name": "Brazzaville",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/brazzaville-congo-africa-city-7444939/",
          "wikiLink": "https://en.wikipedia.org/wiki/Brazzaville"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "ByvdM2PV",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Reunion",
    "code": "RE",
    "capital": "Saint-Denis",
    "lat": -21.15,
    "long": 55.5,
    "cities": [
      "Saint-Pierre",
      "Saint-Denis",
      "Saint-Paul",
      "Le Tampon",
      "Le Port",
      "Saint-Andre"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/f9xoWEnLxlQ",
          "wikiLink": "https://en.wikipedia.org/wiki/R%C3%A9union"
        },
        "landmark": {
          "name": "Kelonia",
          "photographer": "",
          "imageLink": "https://fr.wikipedia.org/wiki/K%C3%A9lonia#/media/Fichier:K%C3%A9lonia.jpg",
          "wikiLink": "https://fr.wikipedia.org/wiki/K%C3%A9lonia",
          "hasLandmark": true
        },
        "city": {
          "name": "Saint-Pierre",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Saint-Pierre,_R%C3%A9union#/media/File:Ville_Saint-Pierre1.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint-Pierre,_R%C3%A9union"
        },
        "landmarksRound": [
          {
            "code": "EE",
            "number": 1
          },
          {
            "code": "TM",
            "number": 1
          },
          {
            "code": "AT",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "National Shrine Basilica of our Lady of Las Lajas",
          "Tikal National Park",
          "Belem Tower",
          "Bled Castle",
          "Vaduz Cathedral",
          "Middle of the World City",
          "Vardzia"
        ],
        "uniqueId": "HwP2bBjy",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/R%C3%A9union#/media/File:Ppalmistes2.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/R%C3%A9union"
        },
        "landmark": {
          "name": "Villa Folio",
          "photographer": "",
          "imageLink": "https://fr.wikipedia.org/wiki/Villa_Folio#/media/Fichier:Maison_Folio.jpg",
          "wikiLink": "https://fr.wikipedia.org/wiki/Villa_Folio",
          "hasLandmark": true
        },
        "city": {
          "name": "Saint-Denis",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Saint-Denis,_R%C3%A9union#/media/File:Saint_Denis_au_cr%C3%A9puscule_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint-Denis,_R%C3%A9union"
        },
        "landmarksRound": [
          {
            "code": "FR",
            "number": 1
          },
          {
            "code": "CH",
            "number": 2
          },
          {
            "code": "GG",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Umayyad Mosque",
          "Fort Sao Sebastiao",
          "Luis I Bridge",
          "Mount Paget",
          "Xunatunich Mayan Ruins",
          "Hagia Sophia",
          "Badshahi Mosque"
        ],
        "uniqueId": "KnTr844s",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Romania",
    "code": "RO",
    "capital": "Bucharest",
    "lat": 46,
    "long": 25,
    "cities": [
      "Brasov",
      "Bucharest",
      "Sibiu",
      "Constanta",
      "Iasi",
      "Oradea"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/nKY59_d9FlA",
          "wikiLink": "https://en.wikipedia.org/wiki/Pele%C8%99_Castle"
        },
        "landmark": {
          "name": "Bran Castle",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/fortress-bran-romania-castle-2413390/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bran_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Brasov",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/buildings-city-aerial-view-4011921/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bra%C8%99ov"
        },
        "landmarksRound": [
          {
            "code": "ZA",
            "number": 1
          },
          {
            "code": "AQ",
            "number": 1
          },
          {
            "code": "LB",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Timgad",
          "Gangtey Monastery",
          "Amber Palace",
          "Chorsu Bazaar",
          "Huka Falls",
          "Charles Bridge",
          "African Renaissance Monument"
        ],
        "uniqueId": "QNW9zSMQ",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/transilvania-romania-alpine-5632013/",
          "wikiLink": "https://en.wikipedia.org/wiki/Romania"
        },
        "landmark": {
          "name": "Merry Cemetery",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Merry_Cemetery#/media/File:Rumunia,_Sapanta,_Weso%C5%82y_Cmentarz_-Aw58-_28.04.12_r..JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Merry_Cemetery",
          "hasLandmark": true
        },
        "city": {
          "name": "Bucharest",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bucharest-night-romania-lights-4009121/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bucharest"
        },
        "landmarksRound": [
          {
            "code": "NP",
            "number": 1
          },
          {
            "code": "AR",
            "number": 2
          },
          {
            "code": "GB",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Karnak",
          "Aleppo Citadel",
          "Pamir Mountains",
          "Olavinlinna",
          "Arc de Triomphe",
          "Masjid al-Haram",
          "Tjibaou Cultural Center"
        ],
        "uniqueId": "HpPj7Jsq",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Russia",
    "code": "RU",
    "capital": "Moscow",
    "lat": 60,
    "long": 100,
    "cities": [
      "Kazan",
      "Saint Petersburg",
      "Moscow",
      "Yekaterinburg",
      "Omsk",
      "Novosibirsk"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/MEWRrCmEiGc",
          "wikiLink": "https://en.wikipedia.org/wiki/Moscow_State_University"
        },
        "landmark": {
          "name": "Red Square",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/moscow-church-russian-russia-2105606/",
          "wikiLink": "https://en.wikipedia.org/wiki/Red_Square",
          "hasLandmark": true
        },
        "city": {
          "name": "Kazan",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kazan-city-architecture-cityscape-3227834/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kazan"
        },
        "landmarksRound": [
          {
            "code": "GT",
            "number": 2
          },
          {
            "code": "JP",
            "number": 2
          },
          {
            "code": "US",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Sule Pagoda",
          "Golden Gate",
          "Diocletian's Palace",
          "Alhambra",
          "Baalbek",
          "Epupa Falls",
          ""
        ],
        "uniqueId": "R5DvSxZx",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/caucasus-russia-elbrus-river-5302236/",
          "wikiLink": "https://en.wikipedia.org/wiki/Russia"
        },
        "landmark": {
          "name": "Winter Palace",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/hermitage-st-petersburg-s-2612663/",
          "wikiLink": "https://en.wikipedia.org/wiki/Winter_Palace",
          "hasLandmark": true
        },
        "city": {
          "name": "Saint Petersburg",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/saint-petersburg-peter-st-petersburg-2547440/",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Petersburg"
        },
        "landmarksRound": [
          {
            "code": "LV",
            "number": 2
          },
          {
            "code": "BD",
            "number": 1
          },
          {
            "code": "ER",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Stonehenge",
          "Marcus Aurelius Arch",
          "Hazrat Ali Mazar",
          "National Shrine Basilica of our Lady of Las Lajas",
          "Herat Citadel",
          "Diocletian's Palace",
          "Tenaru Falls"
        ],
        "uniqueId": "YkQRMMCN",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Rwanda",
    "code": "RW",
    "capital": "Kigali",
    "lat": -2,
    "long": 30,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/riqHVFDBt-8",
          "wikiLink": "https://en.wikipedia.org/wiki/Rwanda"
        },
        "landmark": {
          "name": "Kigali Genocide Memorial",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Kigali_Genocide_Memorial#/media/File:Kigali_Genocide_Memorial_(6817416143).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kigali_Genocide_Memorial",
          "hasLandmark": true
        },
        "city": {
          "name": "Kigali",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kigali-rwanda-africa-city-building-5327838/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kigali"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "DQrVmMs4",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kigali-rwanda-africa-to-travel-5256022/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rwanda"
        },
        "landmark": {
          "name": "King's Palace Museum",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Nyanza,_Rwanda#/media/File:King's_palace_in_Nyanza.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Nyanza,_Rwanda",
          "hasLandmark": true
        },
        "city": {
          "name": "Muhanga",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Muhanga#/media/File:GitaramaCityCropped.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Muhanga"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "XgcgkDXN",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Saint Barthelemy",
    "code": "BL",
    "capital": "Gustavia",
    "lat": 18.5,
    "long": -63.41666666,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/shell-beach-saint-barth%c3%a9lemy-5140800/",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Barth%C3%A9lemy"
        },
        "landmark": {
          "name": "Fort Karl",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/davidstanleytravel/46666832614",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Barth%C3%A9lemy",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Y2GMKsyn",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Grotte_du_puits_Saint_Barth%C3%A9l%C3%A9my.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Barth%C3%A9lemy"
        },
        "landmark": {
          "name": "Shell Beach",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Shell_Beach_St._Barts.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Barth%C3%A9lemy",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "T5PVPKL9",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Saint Helena",
    "code": "SH",
    "capital": "Jamestown",
    "lat": -24.143474,
    "long": -10.030696,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:St-Helena-Jamestown.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Jamestown,_Saint_Helena"
        },
        "landmark": {
          "name": "High Knoll Fort",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/High_Knoll_Fort#/media/File:High_Knoll_Fort_on_Saint_Helena_Island,_March_2020.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/High_Knoll_Fort",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "F8rHWCdz",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:St_Helena,_view_south_over_%27Lot%27_toward_Sandy_Bay,_from_Sandy_Bay_Ridge,_1985-6._Peter_Neaum._-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Sandy_Bay,_Saint_Helena"
        },
        "landmark": {
          "name": "Jacob's Ladder",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Jacob%27s_Ladder_(Saint_Helena)#/media/File:Jacobs_Ladder_viewed_from_the_harbour_of_Jamestown.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Jacob%27s_Ladder_(Saint_Helena)",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "RqQjd64n",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Saint Kitts and Nevis",
    "code": "KN",
    "capital": "Basseterre",
    "lat": 17.33333333,
    "long": -62.75,
    "cities": [
      "Basseterre",
      "Charlestown",
      "Cayon",
      "Sandy Pointe Town",
      "Gingerland",
      "Old Road Town"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cruise-ships-passengers-caribbean-6593595/",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Kitts_and_Nevis"
        },
        "landmark": {
          "name": "Brimstone Hill Fortress",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Brimstone_Hill_Fortress_National_Park#/media/File:BrimstoneHill01.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Brimstone_Hill_Fortress_National_Park",
          "hasLandmark": true
        },
        "city": {
          "name": "Charlestown",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Charlestown,_Nevis#/media/File:Nevis_Charlestown.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Charlestown,_Nevis"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "mQSGgGN8",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/120420083@N05/13924571953",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Kitts_and_Nevis"
        },
        "landmark": {
          "name": "Romney Manor",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Karibik,_St._Kitts_-_Romney_Manor_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Kitts_and_Nevis",
          "hasLandmark": true
        },
        "city": {
          "name": "Basseterre",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/saint-kitts-st-kitts-panorama-3475296/",
          "wikiLink": "https://en.wikipedia.org/wiki/Basseterre"
        },
        "landmarksRound": [
          {
            "code": "KR",
            "number": 2
          },
          {
            "code": "LT",
            "number": 1
          },
          {
            "code": "HK",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "House of the Black Heads",
          "Kim Il Sung Square",
          "Petronas Towers",
          "Abu Simbel Temples",
          "Xunatunich Mayan Ruins",
          "Church of Our Savior",
          "Hato Caves"
        ],
        "uniqueId": "WD9NpPLy",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Saint Lucia",
    "code": "LC",
    "capital": "Castries",
    "lat": 13.88333333,
    "long": -60.96666666,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/st-lucia-caribbean-island-106119/",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Lucia"
        },
        "landmark": {
          "name": "Pitons",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/piton-caribbean-volcano-pitons-164280/",
          "wikiLink": "https://en.wikipedia.org/wiki/Pitons_(Saint_Lucia)",
          "hasLandmark": true
        },
        "city": {
          "name": "Vieux Fort",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Vieux_Fort,_Saint_Lucia#/media/File:STLUC-vieuxfort.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Vieux_Fort,_Saint_Lucia"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Llf3PKNd",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/st-lucia-caribbean-island-106120/",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Lucia"
        },
        "landmark": {
          "name": "Marigot Bay",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/caribbean-bay-nature-sea-1015941/",
          "wikiLink": "https://en.wikipedia.org/wiki/Marigot_Bay",
          "hasLandmark": true
        },
        "city": {
          "name": "Castries",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/st-lucia-castries-caribbean-island-4911631/",
          "wikiLink": "https://en.wikipedia.org/wiki/Castries"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "shF95Rk5",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Saint Martin",
    "code": "MF",
    "capital": "Marigot",
    "lat": 18.08333333,
    "long": -63.95,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/airplane-maho-beach-saint-martin-2690200/",
          "wikiLink": "https://en.wikipedia.org/wiki/Maho_Beach"
        },
        "landmark": {
          "name": "Fort Amsterdam",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Sint_Maarten_-_Fort_Amsterdam_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Fort_Amsterdam_(Sint_Maarten)",
          "hasLandmark": false
        },
        "city": {
          "name": "Marigot",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/davidstanleytravel/49887745551",
          "wikiLink": "https://en.wikipedia.org/wiki/Marigot,_Saint_Martin"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "d9df48cf",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Pinel_Island,_Saint_Martin.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/List_of_Caribbean_islands#Collectivity_of_Saint_Martin"
        },
        "landmark": {
          "name": "Ilet de Pinel",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Pinel_Island,_Saint_Martin.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Outline_of_the_Collectivity_of_Saint_Martin",
          "hasLandmark": false
        },
        "city": {
          "name": "Philipsburg",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/124651729@N04/51319894543",
          "wikiLink": "https://en.wikipedia.org/wiki/Philipsburg,_Sint_Maarten"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "zBJFHtmJ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Saint Pierre and Miquelon",
    "code": "PM",
    "capital": "Saint-Pierre",
    "lat": 46.83333333,
    "long": -56.33333333,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/tibarama-caledonia-new-caledonia-6103043/",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Pierre_and_Miquelon"
        },
        "landmark": {
          "name": "Our Lady of Ardilliers Catholic Church",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Our_Lady_of_Ardilliers_Church,_Miquelon#/media/File:Eglise_de_Miquelon.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Our_Lady_of_Ardilliers_Church,_Miquelon",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "jzRGC4T8",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/danteaguiar/48350975052/in/photolist-2i1XMs-paMqWF-tNR3v-SybBmT-TJkcvy-TN16UB-SybCcF-SybByM-TJkcMA-SybyHg-Sybz72-SybCNa-TcXkyL-DsMekt-tNR3r-4foCKY-spg6vf-LaMFG7-EgW8gn-f6xb95-tNR3t-24sZttT-9jemf2-FWVGe8-GQyYH2-28eocaA-JDL2pK-G2W6Bk-GqvS83-GqxyPS-LaMHqY-JDL4Eg-28eoe8U-25xEth7-JDL5rM-LaMGPC-LaMz4h-28eo1Uf-LaMwUh-25xEtyu-LaMxQL-2gEBomU-28eoasN-FVckz2-LaML9d-JDL6RR-JDL1ak-2gqhEc7-2gqhX2q-dqSZQ4",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Pierre_and_Miquelon"
        },
        "landmark": {
          "name": "Saint-Pierre Cathedral of Saint Pierre and Miquelon",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/St._Pierre_Cathedral,_Saint-Pierre#/media/File:Cathedral_of_St_Pierre_and_Miquelon.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/St._Pierre_Cathedral,_Saint-Pierre",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "DNRXxlkk",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "code": "VC",
    "capital": "Kingstown",
    "lat": 13.25,
    "long": -61.2,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/jetty-sea-boats-sunset-dock-6643909/",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Vincent_and_the_Grenadines"
        },
        "landmark": {
          "name": "Fort Charlotte",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Fort_Charlotte,_Saint_Vincent#/media/File:Kingstown_%E2%80%93_Fort_Charlotte_-_panoramio.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Fort_Charlotte,_Saint_Vincent",
          "hasLandmark": true
        },
        "city": {
          "name": "Port Elizabeth",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/davidstanleytravel/49804087672",
          "wikiLink": "https://en.wikipedia.org/wiki/Port_Elizabeth,_Saint_Vincent_and_the_Grenadines"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "FG6qD6Lb",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/shipwreck-sea-6643956/",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_Vincent_and_the_Grenadines"
        },
        "landmark": {
          "name": "La Soufriere",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/la-soufriere-volcano-table-rock-3639039/",
          "wikiLink": "https://en.wikipedia.org/wiki/La_Soufri%C3%A8re_(volcano)",
          "hasLandmark": true
        },
        "city": {
          "name": "Kingstown",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/st-vincent-kingstown-the-grenadines-4652732/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kingstown"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "8XbvsXTw",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Samoa",
    "code": "WS",
    "capital": "Apia",
    "lat": -13.58333333,
    "long": -172.3333333,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/palm-trees-beach-dream-beach-59/",
          "wikiLink": "https://en.wikipedia.org/wiki/Samoa"
        },
        "landmark": {
          "name": "Apia Town Clock Tower",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Central_Bank_of_Samoa_%282009%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Samoa",
          "hasLandmark": true
        },
        "city": {
          "name": "Apia",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Apia#/media/File:WS-Apia-Immac-concept.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Apia"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "9YwG6sYh",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/palm-trees-beach-dream-beach-52/",
          "wikiLink": "https://en.wikipedia.org/wiki/Samoa"
        },
        "landmark": {
          "name": "To Sua Ocean Trench",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Trench_from_top_of_ladder_by_tree_To_Sua_Ocean_Trench_Samoa.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Samoa",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "zJ99XvqF",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "San Marino",
    "code": "SM",
    "capital": "City of San Marino",
    "lat": 43.76666666,
    "long": 12.41666666,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/S1JVabNkXP0",
          "wikiLink": "https://en.wikipedia.org/wiki/Monte_Titano"
        },
        "landmark": {
          "name": "Guaita Tower",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/san-marino-tower-architecture-2495109/",
          "wikiLink": "https://en.wikipedia.org/wiki/Guaita",
          "hasLandmark": true
        },
        "city": {
          "name": "Serravalle",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:SerravalleRSMPanorama.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Serravalle_(San_Marino)"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "xWrnh4yp",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/san-marino-city-urban-buildings-1966566/",
          "wikiLink": "https://en.wikipedia.org/wiki/San_Marino"
        },
        "landmark": {
          "name": "Palazzo Pubblico",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Palazzo_Pubblico_(San_Marino)#/media/File:Palazzo_pubblico_(San_Marino)_-_Day.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Palazzo_Pubblico_(San_Marino)",
          "hasLandmark": true
        },
        "city": {
          "name": "Borgo Maggiore",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Borgo_Maggiore#/media/File:Borgo_Maggiore_seen_from_San_Marino_-_June_2016.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Borgo_Maggiore"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "knmGKlnW",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Sao Tome and Principe",
    "code": "ST",
    "capital": "São Tome",
    "lat": 1,
    "long": 7,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Sao_Tome_Praia_Inhame_14_%2816247212021%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe"
        },
        "landmark": {
          "name": "Cao Grande Peak",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Pico_C%C3%A3o_Grande#/media/File:Pico_C%C3%A3o_Grande.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Pico_C%C3%A3o_Grande",
          "hasLandmark": true
        },
        "city": {
          "name": "Sao Tome",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9#/media/File:2012SaoTome-8_(8042889363).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "N9WcP6xd",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/43789968@N05/4056983982",
          "wikiLink": "https://en.wikipedia.org/wiki/S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe"
        },
        "landmark": {
          "name": "Lagoa Azul Lighthouse",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Lagoa_Azul_Lighthouse,_S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe_-_20070804.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Lagoa_Azul_Lighthouse",
          "hasLandmark": true
        },
        "city": {
          "name": "Santo Antonio",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Santo_Ant%C3%B3nio#/media/File:Santo_Ant%C3%B3nio,_Capital_of_Pr%C3%ADncipe.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Santo_Ant%C3%B3nio"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "ZvLJ6cMv",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Saudi Arabia",
    "code": "SA",
    "capital": "Riyadh",
    "lat": 25,
    "long": 45,
    "cities": [
      "Mecca",
      "Riyadh",
      "Tabuk",
      "Al Khobar",
      "Taif",
      "Jeddah"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/rzzS0_pMsD0",
          "wikiLink": "https://en.wikipedia.org/wiki/Al-Masjid_an-Nabawi"
        },
        "landmark": {
          "name": "Masjid al-Haram",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/makkah-ksa-saudi-arabia-3986709/",
          "wikiLink": "https://en.wikipedia.org/wiki/Masjid_al-Haram",
          "hasLandmark": true
        },
        "city": {
          "name": "Jeddah",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/jeddah-high-rise-saudi-arabia-652162/",
          "wikiLink": "https://en.wikipedia.org/wiki/Jeddah"
        },
        "landmarksRound": [
          {
            "code": "SN",
            "number": 2
          },
          {
            "code": "BN",
            "number": 1
          },
          {
            "code": "MZ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Dhakeshwari Temple",
          "Wat Arun",
          "Monument to the Heroes of the Restoration",
          "Gyeongbukgong Palace",
          "Trevi Fountain",
          "Christ the Redeemer",
          "Rijksmuseum"
        ],
        "uniqueId": "GrKPtq9Z",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mosque-islam-saudi-arabia-islamic-2654552/",
          "wikiLink": "https://en.wikipedia.org/wiki/Saudi_Arabia"
        },
        "landmark": {
          "name": "Kaaba",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kaaba-the-pilgrim-s-guide-religion-4758809/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kaaba",
          "hasLandmark": true
        },
        "city": {
          "name": "Mecca",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mecca-mekkah-saudi-arabia-saudi-66970/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mecca"
        },
        "landmarksRound": [
          {
            "code": "HR",
            "number": 1
          },
          {
            "code": "IN",
            "number": 1
          },
          {
            "code": "BH",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Vaduz Cathedral",
          "Predjama Castle",
          "Annandale Waterfall",
          "Cape of Good Hope",
          "Taj Mahal",
          "Narikala Fortress",
          "Chimi Lhakhang"
        ],
        "uniqueId": "3QvPQqsG",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Senegal",
    "code": "SN",
    "capital": "Dakar",
    "lat": 14,
    "long": -14,
    "cities": [
      "Kaolack",
      "Dakar",
      "Saint Louis",
      "Theis",
      "Ziguinchor",
      "Louga"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/-n1rIjE55Mw",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint-Louis,_Senegal"
        },
        "landmark": {
          "name": "House of Slaves",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/House_of_Slaves#/media/File:Senegal_Gor%C3%A9e_(8).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/House_of_Slaves",
          "hasLandmark": true
        },
        "city": {
          "name": "Kaolack",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Main_street_in_Kaolack_%28Senegal%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kaolack"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "pBl28rGq",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/fisherman-mosque-dakar-senegal-246976/",
          "wikiLink": "https://en.wikipedia.org/wiki/Senegal"
        },
        "landmark": {
          "name": "African Renaissance Monument",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/African_Renaissance_Monument#/media/File:African_Renaissance_Monument_(5502494604).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/African_Renaissance_Monument",
          "hasLandmark": true
        },
        "city": {
          "name": "Dakar",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Dakar#/media/File:Dakars_Mamelles_(5824544243).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Dakar"
        },
        "landmarksRound": [
          {
            "code": "BH",
            "number": 1
          },
          {
            "code": "RU",
            "number": 2
          },
          {
            "code": "BO",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Herat Citadel",
          "Predjama Castle",
          "Old Panama",
          "Blue Lagoon",
          "Timgad",
          "Wat Xiengthong",
          "Dragon and Tiger Pagodas"
        ],
        "uniqueId": "mMqNsGq5",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Serbia",
    "code": "RS",
    "capital": "Belgrade",
    "lat": 44,
    "long": 21,
    "cities": [
      "Novi Sad",
      "Belgrade",
      "Nis",
      "Subotica",
      "Zrenjanin",
      "Kragujevac"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/xHnomVS0SiU",
          "wikiLink": "https://en.wikipedia.org/wiki/Belgrade"
        },
        "landmark": {
          "name": "Saint Sava Temple",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/belgrade-serbia-5010494/",
          "wikiLink": "https://en.wikipedia.org/wiki/Church_of_Saint_Sava",
          "hasLandmark": true
        },
        "city": {
          "name": "Novi Sad",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/river-sunset-evening-buildings-6519448/",
          "wikiLink": "https://en.wikipedia.org/wiki/Novi_Sad"
        },
        "landmarksRound": [
          {
            "code": "FR",
            "number": 1
          },
          {
            "code": "GH",
            "number": 1
          },
          {
            "code": "GH",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Pyramiden",
          "Cliffs of Moher",
          "Palace of Peace and Reconciliation",
          "Dubai Frame",
          "Grandvalira",
          "Chimi Lhakhang",
          "Grand Ducal Palace"
        ],
        "uniqueId": "NcnCMgN3",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/%c4%91erdap-serbia-castle-river-old-2129567/",
          "wikiLink": "https://en.wikipedia.org/wiki/Serbia"
        },
        "landmark": {
          "name": "Golubac Fortress",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/serbia-golubac-golubac-fortress-5332404/",
          "wikiLink": "https://en.wikipedia.org/wiki/Golubac_Fortress",
          "hasLandmark": true
        },
        "city": {
          "name": "Belgrade",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/architecture-belgrade-gardos-kula-4569749/",
          "wikiLink": "https://en.wikipedia.org/wiki/Belgrade"
        },
        "landmarksRound": [
          {
            "code": "MX",
            "number": 2
          },
          {
            "code": "GQ",
            "number": 1
          },
          {
            "code": "US",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Grandvalira",
          "Monteverde Cloud Forest",
          "Cologne Cathedral",
          "CN Tower",
          "Ostrog Monastery",
          "Blue Grotto",
          "Dragon and Tiger Pagodas"
        ],
        "uniqueId": "tgBBbTsC",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Seychelles",
    "code": "SC",
    "capital": "Victoria",
    "lat": -4.58333333,
    "long": 55.66666666,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/HoNnEDKIar4",
          "wikiLink": "https://en.wikipedia.org/wiki/Seychelles"
        },
        "landmark": {
          "name": "Anse Lazio",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/seychelles-anse-lazio-praslin-2286750/",
          "wikiLink": "https://en.wikipedia.org/wiki/Anse_Lazio",
          "hasLandmark": true
        },
        "city": {
          "name": "Victoria",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Seychelles#/media/File:Ile_de_Mahe_-_Victoria_(9).JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Victoria,_Seychelles"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "fhdxyqwn",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/seychelles-landscape-sea-beach-4916045/",
          "wikiLink": "https://en.wikipedia.org/wiki/Seychelles"
        },
        "landmark": {
          "name": "Morne Seychellois",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Morne_Seychellois_Mountain.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Morne_Seychellois",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Beau_Vallon_%28Seychelles%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Beau_Vallon,_Seychelles"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "HjzmJBRD",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Sierra Leone",
    "code": "SL",
    "capital": "Freetown",
    "lat": 8.5,
    "long": -11.5,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/coast-sea-sierra-leone-island-6508040/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sierra_Leone"
        },
        "landmark": {
          "name": "Cotton Tree",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Cotton_Tree_(Sierra_Leone)#/media/File:Cotton_Tree_(Sierra_Leone).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Cotton_Tree_(Sierra_Leone)",
          "hasLandmark": true
        },
        "city": {
          "name": "Freetown",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Freetown#/media/File:Freetown-aerialview.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Freetown"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "XdhbrMzH",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sierra-leone-town-coast-sea-ocean-6508049/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sierra_Leone"
        },
        "landmark": {
          "name": "Bunce Island",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bunce_Island#/media/File:Bunce_Island_Beach.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bunce_Island",
          "hasLandmark": true
        },
        "city": {
          "name": "Kenema",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Kenema#/media/File:Street_in_Kenema_03.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kenema"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "hCLKh3Mg",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Singapore",
    "code": "SG",
    "capital": "Singapore",
    "lat": 1.36666666,
    "long": 103.8,
    "cities": [
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/McM5BW4jQHc",
          "wikiLink": "https://en.wikipedia.org/wiki/Gardens_by_the_Bay"
        },
        "landmark": {
          "name": "Marina Bay Sands",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/singapore-marina-bay-sands-landmark-1990090/",
          "wikiLink": "https://en.wikipedia.org/wiki/Marina_Bay_Sands",
          "hasLandmark": true
        },
        "city": {
          "name": "Jurong East",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Jurong_East#/media/File:Jurong_East_HDBscape_(8407109505).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Jurong_East"
        },
        "landmarksRound": [
          {
            "code": "MA",
            "number": 2
          },
          {
            "code": "BS",
            "number": 2
          },
          {
            "code": "CO",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Maiden Tower",
          "Xunatunich Mayan Ruins",
          "Wat Xiengthong",
          "Arahoho Blowhole",
          "Hazrat Ali Mazar",
          "Turku Castle",
          "Floralis Generica"
        ],
        "uniqueId": "cTMPmYyM",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/buildings-skyline-city-lights-647400/",
          "wikiLink": "https://en.wikipedia.org/wiki/Singapore"
        },
        "landmark": {
          "name": "Singapore Flyer",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/singapore-ferris-wheel-big-wheel-243671/",
          "wikiLink": "https://en.wikipedia.org/wiki/Singapore_Flyer",
          "hasLandmark": true
        },
        "city": {
          "name": "Bukit Batok",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bukit_Batok#/media/File:BukitBatok.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Bukit_Batok"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "MjVHSTTt",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Slovakia",
    "code": "SK",
    "capital": "Bratislava",
    "lat": 48.66666666,
    "long": 19.5,
    "cities": [
      "Kosice",
      "Bratislava",
      "Nitra",
      "Presov",
      "Trencin",
      "Bardejov"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/vgPgg8X2NyA",
          "wikiLink": "https://en.wikipedia.org/wiki/Bratislava"
        },
        "landmark": {
          "name": "Bratislava Castle",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bratislava-slovakia-castle-evening-3641286/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bratislava_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Kosice",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kosice-outlook-the-tower-the-city-1380221/",
          "wikiLink": "https://en.wikipedia.org/wiki/Ko%C5%A1ice"
        },
        "landmarksRound": [
          {
            "code": "TR",
            "number": 1
          },
          {
            "code": "VU",
            "number": 1
          },
          {
            "code": "MM",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "The Marble Mountains",
          "Kittiwake Shipwreck",
          "Xunatunich Mayan Ruins",
          "Aleppo Citadel",
          "Marina Bay Sands",
          "Dhakeshwari Temple",
          "Fisherman's Bastion"
        ],
        "uniqueId": "75Nt8DPt",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/tatra-mountains-slovakia-lake-7231545/",
          "wikiLink": "https://en.wikipedia.org/wiki/Slovakia"
        },
        "landmark": {
          "name": "Devin Castle",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bratislava-slovakia-dev%c3%adn-channel-4401453/",
          "wikiLink": "https://en.wikipedia.org/wiki/Dev%C3%ADn_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Bratislava",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bratislava-slovakia-1569359/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bratislava"
        },
        "landmarksRound": [
          {
            "code": "BW",
            "number": 1
          },
          {
            "code": "NA",
            "number": 2
          },
          {
            "code": "HN",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Jardin de Balata",
          "Szechenyi Thermal Bath",
          "South Pole (ceremonial)",
          "Golden Gate",
          "The Grand Palace",
          "Rila Monastery",
          "The Blue Mosque"
        ],
        "uniqueId": "QfmNQPRS",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Slovenia",
    "code": "SI",
    "capital": "Ljubljana",
    "lat": 46.11666666,
    "long": 14.81666666,
    "cities": [
      "Koper",
      "Ljubljana",
      "Bled",
      "Novo Mesto",
      "Kranj",
      "Celje"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/W3FC_bCPw8E",
          "wikiLink": "https://en.wikipedia.org/wiki/Lake_Bled"
        },
        "landmark": {
          "name": "Bled Castle",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/the-needle-bled-slovenia-castle-2746879/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bled_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Koper",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Koper#/media/File:Koper_modern_residential_quarter.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Koper"
        },
        "landmarksRound": [
          {
            "code": "NP",
            "number": 1
          },
          {
            "code": "BA",
            "number": 2
          },
          {
            "code": "EG",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Saint Sava Temple",
          "Tegucigalpa Cathedral",
          "Chamarel Waterfall",
          "Iskanderkul",
          "Al Fateh Grand Mosque",
          "George Washington House",
          "Herat Citadel"
        ],
        "uniqueId": "mHTtVZK5",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/ljubljana-flow-slovenia-bridge-2149704/",
          "wikiLink": "https://en.wikipedia.org/wiki/Ljubljana"
        },
        "landmark": {
          "name": "Predjama Castle",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/castle-predjama-castle-67600/",
          "wikiLink": "https://en.wikipedia.org/wiki/Predjama_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Ljubljana",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/europe-ljubljana-castle-3643773/",
          "wikiLink": "https://en.wikipedia.org/wiki/Ljubljana"
        },
        "landmarksRound": [
          {
            "code": "BW",
            "number": 1
          },
          {
            "code": "AU",
            "number": 1
          },
          {
            "code": "AL",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Parthenon",
          "Wat Xiengthong",
          "Pamir Mountains",
          "Santa Catalina Arch",
          "Huka Falls",
          "Temple of Literature",
          "Dunn's River Falls"
        ],
        "uniqueId": "pVsxJ4Yw",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Solomon Islands",
    "code": "SB",
    "capital": "Honiara",
    "lat": -8,
    "long": 159,
    "cities": [
      "Honiara",
      "Gizo",
      "Auki",
      "Kirakira",
      "Buala",
      "Gizo"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/o7oV-rGy53Y",
          "wikiLink": "https://en.wikipedia.org/wiki/Solomon_Islands"
        },
        "landmark": {
          "name": "Tenaru Falls",
          "photographer": "",
          "imageLink": "https://www.pexels.com/photo/anonymous-traveler-enjoying-rapid-waterfall-in-mountains-during-vacation-4153337/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tenaru_Falls",
          "hasLandmark": true
        },
        "city": {
          "name": "Honiara",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Honiara#/media/File:Honiara_View.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Honiara"
        },
        "landmarksRound": [
          {
            "code": "FK",
            "number": 2
          },
          {
            "code": "CZ",
            "number": 2
          },
          {
            "code": "ER",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Bratislava Castle",
          "Niagara Falls",
          "Sukhbaatar Square",
          "Lukan Longshan Temple",
          "Gangtey Monastery",
          "Al Alam Palace",
          "Tavarua"
        ],
        "uniqueId": "c9gh44FW",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/the-solomon-islands-pacific-4573372/",
          "wikiLink": "https://en.wikipedia.org/wiki/Solomon_Islands"
        },
        "landmark": {
          "name": "Marovo Lagoon",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Marovo_Lagoon_%2816_August_2008%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Marovo_Lagoon",
          "hasLandmark": true
        },
        "city": {
          "name": "Gizo",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/dfataustralianaid/10721997443",
          "wikiLink": "https://en.wikipedia.org/wiki/Gizo,_Solomon_Islands"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Xnd9cLyC",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Somalia",
    "code": "SO",
    "capital": "Mogadishu",
    "lat": 10,
    "long": 49,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/I7c0n1_7dZs",
          "wikiLink": "https://en.wikipedia.org/wiki/Mogadishu"
        },
        "landmark": {
          "name": "Jazeera Beach",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Jazeera_Beach#/media/File:Jazeera_beach_view.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Jazeera_Beach",
          "hasLandmark": true
        },
        "city": {
          "name": "Mogadishu",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mogadishu#/media/File:Pexels-yahye-ali-ahmed-3720815.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mogadishu"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "V5t6hGnZ",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/river-somalia-water-5049398/",
          "wikiLink": "https://en.wikipedia.org/wiki/Somalia"
        },
        "landmark": {
          "name": "Daallo Mountain",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Daallo_Mountain_-_Erigavo,_Sanaag_Region,_Somaliland_05.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Daallo_Mountain",
          "hasLandmark": true
        },
        "city": {
          "name": "Merca",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:2013_02_02_AMISOM_Merca_G_%288465140792%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Merca"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "SJfXHJwB",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "South Africa",
    "code": "ZA",
    "capital": "Pretoria",
    "lat": -29,
    "long": 24,
    "cities": [
      "Cape Town",
      "Johannesburg",
      "Pretoria",
      "Durban",
      "Gqeberha",
      "Pietermaritzburg"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/1GgWbP74phY",
          "wikiLink": "https://en.wikipedia.org/wiki/Cape_Town"
        },
        "landmark": {
          "name": "Cape of Good Hope",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/south-africa-cape-town-cape-997540/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cape_of_Good_Hope",
          "hasLandmark": true
        },
        "city": {
          "name": "Cape Town",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cape-town-table-mountain-waterfront-1562907/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cape_Town"
        },
        "landmarksRound": [
          {
            "code": "CL",
            "number": 1
          },
          {
            "code": "MN",
            "number": 2
          },
          {
            "code": "LT",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Christ the Redeemer",
          "Ruhyyet Palace",
          "Alexander Nevsky Cathedral",
          "Golubac Fortress",
          "Akershus Fortress",
          "Langkawi Sky Bridge",
          "Iskanderkul"
        ],
        "uniqueId": "R2cxKzTf",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/capetown-beach-sunset-aerial-view-2295679/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cape_Town"
        },
        "landmark": {
          "name": "Voortrekker Monument",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/voortrekker-monument-monument-392629/",
          "wikiLink": "https://en.wikipedia.org/wiki/Voortrekker_Monument",
          "hasLandmark": true
        },
        "city": {
          "name": "Johannesburg",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/johannesburg-south-africa-city-408643/",
          "wikiLink": "https://en.wikipedia.org/wiki/Johannesburg"
        },
        "landmarksRound": [
          {
            "code": "LK",
            "number": 1
          },
          {
            "code": "CA",
            "number": 2
          },
          {
            "code": "UY",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Leaning Tower of Pisa",
          "Gangtey Monastery",
          "Blue Grotto",
          "Diocletian's Palace",
          "Salvo Palace",
          "Galle Fort",
          "Karnak"
        ],
        "uniqueId": "Wws8dvgY",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "South Georgia",
    "code": "GS",
    "capital": "King Edward Point",
    "lat": -54.5,
    "long": -37,
    "cities": [
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/Z3EwKKX513k",
          "wikiLink": "https://en.wikipedia.org/wiki/South_Georgia_and_the_South_Sandwich_Islands"
        },
        "landmark": {
          "name": "Grytviken Church",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Norwegian_Anglican_Church,_Grytviken#/media/File:Grytviken_church.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Norwegian_Anglican_Church,_Grytviken",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [
          {
            "code": "BA",
            "number": 2
          },
          {
            "code": "FK",
            "number": 2
          },
          {
            "code": "LB",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Aleppo Citadel",
          "Wat Xiengthong",
          "Epupa Falls",
          "Bellapais Abbey",
          "National Shrine Basilica of our Lady of Las Lajas",
          "Blue Lagoon",
          "Monastery of St. Naum"
        ],
        "uniqueId": "CwjsDh6F",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/south-georgia-king-penguins-4709461/",
          "wikiLink": "https://en.wikipedia.org/wiki/South_Georgia_and_the_South_Sandwich_Islands"
        },
        "landmark": {
          "name": "Mount Paget",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mount_Paget#/media/File:Mount_Paget.jpeg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mount_Paget",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [
          {
            "code": "IQ",
            "number": 1
          },
          {
            "code": "JM",
            "number": 1
          },
          {
            "code": "MA",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Ruhyyet Palace",
          "Acropolis",
          "Salvo Palace",
          "Deception Island",
          "Saint Sava Temple",
          "Old Dongola",
          "Dragon and Tiger Pagodas"
        ],
        "uniqueId": "YgJzN7tx",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "South Korea",
    "code": "KR",
    "capital": "Seoul",
    "lat": 37,
    "long": 127.5,
    "cities": [
      "Busan",
      "Incheon",
      "Seoul",
      "Daegu",
      "Gwangju",
      "Ulsan"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/xRAEPmP9M8s",
          "wikiLink": "https://en.wikipedia.org/wiki/Haedong_Yonggungsa"
        },
        "landmark": {
          "name": "Gyeongbukgong Palace",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Gyeongbokgung#/media/File:%EB%B0%A4%EC%9D%98_%EA%B2%BD%EB%B3%B5%EA%B6%81.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Gyeongbokgung",
          "hasLandmark": true
        },
        "city": {
          "name": "Busan",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/building-high-rise-building-beach-2380214/",
          "wikiLink": "https://en.wikipedia.org/wiki/Busan"
        },
        "landmarksRound": [
          {
            "code": "LB",
            "number": 1
          },
          {
            "code": "IR",
            "number": 2
          },
          {
            "code": "PA",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Tokyo Skytree",
          "Maiden Tower",
          "Amberd",
          "Arc de Triomphe",
          "Valle de Cocora",
          "The Marble Mountains",
          "Nizwa Fort"
        ],
        "uniqueId": "pbz2f27q",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/business-bar-restaurant-shield-5475283/",
          "wikiLink": "https://en.wikipedia.org/wiki/Seoul"
        },
        "landmark": {
          "name": "Lotto World",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Lotte_World#/media/File:Lotte_World_morning_view_6.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Lotte_World",
          "hasLandmark": true
        },
        "city": {
          "name": "Incheon",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/incheon-songdo-building-1754841/",
          "wikiLink": "https://en.wikipedia.org/wiki/Incheon"
        },
        "landmarksRound": [
          {
            "code": "MK",
            "number": 1
          },
          {
            "code": "BB",
            "number": 1
          },
          {
            "code": "EG",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Valle de Cocora",
          "Charles Bridge",
          "Stone House",
          "Christ Church Cathedral",
          "Hofburg Palace",
          "Tegucigalpa Cathedral",
          "Bratislava Castle"
        ],
        "uniqueId": "qPjqdHVZ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "South Sudan",
    "code": "SS",
    "capital": "Juba",
    "lat": 7,
    "long": 30,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Juba#/media/File:John_Garang_Mausoleum_Square_in_Juba.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Juba"
        },
        "landmark": {
          "name": "Equatoria Tower",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:UAP_Equatoria_Tower.jpg",
          "wikiLink": "",
          "hasLandmark": true
        },
        "city": {
          "name": "Bor",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bor,_South_Sudan#/media/File:Merol_Market,_Bor_Town,_Jonglei_State,_South_Sudan.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bor,_South_Sudan"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "z5hm8MjQ",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:JUBA_VIEW.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Juba"
        },
        "landmark": {
          "name": "Juba Bridge",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Juba#/media/File:Sudan_Juba_bridge.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Juba_Nile_Bridge",
          "hasLandmark": true
        },
        "city": {
          "name": "Juba",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Juba#/media/File:JUBA_VIEW.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Juba"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "JnVXBhs5",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Spain",
    "code": "ES",
    "capital": "Madrid",
    "lat": 40,
    "long": -4,
    "cities": [
      "Valencia",
      "Madrid",
      "Bilbao",
      "Seville",
      "Granada",
      "Barcelona"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/PWrNP4bQHB4",
          "wikiLink": "https://en.wikipedia.org/wiki/Plaza_de_Espa%C3%B1a,_Seville"
        },
        "landmark": {
          "name": "La Sagrada Familia",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sagrada-fam%c3%adlia-cathedral-552084/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sagrada_Fam%C3%ADlia",
          "hasLandmark": true
        },
        "city": {
          "name": "Barcelona",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/street-roundabout-barcelona-spain-427998/",
          "wikiLink": "https://en.wikipedia.org/wiki/Barcelona"
        },
        "landmarksRound": [
          {
            "code": "RU",
            "number": 1
          },
          {
            "code": "BY",
            "number": 1
          },
          {
            "code": "MT",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Tsingy De Bemaraha",
          "Easter Island",
          "Luis I Bridge",
          "Tash Rabat",
          "Abu Simbel Temples",
          "Mount Kenya",
          "Queen Emma Bridge"
        ],
        "uniqueId": "89L5DKG4",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/valencia-spain-calatrava-sunset-1049389/",
          "wikiLink": "https://en.wikipedia.org/wiki/Valencia"
        },
        "landmark": {
          "name": "Alhambra",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/alhambra-castle-castle-complex-179171/",
          "wikiLink": "https://en.wikipedia.org/wiki/Alhambra",
          "hasLandmark": true
        },
        "city": {
          "name": "Valencia",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cited-arts-science-valencia-spain-905231/",
          "wikiLink": "https://en.wikipedia.org/wiki/Valencia"
        },
        "landmarksRound": [
          {
            "code": "AL",
            "number": 1
          },
          {
            "code": "FI",
            "number": 1
          },
          {
            "code": "BT",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Soroca Fort",
          "Red Square",
          "Masjid al-Haram",
          "Pico Basile",
          "Dunn's River Falls",
          "Boudhanath",
          "Galle Fort"
        ],
        "uniqueId": "lB9Dxq4H",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Sri Lanka",
    "code": "LK",
    "capital": "Sri Jayawardenepura Kotte",
    "lat": 7,
    "long": 81,
    "cities": [
      "Colombo",
      "Kandy",
      "Jaffna",
      "Galle",
      "Batticaloa",
      "Anuradhapura"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/smUAKwMT8XA",
          "wikiLink": "https://en.wikipedia.org/wiki/Sigiriya"
        },
        "landmark": {
          "name": "Galle Fort",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/galle-fort-ocean-clouds-storm-5115527/",
          "wikiLink": "https://en.wikipedia.org/wiki/Galle_Fort",
          "hasLandmark": true
        },
        "city": {
          "name": "Kandy",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Kandy#/media/File:SL_Kandy_asv2020-01_img11_Arthurs_Seat_view.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kandy"
        },
        "landmarksRound": [
          {
            "code": "TR",
            "number": 1
          },
          {
            "code": "FO",
            "number": 1
          },
          {
            "code": "HN",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Tegucigalpa Cathedral",
          "Valle de Cocora",
          "Marina Bay Sands",
          "Tjibaou Cultural Center",
          "Tsingy De Bemaraha",
          "Kittiwake Shipwreck",
          "Castillo San Cristobal"
        ],
        "uniqueId": "JBcgWHwx",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/tower-building-road-lotus-tower-7314495/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sri_Lanka"
        },
        "landmark": {
          "name": "Sri Dalada Maligawa",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sri-lanka-kandy-2107620/",
          "wikiLink": "https://en.wikipedia.org/wiki/Temple_of_the_Tooth",
          "hasLandmark": true
        },
        "city": {
          "name": "Jaffna",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Jaffna.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Jaffna"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "mJGgyfj8",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Sudan",
    "code": "SD",
    "capital": "Khartoum",
    "lat": 15,
    "long": 30,
    "cities": [
      "Nyala",
      "Khartoum",
      "Port Sudan",
      "Kassala",
      "Kosti",
      "Wad Madani"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/9IafO_YOVik",
          "wikiLink": "https://en.wikipedia.org/wiki/Nile"
        },
        "landmark": {
          "name": "Old Dongola",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Old_Dongola#/media/File:Old_Dobgola_Kingdom_of_Makuria,_Throne_hall_(2)_(34019327053).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Old_Dongola",
          "hasLandmark": true
        },
        "city": {
          "name": "Nyala",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Nyala,_Sudan#/media/File:Cinema_street2.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Nyala,_Sudan"
        },
        "landmarksRound": [
          {
            "code": "EC",
            "number": 1
          },
          {
            "code": "SK",
            "number": 1
          },
          {
            "code": "LB",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Kravica Waterfall",
          "Tian Tan Buddha",
          "Pamir Mountains",
          "Akershus Fortress",
          "Ruins of St. Paul's",
          "Charles Bridge",
          "Belvedere Lookout"
        ],
        "uniqueId": "j8RGstPG",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-townscape-horizon-line-3229028/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sudan"
        },
        "landmark": {
          "name": "Pyramids of Meroe",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cartoon-meroe-pyramid-old-one-3050417/",
          "wikiLink": "https://en.wikipedia.org/wiki/Pyramids_of_Mero%C3%AB",
          "hasLandmark": true
        },
        "city": {
          "name": "Khartoum",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Khartoum#/media/File:Elmek_Nimir_Bridge.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Khartoum"
        },
        "landmarksRound": [
          {
            "code": "PH",
            "number": 1
          },
          {
            "code": "IE",
            "number": 2
          },
          {
            "code": "VE",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Bratislava Castle",
          "Iluissat Icefjord",
          "Red Square",
          "Souq Waqif",
          "Hato Caves",
          "Mount Yasur",
          "Christ the Redeemer"
        ],
        "uniqueId": "bCnjddCC",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Suriname",
    "code": "SR",
    "capital": "Paramaribo",
    "lat": 4,
    "long": -56,
    "cities": [
      "Nieuw Amsterdam",
      "Paramaribo",
      "Nieuw Nickerie",
      "Brokopondo",
      "Moengo",
      "Lelydorp"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/amazon-suriname-south-america-265260/",
          "wikiLink": "https://en.wikipedia.org/wiki/Suriname"
        },
        "landmark": {
          "name": "Fort Zeelandia",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Fort_Zeelandia_%2830451941123%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Fort_Zeelandia_(Paramaribo)",
          "hasLandmark": true
        },
        "city": {
          "name": "Nieuw Amsterdam",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Nieuw_Amsterdam,_Suriname#/media/File:New_amsterdam_from_air.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Nieuw_Amsterdam,_Suriname"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "c3HRyTjM",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/water-boat-travel-vacation-3338367/",
          "wikiLink": "https://en.wikipedia.org/wiki/Suriname"
        },
        "landmark": {
          "name": "Neveh Shalom Synagogue",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Neveh_Shalom_Synagogue#/media/File:MoscheeSynagoge.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Neveh_Shalom_Synagogue",
          "hasLandmark": true
        },
        "city": {
          "name": "Paramaribo",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Paramaribo#/media/File:Waterkant_Paramaribo_New.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Paramaribo"
        },
        "landmarksRound": [
          {
            "code": "IT",
            "number": 2
          },
          {
            "code": "AU",
            "number": 2
          },
          {
            "code": "TR",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "St. Tryphon's Cathedral",
          "Villa Folio",
          "Big Ben",
          "Somapura Mahavihara",
          "Temple of Literature",
          "Saqsayhuaman",
          "Wieliczka Salt Mine"
        ],
        "uniqueId": "cbqggpFW",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Svalbard and Jan Mayen",
    "code": "SJ",
    "capital": "Longyearbyen",
    "lat": 77.553604,
    "long": 23.670272,
    "cities": [
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/NmXaYwkw560",
          "wikiLink": "https://en.wikipedia.org/wiki/Svalbard_and_Jan_Mayen"
        },
        "landmark": {
          "name": "Pyramiden",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Pyramiden_35.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Pyramiden",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [
          {
            "code": "AZ",
            "number": 1
          },
          {
            "code": "BY",
            "number": 1
          },
          {
            "code": "BR",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Epupa Falls",
          "Blue Lagoon",
          "Sistine Chapel",
          "Neveh Shalom Synagogue",
          "Soroca Fort",
          "Cathedral Our Lady of the Rosary",
          "Szechenyi Thermal Bath"
        ],
        "uniqueId": "39W5nyQR",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/snow-mountain-winter-hiking-1185467/",
          "wikiLink": "https://en.wikipedia.org/wiki/Svalbard"
        },
        "landmark": {
          "name": "Svalbard Church",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Longyearbyen-Church-2006.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Svalbard_Church",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "xyJPYCqf",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Sweden",
    "code": "SE",
    "capital": "Stockholm",
    "lat": 62,
    "long": 15,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/58X3XfxxevU",
          "wikiLink": "https://en.wikipedia.org/wiki/Abisko_National_Park"
        },
        "landmark": {
          "name": "Skansen",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Skansen#/media/File:Skansen-SkogaholmHerrgard-westwing-MathewDodson.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Skansen",
          "hasLandmark": true
        },
        "city": {
          "name": "Malmo",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/turning-torso-malm%c3%b6-sk%c3%a5ne-sweden-4009540/",
          "wikiLink": "https://en.wikipedia.org/wiki/Malm%C3%B6"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "KmNqhBXJ",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sweden-clouds-travel-scenery-4104028/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sweden"
        },
        "landmark": {
          "name": "Stockholm Palace",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/stockholm-the-riksdag-3411026/",
          "wikiLink": "https://en.wikipedia.org/wiki/Stockholm_Palace",
          "hasLandmark": true
        },
        "city": {
          "name": "Gothenburg",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/gothenburg-overlooking-the-harbour-2414242/",
          "wikiLink": "https://en.wikipedia.org/wiki/Gothenburg"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "fHkFwnwN",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Switzerland",
    "code": "CH",
    "capital": "Bern",
    "lat": 47,
    "long": 8,
    "cities": [
      "Zurich",
      "Lucerne",
      "Lausanne",
      "Chur",
      "Basel",
      "Bern"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/BYPMtjYMEyk",
          "wikiLink": "https://en.wikipedia.org/wiki/Lauterbrunnen"
        },
        "landmark": {
          "name": "Chillon Castle",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/castle-chillon-switzerland-3603881/",
          "wikiLink": "https://en.wikipedia.org/wiki/Chillon_Castle",
          "hasLandmark": true
        },
        "city": {
          "name": "Bern",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/buildings-city-architecture-bern-6262595/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bern"
        },
        "landmarksRound": [
          {
            "code": "PF",
            "number": 1
          },
          {
            "code": "BZ",
            "number": 2
          },
          {
            "code": "IQ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Narikala Fortress",
          "Boudhanath",
          "Senso-ji",
          "Tegucigalpa Cathedral",
          "Turku Castle",
          "Ruhyyet Palace",
          "Fisherman's Bastion"
        ],
        "uniqueId": "vY9kxHk4",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/buildings-city-architecture-bern-6262595/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bern"
        },
        "landmark": {
          "name": "Rhine Falls",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/rhine-falls-waterfall-schaffhausen-1622140/",
          "wikiLink": "https://en.wikipedia.org/wiki/Rhine_Falls",
          "hasLandmark": true
        },
        "city": {
          "name": "Zurich",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bridge-city-river-zurich-4636745/",
          "wikiLink": "https://en.wikipedia.org/wiki/Z%C3%BCrich"
        },
        "landmarksRound": [
          {
            "code": "LB",
            "number": 2
          },
          {
            "code": "BG",
            "number": 1
          },
          {
            "code": "MW",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Turku Castle",
          "Hazrat Ali Mazar",
          "Nizwa Fort",
          "Chorsu Bazaar",
          "Cathedral Our Lady of the Rosary",
          "Kathmandu Durbar Square",
          "Old Town Square"
        ],
        "uniqueId": "5zyd8dmm",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Syria",
    "code": "SY",
    "capital": "Damascus",
    "lat": 35,
    "long": 38,
    "cities": [
      "Homs",
      "Damascus",
      "Aleppo",
      "Hama",
      "Duma",
      "Daraa"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/syria-apamea-roman-ruins-heaven-4033538/",
          "wikiLink": "https://en.wikipedia.org/wiki/Apamea,_Syria"
        },
        "landmark": {
          "name": "Aleppo Citadel",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Citadel_of_Aleppo#/media/File:Citadel_of_Aleppo.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Citadel_of_Aleppo",
          "hasLandmark": true
        },
        "city": {
          "name": "Homs",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Homs#/media/File:Al-Shuhadaa_Square_-_Hims,_Syria.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Homs"
        },
        "landmarksRound": [
          {
            "code": "GE",
            "number": 2
          },
          {
            "code": "GL",
            "number": 2
          },
          {
            "code": "KN",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "National Shrine Basilica of our Lady of Las Lajas",
          "Annandale Waterfall",
          "Sao Filipe Royale Fortress",
          "Pyramids of Meroe",
          "Vaduz Cathedral",
          "Ostrog Monastery",
          "Karnak"
        ],
        "uniqueId": "bKGrn4q5",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/syria-aleppo-citadel-entrance-1886425/",
          "wikiLink": "https://en.wikipedia.org/wiki/Aleppo"
        },
        "landmark": {
          "name": "Umayyad Mosque",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/damascus-syria-umayyad-mosque-3120492/",
          "wikiLink": "https://en.wikipedia.org/wiki/Umayyad_Mosque",
          "hasLandmark": true
        },
        "city": {
          "name": "Damascus",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Damascus#/media/File:Umayyad_Mosque,_Damascus.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Damascus"
        },
        "landmarksRound": [
          {
            "code": "GH",
            "number": 2
          },
          {
            "code": "UG",
            "number": 1
          },
          {
            "code": "BD",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "St. Stephen's Cathedral",
          "Saint Sava Temple",
          "Independence Monument",
          "Santa Catalina Arch",
          "Gravensteen",
          "Grand Ducal Palace",
          "Twelve Apostles"
        ],
        "uniqueId": "7R5z8pzJ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Taiwan",
    "code": "TW",
    "capital": "Taipei",
    "lat": 23.5,
    "long": 121,
    "cities": [
      "Tainan",
      "Taichung",
      "Taipei",
      "Keelung",
      "Kaohsiung",
      "Hualien"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/UDv1n0xIpU8",
          "wikiLink": "https://en.wikipedia.org/wiki/Jiufen"
        },
        "landmark": {
          "name": "Dragon and Tiger Pagodas",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Dragon_and_Tiger_Pagodas#/media/File:Dragon_and_Tiger_Pagodas_02.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Dragon_and_Tiger_Pagodas",
          "hasLandmark": true
        },
        "city": {
          "name": "Tainan",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Downtown_Tainan%282012%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tainan"
        },
        "landmarksRound": [
          {
            "code": "MK",
            "number": 1
          },
          {
            "code": "GS",
            "number": 1
          },
          {
            "code": "TN",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Mount Nyriagongo",
          "Angel Falls",
          "Merry Cemetery",
          "Erbil Citadel",
          "Ocean Park",
          "Arahoho Blowhole",
          "Old Dongola"
        ],
        "uniqueId": "lvBXRQHY",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cityscape-city-city-lights-evening-5543224/",
          "wikiLink": "https://en.wikipedia.org/wiki/Taiwan"
        },
        "landmark": {
          "name": "Lukan Longshan Temple",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Lugang_Lukang_Longshan_Temple_Erstes_Tor_2.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Lukang_Longshan_Temple",
          "hasLandmark": true
        },
        "city": {
          "name": "Taichung",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/metropolitan-taichung-city-dusk-2354546/",
          "wikiLink": "https://en.wikipedia.org/wiki/Taichung"
        },
        "landmarksRound": [
          {
            "code": "BS",
            "number": 1
          },
          {
            "code": "ER",
            "number": 2
          },
          {
            "code": "EE",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Fort Fincastle",
          "Frogner Park",
          "Bran Castle",
          "Badshahi Mosque",
          "Azadi Tower",
          "Rizal Park",
          "Pulhapanzak Waterfalls"
        ],
        "uniqueId": "VMVS6dJH",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Tajikistan",
    "code": "TJ",
    "capital": "Dushanbe",
    "lat": 39,
    "long": 71,
    "cities": [
      "Dushanbe",
      "Kulob",
      "Qurgonteppa",
      "Panjakent",
      "Tursunzoda",
      "Khujand"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/zot26dCO_4I",
          "wikiLink": "https://en.wikipedia.org/wiki/Pamir_Mountains"
        },
        "landmark": {
          "name": "Iskanderkul",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Iskanderkul#/media/File:Iskander-kul,_Tajikistan.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Iskanderkul",
          "hasLandmark": true
        },
        "city": {
          "name": "Khujand",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Khujand#/media/File:%D0%90%D0%BB%D0%B5%D1%8F_%D1%81%D0%BB%D0%B0%D0%B2%D1%8B_%D0%B2_%D0%A5%D1%83%D1%87%D0%B0%D0%BD%D0%B4%D0%B5.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Khujand"
        },
        "landmarksRound": [
          {
            "code": "LU",
            "number": 1
          },
          {
            "code": "GG",
            "number": 2
          },
          {
            "code": "HR",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Seven Colored Earths",
          "Macau Tower",
          "Pico Basile",
          "Turku Castle",
          "Mulafossur Waterfall",
          "Merry Cemetery",
          "Somapura Mahavihara"
        ],
        "uniqueId": "HjGCHVh9",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/tajikistan-iskanderkul-alex-andersee-4584690/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tajikistan"
        },
        "landmark": {
          "name": "Pamir Mountains",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/nature-travel-exploration-outdoors-4606064/",
          "wikiLink": "https://en.wikipedia.org/wiki/Pamir_Mountains",
          "hasLandmark": true
        },
        "city": {
          "name": "Dushanbe",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Dushanbe,Tadjikistan_%2812774957295%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Dushanbe"
        },
        "landmarksRound": [
          {
            "code": "NA",
            "number": 2
          },
          {
            "code": "SY",
            "number": 1
          },
          {
            "code": "FO",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Vardzia",
          "CN Tower",
          "Devin Castle",
          "Christiansborg Palace",
          "Grand Ducal Palace",
          "Souq Waqif",
          "Christ of Havana"
        ],
        "uniqueId": "YNjHrgy9",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Tanzania",
    "code": "TZ",
    "capital": "Dodoma",
    "lat": -6,
    "long": 35,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/B8QoatPKAJg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ngorongoro_Conservation_Area"
        },
        "landmark": {
          "name": "Serengeti",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/acacia-tree-tanzania-safari-277352/",
          "wikiLink": "https://en.wikipedia.org/wiki/Serengeti",
          "hasLandmark": true
        },
        "city": {
          "name": "Dodoma",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Dodoma#/media/File:Beautiful_sunset_in_Dodoma_University_Tanzania.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Dodoma"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "ZzpYff3W",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mount-kilimanjaro-mountain-mount-7287226/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tanzania"
        },
        "landmark": {
          "name": "Mount Kilimanjaro",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mount-kilimanjaro-mountain-safari-1025146/",
          "wikiLink": "https://en.wikipedia.org/wiki/Mount_Kilimanjaro",
          "hasLandmark": true
        },
        "city": {
          "name": "Dar es Salaam",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/tanzania-dar-es-salaam-city-2042777/",
          "wikiLink": "https://en.wikipedia.org/wiki/Dar_es_Salaam"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "D8KdwN63",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Thailand",
    "code": "TH",
    "capital": "Bangkok",
    "lat": 15,
    "long": 100,
    "cities": [
      "Chiang Mai",
      "Phuket",
      "Bangkok",
      "Pattaya City",
      "Krabi",
      "Thani"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/BU6lABNbTpA",
          "wikiLink": "https://en.wikipedia.org/wiki/Phi_Phi_Islands"
        },
        "landmark": {
          "name": "The Grand Palace",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/grand-palace-bangkok-temple-night-1822487/",
          "wikiLink": "https://en.wikipedia.org/wiki/Grand_Palace",
          "hasLandmark": true
        },
        "city": {
          "name": "Chiang Mai",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/asia-street-city-everyday-life-7097815/",
          "wikiLink": "https://en.wikipedia.org/wiki/Chiang_Mai"
        },
        "landmarksRound": [
          {
            "code": "HU",
            "number": 2
          },
          {
            "code": "KZ",
            "number": 2
          },
          {
            "code": "DE",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Katse Dam",
          "Wat Xiengthong",
          "Buenos Aires Obelisk",
          "Olavinlinna",
          "Monument to the Heroes of the Restoration",
          "Monastery of St. Naum",
          "Middle of the World City"
        ],
        "uniqueId": "RvBMC8RN",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/grand-palace-bangkok-temple-night-1822487/",
          "wikiLink": "https://en.wikipedia.org/wiki/Bangkok"
        },
        "landmark": {
          "name": "Wat Arun",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bangkok-thailand-wat-arun-sunset-5477405/",
          "wikiLink": "https://en.wikipedia.org/wiki/Wat_Arun",
          "hasLandmark": true
        },
        "city": {
          "name": "Phuket",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/phuket-town-overlook-phuket-1731277/",
          "wikiLink": "https://en.wikipedia.org/wiki/Phuket_(city)"
        },
        "landmarksRound": [
          {
            "code": "JP",
            "number": 2
          },
          {
            "code": "ID",
            "number": 1
          },
          {
            "code": "LB",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Cape of Good Hope",
          "Dunn's River Falls",
          "Saint Sava Temple",
          "Alhambra",
          "Saqsayhuaman",
          "Stone House",
          "Neveh Shalom Synagogue"
        ],
        "uniqueId": "jbcdf5GZ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Timor-Leste",
    "code": "TL",
    "capital": "Dili",
    "lat": -8.83333333,
    "long": 125.9166667,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/mangrove-timor-leste-atauro-tree-7507812/",
          "wikiLink": "https://en.wikipedia.org/wiki/East_Timor"
        },
        "landmark": {
          "name": "Tatamailau",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Tatamailau#/media/File:View_to_Mt_Tatamailau,_Ainaro_on_a_clear_day.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tatamailau",
          "hasLandmark": true
        },
        "city": {
          "name": "Liquicia",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Liqui%C3%A7%C3%A1#/media/File:Liqui%C3%A7%C3%A1Igreja1.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Liqui%C3%A7%C3%A1"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "HC5gvByH",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Timor-Leste_Coastline.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/East_Timor"
        },
        "landmark": {
          "name": "Cristo Rei",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Cristo_Rei_of_Dili#/media/File:Dili_-_Christusstatue_'Cristo_Rei'.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Cristo_Rei_of_Dili",
          "hasLandmark": true
        },
        "city": {
          "name": "Dili",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Dili#/media/File:Aussenministerium_und_Avenida_de_Portugal.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Dili"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "FFdrrg9C",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Togo",
    "code": "TG",
    "capital": "Lome",
    "lat": 8,
    "long": 1.16666666,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/africa-togo-village-6170643/",
          "wikiLink": "https://en.wikipedia.org/wiki/Togo"
        },
        "landmark": {
          "name": "Independence Monument",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Independence_Monument,_Lome#/media/File:Lom%C3%A9-Monument_de_l'Ind%C3%A9pendance.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Independence_Monument,_Lome",
          "hasLandmark": true
        },
        "city": {
          "name": "Kpalime",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Kpalim%C3%A9#/media/File:Marchekpalime.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kpalim%C3%A9"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "cmXjChZ8",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/africa-togo-village-6170633/",
          "wikiLink": "https://en.wikipedia.org/wiki/Togo"
        },
        "landmark": {
          "name": "Palais de Congres",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Lom%C3%A9#/media/File:Buildings_in_Lom%C3%A9_(6717151255).jpg",
          "wikiLink": "https://fr.wikipedia.org/wiki/Palais_des_congr%C3%A8s_de_Lom%C3%A9",
          "hasLandmark": true
        },
        "city": {
          "name": "Lome",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Lom%C3%A9#/media/File:Blvd_des_Armee_Lome_(Lome,_TOGO_2019).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Lom%C3%A9"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "8ZSsMZ6Q",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Tokelau",
    "code": "TK",
    "capital": "Fakaofo",
    "lat": -9,
    "long": -172,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Tokelau_barge.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Tokelau"
        },
        "landmark": {
          "name": "Atafu",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/dfataustralianaid/12779814375",
          "wikiLink": "https://en.wikipedia.org/wiki/Atafu",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "J5qcs3Nd",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Aerial-view-tokelau-2012-photo-new-zealand-ministry-of-foreign-affairs-and-trade_12780239164_o.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Tokelau"
        },
        "landmark": {
          "name": "Fakaofo",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Fakaofo#/media/File:Fakaofo_Atoll.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Fakaofo",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "jTWxYwjp",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Tonga",
    "code": "TO",
    "capital": "Nuku'alofa",
    "lat": -20,
    "long": -175,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://www.britannica.com/place/Tonga/Economy",
          "wikiLink": "https://en.wikipedia.org/wiki/Tonga"
        },
        "landmark": {
          "name": "Mapu'a Vaea Blowholes",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mapu_a_Vaea#/media/File:Mapu_a_Vaea.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mapu_a_Vaea",
          "hasLandmark": true
        },
        "city": {
          "name": "Neiafu",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Neiafu_(Vava%CA%BBu)#/media/File:Neiafu.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Neiafu_(Vava%CA%BBu)"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "sGD3358s",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/shipwreck-ocean-sea-rusty-3821992/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tonga"
        },
        "landmark": {
          "name": "Ha'amonga'a Maui Trilithon",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ha%CA%BBamonga_%CA%BBa_Maui#/media/File:Ha%CA%BBamonga.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ha%CA%BBamonga_%CA%BBa_Maui",
          "hasLandmark": true
        },
        "city": {
          "name": "Nuku'alofa",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Nuku%CA%BBalofa#/media/File:Talamahu.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Nuku%CA%BBalofa"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "gBZ35hvZ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Trinidad and Tobago",
    "code": "TT",
    "capital": "Port of Spain",
    "lat": 11,
    "long": -61,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/DEMrRuvJvqo",
          "wikiLink": "https://en.wikipedia.org/wiki/Port_of_Spain"
        },
        "landmark": {
          "name": "Mount St. Benedict Abbey",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mount_Saint_Benedict#/media/File:TnT_St._Augustine_Mount_St._Benedict.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mount_Saint_Benedict",
          "hasLandmark": true
        },
        "city": {
          "name": "San Fernando",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/San_Fernando,_Trinidad_and_Tobago#/media/File:STAPP_022_San_Fernando_San_Fernando_Hill.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/San_Fernando,_Trinidad_and_Tobago"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "g5k9yPTw",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/water-nature-landscape-panoramic-3274999/",
          "wikiLink": "https://en.wikipedia.org/wiki/Trinidad_and_Tobago"
        },
        "landmark": {
          "name": "Bamboo Cathedral",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Lighthouse_At_Fort_King_George_Tobago_%28145874753%29.jpeg",
          "wikiLink": "https://en.wikipedia.org/wiki/Scarborough,_Tobago",
          "hasLandmark": true
        },
        "city": {
          "name": "Port of Spain",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Port_of_Spain#/media/File:QPSpicfromhilton23.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Port_of_Spain"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Wytv6mYc",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Tunisia",
    "code": "TN",
    "capital": "Tunis",
    "lat": 34,
    "long": 9,
    "cities": [
      "Tunis",
      "Kairoan",
      "Gabes",
      "Sousse",
      "Hammamet",
      "Sfax"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/5NqUXa6pZDM",
          "wikiLink": "https://en.wikipedia.org/wiki/Sidi_Bou_Said"
        },
        "landmark": {
          "name": "Ampitheater of El Jem",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Amphitheatre_of_El_Jem#/media/File:El_Djem_Amphitheater_aerial_view.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Amphitheatre_of_El_Jem",
          "hasLandmark": true
        },
        "city": {
          "name": "Tunis",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/tunis-tunisia-cityscape-city-106951/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tunis"
        },
        "landmarksRound": [
          {
            "code": "GL",
            "number": 2
          },
          {
            "code": "BH",
            "number": 2
          },
          {
            "code": "GQ",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Deadvlei",
          "Souq Waqif",
          "Blue Grotto",
          "Huka Falls",
          "Tavarua",
          "Pamir Mountains",
          "Baiterek"
        ],
        "uniqueId": "t3VQqwqK",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/tunis-tunisia-cityscape-city-106951/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tunis"
        },
        "landmark": {
          "name": "Baths of Antoninus",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Baths_of_Antoninus#/media/File:Antonine_Baths_at_Carthage.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Baths_of_Antoninus",
          "hasLandmark": true
        },
        "city": {
          "name": "Kairoan",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Kairouan#/media/File:Imgp7579a.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kairouan"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "V3f5rKq7",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Turkey",
    "code": "TR",
    "capital": "Ankara",
    "lat": 39,
    "long": 35,
    "cities": [
      "Ankara",
      "Izmir",
      "Istanbul",
      "Sanliurfa",
      "Konya",
      "Bursa"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/bBF9e2UUh88",
          "wikiLink": "https://en.wikipedia.org/wiki/Cappadocia"
        },
        "landmark": {
          "name": "The Blue Mosque",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/istanbul-turkey-m-islam-4455899/",
          "wikiLink": "https://en.wikipedia.org/wiki/Blue_Mosque,_Istanbul",
          "hasLandmark": true
        },
        "city": {
          "name": "Ankara",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-ankara-architecture-urban-4147937/",
          "wikiLink": "https://en.wikipedia.org/wiki/Ankara"
        },
        "landmarksRound": [
          {
            "code": "SI",
            "number": 1
          },
          {
            "code": "ME",
            "number": 1
          },
          {
            "code": "AD",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Rock of Cashel",
          "Vilnius Cathedral",
          "Arc de Triomphe",
          "Galle Fort",
          "Deception Island",
          "Charles Bridge",
          "Kim Il Sung Square"
        ],
        "uniqueId": "MGSFTBxV",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/river-sunset-maiden-s-tower-views-4873198/",
          "wikiLink": "https://en.wikipedia.org/wiki/Istanbul"
        },
        "landmark": {
          "name": "Hagia Sophia",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/hagia-sophia-turkey-istanbul-church-216471/",
          "wikiLink": "https://en.wikipedia.org/wiki/Hagia_Sophia",
          "hasLandmark": true
        },
        "city": {
          "name": "Izmir",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/izmir-panorama-tourism-bell-tower-3731303/",
          "wikiLink": "https://en.wikipedia.org/wiki/%C4%B0zmir"
        },
        "landmarksRound": [
          {
            "code": "EC",
            "number": 1
          },
          {
            "code": "CW",
            "number": 2
          },
          {
            "code": "HU",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Frogner Park",
          "Romney Manor",
          "Palace of Versailles",
          "Deadvlei",
          "Souq Waqif",
          "Roman theatre",
          "Gyeongbukgong Palace"
        ],
        "uniqueId": "VrccjNry",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Turkmenistan",
    "code": "TM",
    "capital": "Ashgabat",
    "lat": 40,
    "long": 60,
    "cities": [
      "Ashgabat",
      "Turkmenabat",
      "Kerki",
      "Balkanabat",
      "Dashoguz",
      "Mary"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Turkmenistan-Ashgabat.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ashgabat"
        },
        "landmark": {
          "name": "Ruhyyet Palace",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ruhy%C3%BDet_Palace#/media/File:Independence_Day_Parade_-_Flickr_-_Kerri-Jo_(160).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ruhy%C3%BDet_Palace",
          "hasLandmark": true
        },
        "city": {
          "name": "Ashgabat",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ashgabat#/media/File:Independence_Square,_Ashgabat.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ashgabat"
        },
        "landmarksRound": [
          {
            "code": "HR",
            "number": 1
          },
          {
            "code": "GB",
            "number": 2
          },
          {
            "code": "LV",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Taj Mahal",
          "Belvedere Lookout",
          "Big Ben",
          "Cologne Cathedral",
          "Iskanderkul",
          "Magnus Cathedral",
          "Sydney Opera House"
        ],
        "uniqueId": "mTRPPbCD",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Turkmenistan.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Turkmenistan"
        },
        "landmark": {
          "name": "Independence Monument",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Independence_Monument,_Ashgabat#/media/File:Independence_Monument_(1)_(45993949352).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Independence_Monument,_Ashgabat",
          "hasLandmark": true
        },
        "city": {
          "name": "Turkmenabat",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/T%C3%BCrkmenabat#/media/File:%D0%A6%D0%B5%D0%BD%D1%82%D1%80_%D0%A2%D1%83%D1%80%D0%BA%D0%BC%D0%B5%D0%BD%D0%B0%D0%B1%D0%B0%D0%B4%D0%B0.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/T%C3%BCrkmenabat"
        },
        "landmarksRound": [
          {
            "code": "BH",
            "number": 2
          },
          {
            "code": "PY",
            "number": 1
          },
          {
            "code": "BD",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Macau Tower",
          "Pyramiden",
          "English Harbour",
          "Great Blue Hole",
          "Grand Ducal Palace",
          "Deception Island",
          "Castle Cornet"
        ],
        "uniqueId": "4qZJcCfc",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Turks and Caicos Islands",
    "code": "TC",
    "capital": "Cockburn Town",
    "lat": 21.75,
    "long": -71.58333333,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/turks-caicos-island-teal-water-2307310/",
          "wikiLink": "https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands"
        },
        "landmark": {
          "name": "Grace Bay Beach",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands",
          "wikiLink": "https://commons.wikimedia.org/wiki/File:Sunset_in_Grace_Bay,_Turks_and_Caicos_Islands.jpg",
          "hasLandmark": true
        },
        "city": {
          "name": "Cockburn Town",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/124651729@N04/52374938636",
          "wikiLink": "https://en.wikipedia.org/wiki/Cockburn_Town"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "DYmhwwsM",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/turks-and-caicos-beach-caicos-ocean-1220019/",
          "wikiLink": "https://en.wikipedia.org/wiki/Turks_and_Caicos_Islands"
        },
        "landmark": {
          "name": "Cheshire Hall Plantation",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Cheshire_Hall_Plantation#/media/File:Main_House_ruins_at_Cheshire_Hall_plantation.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Cheshire_Hall_Plantation",
          "hasLandmark": true
        },
        "city": {
          "name": "Providenciales",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Providenciales#/media/File:Leeward_Highway_2.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Providenciales"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "M5T2v79V",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Tuvalu",
    "code": "TV",
    "capital": "Funafuti",
    "lat": -8,
    "long": 178,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sea-ocean-water-beach-nature-384055/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tuvalu"
        },
        "landmark": {
          "name": "Fongafale",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Tuvalu_Funafuti_Atoll.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Fongafale",
          "hasLandmark": false
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "w77V4tZR",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/dfataustralianaid/12779525995",
          "wikiLink": "https://en.wikipedia.org/wiki/Tuvalu"
        },
        "landmark": {
          "name": "Funafala",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Funafala#/media/File:Ocean_side_Funafuti.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Funafala",
          "hasLandmark": false
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "P4kX27fp",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Uganda",
    "code": "UG",
    "capital": "Kampala",
    "lat": 1,
    "long": 32,
    "cities": [
      "Kampala",
      "Jinja",
      "Gulu",
      "Mbarara",
      "Jinja",
      "Mbale"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/murchison-national-park-uganda-2111157/",
          "wikiLink": "https://en.wikipedia.org/wiki/Murchison_Falls_National_Park"
        },
        "landmark": {
          "name": "Kasubi Royal Tombs",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Kasubi_Tombs#/media/File:Kampala_Kasubi_Tombs.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Kasubi_Tombs",
          "hasLandmark": true
        },
        "city": {
          "name": "Kampala",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kampala-uganda-city-africa-capital-2376011/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kampala"
        },
        "landmarksRound": [
          {
            "code": "BH",
            "number": 1
          },
          {
            "code": "OM",
            "number": 1
          },
          {
            "code": "IT",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Church of Our Savior",
          "Wawel Castle",
          "Fisherman's Bastion",
          "The Marble Mountains",
          "Queen's Staircase",
          "Grand Ducal Palace",
          "The Blue Mosque"
        ],
        "uniqueId": "CJsRTQdm",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/lake-victoria-beach-africa-uganda-2108871/",
          "wikiLink": "https://en.wikipedia.org/wiki/Lake_Victoria"
        },
        "landmark": {
          "name": "Uganda National Mosque",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Uganda_National_Mosque#/media/File:Laika_ac_Gaddafi_National_Mosque,_Kampala_(6693328097).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Uganda_National_Mosque",
          "hasLandmark": true
        },
        "city": {
          "name": "Jinja",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Jinja,_Uganda#/media/File:Uganda_Jinja_Streetview.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Jinja,_Uganda"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "KHnBVpGQ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Ukraine",
    "code": "UA",
    "capital": "Kyiv",
    "lat": 49,
    "long": 32,
    "cities": [
      "Odesa",
      "Kharkiv",
      "Kherson",
      "Bakhmut",
      "Mariupol",
      "Kyiv"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/a6jaMBfDeoo",
          "wikiLink": "https://en.wikipedia.org/wiki/Kyiv_Pechersk_Lavra"
        },
        "landmark": {
          "name": "Golden Gate",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Golden_Gate,_Kyiv#/media/File:Golden_Gate,_Kyiv.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Golden_Gate,_Kyiv",
          "hasLandmark": true
        },
        "city": {
          "name": "Kyiv",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kiev-ukraine-city-sky-building-5202547/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kyiv"
        },
        "landmarksRound": [
          {
            "code": "NZ",
            "number": 2
          },
          {
            "code": "AM",
            "number": 1
          },
          {
            "code": "FK",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Stone House",
          "Romney Manor",
          "Patuxay Monument",
          "Old Dongola",
          "St. Olaf's Church",
          "Christiansborg Palace",
          "Fort Christian"
        ],
        "uniqueId": "3rwXsKss",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/river-buildings-street-kiev-6824576/",
          "wikiLink": "https://en.wikipedia.org/wiki/Kyiv"
        },
        "landmark": {
          "name": "Motherland Monument",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/kiev-ukraine-motherland-monument-2746806/",
          "wikiLink": "https://en.wikipedia.org/wiki/Motherland_Monument",
          "hasLandmark": true
        },
        "city": {
          "name": "Odesa",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Odesa#/media/File:Beach-Chayka-aerial-4.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Odesa"
        },
        "landmarksRound": [
          {
            "code": "TR",
            "number": 1
          },
          {
            "code": "MC",
            "number": 2
          },
          {
            "code": "IM",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Baiterek",
          "Amalienborg",
          "St. Tryphon's Cathedral",
          "Tash Rabat",
          "Pamir Mountains",
          "Buenos Aires Obelisk",
          "Olavinlinna"
        ],
        "uniqueId": "4C2Z4KHJ",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "United Arab Emirates",
    "code": "AE",
    "capital": "Abu Dhabi",
    "lat": 24,
    "long": 54,
    "cities": [
      "Abu Dhabi",
      "Dubai",
      "Al Ain",
      "Ajman",
      "Sharjah",
      "Ras Al-Khaimah"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/Fr6zexbmjmc",
          "wikiLink": "https://en.wikipedia.org/wiki/Burj_Khalifa"
        },
        "landmark": {
          "name": "Burj Khalifa",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/burj-khalifa-emirates-dubai-uae-2212978/",
          "wikiLink": "https://en.wikipedia.org/wiki/Burj_Khalifa",
          "hasLandmark": true
        },
        "city": {
          "name": "Abu Dhabi",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/abu-dhabi-city-skyline-emirates-1177898/",
          "wikiLink": "https://en.wikipedia.org/wiki/Abu_Dhabi"
        },
        "landmarksRound": [
          {
            "code": "AM",
            "number": 2
          },
          {
            "code": "CW",
            "number": 2
          },
          {
            "code": "GH",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Saint Michael's Cave",
          "Szechenyi Thermal Bath",
          "St. Olaf's Church",
          "Romney Manor",
          "Chimi Lhakhang",
          "Boudhanath",
          "Cape of Good Hope"
        ],
        "uniqueId": "ffR3c8nz",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/downtown-dubai-uae-tourism-city-4045035/",
          "wikiLink": "https://en.wikipedia.org/wiki/Dubai"
        },
        "landmark": {
          "name": "Dubai Frame",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/dubai-frame-landmark-architecture-6486776/",
          "wikiLink": "https://en.wikipedia.org/wiki/Dubai_Frame",
          "hasLandmark": true
        },
        "city": {
          "name": "Dubai",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/dubai-emirates-burj-khalifa-skyline-4044183/",
          "wikiLink": "https://en.wikipedia.org/wiki/Dubai"
        },
        "landmarksRound": [
          {
            "code": "VU",
            "number": 1
          },
          {
            "code": "BD",
            "number": 1
          },
          {
            "code": "LS",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Monastery of St. Naum",
          "Rock of Cashel",
          "Souq Waqif",
          "St. Tryphon's Cathedral",
          "Charles Bridge",
          "Voortrekker Monument",
          "Palace of Versailles"
        ],
        "uniqueId": "xbMqvVSX",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "United Kingdom",
    "code": "GB",
    "capital": "London",
    "lat": 54,
    "long": -2,
    "cities": [
      "Cardiff",
      "Liverpool",
      "London",
      "Edinburgh",
      "Birmingham",
      "Belfast"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/PhBF73HeEVM",
          "wikiLink": "https://en.wikipedia.org/wiki/Lairg"
        },
        "landmark": {
          "name": "Stonehenge",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/sunrise-stonehenge-ancient-sky-938998/",
          "wikiLink": "https://en.wikipedia.org/wiki/Stonehenge",
          "hasLandmark": true
        },
        "city": {
          "name": "Cardiff",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cardiff-city-buildings-sunrise-3891731/",
          "wikiLink": "https://en.wikipedia.org/wiki/Cardiff"
        },
        "landmarksRound": [
          {
            "code": "MY",
            "number": 1
          },
          {
            "code": "BY",
            "number": 1
          },
          {
            "code": "SA",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Acropolis",
          "Queen Emma Bridge",
          "Bellapais Abbey",
          "Villa Folio",
          "Frogner Park",
          "Vilnius Cathedral",
          "Leaning Tower of Pisa"
        ],
        "uniqueId": "pCBJCctF",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/castle-building-architecture-7728772/",
          "wikiLink": "https://en.wikipedia.org/wiki/Edinburgh"
        },
        "landmark": {
          "name": "Big Ben",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/architecture-big-ben-buildings-city-1866767/",
          "wikiLink": "https://en.wikipedia.org/wiki/Big_Ben",
          "hasLandmark": true
        },
        "city": {
          "name": "Liverpool",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/liverpool-landmark-cityscape-2538914/",
          "wikiLink": "https://en.wikipedia.org/wiki/Liverpool"
        },
        "landmarksRound": [
          {
            "code": "EC",
            "number": 1
          },
          {
            "code": "NC",
            "number": 2
          },
          {
            "code": "ME",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Cathedral of Brasilica",
          "Old Town Square",
          "Rizal Park",
          "Rila Monastery",
          "Wawel Castle",
          "House of the Black Heads",
          "Vianden Castle"
        ],
        "uniqueId": "scHrv42v",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "United States",
    "code": "US",
    "capital": "Washington D.C.",
    "lat": 38,
    "long": -97,
    "cities": [
      "Chicago",
      "Boston",
      "Washington D.C.",
      "Los Angeles",
      "Las Vegas",
      "Miami"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/-rNlq4IN_1k",
          "wikiLink": "https://en.wikipedia.org/wiki/United_states"
        },
        "landmark": {
          "name": "Golden Gate Bridge",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/golden-gate-bridge-san-francisco-4271360/",
          "wikiLink": "https://en.wikipedia.org/wiki/Golden_Gate_Bridge",
          "hasLandmark": true
        },
        "city": {
          "name": "Chicago",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/architecture-chicago-buildings-1869211/",
          "wikiLink": "https://en.wikipedia.org/wiki/Chicago"
        },
        "landmarksRound": [
          {
            "code": "IR",
            "number": 1
          },
          {
            "code": "DE",
            "number": 2
          },
          {
            "code": "HU",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Taj Mahal",
          "Liwonde National Park",
          "Tavarua",
          "Mostar Old Bridge",
          "Wat Xiengthong",
          "Pigeon Rocks",
          "Easter Island"
        ],
        "uniqueId": "hKLNHzlM",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/big-bend-texas-landscape-scenic-113099/",
          "wikiLink": "https://en.wikipedia.org/wiki/Big_Bend_(Texas)"
        },
        "landmark": {
          "name": "Gateway Arch",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/st-louis-st-louis-arch-illinois-996718/",
          "wikiLink": "https://en.wikipedia.org/wiki/Gateway_Arch",
          "hasLandmark": true
        },
        "city": {
          "name": "Boston",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/boston-massachusetts-faneuil-hall-1631460/",
          "wikiLink": "https://en.wikipedia.org/wiki/Boston"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "ZWlc9YnK",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "United States Virgin Islands",
    "code": "VI",
    "capital": "Charlotte Amalie",
    "lat": 18.35,
    "long": -64.933333,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Magens_Bay,_St._Thomas,_USVI.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Magens_Bay"
        },
        "landmark": {
          "name": "Annaberg Sugar Plantation",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Annaberg_Plantation_windmill.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Saint_John,_U.S._Virgin_Islands",
          "hasLandmark": true
        },
        "city": {
          "name": "Charlotte Amalie",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/usvi-st-thomas-charlotte-amalie-1154521/",
          "wikiLink": "https://en.wikipedia.org/wiki/Charlotte_Amalie,_U.S._Virgin_Islands"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "vjTRzHm2",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Cruz_Bay,_Saint_John;_United_States_Virgin_Islands.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Cruz_Bay,_U.S._Virgin_Islands"
        },
        "landmark": {
          "name": "Fort Christian",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Fort_Christian#/media/File:FortChristian.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Fort_Christian",
          "hasLandmark": true
        },
        "city": {
          "name": "Frederiksted",
          "photographer": "",
          "imageLink": "https://www.flickr.com/photos/prayitnophotography/22910031253",
          "wikiLink": "https://en.wikipedia.org/wiki/Frederiksted,_U.S._Virgin_Islands"
        },
        "landmarksRound": [
          {
            "code": "AF",
            "number": 1
          },
          {
            "code": "HU",
            "number": 1
          },
          {
            "code": "PR",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Al Fateh Grand Mosque",
          "Al Alam Palace",
          "Deadvlei",
          "Atomium",
          "Merry Cemetery",
          "Fasil Ghebbi",
          "Amalienborg"
        ],
        "uniqueId": "Z6RBqQQz",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Uruguay",
    "code": "UY",
    "capital": "Montevideo",
    "lat": -33,
    "long": -56,
    "cities": [
      "Salto",
      "Paysandu",
      "Ciudad de la Costa",
      "Maldonado",
      "Rivera",
      "Montevideo"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/Y_J9VezlMPc",
          "wikiLink": "https://en.wikipedia.org/wiki/La_Mano_de_Punta_del_Este"
        },
        "landmark": {
          "name": "Salvo Palace",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Palacio_Salvo#/media/File:Palacio_Salvo.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Palacio_Salvo",
          "hasLandmark": true
        },
        "city": {
          "name": "Montevideo",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/rambla-beach-montevideo-uruguay-613113/",
          "wikiLink": "https://en.wikipedia.org/wiki/Montevideo"
        },
        "landmarksRound": [
          {
            "code": "PH",
            "number": 1
          },
          {
            "code": "LA",
            "number": 2
          },
          {
            "code": "IR",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Sky Tower",
          "Dubai Frame",
          "Anne Frank House",
          "Rila Monastery",
          "Panfilov Park",
          "Vilnius Cathedral",
          "Palace of Versailles"
        ],
        "uniqueId": "rd5GTwcX",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/palacio-salvo-montevideo-uruguay-2830233/",
          "wikiLink": "https://en.wikipedia.org/wiki/Uruguay"
        },
        "landmark": {
          "name": "Solis Theater",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Sol%C3%ADs_Theatre#/media/File:2016_Columnas_del_Tatro_sol%C3%ADs_de_Montevideo.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Sol%C3%ADs_Theatre",
          "hasLandmark": true
        },
        "city": {
          "name": "Ciudad de la Costa",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Ciudad_de_la_Costa#/media/File:Puente_americas_canelones.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ciudad_de_la_Costa"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "zSdgG2Jt",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Uzbekistan",
    "code": "UZ",
    "capital": "Tashkent",
    "lat": 41,
    "long": 64,
    "cities": [
      "Tashkent",
      "Bukhara",
      "Samarkand",
      "Nukus",
      "Namangan",
      "Andijan"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/x-xwFxX2wVU",
          "wikiLink": "https://en.wikipedia.org/wiki/Samarkand"
        },
        "landmark": {
          "name": "Chorsu Bazaar",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Inside_Chorsu_bazaar_in_Tashkent,_Uzbekistan.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Chorsu_Bazaar",
          "hasLandmark": true
        },
        "city": {
          "name": "Bukhara",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Bukhara_city_center.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bukhara"
        },
        "landmarksRound": [
          {
            "code": "LI",
            "number": 1
          },
          {
            "code": "SK",
            "number": 2
          },
          {
            "code": "HN",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Fort Fincastle",
          "Vianden Castle",
          "Wieliczka Salt Mine",
          "Palace of Versailles",
          "Tomb of Hafez",
          "Stone House",
          "Rhine Falls"
        ],
        "uniqueId": "p5YGBQNd",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/bibi-xanom-mosque-samarkand-196944/",
          "wikiLink": "https://en.wikipedia.org/wiki/Uzbekistan"
        },
        "landmark": {
          "name": "Registan Square",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/uzbekistan-mosque-samarkand-2636505/",
          "wikiLink": "https://en.wikipedia.org/wiki/Registan",
          "hasLandmark": true
        },
        "city": {
          "name": "Tashkent",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/uzbekistan-tashkent-capital-city-4580836/",
          "wikiLink": "https://en.wikipedia.org/wiki/Tashkent"
        },
        "landmarksRound": [
          {
            "code": "VU",
            "number": 1
          },
          {
            "code": "FR",
            "number": 2
          },
          {
            "code": "VI",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Forbidden City",
          "George Washington House",
          "Christ Church Cathedral",
          "Cologne Cathedral",
          "Church of Saint Lazarus",
          "Golden Gate Bridge",
          "Boudhanath"
        ],
        "uniqueId": "dwrc4hrx",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Vanuatu",
    "code": "VU",
    "capital": "Port Vila",
    "lat": -16,
    "long": 167,
    "cities": [
      "Luganville",
      "Port Vila",
      "Port Orly",
      "Isangel",
      "Lakatoro",
      "Norsup"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/JxL6t8iVri4",
          "wikiLink": "https://en.wikipedia.org/wiki/Vanuatu"
        },
        "landmark": {
          "name": "Mount Yasur",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Mount_Yasur#/media/File:Mount_Yasur_eruption_2006,_Tanna_Island,_Vanuatu,_VAN_0516.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Mount_Yasur",
          "hasLandmark": true
        },
        "city": {
          "name": "Port Vila",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Port_Vila#/media/File:Port_Vila_aerial.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Port_Vila"
        },
        "landmarksRound": [
          {
            "code": "NL",
            "number": 1
          },
          {
            "code": "OM",
            "number": 2
          },
          {
            "code": "GT",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Dubai Frame",
          "Herat Citadel",
          "Santa Catalina Arch",
          "Huka Falls",
          "Burj Khalifa",
          "Hagia Sophia",
          "Blue Grotto"
        ],
        "uniqueId": "JNqrVsgJ",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/vanuatu-harbor-bay-water-shoreline-80771/",
          "wikiLink": "https://en.wikipedia.org/wiki/Vanuatu"
        },
        "landmark": {
          "name": "Champagne Beach",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Champagne_Beach_(Vanuatu)#/media/File:Champagne_Beach_Vanuatu.JPG",
          "wikiLink": "https://en.wikipedia.org/wiki/Champagne_Beach_(Vanuatu)",
          "hasLandmark": true
        },
        "city": {
          "name": "Luganville",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Luganville#/media/File:Main_Street_Luganville_(28661070193).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Luganville"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "BDhwgzCh",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Vatican City",
    "code": "VA",
    "capital": "Vatican City",
    "lat": 41.9,
    "long": 12.45,
    "cities": [
      "",
      "",
      "",
      "",
      "",
      ""
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/mJDuO7CzO74",
          "wikiLink": "https://en.wikipedia.org/wiki/St._Peter%27s_Basilica"
        },
        "landmark": {
          "name": "Sistine Chapel",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Sistine_Chapel#/media/File:Veduta_della_cappella_sistina_dai_giardini_vaticani,_03.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Sistine_Chapel",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [
          {
            "code": "AL",
            "number": 1
          },
          {
            "code": "MU",
            "number": 2
          },
          {
            "code": "GT",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "St. Tryphon's Cathedral",
          "Neveh Shalom Synagogue",
          "Ulu Temburong National Park",
          "Annandale Waterfall",
          "Tomb of Hafez",
          "Ouzoud Falls",
          "South Pole (ceremonial)"
        ],
        "uniqueId": "zHv3wmtm",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/vatican-rome-st-peters-basilica-594612/",
          "wikiLink": "https://en.wikipedia.org/wiki/Vatican_City"
        },
        "landmark": {
          "name": "St. Peter's Basilica",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/st-peter-s-basilica-rome-catholic-2677061/",
          "wikiLink": "https://en.wikipedia.org/wiki/St._Peter%27s_Basilica",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "DQzl7yj9",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Venezuela",
    "code": "VE",
    "capital": "Caracas",
    "lat": 8,
    "long": -66,
    "cities": [
      "Caracas",
      "La Plata",
      "Buenos Aires",
      "Merida",
      "Ciudad Bolivar",
      "Barquisimeto"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/ZZeuF8ZLi3o",
          "wikiLink": "https://en.wikipedia.org/wiki/Venezuela"
        },
        "landmark": {
          "name": "Plaza Francia",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Plaza_Francia,_Buenos_Aires#/media/File:Plaza_Francia,_Recoleta.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Plaza_Francia,_Buenos_Aires",
          "hasLandmark": true
        },
        "city": {
          "name": "La Plata",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/La_Plata#/media/File:Casco_urbano_fundacional_de_la_Ciudad_de_La_Plata.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/La_Plata"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "YYXrnhl4",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/caracas-venezuela-sunset-clouds-4539664/",
          "wikiLink": "https://en.wikipedia.org/wiki/Caracas"
        },
        "landmark": {
          "name": "Angel Falls",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Angel_Falls#/media/File:Angel_falls_panoramic_20080314.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Angel_Falls",
          "hasLandmark": true
        },
        "city": {
          "name": "Buenos Aires",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/buenos-aires-argentina-obelisk-2437858/",
          "wikiLink": "https://en.wikipedia.org/wiki/Buenos_Aires"
        },
        "landmarksRound": [
          {
            "code": "CA",
            "number": 1
          },
          {
            "code": "PT",
            "number": 2
          },
          {
            "code": "GE",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Charles Bridge",
          "Burj Khalifa",
          "Umayyad Mosque",
          "Marcus Aurelius Arch",
          "Golden Gate Bridge",
          "Uyuni Salt Flat",
          "Saqsayhuaman"
        ],
        "uniqueId": "pM7LbFfv",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Vietnam",
    "code": "VN",
    "capital": "Hanoi",
    "lat": 16.16666666,
    "long": 107.8333333,
    "cities": [
      "Hanoi",
      "Haiphong",
      "Da Nang",
      "Ho Chi Minh City",
      "Can Tho",
      "Hue"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/vcu-OZBxxRk",
          "wikiLink": "https://en.wikipedia.org/wiki/H%E1%BA%A1_Long_Bay"
        },
        "landmark": {
          "name": "Temple of Literature",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/the-memorial-of-literature-7169179/",
          "wikiLink": "https://en.wikipedia.org/wiki/Temple_of_Literature,_Hanoi",
          "hasLandmark": true
        },
        "city": {
          "name": "Haiphong",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Haiphong,_Vietnam_-_panoramio_%288%29.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Haiphong"
        },
        "landmarksRound": [
          {
            "code": "CA",
            "number": 1
          },
          {
            "code": "AU",
            "number": 1
          },
          {
            "code": "BT",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Pyramiden",
          "Parthenon",
          "Voortrekker Monument",
          "Souq Waqif",
          "Tsingy De Bemaraha",
          "Kittiwake Shipwreck",
          "Monteverde Cloud Forest"
        ],
        "uniqueId": "WgSMwwWl",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/view-landscape-nature-vietnam-2843338/",
          "wikiLink": "https://en.wikipedia.org/wiki/Vietnam"
        },
        "landmark": {
          "name": "The Marble Mountains",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/marble-mountain-mountain-vietnam-4470296/",
          "wikiLink": "https://en.wikipedia.org/wiki/Marble_Mountains_(Vietnam)",
          "hasLandmark": true
        },
        "city": {
          "name": "Da Nang",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/cityscape-danang-pretty-3716797/",
          "wikiLink": "https://en.wikipedia.org/wiki/Da_Nang"
        },
        "landmarksRound": [
          {
            "code": "CY",
            "number": 2
          },
          {
            "code": "BA",
            "number": 1
          },
          {
            "code": "KR",
            "number": 2
          }
        ],
        "landmarkPlaces": [
          "Chamarel Waterfall",
          "Shwedagon Pagoda",
          "Great Blue Hole",
          "Chorsu Bazaar",
          "National Shrine Basilica of our Lady of Las Lajas",
          "Palace of Versailles",
          "Tavarua"
        ],
        "uniqueId": "6zQmtvTH",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Wallis and Futuna",
    "code": "WF",
    "capital": "Mata-Utu",
    "lat": -13.3,
    "long": -176.2,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Mata-Utu_seen_from_a_drone_%28Wallis_and_Futuna%29.png",
          "wikiLink": "https://en.wikipedia.org/wiki/Mata_Utu"
        },
        "landmark": {
          "name": "Lake Lalolalo",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Lac_Lalolalo_Sept._2018_drone.png",
          "wikiLink": "https://en.wikipedia.org/wiki/Lake_Lalolalo",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "Jzsx6T6h",
        "hasMapAndCityRound": false
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pxhere.com/en/photo/1642780",
          "wikiLink": "https://en.wikipedia.org/wiki/Wallis_and_Futuna"
        },
        "landmark": {
          "name": "Mata-Utu Cathedral",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Cath%C3%A9drale_de_Mata-Utu_%28Wallis-et-Futuna%29_drone.png",
          "wikiLink": "https://en.wikipedia.org/wiki/Cathedral_of_Our_Lady_of_the_Assumption,_Mata-Utu",
          "hasLandmark": true
        },
        "city": {
          "name": "",
          "photographer": "",
          "imageLink": "",
          "wikiLink": ""
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "2T9h33Vw",
        "hasMapAndCityRound": false
      }
    ]
  },
  {
    "name": "Western Sahara",
    "code": "EH",
    "capital": "El Aaiún",
    "lat": 24.5,
    "long": -13,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Western_sahara_landscape.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Western_Sahara"
        },
        "landmark": {
          "name": "Cintra Bay",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Cintra_Bay#/media/File:Western_Sahara,_2007-12-25.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Cintra_Bay",
          "hasLandmark": true
        },
        "city": {
          "name": "Dakhla",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Dakhla,_Western_Sahara#/media/File:Dakhla,_Western_Sahara_(11).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Dakhla,_Western_Sahara"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "YgcfFhrT",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://www.jns.org/report-israel-us-consider-deal-to-recognize-moroccan-sovereignty-over-western-sahara/",
          "wikiLink": "https://en.wikipedia.org/wiki/Western_Sahara"
        },
        "landmark": {
          "name": "People's Liberation Army Museum",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/People%27s_Liberation_Army_Museum#/media/File:Mus%C3%A9e_RASD_Polisario.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/People%27s_Liberation_Army_Museum",
          "hasLandmark": true
        },
        "city": {
          "name": "Smara",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Smara#/media/File:Smara,rooftopE.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Smara"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "d9mX4nXg",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Yemen",
    "code": "YE",
    "capital": "Sana'a",
    "lat": 15,
    "long": 48,
    "cities": [
      "Sana'a",
      "Taizz",
      "Zinjibar",
      "Aden",
      "Ibb",
      "Al Mukalla"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/REwZEYzw19g",
          "wikiLink": "https://en.wikipedia.org/wiki/Dracaena_cinnabari"
        },
        "landmark": {
          "name": "Stone House",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Dar_al-Hajar#/media/File:Dar_al_hajar_edit.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Dar_al-Hajar",
          "hasLandmark": true
        },
        "city": {
          "name": "Sana'a",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-sana-a-yemen-5046666/",
          "wikiLink": "https://en.wikipedia.org/wiki/Sanaa"
        },
        "landmarksRound": [
          {
            "code": "MQ",
            "number": 1
          },
          {
            "code": "RE",
            "number": 1
          },
          {
            "code": "GL",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Christ the Redeemer",
          "Souq Waqif",
          "Golden Gate",
          "Ampitheater of El Jem",
          "Taj Mahal",
          "St. Alexander Nevsky Cathedral",
          "Chimi Lhakhang"
        ],
        "uniqueId": "rcVccWDV",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/yemen-middle-east-neighborhood-6037293/",
          "wikiLink": "https://en.wikipedia.org/wiki/Yemen"
        },
        "landmark": {
          "name": "Detwah Lagoon",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Detwah_Lagoon#/media/File:Detwah_lagoon_(6408178947).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Detwah_Lagoon",
          "hasLandmark": true
        },
        "city": {
          "name": "Taizz",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Taiz#/media/File:Taiz_(15182373707).jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Taiz"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "zjGcfP2g",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Zambia",
    "code": "ZM",
    "capital": "Lusaka",
    "lat": -15,
    "long": 30,
    "cities": [],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/zambia-sky-clouds-mountains-lake-94526/",
          "wikiLink": "https://en.wikipedia.org/wiki/Zambia"
        },
        "landmark": {
          "name": "Victoria Falls",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/rainbow-waterfall-water-landscape-3320571/",
          "wikiLink": "https://en.wikipedia.org/wiki/Victoria_Falls",
          "hasLandmark": true
        },
        "city": {
          "name": "Ndola",
          "photographer": "",
          "imageLink": "https://commons.wikimedia.org/wiki/File:Ndola_view_-_Flickr.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Ndola"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "29CWYrvy",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/rainbow-waterfall-water-landscape-3320571/",
          "wikiLink": "https://en.wikipedia.org/wiki/Victoria_Falls"
        },
        "landmark": {
          "name": "Chishimba Falls",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/falls-rocks-water-leaves-evening-7289265/",
          "wikiLink": "https://en.wikipedia.org/wiki/Chisimba_Falls",
          "hasLandmark": true
        },
        "city": {
          "name": "Lusaka",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/lusaka-skyline-morning-6228562/",
          "wikiLink": "https://en.wikipedia.org/wiki/Lusaka"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "5mkSySTP",
        "hasMapAndCityRound": true
      }
    ]
  },
  {
    "name": "Zimbabwe",
    "code": "ZW",
    "capital": "Harare",
    "lat": -20,
    "long": 30,
    "cities": [
      "Bulawayo",
      "Harare",
      "Chitungwiza",
      "Mutare",
      "Marondera",
      "Chinhoyi"
    ],
    "game": [
      {
        "number": 1,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://unsplash.com/photos/JCFpOA5gnRA",
          "wikiLink": "https://en.wikipedia.org/wiki/Lake_Kariba"
        },
        "landmark": {
          "name": "Victoria Falls Bridge",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/victoria-falls-bird-s-eye-view-4857680/",
          "wikiLink": "https://en.wikipedia.org/wiki/Victoria_Falls_Bridge",
          "hasLandmark": true
        },
        "city": {
          "name": "Bulawayo",
          "photographer": "",
          "imageLink": "https://en.wikipedia.org/wiki/Bulawayo#/media/File:Bulawayo_CBD.jpg",
          "wikiLink": "https://en.wikipedia.org/wiki/Bulawayo"
        },
        "landmarksRound": [],
        "landmarkPlaces": [],
        "uniqueId": "SZmwmn4J",
        "hasMapAndCityRound": true
      },
      {
        "number": 2,
        "mainImage": {
          "name": "",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/pond-field-mountains-trees-meadow-6075837/",
          "wikiLink": "https://en.wikipedia.org/wiki/Zimbabwe"
        },
        "landmark": {
          "name": "Matobo National Park",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/matobo-hills-granite-rock-6574279/",
          "wikiLink": "https://en.wikipedia.org/wiki/Matobo_National_Park",
          "hasLandmark": true
        },
        "city": {
          "name": "Harare",
          "photographer": "",
          "imageLink": "https://pixabay.com/photos/city-zimbabwe-harare-568208/",
          "wikiLink": "https://en.wikipedia.org/wiki/Harare"
        },
        "landmarksRound": [
          {
            "code": "GT",
            "number": 2
          },
          {
            "code": "CW",
            "number": 1
          },
          {
            "code": "PT",
            "number": 1
          }
        ],
        "landmarkPlaces": [
          "Wat Xiengthong",
          "Green Grotto Caves",
          "Voortrekker Monument",
          "Al Alam Palace",
          "Motherland Monument",
          "Arecibo Observatory",
          "Lotto World"
        ],
        "uniqueId": "jRKgzR2Z",
        "hasMapAndCityRound": true
      }
    ]
  }
]
