import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, take } from 'rxjs';
import { UserDetails } from 'src/app/interfaces/UserDetails';
import { Country, Game, Stats } from 'src/app/interfaces/interfaces';
import { CountriesService } from 'src/app/services/countries.service';
import { DataService } from 'src/app/services/data.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
})
export class GameComponent implements OnInit, AfterViewInit {
  state$: Observable<Game>;
  stats$: Observable<Stats>;
  user$: Observable<UserDetails>;
  places: Country[];

  constructor(
    private data: DataService,
    private countries: CountriesService,
    private title: Title,
    private route: ActivatedRoute,
    private user: UserService,
    private router: Router
  ) {
    this.state$ = this.data.getState();
    this.places = this.countries.getCountries();
    this.title.setTitle('WhereTaken');
    this.stats$ = this.user.getStats();
    this.user$ = this.user.getUserDetails();
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(take(1)).subscribe(val => {
      if (val['game']) {
        this.user$.pipe(take(1)).subscribe(user => {
          if(user?.subscription?.isPremium) {
            this.data.setArchiveGame(val['game']);
            this.user.setArchiveGame(true);
          } else {
            this.router.navigate(['/archive']);
          }
        });
      }
    });
    this.data.createGame();
  }

  ngAfterViewInit() {
    this.data.updatePreviousPage("/");
  }
}
