import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';
import { GameProgressStat, StatType, Stats } from '../interfaces/interfaces';
import { PlatformService } from './platform.service';


@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(private http: HttpClient, private platform: PlatformService) { }

  logGameProgress(data: GameProgressStat) {
    if (!this.platform.isInBrowser()) return;
    const ga4id = localStorage.getItem("ga:clientId") ?? "";

    const newData: StatType = {
      puzzle: data.puzzle,
      message: "wheretaken",
      guesses: data.guesses.join(","),
      status: data.status,
      language: `${data.language}:${ga4id}`,
    };
    this.update(newData).pipe(take(1)).subscribe();
  }

  update(payload: any): Observable<any> {
    return this.http.post<StatType>(
      "https://teuteuf-api.pages.dev/api/stats/log",
      payload
    );
  }

  refreshTokens(): Observable<any> {
    return this.http.post(
      `${this.getServerURL()}/auth/refresh`,
      {},
      { withCredentials: true }
    );
  }

  saveStats(stats: Stats) {
    const payload = {
      stats: JSON.stringify(stats),
      gameId: "wheretaken"
    }
    return this.http.post(
      `${this.getServerURL()}/api/savestats`,
      payload,
      { withCredentials: true }
    );
  }

  getUserDetails(): Observable<any> {
    return this.http.get(
      `${this.getServerURL()}/api/getuser`,
      { withCredentials: true }
    );
  }

  getServerURL() {
    if (!this.platform.isInBrowser()) return;
    const href = window.location.href;
    let url = ""
    if(href.includes("localhost")) {
      url = "http://localhost:3000"
    } else if (href.includes("staging")) {
      url = "https://staging-auth.teuteuf.fr";
    } else {
      url = "https://auth.teuteuf.fr";
    }
    return url;
  }
}
