import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { countries } from 'src/app/data/countries';
import { randomPerms } from 'src/app/data/perms';
import { ArchiveGame } from 'src/app/interfaces/ArchiveGame';
import { UserDetails } from 'src/app/interfaces/UserDetails';
import { ArchiveService } from 'src/app/services/archive.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {

  user$: Observable<UserDetails>
  archiveGames: ArchiveGame[] = [];

  constructor(private user: UserService, private router: Router, private archive: ArchiveService) {
    this.user$ = this.user.getUserDetails();
  }

  ngOnInit(): void {
    this.archiveGames = this.archive.getArchiveGames();
  };

  goToArchiveGame(code: string) {
    this.router.navigate(["/"], { queryParams: { game: code }});
  }

  playRandomGame() {
    const random = Math.floor(Math.random() * this.archiveGames.length);
    const randomGame = this.archiveGames[random];
    this.router.navigate(["/"], { queryParams: { game: randomGame.code }});
  }
}
