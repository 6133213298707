<div *ngIf="user$ | async as user">
  <div *ngIf="!user.subscription?.isPremium"
    class="flex flex-col gap-6 items-center mb-6 mt-10 text-black dark:!text-white">
    <div class="text-center">
      <a href="https://account.teuteuf.fr/premium-sign-up" class="font-bold text-blue-700 dark:text-blue-500">Sign up
        for Teuteuf Premium</a>
      <p>Remove ads, play the archive and replay games.</p>
    </div>
    <div>
      <span><b>❤️ WHERE<span class="text-blue-600 dark:text-blue-300">TAKEN</span>?</b></span>
      <span> - </span>
      <a class="underline" href="https://semantle.com/junior" target="_blank">Try Semantle!</a>
    </div>
  </div>
</div>
